import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import {
  ApiError,
  OrganizationManagementService,
  OrganizationProfileUpdateRequest,
} from '@/open-api';

interface Config {
  onErrorCallback: (error: ApiError) => void;
  onSuccessCallback: () => void;
}

export const useEditCompanyProfileMutation = (
  config: Config
): UseMutationResult<
  unknown,
  ApiError,
  { id: string; data: OrganizationProfileUpdateRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: string;
      data: OrganizationProfileUpdateRequest;
    }) => {
      const response =
        await OrganizationManagementService.updateOrganizationProfile({
          id,
          requestBody: data,
        });
      return response;
    },

    onSuccess: () => {
      config?.onSuccessCallback();
      queryClient.invalidateQueries({ queryKey: ['companyProfile'] });
    },
    onError: (error: ApiError) => {
      config?.onErrorCallback(error);
    },
  });
};
