type Role =
  | 'CAE Viewer'
  | 'CAE Sys Admin'
  | 'Admin'
  | 'CAE Admin'
  | 'Training'
  | 'Records'
  | 'Finance'
  | 'CAE Instructor';

export function checkRoleMatch(roles: string[], currentRole: string): boolean {
  if (roles && roles.includes(currentRole)) {
    return true;
  }
  return false;
}

const INTERNAL_ROLES: Role[] = [
  'CAE Viewer',
  'CAE Sys Admin',
  'CAE Admin',
  'CAE Instructor',
];

export function checkCAESysAdminRole(roles: (string | undefined)[]): boolean {
  if (roles && roles.find(role => role === 'CAE Sys Admin')) {
    return true;
  }
  return false;
}
export function internalRoleMatching(
  roles: (string | undefined)[] | undefined
): boolean {
  if (!roles) {
    return false;
  }

  if (roles && roles.length) {
    for (let i = 0; i < roles.length; i += 1) {
      for (let j = 0; j < INTERNAL_ROLES.length; j += 1) {
        if (roles[i] === INTERNAL_ROLES[j]) {
          return true;
        }
      }
    }
  }
  return false;
}
