import { format, parseISO } from 'date-fns';
import { DaySchedule } from '../day-schedule/DaySchedule';
import './ScheduleTimeline.css';
import { TrainingDailySessions } from '../../types';

interface ScheduleTimelineContentProps {
  dailySessions: TrainingDailySessions[];
  trainingCenterCurrentDate: string;
}

function ScheduleTimelineContent({
  dailySessions,
  trainingCenterCurrentDate,
}: Readonly<ScheduleTimelineContentProps>): JSX.Element {
  const today = parseISO(trainingCenterCurrentDate);

  return (
    <div className="schedule-timeline">
      {Array.isArray(dailySessions) &&
        dailySessions.map(ds => {
          const x = parseISO(ds.currentDate);
          const dayName = format(x, 'ccc');
          const dayNumber = format(x, 'dd');
          const current =
            format(x, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd');

          return (
            <div
              className={current ? 'current' : ''}
              key={`day-${ds.currentDate}`}
            >
              <span className="day-line">
                <h6>
                  <span>{dayName}</span>
                  <span>{dayNumber}</span>
                </h6>
                <span className="day-pointer"></span>
              </span>
              <DaySchedule data={ds.sessions ?? []} />
            </div>
          );
        })}
    </div>
  );
}

export default ScheduleTimelineContent;
