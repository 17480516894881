import * as React from 'react';
import { Button } from '@cae/cobalt-react';
import { DocumentUploadRequest, UploadResult } from '@/open-api';

import { FileUpload } from '@/shared/components/file-upload/FileUpload';

import type { PostResponseStatus } from '@/shared/types';
import { SupportedFilesDialog } from './SupportedFilesDialog';

import { ACCEPTED_FILETYPE_MIMETYPE_MAPPING } from './constants';

import './SubmitDocumentsForm.css';

interface Props {
  maxFiles?: number;
  maxFileSize?: number;
  maxSize?: number;
  onCancel: (e: React.SyntheticEvent<HTMLDialogElement | Element>) => void;
  onSubmit: (data: DocumentUploadRequest) => void;
  status: PostResponseStatus;
  isLoading?: boolean;
  uploadResponse?: UploadResult[];
}

export function SubmitDocumentsForm({
  maxFiles = 10,
  maxFileSize,
  maxSize = 500,
  onCancel,
  onSubmit,
  status = { type: 'idle' },
  isLoading = false,
  uploadResponse,
}: Props): JSX.Element {
  const [files, setFiles] = React.useState<File[]>([]);
  const onFilesChange = (files: File[]): void => {
    setFiles(files);
  };
  const prepareSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    const data: DocumentUploadRequest = {
      files,
      notes: 'Short note',
      shouldAddCustomerAuthority: true,
    };
    if (typeof onSubmit === 'function') {
      onSubmit(data);
    }
  };

  return (
    <form
      action="."
      autoComplete="off"
      className="submit-documents"
      encType="multipart/form-data"
      method="post"
      noValidate
      onSubmit={prepareSubmit}
    >
      <fieldset>
        <legend>Upload file</legend>
        <p>Total upload size should not exceed {maxSize}MB.</p>
        <SupportedFilesDialog
          className="supported-file-types"
          options={ACCEPTED_FILETYPE_MIMETYPE_MAPPING.map(x => x[0])}
        />
        <FileUpload
          disabled={status.type === 'success'}
          onFilesChange={onFilesChange}
          maxFiles={maxFiles}
          maxFileSize={maxFileSize}
          maxSize={maxSize}
          variant="dnd"
          onLoading={isLoading}
          uploadResponse={uploadResponse}
        />
      </fieldset>
      <div className="form-actions">
        <Button type="button" variant="secondary" onClick={onCancel}>
          {uploadResponse?.every(result => result.success) ? `Close` : `Cancel`}
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={
            (status.type === 'pending' ||
              status.type === 'success' ||
              uploadResponse?.every(result => result.success)) ??
            files.length === 0
          }
          pending={status.type === 'pending'}
        >
          {status.type === 'pending'
            ? 'Submitting'
            : uploadResponse?.some(result => !result.success)
              ? 'Try again'
              : 'Submit'}
        </Button>
      </div>
    </form>
  );
}

SubmitDocumentsForm.displayName = 'SubmitDocumentsForm';
