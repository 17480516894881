import InvoicePaymentsAlertDetails from '@/features/finance/components/invoice-payments-alert-details/InvoicePaymentsAlertDetails';
import { InvoicePaymentsDetails } from '@/features/finance/components/invoice-payments-details/InvoicePaymentsDetails';
import { InvoicePaymentsDetailsData } from '@/features/finance/components/invoice-payments-details/InvoicePaymentsDetailsData';

import FinancePage from '@/pages/finance/FinancePage';
import { AlertsPage } from '@/shared/components/alerts-page/AlertsPage';

export const invoiceAndPaymentsRoutes = {
  path: 'finance',
  element: <FinancePage />,
  children: [
    {
      path: 'alerts',
      element: <AlertsPage navigateURL="/finance" />,
      children: [
        {
          path: ':type',
          element: <InvoicePaymentsAlertDetails />,
        },
      ],
    },
    {
      path: ':accountReceivableId',
      element: <InvoicePaymentsDetails />,
      children: [
        {
          index: true,
          element: <InvoicePaymentsDetailsData />,
        },
      ],
    },
  ],
};
