import { Button, Checkbox } from '@cae/cobalt-react';
import { useEffect, useState } from 'react';

function SurveyRedirectInfo({
  setShow,
  surveyLink,
}: {
  setShow: (arg: boolean) => void;
  surveyLink: string;
}): JSX.Element {
  const [countDown, setCountDown] = useState(3);
  const [checked, setChecked] = useState(false);

  const handleCloseSurvey = (e: React.SyntheticEvent): void => {
    e.stopPropagation();
    setShow(false);
  };

  useEffect(() => {
    if (countDown === 0) {
      if (checked) localStorage.setItem('survey-redirect', 'true');
      window.open(surveyLink, '_blank');
      setShow(false);
    }
  }, [countDown, checked, surveyLink, setShow]);

  setInterval(() => {
    setCountDown(() => countDown - 1);
  }, 1000);

  return (
    <div className="survey-info">
      <h2>Let us get you to survey</h2>
      <p>{`Opening in ${countDown}...`}</p>
      <Button
        onClick={handleCloseSurvey}
        variant="primary"
        className="survey-button"
      >
        Cancel
      </Button>
      <Checkbox
        field={{
          id: 'survey-redirect-checkbox',
          checked,
          onChange: () => setChecked(!checked),
        }}
        label="Do not show again"
        className="survey-checkbox"
      />
    </div>
  );
}

export default SurveyRedirectInfo;
