type K = string | number | symbol;
type N = { [k: K]: unknown };

/**
 * Creates a new object with properties of a given object reduced
 * by properties specified in array of keys.
 */
export function omit(obj: N, arr: string[]): N {
  const keys = Object.keys(obj);
  if (keys.length < 1) return {};

  return keys
    .filter(k => !arr.includes(k))
    .reduce((a: N, key: K) => ({ ...a, [key]: obj[key] }), {});
}

/**
 * Creates a new array based on a given array with/without a given value.
 */
export function toggleValue(v: string, a: string[]): string[] {
  if (a.includes(v)) {
    return a.filter((i: string) => i !== v);
  } else {
    const n = [...a];
    n.push(v);
    return n;
  }
}
