import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@cae/cobalt-react';
import { useGetCurrentOrganization } from '@/features/frame/api/useGetCurrentOrganization';
import { UserAccessResponse } from '@/open-api';
import LogoutButton from '@/features/user-menu/components/logout-button/LogoutButton';
import { useUserInfo } from '@/contexts/userInfo';
import './UserMenu.css';
import TermsAndConditionsView from './components/terms-and-conditions-view/TermsAndConditionsView';

interface ProfileLinkProps {
  profile: UserAccessResponse;
  toggleUserMenu: () => void;
}

export function ProfileLink({
  profile,
  toggleUserMenu,
}: ProfileLinkProps): JSX.Element {
  const navigate = useNavigate();
  const { isInternalUser } = useUserInfo();
  const handleProfileClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    toggleUserMenu();
    navigate('/my-profile/info');
  };

  const getInitials = (profileData: UserAccessResponse): string => {
    const { firstName, lastName } = profileData;
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }
    return '?';
  };

  return (
    <button
      type="button"
      className="btn-link-profile"
      onClick={handleProfileClick}
      tabIndex={0}
      data-toggle-menu="true"
      data-testid="manageProfile"
    >
      <Avatar elevation="base" initials={getInitials(profile)} size="sm" />
      <span className="hgroup">
        <b className="fullname">
          {profile.firstName} {profile.lastName}
        </b>
        <span className="username">{profile.username}</span>
      </span>
      {!isInternalUser && <span className="link-label">Manage profile</span>}
    </button>
  );
}

ProfileLink.displayName = 'ProfileLink';

interface UserMenuProps {
  profile: UserAccessResponse;
  toggleUserMenu: () => void;
  visible: boolean;
}

export const UserMenu = React.forwardRef<HTMLElement, UserMenuProps>(
  ({ profile, toggleUserMenu, visible = false }, ref): JSX.Element | null => {
    const { handleLogout } = useGetCurrentOrganization();

    const handleClick = (e: React.SyntheticEvent<HTMLElement>): void => {
      const target = e.target as HTMLElement;
      const { dataset } = target;
      if (dataset.toggleMenu === 'true') {
        toggleUserMenu();
      }
    };

    React.useEffect(() => {
      const handleLogoutEvent = (event: MessageEvent): void => {
        if (event.data.type === 'logout') {
          handleLogout('redirect');
        }
      };

      const logoutChannel = new BroadcastChannel('logout-channel');
      logoutChannel.addEventListener('message', handleLogoutEvent);

      return () => {
        logoutChannel.removeEventListener('message', handleLogoutEvent);
      };
    }, [handleLogout]);

    if (!profile.id || !profile.username) return null;

    return (
      <nav
        className="co-user-nav"
        data-testid="userMenuDropdown"
        onClick={handleClick}
        aria-hidden={!visible}
        ref={ref}
      >
        <ProfileLink profile={profile} toggleUserMenu={toggleUserMenu} />
        <LogoutButton handleLogout={handleLogout} />
        <TermsAndConditionsView />
      </nav>
    );
  }
);

UserMenu.displayName = 'UserMenu';
