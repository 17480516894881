import './DashboardTabs.css';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import DashboardChartWrapper from '../dashboard-chart-wrapper/DashboardChartWrapper';

export function DashboardTabs(): JSX.Element {
  const currentRoles = useCurrentRoles();
  return (
    <div className="dashboard-tabs">
      <h3>
        {currentRoles &&
        !currentRoles?.includes('Admin') &&
        !currentRoles?.includes('Training') &&
        currentRoles?.includes('Records')
          ? 'Your Records'
          : 'Your reservations'}
      </h3>

      <DashboardChartWrapper />
    </div>
  );
}

DashboardTabs.displayName = 'DashboardTabs';
