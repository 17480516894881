import { ScreenContent, ScreenHeader } from '@cae/cobalt-react';
import { MyProfileContainer, MyProfileAvatar } from '@/features/my-profile';
import './MyProfilePage.css';

function MyProfilePage(): JSX.Element {
  return (
    <ScreenContent className="my-profile--main">
      <ScreenHeader elevation="skim">
        <MyProfileAvatar />
      </ScreenHeader>
      <MyProfileContainer />
    </ScreenContent>
  );
}

export default MyProfilePage;
