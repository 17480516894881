import * as React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Modal, SplitPanel } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

import './AlertsPage.css';

export interface AlertsPageProps {
  navigateURL: string;
}

export function AlertsPage({ navigateURL }: AlertsPageProps): JSX.Element {
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const handleCloseModal = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate(`${navigateURL}`);
  };

  const handleToggleSplitPanel = (): void => {
    navigate(`${navigateURL}`);
  };

  return width <= 768 + 22.25 * 16 ? (
    <Modal
      animate
      className="common-alerts--dialog"
      dismissible
      onCancel={handleCloseModal}
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
    >
      <Outlet />
    </Modal>
  ) : (
    <SplitPanel
      collapsed={false}
      onToggle={() => {
        handleToggleSplitPanel();
      }}
      className="common--split-panel"
      size="sm"
    >
      <Outlet />
    </SplitPanel>
  );
}
