import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  InvoiceAndPaymentManagementService,
  PagedListOfInvoiceResponse,
} from '@/open-api';
import {
  InvoicePaymentsFilters,
  InvoicePaymentsStatus,
} from '../components/filter-modal/useFilterModalReducer';
import { format } from 'date-fns';
import useDebounce from '@/shared/hooks/useDebounce';
type StatusFilter = InvoicePaymentsStatus;
type SortOrder = Array<
  | '+type'
  | '+accountReceivableId'
  | '+transactionNumber'
  | '+legalEntityName'
  | '+dueDate'
  | '+issuedOn'
  | '-type'
  | '-accountReceivableId'
  | '-transactionNumber'
  | '-legalEntityName'
  | '-dueDate'
  | '-issuedOn'
>;
function useInvoiceAndPaymentsTableDataQuery({
  invoiceType,
  pageIndex,
  pageSize,
  filters,
  searchText,
}: {
  invoiceType: StatusFilter;
  pageIndex: number;
  pageSize: number;
  filters: InvoicePaymentsFilters;
  searchText: string;
}): UseQueryResult<PagedListOfInvoiceResponse> {
  const {
    invoiceDateRange,
    trainingDateRange,
    currency,
    platform,
    location,
    status,
  } = filters;
  const debouncedValue = useDebounce(searchText, 500);

  const sortOrder: SortOrder =
    invoiceType === 'All' ? ['-dueDate'] : ['+dueDate'];
  return useQuery({
    queryKey: [
      'invoiceAndPaymentsTable',
      pageIndex,
      pageSize,
      debouncedValue,
      invoiceDateRange,
      trainingDateRange,
      currency,
      platform,
      location,
      [invoiceType],
      status,
    ],
    queryFn: () =>
      InvoiceAndPaymentManagementService.getInvoicePayments({
        pageIndex,
        pageSize,
        sort: sortOrder,
        location,
        platform,
        currency,
        status: [invoiceType],
        paymentStatus: status,
        searchText: debouncedValue,
        startDueDate: invoiceDateRange[0]
          ? format(invoiceDateRange[0], 'yyyy-MM-dd')
          : undefined,
        endDueDate: invoiceDateRange[1]
          ? format(invoiceDateRange[1], 'yyyy-MM-dd')
          : undefined,
        issuedStartDate: trainingDateRange[0]
          ? format(trainingDateRange[0], 'yyyy-MM-dd')
          : undefined,
        issuedEndDate: trainingDateRange[1]
          ? format(trainingDateRange[1], 'yyyy-MM-dd')
          : undefined,
      }),
  });
}

export default useInvoiceAndPaymentsTableDataQuery;
