import { useUserInfo } from '@/contexts/userInfo';
import { DocumentType, DocumentFolderDto } from '@/open-api';
import { Link } from 'react-router-dom';

interface DocumentLocationProps {
  readonly type?: DocumentType;
  readonly parentFolders: DocumentFolderDto[] | undefined;
}

function getCompanyDocumentsRootFolderName(
  organizationName: string | undefined
): string {
  return organizationName
    ? `${organizationName} training documents`
    : 'Training documents';
}

function getDocumentLocationPath(
  type: DocumentType,
  parentFolders: DocumentFolderDto[] | undefined,
  organization: string
): string {
  let prefix = '';

  switch (type) {
    case DocumentType.CAE:
      prefix = 'CAE documents';
      break;
    case DocumentType.COMPANY:
      prefix = getCompanyDocumentsRootFolderName(organization);
      break;
    case DocumentType.SUBMITTED:
    case null:
      prefix = 'Submitted documents';
      break;
    default:
      prefix = '/';
  }

  if (parentFolders !== undefined && parentFolders?.length > 0) {
    prefix += '/';
  }

  const path =
    prefix + (parentFolders ?? []).map(folder => folder.name).join('/');
  return path;
}

function DocumentLocation({
  parentFolders,
  type,
}: DocumentLocationProps): JSX.Element {
  const { selectedOrganization } = useUserInfo();

  const documentType = type ?? DocumentType.SUBMITTED;
  const path = getDocumentLocationPath(
    documentType,
    parentFolders,
    selectedOrganization?.name ?? ''
  );
  return (
    <div className="document-location">
      <h6>Document location:</h6>
      <Link
        to={`/documents/${documentType}`}
        state={{ parentFolderId: parentFolders?.slice(-1)[0]?.id }}
      >
        <p
          className="file-location"
          title={path}
          data-testid="document-location-path"
        >
          {path.length > 24
            ? `…${path.slice(path.length - 24, path.length)}`
            : path}
        </p>
      </Link>
    </div>
  );
}

export default DocumentLocation;
