import * as React from 'react';

import type { ColumnDef, PaginationState } from '@tanstack/react-table';
import type { QueryStatus } from '@tanstack/react-query';
import { Icon, Tooltip } from '@cae/cobalt-react';

import { CheckAirmenPerPlatformDto } from '@/open-api';
import {
  CobaltTable,
  DataLoadingError,
  DataLoadingGrid,
  NoData,
} from '@/shared/components';

import { AirmenNumber } from '../airmen-number/AirmenNumber';

const columns: ColumnDef<CheckAirmenPerPlatformDto>[] = [
  {
    header: 'Location',
    accessorKey: 'location',
  },
  {
    header: 'Platform',
    accessorKey: 'platform',
  },
  {
    header: 'Approved check airmen',
    accessorKey: 'approvedCheckAirmen',
    cell: info => <AirmenNumber value={info.getValue<number>()} />,
  },
];

interface Props {
  data?: CheckAirmenPerPlatformDto[];
  queryStatus: QueryStatus;
  pageIndex: number;
  pageSize: number;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
}

export function InstructorsTable({
  data,
  queryStatus,
  pageIndex,
  pageSize = 10,
  setPagination,
  totalPages = 1,
}: Props): JSX.Element {
  return (
    <>
      <hgroup>
        <h3>Approved check airmen</h3>
        <Tooltip placement="bottom-start">
          <Tooltip.Trigger>
            <span className="instructor-information_icon">
              <Icon id="info-circle-solid" size="sm" />
            </span>
          </Tooltip.Trigger>
          <Tooltip.Content
            style={{
              borderRadius: '0.75rem',
              border: 0,
              boxShadow: 'var(--shadow--lifted-16)',
              margin: '0.75rem',
            }}
          >
            <p>
              Note that we will only flag risks for aircrafts you already have
              reservations booked against or already have a check airman
              approved for.
            </p>
          </Tooltip.Content>
        </Tooltip>
      </hgroup>
      {queryStatus === 'loading' && (
        <DataLoadingGrid numberRows={15} numberColumns={3} />
      )}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data && data.length < 1 && <NoData />}
      {'success' && data && data.length && (
        <CobaltTable
          className="instructors--table"
          data={data}
          columns={columns}
          handleRowClick={() => {}}
          sortable={false}
          pageIndex={pageIndex}
          pageSize={pageSize}
          paginationStyle={{
            backgroundColor: 'var(--white)',
            bottom: '0',
            position: 'sticky',
          }}
          setPagination={setPagination}
          totalPages={totalPages}
        />
      )}
    </>
  );
}
