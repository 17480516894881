import {
  CaeCustomerAuthority,
  OrganizationManagementService,
} from '@/open-api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetSelectedOrgAuthoritiesQuery(
  id?: string
): UseQueryResult<CaeCustomerAuthority[]> {
  const { documentauthorities } = useFlags();

  return useQuery<CaeCustomerAuthority[]>({
    queryKey: ['selectedOrganization', id, 'documents', 'authorities'],
    queryFn: async () =>
      OrganizationManagementService.getAuthorities({ id: id! }),
    enabled: !!id && !!documentauthorities,
  });
}
