import {
  Button,
  Icon,
  Modal,
  ScreenContent,
  ScreenHeader,
} from '@cae/cobalt-react';

import './InvoiceAndPaymentsPage.css';

import * as React from 'react';
import { Outlet } from 'react-router-dom';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { InvoiceAndPaymentsAlertCards } from '@/features/finance/components/invoice-payments-alert-cards/InvoiceAndPaymentsAlertCards';
import { InvoiceAndPaymentsTabs } from '@/features/finance/components/invoice-payments-tabs/InvoiceAndPaymentsTabs';
import { SearchInput } from '@/shared/components/search-input/SearchInput';
import { FilterModal } from '@/features/finance/components/filter-modal/FilterModal';
import useInvoicePaymentsFilter from '@/features/finance/hooks/useInvoicePaymentsFilter';
import { useInvoicePaymentsMetadataQuery } from '@/features/finance/api/useInvoicePaymentsMetadataQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BillsManagementCard from '@/features/finance/components/bills-management-card/BillsManagementCard';
import { useUserInfo } from '@/contexts/userInfo';
import FirstTimeUser from '@/features/first-time-user/FirstTimeUser';

export function InvoiceAndPaymentsPage(): JSX.Element {
  const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false);
  const toggleFiltersModal = (): void => setFiltersOpen(!filtersOpen);
  const [searchText, setSearchText] = React.useState<string>('');
  const { filters, resetFilters, toggleAllFilters, filterCounter } =
    useInvoicePaymentsFilter();
  const { isInternalUser } = useUserInfo();
  const filterCount = filterCounter();
  const { data: metadata = {}, isLoading } = useInvoicePaymentsMetadataQuery(
    filters,
    searchText
  );
  const { filterOptions = {}, counters: counts = {} } = metadata;
  const { invoiceandpaymentstable } = useFlags();

  const shouldHideBillsManagementCard = counts.all === 0 && counts.unPaid === 0;
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    setSearchText(value);
  };
  const isFilterApplied = filterCounter() > 0;
  return (
    <HasAccess permissions="view_invoice_list">
      <section className="page page-invoice-payments">
        <div className="page-wrapper">
          <ScreenHeader>
            <hgroup>
              <h2>Invoices and payment</h2>
            </hgroup>

            <div className="invoice-payments--header-cards">
              {<InvoiceAndPaymentsAlertCards />}

              {!shouldHideBillsManagementCard && <BillsManagementCard />}
            </div>

            <div className="toolbar">
              <div
                className="search-input"
                data-tooltip="Search by transaction number, reservation number, pilot name or po number."
              >
                <SearchInput
                  onChange={handleSearch}
                  onClear={e => {
                    e.preventDefault();
                    setSearchText('');
                  }}
                  value={searchText}
                />
              </div>

              <Button
                className="btn-filters"
                type="button"
                variant="ghost"
                onClick={toggleFiltersModal}
                disabled={isLoading}
              >
                <Icon
                  id={isFilterApplied ? 'filter-solid' : 'filter-outline'}
                  size="sm"
                />
                <span className="label">Filters</span>
                {filterCount > 0 && `(${filterCount})`}
              </Button>
            </div>
          </ScreenHeader>
          <ScreenContent>
            {invoiceandpaymentstable && (
              <InvoiceAndPaymentsTabs
                searchText={searchText}
                filters={filters}
                counts={counts}
              />
            )}
          </ScreenContent>
        </div>
        <Outlet />
        <Modal
          open={filtersOpen}
          onCancel={toggleFiltersModal}
          placement="right"
          dismissible
          size="md"
          className="filter--dialog"
        >
          {filtersOpen && (
            <FilterModal
              filterOptions={filterOptions}
              filters={filters}
              resetFilters={resetFilters}
              toggleAllFilters={toggleAllFilters}
              toggleFilterSection={toggleFiltersModal}
            />
          )}
        </Modal>
        {!isInternalUser && <FirstTimeUser />}
      </section>
    </HasAccess>
  );
}
