import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertMessage, Button, Dropdown, TextInput } from '@cae/cobalt-react';

import { ApiError, AuthorityRequest, CaeCustomerAuthority } from '@/open-api';

import { useAuthoritiesMutation } from './useAuthoritiesMutation';
import { ControlField } from '@/shared/components/form-adapters';

interface FormValues extends AuthorityRequest {}

const FormSchema = z.object({
  authorities: z.string().array().min(1, 'Select at least one authority'),
  certificateCode: z.string().max(10, 'At most 10 characters'),
});

interface FormProps {
  availableAuthorities?: string[];
  selectedAuthorities: Array<CaeCustomerAuthority>;
  onCancel: (e: React.SyntheticEvent) => void;
  onSuccess: () => void;
  organizationId: string;
}

export function Form({
  availableAuthorities = [],
  selectedAuthorities = new Array<CaeCustomerAuthority>(),
  onCancel,
  onSuccess,
  organizationId,
}: Readonly<FormProps>): JSX.Element {
  const [pending, setPending] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ApiError | null>(null);

  const authorityOptions = availableAuthorities.map(a => ({
    label: a,
    value: a,
  }));

  const mutation = useAuthoritiesMutation(organizationId, {
    onMutate: () => {
      setError(null);
      setPending(true);
    },
    onError: (error: ApiError) => {
      setPending(false);
      setError(error);
    },
    onSuccess: () => {
      setPending(false);
      onSuccess();
    },
  });

  const f = useForm<FormValues>({
    defaultValues: {
      authorities: selectedAuthorities.filter(a => a) ?? [],
      certificateCode: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(FormSchema),
  });

  const watchAuthorities = useWatch({
    control: f.control,
    name: 'authorities',
  });

  React.useEffect(() => {
    if (watchAuthorities.indexOf(CaeCustomerAuthority.FAA) < 0) {
      f.setValue('certificateCode', '');
    }
  }, [f, watchAuthorities]);

  const onSubmit = async (data: FormValues): Promise<void> => {
    mutation.mutate(data);
  };

  return (
    <form
      action="."
      method="post"
      autoComplete="off"
      noValidate
      onSubmit={f.handleSubmit(onSubmit)}
    >
      {mutation.status === 'error' && error !== null && (
        <AlertMessage
          type="error"
          heading="There was an error with your request"
        >
          <p>
            We apologize for this inconvenience. Application administrators have
            been notified and should try to resolve this issue shortly.
          </p>
        </AlertMessage>
      )}
      <div className="grid-12">
        <ControlField
          is={Dropdown}
          id="field-authorities"
          data-testid="select-authorities"
          name="authorities"
          label="Authority name(s)"
          multiple
          options={authorityOptions}
          valueDisplay="chips"
          control={f.control}
        />
        {watchAuthorities.includes(CaeCustomerAuthority.FAA) ? (
          <>
            <AlertMessage type="warning" heading="">
              <p>
                If you have FAA Part-135 training, please enter the Certificate
                Code below.
              </p>
            </AlertMessage>
            <ControlField
              is={TextInput}
              name="certificateCode"
              label="Certificate Code (optional)"
              control={f.control}
            />
          </>
        ) : null}
        {watchAuthorities.includes(CaeCustomerAuthority.ANAC_AR) ||
        watchAuthorities.includes(CaeCustomerAuthority.ANAC_BR) ||
        watchAuthorities.includes(CaeCustomerAuthority.TRANSPORT_CANADA) ? (
          <>
            <AlertMessage type="warning" heading="">
              <p>
                The authority you selected isn&rsquo;t supported yet. You can
                save this setting to your profile to help us grow authority
                support.
              </p>
            </AlertMessage>
            <span></span>
          </>
        ) : null}
      </div>
      <footer>
        <menu>
          <Button type="button" variant="ghost" onClick={onCancel}>
            Back to dashboard
          </Button>
          <Button type="submit" variant="primary" disabled={pending}>
            {!pending ? 'Save to profile' : 'Loading…'}
          </Button>
        </menu>
      </footer>
    </form>
  );
}
