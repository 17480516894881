import { FilterSection } from '@/shared/components';
import { Button } from '@cae/cobalt-react';
import {
  useFilterModalReducer,
  SubmittedDocumentsFilters,
} from './useFilterModalReducer';

export type FilterModalProps = {
  filterOptions: SubmittedDocumentsFilters;
  filters: SubmittedDocumentsFilters;
  resetFilters: () => void;
  toggleFilterSection: () => void;
  toggleAllFilters: (selected: SubmittedDocumentsFilters) => void;
};

function compareArrays(
  filters: ('submitted' | 'in-review' | 'completed')[],
  selectedValues: string[]
): boolean {
  if (!filters && selectedValues.length === 0) return true;
  if (!filters || !selectedValues) return false;
  if (filters.length !== selectedValues.length) return false;
  return filters.every(item => selectedValues.includes(item));
}

export function FilterModal({
  filterOptions,
  filters,
  toggleAllFilters,
  resetFilters,
  toggleFilterSection,
}: FilterModalProps): JSX.Element {
  const { selectedFilters, clearSelectedFilters, changeSelectedStatus } =
    useFilterModalReducer(filters);

  const handleApply = (): void => {
    toggleAllFilters(selectedFilters);
    toggleFilterSection();
  };

  const handleReset = (): void => {
    resetFilters();
    clearSelectedFilters();
  };

  return (
    <div className="filter-list">
      <h3>Filters</h3>
      <div className="filter-sections">
        <FilterSection
          label="Statuses"
          options={filterOptions?.status}
          selected={selectedFilters.status}
          onSelect={changeSelectedStatus}
        />
      </div>
      <div className="actions">
        <Button variant="ghost" onClick={handleReset}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleApply}
          disabled={compareArrays(filters.status, selectedFilters.status)}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}
