import { Button } from '@cae/cobalt-react';
import { NavLink } from 'react-router-dom';
import './UnsupportedAuthorities.css';

interface UnsupportedAuthoritiesProps {
  readonly onButtonClick: () => void;
}

export function UnsupportedAuthorities({
  onButtonClick,
}: UnsupportedAuthoritiesProps): JSX.Element {
  return (
    <div
      className="unsupported-authorities"
      data-testid="unsupported-authorities"
    >
      <span className="swirls-top-right"></span>
      <span className="swirls-bottom-left"></span>
      <div className="unsupported-authorities-content">
        <span className="unsupported-authorities-content-img"></span>
        <h2>Your authorities are not supported</h2>
        <p>
          We only support FAA, EASA and UKCAA in CAE Connect. Please contact
          your Account Executive or CAE representative to provide documents for
          other authorities.
        </p>
        <div className="unsupported-authorities-content-actions">
          <Button variant="primary" onClick={onButtonClick}>
            Update authorities
          </Button>
          <NavLink to="/documents/unsupported-authority?show-support-menu=true">
            <Button type="button" variant="transparent" onClick={() => {}}>
              Get help
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default UnsupportedAuthorities;
