import { ReservationFilters } from '../components/filter-modal/useFilterModalReducer';

export const enum Actions {
  resetFilter = 'reset-filters',
  toggleAllFilters = 'toggle-fleets-bases-ranks',
}

type ResetFilterAction = {
  type: Actions.resetFilter;
};

type ToggleAllFiltersAction = {
  type: Actions.toggleAllFilters;
  payload: ReservationFilters;
};

type ReservationFilterActions = ToggleAllFiltersAction | ResetFilterAction;

export function filtersReducer(
  state: ReservationFilters,
  action: ReservationFilterActions
): ReservationFilters {
  switch (action.type) {
    case Actions.toggleAllFilters:
      return {
        ...state,
        locations: action.payload.locations,
        platforms: action.payload.platforms,
        courseTypes: action.payload.courseTypes,
        etcaStatus: action.payload.etcaStatus,
        tsaStatus: action.payload.tsaStatus,
        scheduledStatus: action.payload.scheduledStatus,
        records: action.payload.records,
        dateRange: action.payload.dateRange,
        regulatoryAgencies: action.payload.regulatoryAgencies,
      };
    case Actions.resetFilter:
      return {
        locations: [],
        platforms: [],
        courseTypes: [],
        etcaStatus: [],
        tsaStatus: [],
        scheduledStatus: [],
        records: [],
        dateRange: [],
        regulatoryAgencies: [],
      };
    default:
      return state;
  }
}
