import { Modal } from '@cae/cobalt-react';

import * as React from 'react';
import TermsAndConditions from '../../../../assets/pdf/FINAL_Terms_and_Conditions_CAE_Account_2023_02_06.pdf';

function TermsAndConditionsView(): JSX.Element {
  const [showTermsAndConditions, setShowTermsAnsConditions] =
    React.useState(false);
  return (
    <>
      <button
        type="button"
        className="terms-conditions terms-conditions-btn"
        tabIndex={0}
        data-toggle-menu="true"
        onClick={() => setShowTermsAnsConditions(true)}
        data-testid="termsAndConditions"
      >
        Terms of Use and Privacy Policy
      </button>
      {showTermsAndConditions ? (
        <Modal
          className="terms-and-conditions-main"
          header={<h2>Terms and Conditions</h2>}
          onCancel={() => setShowTermsAnsConditions(false)}
          onConfirm={() => {}}
          dismissible
          open
          placement="center"
          size="xl"
        >
          <div style={{ width: '100%', height: '100%' }}>
            <object
              data={TermsAndConditions}
              aria-label="terms-and-conditions"
              width="100%"
              height="98%"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
}

export default TermsAndConditionsView;
