import { ScheduleMfePage } from '@/pages/schedule-mfe';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate } from 'react-router-dom';

const ScheduleMfeRoute: React.FC = () => {
  const { instructormyschedule } = useFlags();
  return instructormyschedule ? (
    <ScheduleMfePage />
  ) : (
    <Navigate to="/home" replace />
  );
};

export const scheduleMfeRoutes = {
  path: 'schedule',
  element: <ScheduleMfeRoute />,
};
