/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Training record status
 */
export enum RecordStatus {
  AVAILABLE = 'Available',
  PENDING = 'Pending',
  NOT_APPLICABLE = 'NotApplicable',
  HARD_COPY_ONLY = 'HardCopyOnly',
}
