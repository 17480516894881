import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  InstructorManagementService,
  InstructorProgramSpecificMetaDto,
} from '@/open-api';

export function useProgramMetadataQuery(
  program: string
): UseQueryResult<InstructorProgramSpecificMetaDto, Error> {
  return useQuery({
    queryKey: ['instructor', program, 'metadata'],
    queryFn: () =>
      InstructorManagementService.getInstructorCountAtTrainingCenterForProgram({
        program,
      }),
  });
}
