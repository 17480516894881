import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserAccessResponse, UserManagementService } from '@/open-api';

export function useGetProfileQuery(): UseQueryResult<
  UserAccessResponse,
  Error
> {
  return useQuery<UserAccessResponse, Error>({
    queryKey: ['profile'],
    queryFn: () => UserManagementService.getLoggedInUserInfo(),
  });
}
