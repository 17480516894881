import ScheduleTimelineContent from '../ScheduleTimelineContent';
import {
  ReservationCourse,
  ReservationTrainingLocation,
  TrainingSchedule,
} from '../../../types';
import { AlertMessage } from '@cae/cobalt-react';
import './ScheduleToPDF.css';
import { useLocation, useParams } from 'react-router-dom';
import { useSelectBasicInfo } from '../../../api/hooks';

interface ScheduleToPDFProps {
  schedule: TrainingSchedule;
  trainingLocation: ReservationTrainingLocation;
  course: ReservationCourse;
  startDate: string;
  endDate: string;
}

function ScheduleToPDF({
  schedule,
  trainingLocation,
  course,
  startDate,
  endDate,
}: Readonly<ScheduleToPDFProps>): JSX.Element {
  const { reservationId = '' } = useParams();
  const { data: basicInfo } = useSelectBasicInfo(reservationId);
  const location = useLocation();
  const { state: locationState } = location;

  return (
    <div className="schedule-pdf">
      <h1 className="schedule-pdf-title">{course.name}</h1>
      <AlertMessage
        type="info"
        heading="Live schedule, subject to change"
        className="alert"
      >
        <p>
          All permanent changes will be communicated to you by your Training
          Centre staff.
        </p>
      </AlertMessage>
      <div className="schedule-pdf-info">
        <div className="schedule-pdf-info-content">
          <p>
            From {startDate} to {endDate}
          </p>
          <p>{trainingLocation.name}</p>
          <p>
            {basicInfo?.clientName} (Reservation #
            {locationState.originalSchedulerReservationId})
          </p>
        </div>
        <div className="schedule-pdf-info-date">
          Downloaded on {new Date().toLocaleDateString()}
        </div>
      </div>

      <ScheduleTimelineContent
        dailySessions={schedule.dailySessions}
        trainingCenterCurrentDate={schedule.trainingCenterCurrentDate}
      />
    </div>
  );
}

export default ScheduleToPDF;
