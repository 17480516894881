import { Link, useParams } from 'react-router-dom';
import { Card, CardHeader } from '@cae/cobalt-react';
import {
  InvoiceAndPaymentsTilesCount,
  useGetInvoiceAndPaymentsTilesCountQuery,
} from '../../api/useGetInoviceTilesCountQuery';
import './InvoicePaymentsAlertDetails.css';
import CurrencyFormat from '@/shared/components/format-currency/FormatCurrency';
import InvoiceDownloadButton from '../invoice-download-button/InvoiceDownloadButton';

function getDescription(alertType: string, numberOfAlerts: number): string {
  const numberToDisplay = numberOfAlerts ? numberOfAlerts.toString() : '';
  let description = '';

  if (alertType === 'invoicesUpcomingDueList') {
    description = `You have ${numberToDisplay} alerts for invoices due in the next 7 days.`;
  } else if (alertType === 'invoicesPastDueList') {
    description = `You have ${numberToDisplay} alerts for invoices past due.`;
  }

  return description;
}

interface RenderSVGProps {
  alertType: string | undefined;
}
interface Alert {
  transactionNumber: string;
  amount: number;
  currency: string;
  accountReceivableId: string;
}

const RenderSVG: React.FC<RenderSVGProps> = ({
  alertType,
}): JSX.Element | null => {
  const renderSVGElement = (alertType: string): JSX.Element | null => {
    switch (alertType) {
      case 'invoicesPastDueList':
        return (
          <svg height="12" width="12" className="alert-danger-circle">
            <circle cx="6" cy="6" r="6" fill="var(--danger-color)" />
          </svg>
        );
      case 'invoicesUpcomingDueList':
        return (
          <svg height="12" width="12" className="alert-warning-circle">
            <circle cx="6" cy="6" r="6" fill="var(--warning-color)" />
          </svg>
        );
      default:
        return null;
    }
  };

  return renderSVGElement(alertType as string);
};

function InvoicePaymentsAlertDetails(): JSX.Element {
  const { type } = useParams<{ type: keyof InvoiceAndPaymentsTilesCount }>();
  const { data, status } = useGetInvoiceAndPaymentsTilesCountQuery();
  let alerts: Alert[] = [];

  if (data) {
    alerts =
      (data[type as keyof InvoiceAndPaymentsTilesCount] as Alert[]) || [];
  }
  return (
    <aside className="invoice-payments--alert-details">
      {status === 'loading' && <p>Loading alerts...</p>}
      {status === 'error' && <p>Error loading alerts</p>}
      {status === 'success' && (
        <>
          <h3>Your Alerts</h3>
          <p>{getDescription(type as string, alerts?.length)}</p>
          <div className="scroll-container">
            {alerts.map((alert: Alert) => (
              <Link
                to={`/finance/${alert.accountReceivableId}`}
                key={alert.transactionNumber}
              >
                <Card>
                  <CardHeader>
                    <hgroup>
                      <h4 className="invoice-payments--alert-details_transaction-number">
                        {alert.transactionNumber}
                      </h4>
                      <p>
                        <CurrencyFormat
                          value={alert.amount}
                          currency={alert.currency}
                        />
                      </p>
                    </hgroup>
                    <RenderSVG alertType={type} />
                  </CardHeader>

                  <button
                    type="button"
                    onClick={(e: React.SyntheticEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onKeyDown={(e: React.SyntheticEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <InvoiceDownloadButton
                      invoiceId={alert.accountReceivableId}
                      label="Download"
                      fileName={alert.transactionNumber}
                    />
                  </button>
                </Card>
              </Link>
            ))}
          </div>
        </>
      )}
    </aside>
  );
}

InvoicePaymentsAlertDetails.displayName = 'InvoicePaymentsAlertDetails';

export default InvoicePaymentsAlertDetails;
