import {
  InstructorApprovalRequestDto,
  InstructorManagementService,
} from '@/open-api';

export function useInstructorApproveQuery(): {
  approveInstructor: (data: InstructorApprovalRequestDto[]) => Promise<unknown>;
} {
  const approveInstructor = async (
    requestBody: InstructorApprovalRequestDto[]
  ): Promise<unknown> =>
    await InstructorManagementService.addApprovalRequests({ requestBody });
  return { approveInstructor };
}
