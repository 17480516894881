import { Card, Icon } from '@cae/cobalt-react';
import './MyProfileInfo.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/contexts/userInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeRequestBanner } from '../change-request-banner/ChangeRequestBanner';

export function MyProfileInfo(): JSX.Element {
  const profile = useUserInfo();
  const navigate = useNavigate();

  const { webappeditprofile } = useFlags();

  const openEditNameModal = (): void => {
    if (webappeditprofile) {
      navigate('change-name');
    }
  };

  return (
    <div className="profile-info">
      <h1 className="__personal-heading">Personal Information</h1>
      <ChangeRequestBanner />
      {profile ? (
        <div className="__cards-list">
          <Card>
            <h3>Email</h3>
            <p>{profile?.email}</p>
          </Card>
          <Card onClick={() => openEditNameModal()}>
            <h3>Full name and nickname</h3>
            <p>
              {profile?.firstName} {profile?.lastName}
            </p>
            <Icon
              className="__right-icon"
              id="chevron-right-outline"
              size="sm"
            />
          </Card>
          <Card>
            <h3>Phone number</h3>
            <Icon
              className="__right-icon"
              id="chevron-right-outline"
              size="sm"
            />
          </Card>
        </div>
      ) : (
        <p>Profile information not available</p>
      )}
      <Outlet />
    </div>
  );
}
