import { useParams, Link } from 'react-router-dom';
import { Card, CardHeader } from '@cae/cobalt-react';
import './DashboardRecordAlertCards.css';
import { useGetRecordsTilesCountQuery } from '../../api/useGetRecordsTileCountQuery';

type MissingRecord = {
  recordId?: string | undefined;
  courseName?: string | undefined;
  clientName?: string | undefined;
  endDate?: string | undefined;
  startDate?: string | undefined;
};

export type UpcomingReservation = {
  recordId?: string;
  courseName?: string;
  clientName?: string;
  startDate?: string;
  endDate?: string | undefined;
};

function getDescription(
  alertType: string,
  numberOfAlerts: number | undefined
): string {
  const numberToDisplay = numberOfAlerts ? numberOfAlerts.toString() : '';

  const descriptions: { [name: string]: string } = {
    missingRecordsCount: `You have ${numberToDisplay} digital records published in the last 30 days."`,
    upcomingReservationsCount: `You have ${numberToDisplay} reservations in progress (Training ending within 1 week).`,
  };
  return descriptions[alertType];
}

function renderMessage(
  alertDetailData: MissingRecord | UpcomingReservation
): JSX.Element {
  const dateOfTraining =
    'startDate' in alertDetailData
      ? alertDetailData?.startDate
      : alertDetailData?.endDate;
  let message = '';
  const today = new Date();
  const diffInMs = today.getTime() - Date.parse(dateOfTraining as string);
  const diffInDays = Math.ceil(diffInMs / (1000 * 3600 * 24));

  message = `Training ended ${diffInDays} days ${
    'endDate' in alertDetailData ? 'ago' : ''
  }`;

  if (dateOfTraining) {
    return (
      <div className="alert-card-footer">
        <div className="alert-card-footer">{message}</div>
      </div>
    );
  }
  return <></>;
}

export function DashboardRecordsAlertDetails(): JSX.Element {
  const { type } = useParams();
  const dataListType =
    type === 'missingRecordsCount'
      ? 'missingRecordsList'
      : 'upcomingReservationsList';
  const { data, status } = useGetRecordsTilesCountQuery();
  const missingRecordsCount = data?.missingRecordsCount;
  const upcomingReservationsCount = data?.upcomingReservationsCount;
  const count =
    type === 'missingRecordsCount'
      ? missingRecordsCount
      : upcomingReservationsCount;
  return (
    <aside className="dashboard-records--alert-details">
      {status === 'loading' && <p>Loading alerts...</p>}
      {status === 'error' && <p>Error loading alerts</p>}
      {status === 'success' &&
        ((data.missingRecordsList && data.missingRecordsList.length) ||
          (data.upcomingReservationsList &&
            data.upcomingReservationsList.length)) && (
          <>
            <h3>Your Alerts</h3>
            {type && <p>{getDescription(type, count)}</p>}
            <div className="scroll-container record-alerts--details-container">
              {data?.[dataListType]?.map(alert => {
                return (
                  <Link to={`/records/${alert.recordId}`} key={alert.recordId}>
                    <Card>
                      <CardHeader>
                        <hgroup>
                          <p>{alert.courseName}</p>
                          <h5>{alert.clientName}</h5>
                        </hgroup>
                        {type === 'missingRecordsCount' ? (
                          <svg
                            height="12"
                            width="12"
                            className="alert-danger-circle"
                          >
                            <circle
                              cx="6"
                              cy="6"
                              r="6"
                              fill="var(--danger-color)"
                            />
                          </svg>
                        ) : (
                          ''
                        )}
                      </CardHeader>
                      <p>{renderMessage(alert)}</p>
                    </Card>
                  </Link>
                );
              })}
            </div>
          </>
        )}
    </aside>
  );
}

DashboardRecordsAlertDetails.displayName = 'DashboardRecordsAlertDetails';
