import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Icon } from '@cae/cobalt-react';

import './InstructorsReportCards.css';

const reportTypes = [
  {
    name: '8440 IP Report',
    description:
      'Get a part-135 recommended contract simulator instructors report',
    path: '/instructors/ip-report',
  },
  {
    name: '8440 CA Report',
    description: 'Get a part-135 recommended contract simulator CA report',
    path: '/instructors/ca-report',
  },
  {
    name: '8470 Recurrent',
    description: 'Get a part-135 training summary instructors & check airmen',
    path: '/instructors/recurrent-report',
  },
  {
    name: 'Approved Instructors',
    description: 'Get report of all your approved instructors and check airmen',
    path: '/instructors/approved-instructors-report',
  },
];

export function InstructorsReportCards(): JSX.Element {
  const navigate = useNavigate();
  const handleOpenReport = (e: React.SyntheticEvent, path: string): void => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <div className="card-list">
      {reportTypes.map(r => (
        <Card
          key={r.name}
          onClick={(e: React.SyntheticEvent) => handleOpenReport(e, r.path)}
        >
          <h3>{r.name}</h3>
          <p>{r.description}</p>
          <Icon id="arrow-right-outline" size="sm" />
        </Card>
      ))}
    </div>
  );
}
