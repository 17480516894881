import * as React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Button, Icon } from '@cae/cobalt-react';
import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';
import SurveyRedirectModal from '@/features/survey-redirect-modal/SurveyRedirectModal';
import { internalRoleMatching } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { useUserInfo } from '@/contexts/userInfo';
import { CaeCustomerAuthority, UserPermissions } from '@/open-api';
import OrganizationSwitcher from '../organization-switcher/OrganizationSwitcher';
import { conditionalElement } from '@/shared/helpers/conditionalElement';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type NavigationItem = {
  children?: NavigationItem[];
  end?: boolean;
  iconName?: string;
  label: string;
  path: string;
  value?: string;
};

export type NavigationDefinition = {
  contextSwitch?: React.ReactNode;
  main: NavigationItem[];
  support: NavigationItem[];
};

function isFaa(authorities: CaeCustomerAuthority[]): boolean {
  return authorities.includes(CaeCustomerAuthority.FAA);
}

function SingleLink({ data }: Readonly<{ data: NavigationItem }>): JSX.Element {
  const location = useLocation();
  return (
    <li
      className={
        data.path !== '/' && location.pathname.startsWith(data.path)
          ? 'current'
          : data.path === '/' && location.pathname === '/'
            ? 'current'
            : 'not-current'
      }
    >
      <NavLink to={data.path} end={!!data.end}>
        {({ isActive }) => (
          <>
            {data.iconName && (
              <Icon id={`${data.iconName}-${isActive ? 'solid' : 'outline'}`} />
            )}
            <span className="label">{data.label}</span>
          </>
        )}
      </NavLink>
    </li>
  );
}

function NestedLink({ data }: Readonly<{ data: NavigationItem }>): JSX.Element {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(false);
  const { data: profileQuery } = useGetProfileQuery();
  let currentPermission =
    profileQuery &&
    profileQuery.userPermissions &&
    profileQuery?.userPermissions.find(
      (permission: UserPermissions) => permission.taskValue === data.value
    );
  if (currentPermission && currentPermission.enable === true) {
    return (
      <li
        data-expanded={expanded}
        className={
          location.pathname.startsWith(data.path) ? 'current' : 'not-current'
        }
      >
        <span className="nested">
          <NavLink
            to={data.path}
            end={data.path === '/'}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {({ isActive }) => (
              <>
                {data.iconName && (
                  <Icon
                    id={`${data.iconName}-${isActive ? 'solid' : 'outline'}`}
                  />
                )}
                <span className="label">{data.label}</span>
              </>
            )}
          </NavLink>
          <Button
            variant="icon"
            size="md"
            type="button"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Icon id={`chevron-${expanded ? 'up' : 'down'}-outline`} />
          </Button>
        </span>
        {expanded && (
          <ul>
            {Array.isArray(data.children) &&
              data.children.map((item, i) => {
                currentPermission =
                  profileQuery &&
                  profileQuery.userPermissions &&
                  profileQuery?.userPermissions.find(
                    (permission: UserPermissions) =>
                      permission.taskValue === item.value
                  );
                if (currentPermission && currentPermission.enable === true) {
                  return (
                    <li
                      key={`i-${i}`}
                      className={
                        location.pathname.startsWith(item.path)
                          ? 'current'
                          : 'not-current'
                      }
                    >
                      <NavLink to={item.path} end={data.path === '/'}>
                        <span className="label">{item.label}</span>
                      </NavLink>
                    </li>
                  );
                }
              })}
          </ul>
        )}
      </li>
    );
  }
  return <></>;
}

export function AppNavigation(): JSX.Element {
  const {
    selectedOrganization,
    authorities,
    isPart135Certified,
    isInternalUser,
  } = useUserInfo();
  const { documentauthorities, instructormyschedule, accessPageEnabledWebApp } =
    useFlags();

  const NAVIGATION: NavigationDefinition = React.useMemo(
    () => ({
      main: [
        {
          path: '/home',
          label: 'Home',
          iconName: 'home',
          end: true,
          value: 'common',
        },
        {
          path: '/reservations',
          label: 'Reservations',
          value: 'view_reservations',
          iconName: 'bulleted-list',
        },
        {
          path: '/records',
          label: 'Records',
          value: 'view_records_list',
          iconName: 'tasks',
        },
        {
          path: '/finance',
          label: 'Invoices and payments',
          value: 'view_invoice_list',
          iconName: 'document',
        },
        ...conditionalElement(instructormyschedule, {
          path: '/schedule',
          label: 'My Schedule',
          iconName: 'calendar',
          value: 'view_schedule',
        }),
        ...conditionalElement(accessPageEnabledWebApp, {
          path: '/accesspass',
          label: 'Access Pass',
          value: 'request_visitor_pass',
          iconName: 'qr-code',
        }),
        {
          path: '/documents/overview',
          label: 'Documents',
          value: 'view_documents_library',
          iconName: 'folder',
          children: [
            {
              label: 'Documents overview',
              path: '/documents/overview',
              value: 'view_documents_library',
            },
            ...conditionalElement(isFaa(authorities) || !documentauthorities, {
              label: `${selectedOrganization?.name} training documents`,
              path: '/documents/company',
              value: 'view_documents_library',
            }),
            {
              label: 'CAE documents',
              path: '/documents/cae',
              value: 'view_documents_library',
            },
            {
              label: 'Submitted documents',
              path: '/documents/submitted',
              value: 'view_documents_library',
            },
          ],
          end: true,
        },
        ...conditionalElement(isPart135Certified || !!isInternalUser, {
          path: '/instructors',
          label: 'Instructors',
          iconName: 'instructor',
          value: 'view_instructor_list',
        }),
        {
          path: '/users',
          label: 'Users',
          value: 'get_user',
          iconName: 'user-management',
        },
        {
          path: '/roles',
          label: 'Roles',
          value: 'modify_role',
          iconName: 'settings',
        },
      ],
      support: [],
    }),
    [
      selectedOrganization,
      authorities,
      documentauthorities,
      isPart135Certified,
      instructormyschedule,
      accessPageEnabledWebApp,
      isInternalUser,
    ]
  );

  const definition = NAVIGATION;
  const profileQuery = useGetProfileQuery();
  const currentRoles = useCurrentRoles();

  definition.contextSwitch = internalRoleMatching(currentRoles) ? (
    <OrganizationSwitcher></OrganizationSwitcher>
  ) : null;

  return (
    <>
      {definition.contextSwitch}
      {profileQuery?.data &&
        profileQuery.data.selectedOrganization !== null &&
        Array.isArray(definition.main) &&
        definition.main.length > 0 && (
          <ul>
            {definition.main.map((item, i) => {
              if (Array.isArray(item.children))
                return <NestedLink data={item} key={`n-${i}`} />;

              if (item.value === 'common') {
                if (
                  (currentRoles &&
                    currentRoles.length === 1 &&
                    currentRoles[0] === 'Finance') ||
                  currentRoles[0] === 'CAE Instructor'
                ) {
                  return null;
                }
                return <SingleLink data={item} key={`s-${item.path}`} />;
              }
              const currentPermissions =
                profileQuery?.data?.userPermissions?.filter(
                  (_permission: UserPermissions) =>
                    _permission.taskValue === item.value &&
                    _permission.enable === true
                );
              const uniquePermissions = Array.from(new Set(currentPermissions));
              if (uniquePermissions.length > 0) {
                return <SingleLink data={item} key={`s-${item.label}`} />;
              }
            })}
          </ul>
        )}

      <ul>
        {profileQuery?.data &&
          profileQuery.data.selectedOrganization !== null &&
          definition.support.map((item, i) => (
            <SingleLink data={item} key={`s-${i}`} />
          ))}
        <li>
          <SurveyRedirectModal />
        </li>
      </ul>
    </>
  );
}
