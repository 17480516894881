import { Icon } from '@cae/cobalt-react';

import type { ReservationData } from '@/features/reservation-details/types';

const tsaIconMapping: { [k: string]: string } = {
  success: 'checkmark-circle-solid',
  warning: 'warning-triangle-solid',
  danger: 'warning-triangle-solid',
  neutral: 'circle-outline',
  'neutral-pending': 'circle-dotted-outline',
};

export function TSAStatusInfo({
  data,
}: {
  data: ReservationData['statusInfo']['tsa'];
}): JSX.Element {
  return (
    <article>
      <h4>TSA approval</h4>
      {data && (
        <p>
          {data.style && (
            <Icon
              id={tsaIconMapping[data.style]}
              className={`status ${data.style}`}
              size="sm"
            />
          )}
          {data.message}
        </p>
      )}
    </article>
  );
}
