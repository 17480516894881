import * as React from 'react';
import { AlertMessage } from '@cae/cobalt-react';

import { AdminContact } from '@/open-api';

const defaultVisibilityThreshold = 2;

export function AdministratorListing({
  data = [],
}: {
  data?: AdminContact[];
}): JSX.Element {
  const [visibilityThreshold, setVisibilityThreshold] = React.useState<number>(
    defaultVisibilityThreshold
  );
  const toggleVisibilityThreshold: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    setVisibilityThreshold(t =>
      t < data.length ? data.length : defaultVisibilityThreshold
    );
  };

  return (
    <div className="fragment">
      <h3 data-testid="customerCarePanel">Access changes?</h3>
      <p>
        Administrators of your company can manage your access & preferences.
      </p>
      {Array.isArray(data) && data.length ? (
        <>
          <ul className="administrator-listing">
            {data.map((a, i) => (
              <li aria-hidden={i > visibilityThreshold - 1} key={`item-${i}`}>
                {a.firstName && a.lastName && a.email ? (
                  <a
                    href={`mailto:${a.email}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <h4>{`${a.firstName} ${a.lastName}`}</h4>
                    <p>{a.email}</p>
                  </a>
                ) : (
                  'Insufficient info'
                )}
              </li>
            ))}
          </ul>
          {data.length > defaultVisibilityThreshold && (
            <button type="button" onClick={toggleVisibilityThreshold}>
              {data.length > visibilityThreshold
                ? `See more (+${data.length - visibilityThreshold})`
                : `See less`}
            </button>
          )}
        </>
      ) : (
        <AlertMessage type="warning" heading="No data available">
          <p>Looks like there are no adminstrators assigned to this company.</p>
        </AlertMessage>
      )}
    </div>
  );
}
