/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * List with requested items
 */
export type NotificationResponse = {
  id?: string;
  schedulerReservationId?: string;
  name?: string;
  status?: string;
  address?: string;
  courseName?: string;
  /**
   * Due date of the event
   */
  dueDate?: string;
  /**
   * Start date of the event
   */
  startDate?: string;
  /**
   * Notification type
   */
  notificationType?: NotificationResponse.notificationType;
  /**
   * Notification message
   */
  notificationMessage?: string;
};
export namespace NotificationResponse {
  /**
   * Notification type
   */
  export enum notificationType {
    PROVISIONAL_AT_RISK = 'ProvisionalAtRisk',
    MISSING_TSA = 'MissingTsa',
    MISSING_ETCA = 'MissingEtca',
  }
}
