import * as React from 'react';

export default function useDebounce(
  value: string,
  delay: number = 500
): string {
  const [debouncedValue, setDebouncedValue] = React.useState<string>(value);

  React.useEffect(() => {
    const handler = setTimeout(
      () => {
        setDebouncedValue(() => value);
      },
      delay,
      value
    );

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
