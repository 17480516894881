import { useLocation, useParams } from 'react-router-dom';
import { AlertMessage } from '@cae/cobalt-react';

import { ReservationDetailsResponse } from '@/open-api';

import {
  BackNavigation,
  LocationInfo,
  ScheduleTimeline,
} from '@/features/reservation-details/components';

import { useSelectLocationCourseSchedule } from '@/features/reservation-details/api/hooks';
import { ScheduleEmptyState } from '@/features/reservation-details/components/empty-states/ScheduleEmptyState';

import './css/LocationSchedulePage.css';

export function LocationSchedulePage(): null | JSX.Element {
  const { reservationId = '' } = useParams();
  const location = useLocation();
  const { state: locationState } = location;
  const { data, status } = useSelectLocationCourseSchedule(reservationId);

  const s = ReservationDetailsResponse.status;

  if (!reservationId) return null;

  if (!data) {
    return (
      <section
        className="dialog-details__page location-schedule"
        data-reservation-id={reservationId}
      >
        <BackNavigation
          reservationId={reservationId}
          schedulerReservationId={locationState.originalSchedulerReservationId}
        />
        <header>
          <h2>Location and Schedule</h2>
        </header>
        {status === 'loading' && <p>Loading schedule data</p>}
        {status === 'error' && <p>Error loading schedule data</p>}
      </section>
    );
  }

  const { course, trainingLocation, schedule } = data;

  return (
    <section
      className="dialog-details__page location-schedule"
      data-reservation-id={reservationId}
    >
      <BackNavigation
        reservationId={reservationId}
        schedulerReservationId={locationState.originalSchedulerReservationId}
      />
      <header>
        <h2>Location and Schedule</h2>
        {trainingLocation ? <LocationInfo data={trainingLocation} /> : null}
      </header>
      {data.status !== s.PROVISIONAL ? (
        <section className="dialog-details__fragment">
          {course && <h3>{course.name}</h3>}
          {schedule.scheduleAvailable ? (
            <>
              <p>
                Schedule details will only be available for the upcoming seven
                days.
              </p>
              <AlertMessage
                type="info"
                heading="Live schedule, subject to change"
                style={{ margin: '1.5rem 0' }}
              >
                <p>
                  All permanent changes will be communicated to you by your
                  Training Centre staff.
                </p>
              </AlertMessage>
              <ScheduleTimeline
                schedule={schedule}
                course={course}
                trainingLocation={trainingLocation}
              />
            </>
          ) : (
            <section className="reservation-details__fragment schedule-timeline">
              <ScheduleEmptyState>
                <h5>No schedule available yet.</h5>
              </ScheduleEmptyState>
            </section>
          )}
        </section>
      ) : null}
    </section>
  );
}

LocationSchedulePage.displayName = 'LocationSchedulePage';
