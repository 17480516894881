import { ToastOptions, toast } from 'react-toastify';

const defaultToastConfig: ToastOptions = {
  containerId: 'general',
  position: 'top-right',
  autoClose: 5000,
};

export const toastMessages = {
  success: (message: string, customConfig?: ToastOptions) => {
    const config = { ...defaultToastConfig, ...customConfig };
    toast.success(message, config);
  },
  error: (message: string, customConfig?: ToastOptions) => {
    const config = { ...defaultToastConfig, ...customConfig };
    toast.error(message, config);
  },
};
