import { useParams } from 'react-router-dom';

import {
  ContactInfo,
  QuickInfoFragment,
  LoadingReservation,
  LocationScheduleFragment,
  RelatedReservationsFragment,
  StatusActionsFragment,
} from '@/features/reservation-details/components';

import { useGetReservationDetailsQuery } from '@/features/reservation-details/api/useGetReservationDetails';
import { ReservationHeader } from '@/features/reservation-details/components/reservation-header/ReservationHeader';
import { ReservationDetailsResponse } from '@/open-api';

interface OverviewpageProps {
  navigateTo: string;
}
export function OverviewPage({
  navigateTo,
}: OverviewpageProps): null | JSX.Element {
  const { reservationId = '' } = useParams();
  const { isLoading, isError, data } =
    useGetReservationDetailsQuery<ReservationDetailsResponse>(reservationId);

  return reservationId ? (
    <section
      className="dialog-details__page overview"
      data-reservation-id={reservationId}
    >
      {!data && isLoading && <LoadingReservation />}
      {!data && isError && <p>Error loading reservation details</p>}
      {data ? (
        <>
          <ReservationHeader data={data} reservationId={reservationId} />
          <QuickInfoFragment reservationId={reservationId} />
          <StatusActionsFragment reservationId={reservationId} />
          <LocationScheduleFragment
            reservationId={reservationId}
            schedulerReservationId={data.schedulerReservationId}
          />
          <RelatedReservationsFragment
            reservationId={reservationId}
            schedulerReservationId={data.schedulerReservationId}
            navigateTo={navigateTo}
          />
          <ContactInfo reservationId={reservationId} />
        </>
      ) : null}
    </section>
  ) : (
    <p>reservationId is missing</p>
  );
}

OverviewPage.displayName = 'OverviewPage';
