import { UserResponse } from '@/open-api/models/UserResponse';
import './UsersListMobile.css';
import { Card } from '@cae/cobalt-react';
import { format } from 'date-fns';

interface UsersListProps {
  readonly users: ReadonlyArray<Readonly<UserResponse>>;
  readonly cardUserDetails: (rowData: Readonly<UserResponse>) => void;
}

function EmailIdRow({ data }: { readonly data: string }): JSX.Element {
  return (
    <>
      <dt>Email</dt>
      <dd>{data || 'null'}</dd>
    </>
  );
}

function RoleRow({
  data,
}: {
  readonly data: ReadonlyArray<string>;
}): JSX.Element {
  return (
    <>
      <dt>Role</dt>
      <dd>{data?.map((item: string) => item || 'null')}</dd>
    </>
  );
}

function LastLogIn({ data }: { readonly data: string }): JSX.Element {
  return (
    <>
      <dt>Last Log in</dt>
      <dd>{data ? format(new Date(), 'dd-MMM-yyyy') : '-'}</dd>
    </>
  );
}

function LastModified({ data }: { readonly data: string }): JSX.Element {
  return (
    <>
      <dt>Last modified</dt>
      <dd>{data ? format(new Date(), 'dd-MMM-yyyy') : '-'}</dd>
    </>
  );
}

function UsersListMobile({
  users,
  cardUserDetails,
}: UsersListProps): JSX.Element {
  return (
    <div>
      {users.map((item: Readonly<UserResponse>) => (
        <div key={item.id} className="reservations--card-listing">
          <Card onClick={() => cardUserDetails(item)}>
            <h4>{item?.firstName}</h4>
            <dl>
              <EmailIdRow data={item.email ?? ''} />
              <RoleRow data={item.roleNames ?? []} />
              <LastLogIn data={item.lastLoggedInTime ?? ''} />
              <LastModified data={item.updatedDate ?? ''} />
            </dl>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default UsersListMobile;
