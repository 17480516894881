import { Button, Card, Icon } from '@cae/cobalt-react';
import './BillsManagementCard.css';
import { useState } from 'react';
import { RedirectDialog } from '@/shared/components';
import { useFlags } from 'launchdarkly-react-client-sdk';

function BillsManagementCard(): JSX.Element | null {
  const [redirectToOBMDialogOpen, setRedirectToOBMDialogOpen] = useState(false);
  const { invoicesandpaymentactioncard } = useFlags();
  const redirectLink = import.meta.env.VITE_ORACLE_OBM_REDIRECT;

  return invoicesandpaymentactioncard ? (
    <Card
      elevation="skim"
      className="bills-management"
      style={{ gridColumn: '1/6' }}
      data-testid="billsManagementTile"
    >
      <h1>Looking to pay your US invoices?</h1>

      <p className="card-content">
        Access Bills Management to pay your US invoices by credit card online.
      </p>

      <div className="bills-management--buttons-container">
        <Button
          type="button"
          size="md"
          variant="ghost"
          className="bills-management--btn-external-link"
          onClick={() => {
            setRedirectToOBMDialogOpen(true);
          }}
        >
          Go to Bills Management
          <Icon id="external-link-outline" size="sm" />
        </Button>
      </div>

      <RedirectDialog
        title="On your way to Bills Management"
        setIsDialogOpen={setRedirectToOBMDialogOpen}
        isDialogOpen={redirectToOBMDialogOpen}
        redirectLink={redirectLink}
        identifier="billsmanagement"
      />
    </Card>
  ) : null;
}

export default BillsManagementCard;
