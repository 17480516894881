import { Button } from '@cae/cobalt-react';

import { FilterSection } from '@/shared/components';
import { useFilterModalReducer, UserFilters } from './useFilterModalReducer';

export type FilterModalProps = {
  filterOptions: UserFilters;
  filters: UserFilters;
  resetFilters: () => void;
  toggleFilterSection: () => void;
  toggleAllFilters: (selected: UserFilters) => void;
};

function compareArrays(
  filters: ('Training' | 'Records' | 'Finance' | 'Admin')[],
  selectedValues: string[]
): boolean {
  if (!filters && selectedValues.length === 0) return true;
  if (!filters || !selectedValues) return false;
  if (filters.length !== selectedValues.length) return false;
  return filters.every(item => selectedValues.includes(item));
}

function FilterModal({
  filterOptions,
  filters,
  toggleAllFilters,
  resetFilters,
  toggleFilterSection,
}: FilterModalProps): JSX.Element {
  const { selectedFilters, clearSelectedFilters, changeSelectedRole } =
    useFilterModalReducer(filters);

  const handleApply = (): void => {
    toggleAllFilters(selectedFilters);
    toggleFilterSection();
  };

  const handleReset = (): void => {
    resetFilters();
    clearSelectedFilters();
  };

  return (
    <div className="filter-list">
      <h3>Filters</h3>
      <div className="filter-sections">
        <FilterSection
          className="last"
          label="Role"
          searchPlaceholder="Search Locations"
          options={filterOptions?.role}
          selected={selectedFilters.role}
          onSelect={changeSelectedRole}
        />
      </div>
      <div className="actions">
        <Button variant="ghost" onClick={handleReset}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleApply}
          disabled={compareArrays(filters.role, selectedFilters.role)}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default FilterModal;
