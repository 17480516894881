import { Tabs } from '@cae/cobalt-react';
import { t } from 'i18next';
import { RecordsFilters } from '../filter-modal/useFilterModalReducer';
import { RecordsTableWrapper } from '../RecordsTableWrapper/RecordsTableWrapper';
import { useRecordsMetadataQuery } from '../../api/useRecordsMetadataQuery';
import { RecordCountersDto, RecordStatusFilter } from '@/open-api';

type RecordsTab = {
  label: string;
  type: RecordStatusFilter;
};

interface RecordsTabsProps {
  filters: RecordsFilters;
  searchText?: string;
}

const RECORDS_TABS: RecordsTab[] = [
  { label: 'all', type: RecordStatusFilter.ALL },
  { label: 'available', type: RecordStatusFilter.AVAILABLE },
  { label: 'hardCopyOnly', type: RecordStatusFilter.HARD_COPY_ONLY },
  { label: 'pending', type: RecordStatusFilter.PENDING },
];

export function RecordTabs({
  filters,
  searchText = '',
}: RecordsTabsProps): JSX.Element {
  const { data: metadata = {} } = useRecordsMetadataQuery(filters);
  const { counters = {} as RecordCountersDto } = metadata;

  const allCount = (counters?.all ?? 0) - (counters?.notApplicable ?? 0);

  return (
    <Tabs variant="contained">
      {({ selectedIndex = 1 }) => (
        <>
          <Tabs.TabList>
            {RECORDS_TABS.map((tab, i) => (
              <Tabs.Tab
                id={`tab--records-${tab.type}`}
                key={`tab--records-${tab.type}`}
                selected={i === selectedIndex}
                data-testid={`${tab.label}RecordsTabs`}
              >
                {t(`Records.${tab.label}`)}
                {tab && tab.label === 'all' && counters ? (
                  <i className="count">({allCount})</i>
                ) : (
                  <i className="count">
                    ({`${counters[tab.label as keyof typeof counters] ?? 0}`})
                  </i>
                )}
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
          {RECORDS_TABS.map((panel, i) => (
            <Tabs.TabPanel
              id={`tab-panel--reservations-${panel.type}`}
              selected={i === selectedIndex}
              aria-labelledby={`tab--reservations-${panel.type}`}
              key={panel.type}
              data-testid={`${panel.label}RecordsList`}
            >
              <RecordsTableWrapper
                recordsType={panel.type}
                filters={filters}
                searchText={searchText}
              />
            </Tabs.TabPanel>
          ))}
        </>
      )}
    </Tabs>
  );
}
