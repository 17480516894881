import { Button, Icon, Modal } from '@cae/cobalt-react';
import './LandingHeader.css';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { useModalState } from '@/shared/hooks/modal-state/useModalStates';
import { useLogin } from '@/shared/hooks/useLogin';
import handleHelpButtonClick from '@/shared/helpers/help-button/helpButtonClick';
import { useLanguageInitialization } from '@/shared/hooks/language-initialization/useLanguageInitialization';

function LandingHeader(): JSX.Element {
  const { handleLogin } = useLogin();
  const { t, i18n } = useTranslation();
  const { width } = useViewportSize();
  const [language, setLanguage] = React.useState(i18n.language);
  const { isOpen, openDialog, closeDialog } = useModalState();

  useLanguageInitialization();

  function getButtons(): JSX.Element {
    return (
      <>
        <Button
          type="button"
          variant="ghost"
          onClick={() => handleLogin('redirect')}
          onKeyDown={() => handleLogin('redirect')}
        >
          {t('common.login')}
        </Button>
        <Button
          onClick={() => handleTranslation()}
          type="button"
          variant="ghost"
        >
          {t('common.changeLanguageTo')}
          <Icon id="language-outline" size="sm" />
        </Button>
        <Button type="button" variant="ghost" onClick={handleHelpButtonClick}>
          {t('common.help')}
          <Icon id="help-circle-outline" size="sm" />
        </Button>
      </>
    );
  }

  function handleTranslation(): void {
    const changeLanguageTo = language === 'en' ? 'fr' : 'en';
    setLanguage(changeLanguageTo);
    i18n.changeLanguage(changeLanguageTo);
  }

  return (
    <div className="landing-page-header">
      <div className="landing-page-header-app-name">
        CAE<strong>Connect</strong>
      </div>
      {width > 700 ? (
        <div className="landing-page-header-actions">{getButtons()} </div>
      ) : (
        <Button type="button" variant="transparent" onClick={openDialog}>
          <Icon id="menu-burger-outline" size="md"></Icon>
        </Button>
      )}

      {isOpen && (
        <Modal
          animate
          className="landing-page-header-dialog"
          dismissible
          size="sm"
          onCancel={closeDialog}
          header={<h3>CAE Connect</h3>}
          open
          placement="top"
        >
          {getButtons()}
        </Modal>
      )}
    </div>
  );
}

export default LandingHeader;
