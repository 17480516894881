import * as React from 'react';
import { Button, Icon } from '@cae/cobalt-react';
import { UploadResult } from '@/open-api';
import Spinner from '@/shared/components/file-upload/spinner/Spinner';

export function ValidAttachmentList({
  files = [],
  onClick,
  onLoading = false,
  uploadResponse,
}: {
  files: File[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onLoading?: boolean;
  uploadResponse?: UploadResult[];
}): JSX.Element {
  return (
    <ul className="co-form__file-list">
      {files.map((f, i) => (
        <li key={`f-${f.name}`}>
          <span className="name">
            {f.name} <small>({(f.size / 1000 / 1024).toFixed(2)}MB)</small>
          </span>
          {!onLoading ? (
            uploadResponse?.[i].success ? (
              <span className="status-icon">
                <Icon id="checkmark-circle-solid" size="sm" type="success" />
              </span>
            ) : (
              <Button
                type="button"
                size="sm"
                variant="icon"
                aria-label="Remove"
                data-action="remove-file"
                data-name={f.name}
                onClick={onClick}
              >
                <Icon id="close-solid" size="sm" />
              </Button>
            )
          ) : (
            <Spinner />
          )}
        </li>
      ))}
    </ul>
  );
}

ValidAttachmentList.displayName = 'ValidAttachmentList';
