import { AlertMessage, ScreenContent, ScreenHeader } from '@cae/cobalt-react';

import { AuthoritiesSetupWizard } from '@/features/documents/components';
import { useUserInfo } from '@/contexts/userInfo';
import { useGetAuthoritiesQuery } from '@/features/documents/api';
import { DataLoadingGrid } from '@/shared/components';

export function DocumentsAuthoritySelectionPage(): JSX.Element {
  const { selectedOrganization } = useUserInfo();
  const organizationId = selectedOrganization?.id ?? '';
  const { data: availableAuthorities = [] } = useGetAuthoritiesQuery();

  return (
    <div className="page-wrapper">
      <ScreenHeader elevation="base">
        <hgroup>
          <h2>Documents</h2>
        </hgroup>
      </ScreenHeader>
      <ScreenContent>
        {!organizationId ? (
          <AlertMessage type="error" heading="No selected organization">
            <p>We were unable to determine organization you selected.</p>
          </AlertMessage>
        ) : (
          <DataLoadingGrid numberRows={15} numberColumns={5} />
        )}
      </ScreenContent>
      {organizationId && availableAuthorities.length > 0 && (
        <AuthoritiesSetupWizard
          availableAuthorities={availableAuthorities}
          organizationId={organizationId}
        />
      )}
    </div>
  );
}

DocumentsAuthoritySelectionPage.displayName = 'DocumentsAuthoritySelectionPage';
