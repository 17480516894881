import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import { useSelectBasicInfo } from '../../api/hooks';

interface Props {
  reservationId: string;
  schedulerReservationId?: string;
}

export function BackNavigation({
  reservationId = '',
  schedulerReservationId = '',
}: Props): null | JSX.Element {
  const { data } = useSelectBasicInfo(reservationId);
  const currentUrl = window.location.href;
  if (data?.reservationId === '') return null;
  const targetUrl = currentUrl.includes('/home')
    ? `/home/${reservationId}`
    : `/reservations/${reservationId}`;

  return (
    <nav>
      <Link to={targetUrl}>
        <Icon id="arrow-left-solid" size="sm" />
        {data?.clientName || 'Overview'} (Reservation #
        {schedulerReservationId || reservationId})
      </Link>
    </nav>
  );
}
