import { useTranslation } from 'react-i18next';
import './LandingMoreFeatures.css';

function LandingMoreFeatures(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="landing-more-features">
      <div className="landing-more-features-swirls"></div>
      <div className="landing-more-features-content">
        <h2>
          {t('landingPage.moreFeaturesTitle1')} <br />
          <span className="landing-more-features-content-title">
            {t('landingPage.moreFeaturesTitle2')}
          </span>
        </h2>
        <div className="landing-more-features-content-cards">
          <div className="landing-more-features-content-cards-row">
            <div className="landing-more-features-content-cards-row-card user-card">
              <div className="landing-more-features-content-cards-row-card-info">
                <h3>{t('landingPage.userFeature')}</h3>
                <p>{t('landingPage.userFeatureDescription')}</p>
              </div>
              <img
                src="/img/landing/user-management-features.png"
                alt="User management"
              />
            </div>
            <div className="landing-more-features-content-cards-row-card login-card">
              <div className="landing-more-features-login-swirls"></div>
              <div className="landing-more-features-content-cards-row-card-info">
                <h3>{t('landingPage.loginFeature')}</h3>
                <p>{t('landingPage.loginFeatureDescription')}</p>
              </div>
              <img
                src="img/landing/features-login.png"
                alt="User Login feature"
              />
            </div>
          </div>

          <div className="landing-more-features-content-cards-row">
            <div className="landing-more-features-content-cards-row-card instructor-card">
              <h3>{t('landingPage.instructorFeature')}</h3>
              <p>{t('landingPage.instructorFeatureDescription')}</p>
              <img
                src="img/landing/features-instructor.png"
                alt="Instructor feature"
              />
            </div>
            <div className="landing-more-features-content-cards-row-card onboarding-card">
              <h3>{t('landingPage.onboardingFeature')}</h3>
              <p>{t('landingPage.onboardingFeatureDescription')}</p>
              <img
                src="img/landing/features-onboarding.png"
                alt="Instructor feature"
              />
            </div>
          </div>
        </div>
        <div className="landing-more-features-content-footer">
          <h1>
            <span>{t('landingPage.interestedKnowingMoreTitle1')}</span>
            <br />
            {t('landingPage.interestedKnowingMoreTitle2')}
          </h1>
          <p>
            {t('landingPage.haveAccount')}{' '}
            <a href="/home">{t('common.login')}</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingMoreFeatures;
