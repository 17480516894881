import { DocumentInfoFragment } from '@/features/document-details';
import { Link, useLocation, useParams } from 'react-router-dom';

import { truncate } from '@/shared/helpers/strings';
import { useDocumentDetailsQuery } from '@/features/document-details/api/useDocumentDetailsQuery';

interface DocumentOverviewPageHeaderProps {
  readonly title: string;
  readonly status: string | undefined;
}

export function DocumentOverviewPageHeader({
  title = '',
  status = '',
}: DocumentOverviewPageHeaderProps): JSX.Element {
  const location = useLocation();

  const displayChangeRequestFragment =
    (location.pathname.includes('/documents/submitted') &&
      status === 'submitted') ||
    location.pathname.includes('/documents/company');

  return (
    <header>
      <h2 title={title}>{truncate(title, 8)}</h2>
      {displayChangeRequestFragment && (
        <p>
          In order to make an update to this document, please submit a{' '}
          <Link to={`${location.pathname}/request`}>change request</Link>
        </p>
      )}
    </header>
  );
}

export function DocumentOverviewPage(): JSX.Element {
  const { documentId = '' } = useParams();

  const { data, isInitialLoading } = useDocumentDetailsQuery(documentId);

  if (isInitialLoading) {
    return (
      <section className="dialog-details__page overview">
        <p>Loading...</p>
      </section>
    );
  }

  if (!data || !documentId) {
    return (
      <section className="dialog-details__page overview">
        <p>Sorry, cannot find document</p>
      </section>
    );
  }

  return (
    <article className="dialog-details__page overview">
      <DocumentOverviewPageHeader
        title={data.name}
        status={data.status?.status}
      />
      <DocumentInfoFragment data={data} />
    </article>
  );
}

DocumentOverviewPage.displayName = 'DocumentOverviewPage';
