import { useGetProfileQuery } from '../api/useGetProfileQuery';

export const useCurrentRoles = (): string[] => {
  const { data } = useGetProfileQuery();
  return (
    data?.organizations
      ?.find(org => org.id === data.selectedOrganization)
      ?.roles?.map(role => role.name as string) ?? []
  );
};
