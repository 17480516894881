/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TsaDto = {
  /**
   * TSA status
   */
  status?: TsaDto.status;
  /**
   * TSA message
   */
  message?: string;
  /**
   * TSA style
   */
  style?: TsaDto.style;
};
export namespace TsaDto {
  /**
   * TSA status
   */
  export enum status {
    NOT_APPLICABLE = 'NotApplicable',
    APPROVED = 'Approved',
    WAIT_PERIOD_APPROVED = 'WaitPeriodApproved',
    PENDING = 'Pending',
    DOCUMENTS_AND_PAYMENT_NOT_SENT = 'DocumentsAndPaymentNotSent',
    DOCUMENTS_ACCEPTED = 'DocumentsAccepted',
    SUBMITTED = 'Submitted',
    INSUFFICIENT = 'Insufficient',
    REQUIRES_RE_APPROVAL = 'RequiresReApproval',
    DENIED = 'Denied',
    UNKNOWN = 'Unknown',
  }
  /**
   * TSA style
   */
  export enum style {
    NEUTRAL = 'neutral',
    NEUTRAL_PENDING = 'neutral-pending',
    WARNING = 'warning',
    DANGER = 'danger',
    SUCCESS = 'success',
  }
}
