import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { UploadDocuments } from '@/features/documents/components';

export function DocumentsUploadPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const pathEls = location.pathname.split('/');
    const closePath = pathEls.slice(0, pathEls.length - 1).join('/');
    navigate(closePath);
  };

  return (
    <Modal
      animate
      className="upload-documents--dialog"
      dismissible
      onCancel={handleCancel}
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
    >
      <UploadDocuments onCancel={handleCancel} />
    </Modal>
  );
}

DocumentsUploadPage.displayName = 'DocumentsUploadPage';
