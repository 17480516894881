import { Tag } from '@cae/cobalt-react';
import './InvoicePaymentsHeader.css';
import { InvoiceDetailsResponse } from '@/open-api';
import InvoiceDownloadButton from '../invoice-download-button/InvoiceDownloadButton';

export const statusColors: {
  [key: string]: string;
} = {
  neutral: 'standard',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
  pending: 'yellow',
  unknown: 'standard',
};
type Props = {
  readonly invoiceDetails: InvoiceDetailsResponse;
};
export function InvoicePaymentsHeader({ invoiceDetails }: Props): JSX.Element {
  return (
    <header>
      <span className="InvoicePayments__type">
        {invoiceDetails.type === 'CreditMemo'
          ? 'Credit memo'
          : invoiceDetails.type}
      </span>{' '}
      {invoiceDetails.type !== 'CreditMemo' && (
        <Tag
          color={
            (statusColors[
              invoiceDetails?.invoicePaymentStatusDto?.style
                ? invoiceDetails?.invoicePaymentStatusDto?.style?.toLowerCase()
                : 'unknown'
            ] as 'standard' | 'green' | 'red' | 'yellow' | undefined) ??
            'standard'
          }
          title={invoiceDetails?.invoicePaymentStatusDto?.status}
        >
          {invoiceDetails?.invoicePaymentStatusDto?.status}
        </Tag>
      )}
      <h2 className="InvoicePayements__title">
        Transaction number : {invoiceDetails?.transactionNumber}
      </h2>
      <InvoiceDownloadButton
        invoiceId={invoiceDetails.accountReceivableId}
        fileName={invoiceDetails.transactionNumber}
        label="Download"
      />
    </header>
  );
}
