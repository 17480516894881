import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  UserManagementService,
  UserSyncRequest,
  UserSyncResponse,
} from '@/open-api';

type ProfileMutationCallback = {
  onSuccessCallback: (data: UserSyncResponse) => void;
  onErrorCallback: () => void;
};

export const useCreateOrganizationMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: ProfileMutationCallback): UseMutationResult<
  unknown,
  unknown,
  {
    data: UserSyncRequest;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ data }: { data: UserSyncRequest }) => {
      const response = await UserManagementService.syncUser({
        requestBody: data,
      });
      return response;
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: ['createOrganization'] });
        onSuccessCallback(response);
      },
      onError: error => {
        onErrorCallback();
        throw error;
      },
    }
  );
};
