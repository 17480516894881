import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

export function ReservationDetailsPage({
  onClose,
}: { onClose?: (e: React.SyntheticEvent) => void } = {}): JSX.Element | null {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { reservationId = '' } = useParams();

  const currentUrl = window.location.href;

  const handleCloseReservation = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const doesAnyHistoryEntryExist = currentUrl.includes('/home/') ?? false;
    if (doesAnyHistoryEntryExist) {
      navigate('/home');
    } else {
      navigate('/reservations');
    }
  };

  return reservationId ? (
    <Modal
      animate
      className="reservation-details--dialog"
      dismissible
      onCancel={onClose ?? handleCloseReservation}
      open
      placement={width < 480 ? 'bottom' : 'right'}
      data-testid="reservationDetailsPanel"
    >
      <Outlet />
    </Modal>
  ) : null;
}
