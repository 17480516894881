import { TagColor } from '@cae/cobalt-react';

export type RecordStatus =
  | 'Available'
  | 'Pending'
  | 'NotApplicable'
  | 'HardCopyOnly'
  | 'Unknown';

export type TagStatus = {
  label: string;
  longMessage: string;
  color: TagColor;
};

const mapRecordStatusToTagStatus: Record<RecordStatus, TagStatus> = {
  Available: { color: 'green', label: 'Available', longMessage: 'Available' },
  Pending: { color: 'yellow', label: 'Pending', longMessage: 'Pending' },
  HardCopyOnly: {
    color: 'standard',
    label: 'Hard copy only',
    longMessage: 'Hard copy only',
  },
  NotApplicable: {
    color: 'standard',
    label: 'Not applicable',
    longMessage: 'Not applicable',
  },
  Unknown: {
    color: 'standard',
    label: 'Unknown',
    longMessage: 'Unknown',
  },
};

export class RecordModel {
  public static mapRecordStatusToTagStatus(
    status: RecordStatus | undefined
  ): TagStatus {
    return mapRecordStatusToTagStatus[status ?? 'Unknown'];
  }
}
