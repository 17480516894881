import * as React from 'react';
import type { QueryStatus } from '@tanstack/react-query';
import type { ColumnDef, PaginationState } from '@tanstack/react-table';

import {
  DataLoadingError,
  NoData,
  DataLoadingGrid,
} from '@/shared/components/empty-states';
import { CobaltTableWithSorting, ColumnSort } from '@/shared/components';

import type { ReservationResponseDto } from '@/open-api';

import {
  allColumns,
  confirmedColumns,
  completedColumns,
  inProgressColumns,
  provisionalColumns,
  upcoming,
} from './columns';

import type { ReservationType } from '../../api/useGetReservationsTableDataQuery';

import './ReservationsTableListing.css';

const columns: Record<
  ReservationType,
  ColumnDef<ReservationResponseDto, string>[]
> = {
  all: allColumns,
  provisional: provisionalColumns,
  completed: completedColumns,
  confirmed: confirmedColumns,
  inProgress: inProgressColumns,
  recentlyCompleted: completedColumns,
  upcoming,
};

interface ReservationsTableListingProps {
  data: ReservationResponseDto[];
  openDetails: (reservation: ReservationResponseDto) => void;
  pageIndex: number;
  pageSize: number;
  reservationsType: ReservationType;
  searchText: string;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  queryStatus: QueryStatus;
  totalPages: number;
  defaultSortBy?: ColumnSort[];
  setSorting: React.Dispatch<React.SetStateAction<ColumnSort[] | []>>;
}

export function ReservationsTableListing({
  data,
  openDetails,
  pageIndex,
  pageSize,
  reservationsType = 'all',
  setPagination,
  queryStatus,
  totalPages,
  defaultSortBy,
  setSorting,
}: ReservationsTableListingProps): JSX.Element {
  return (
    <>
      {queryStatus === 'loading' && (
        <DataLoadingGrid
          numberColumns={columns[reservationsType].length}
          numberRows={10}
          style={{ marginTop: '1rem' }}
        />
      )}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data.length < 1 && <NoData />}
      {queryStatus === 'success' && data.length > 0 && (
        <CobaltTableWithSorting
          className="table-reservations"
          data={data}
          columns={columns[reservationsType]}
          handleRowClick={openDetails}
          pageIndex={pageIndex}
          pageSize={pageSize}
          paginationStyle={{
            backgroundColor: 'var(--white)',
            bottom: '0',
            position: 'sticky',
          }}
          setPagination={setPagination}
          setSorting={setSorting}
          defaultSortBy={defaultSortBy}
          totalPages={totalPages ?? 1}
        />
      )}
    </>
  );
}

ReservationsTableListing.displayName = 'ReservationsTableListing';
