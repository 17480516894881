import * as React from 'react';
import { Button } from '@cae/cobalt-react';

import type { InvalidFile } from './FileUpload.types';

export function InvalidAttachmentList({
  files = [],
  onClick,
}: {
  files: InvalidFile[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}): null | JSX.Element {
  if (files.length < 1) return null;
  return (
    <ul className="co-form__file-list error-list">
      {files.map((e, i) => (
        <li key={`error-${i}`}>
          <span className="file">
            <span className="name">{e.name}</span>
            <svg className="co-icon--sm error">
              <use href="/symbols.svg#warning-circle-solid" />
            </svg>
            <Button
              type="button"
              size="sm"
              variant="icon"
              aria-label="Remove"
              data-action="remove-error"
              data-name={e.name}
              onClick={onClick}
            >
              <svg className="co-icon--sm">
                <use href="/symbols.svg#close-outline" />
              </svg>
            </Button>
          </span>
          <span className="co-form__error-message">{e.message}</span>
        </li>
      ))}
    </ul>
  );
}

InvalidAttachmentList.displayName = 'InvalidAttachmentList';
