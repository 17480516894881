import { loginRequest } from '@/configs/authConfig';
import Logger from '@/libs/logging/Logger';
import { IPublicClientApplication } from '@azure/msal-browser';

export async function resetPasswordRedirect(
  msalInstance: IPublicClientApplication
): Promise<void> {
  try {
    await msalInstance.loginRedirect({
      authority: `${
        import.meta.env.VITE_AUTH_BASE_AUTHORITY
      }/B2C_1A_passwordreset`,
      scopes: [...loginRequest.scopes],
    });
  } catch (error) {
    Logger.log({
      type: 'code',
      message: `Password reset error: ${(error as Error).message}`,
      level: 'error',
    });
  }
}
