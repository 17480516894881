
      (function () {
        var walkme = document.createElement('script');
        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = `https://cdn.walkme.com/users/${import.meta.env.VITE_WALKME_GUID}/${import.meta.env.VITE_ENVIRONMENT === 'development' || import.meta.env.VITE_ENVIRONMENT === 'test' ? import.meta.env.VITE_ENVIRONMENT + '/' : ''}walkme_${import.meta.env.VITE_WALKME_GUID}_https.js`;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = { smartLoad: true };
      })();
    