import { Button, Icon } from '@cae/cobalt-react';

export function DownloadReportAction({
  permitted = false,
  className,
  label,
  onDownloadReportClick,
}: Readonly<{
  notificationContainerId?: string;
  permitted?: boolean;
  className?: string;
  label?: string;
  onDownloadReportClick: React.MouseEventHandler<HTMLButtonElement>;
}>): JSX.Element {
  if (!permitted) return <></>;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    onDownloadReportClick(event);
  };

  const rootProps = {
    className,
  };

  return (
    <div {...rootProps}>
      <Button
        type="button"
        variant="secondary"
        size="md"
        onClick={handleClick}
        title="Download document"
      >
        <Icon id="download-outline" size="md" />
        {label}
      </Button>
    </div>
  );
}

DownloadReportAction.displayName = 'DownloadReportAction';
