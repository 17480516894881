import { DashboardAlertModal } from '@/features/dashboard/components/dashboard-alert-modal/DashboardAlertModal.tsx';
import { DashboardRecordsAlertDetails } from '@/features/dashboard/components/dashboard-record-alert-cards/DashboardRecordDetailsAlerts';
import { ReservationDetailsPage } from '@/pages';
import { HomePage } from '@/pages/home';
import {
  LocationSchedulePage,
  OverviewPage,
  ReservationRequestPage,
} from '@/pages/reservation-details';
import { ReservationsAlertDetailsPage } from '@/pages/reservations-alerts';

export const homeRoutes = {
  path: 'home',
  element: <HomePage />,
  children: [
    {
      path: ':reservationId',
      element: <ReservationDetailsPage />,
      children: [
        { index: true, element: <OverviewPage navigateTo="home" /> },
        { path: 'request', element: <ReservationRequestPage /> },
        {
          path: 'location-schedule',
          element: <LocationSchedulePage />,
        },
      ],
    },
    {
      path: 'alerts',
      element: <DashboardAlertModal />,
      children: [
        {
          path: ':type',
          element: <ReservationsAlertDetailsPage />,
        },
        {
          path: 'records',
          children: [
            {
              path: ':type',
              element: <DashboardRecordsAlertDetails />,
            },
          ],
        },
      ],
    },
  ],
};
