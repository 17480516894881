import {
  InstructorManagementService,
  PagedListOfCheckAirmenPerPlatformDto,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useInstructorManagementQuery(
  pageIndex: number,
  pageSize: number
): UseQueryResult<PagedListOfCheckAirmenPerPlatformDto, unknown> {
  return useQuery({
    queryKey: ['instructorManagement', pageIndex, pageSize],
    queryFn: () =>
      InstructorManagementService.getCheckAirmenPerPlatform({
        pageIndex,
        pageSize,
      }),
    keepPreviousData: true,
  });
}
