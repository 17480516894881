import { UseMutationResult, useMutation } from '@tanstack/react-query';

import {
  ApiError,
  AuthorityRequest,
  OrganizationManagementService,
} from '@/open-api';

interface Config {
  onMutate: (data: AuthorityRequest) => void;
  onError: (error: ApiError) => void;
  onSuccess: (data: AuthorityRequest) => void;
}

export function useAuthoritiesMutation(
  organizationId: string,
  config: Config
): UseMutationResult<unknown, ApiError, AuthorityRequest, AuthorityRequest> {
  return useMutation({
    mutationFn: async (data: AuthorityRequest) => {
      return OrganizationManagementService.updateAuthorities({
        id: organizationId,
        requestBody: data,
      });
    },
    onMutate: (data: AuthorityRequest) => {
      config?.onMutate(data);
      return data;
    },
    onError: (error: ApiError) => {
      config?.onError(error);
    },
    onSuccess: (_data: AuthorityRequest, context: AuthorityRequest) => {
      config?.onSuccess(context);
    },
  });
}
