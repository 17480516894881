import { useState } from 'react';

export function useModalState(initialState = false): {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
} {
  const [isOpen, setIsOpen] = useState(initialState);

  const openDialog = (): void => setIsOpen(true);
  const closeDialog = (): void => setIsOpen(false);

  return { isOpen, openDialog, closeDialog };
}
