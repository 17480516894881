import { Icon, Tabs } from '@cae/cobalt-react';
import { InstructorCountPerLocationDto } from '@/open-api';
import './ProgramTabs.css';
import { InstructorsListContainer } from '@/features/instructor-management/components/instructors-list-container/InstructorsListContainer.tsx';
import { useState } from 'react';

interface InstructorTabsProps {
  data: Array<InstructorCountPerLocationDto> | [];
  program: string;
}

export function ProgramTabs({
  data,
  program,
}: Readonly<InstructorTabsProps>): JSX.Element {
  const [isWarning, setIsWarning] = useState(false);
  if (!data.length) {
    return <></>;
  }
  return (
    <Tabs variant="contained" defaultIndex={0} data-testid={'programTabs'}>
      {({ selectedIndex = 0 }) => {
        return (
          <>
            <Tabs.TabList>
              {data.map(({ trainingCenterName: location, count }, i) => (
                <Tabs.Tab
                  id={`tab--program-${location}`}
                  key={`tab--program-${location}`}
                  data-testid={`${location}ProgramTabs`}
                  selected={i === selectedIndex}
                  className="program-tabs__tab"
                >
                  {`${location}`}
                  <span className="count">({`${count ?? 0}`})</span>
                  {isWarning && <Icon id="warning-triangle-solid" />}
                </Tabs.Tab>
              ))}
            </Tabs.TabList>
            {data.map(
              (
                { trainingCenterId: key = '', trainingCenterName: location },
                i
              ) => (
                <Tabs.TabPanel
                  id={`tab-panel--program-${location}`}
                  selected={i === selectedIndex}
                  aria-labelledby={`tab--program-${location}`}
                  key={key}
                  data-testid={`${location}ProgramsList`}
                >
                  <InstructorsListContainer
                    trainingCenterId={key}
                    program={program}
                    location={location ?? ''}
                    setIsWarning={setIsWarning}
                  />
                </Tabs.TabPanel>
              )
            )}
          </>
        );
      }}
    </Tabs>
  );
}
