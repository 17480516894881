import {
  CompanyAuthorities,
  CompanyContacts,
  CompanyInformation,
  CompanyFleet,
  CompanyFleetInfo,
  CompanyProfileChangeRequest,
  CompanyOperatingCountryRegion,
  CompanyBilling,
} from '@/features/my-company-profile';
import CompanyPhoneFax from '@/features/my-company-profile/components/companyPhoneFax/CompanyPhoneFax';
import MyCompanyPage from '@/pages/my-company/MyCompanyPage';

export const companyProfileRoutes = {
  path: 'company-profile',
  element: <MyCompanyPage />,
  children: [
    {
      path: 'contact-info',
      element: <CompanyContacts />,
      children: [
        {
          path: 'change-request',
          element: <CompanyProfileChangeRequest />,
        },
        {
          path: 'edit-phone',
          element: <CompanyPhoneFax />,
        },
        {
          path: 'edit-fax',
          element: <CompanyPhoneFax />,
        },
      ],
    },
    {
      path: 'fleet-info',
      element: <CompanyFleetInfo />,
      children: [
        {
          path: 'add',
          element: <CompanyFleet />,
        },
        {
          path: 'edit',
          element: <CompanyFleet />,
        },
      ],
    },
    {
      path: 'company-info',
      element: <CompanyInformation />,
      children: [
        {
          path: 'authorities',
          element: <CompanyAuthorities />,
        },
        {
          path: 'region',
          element: <CompanyOperatingCountryRegion />,
        },
      ],
    },
    {
      path: 'billing',
      element: <CompanyBilling />,
    },
  ],
};
