import { Dispatch, SetStateAction, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../configs/authConfig';
import Logger from '@/libs/logging/Logger';

interface LoginProps {
  handleLogin: (loginType: string) => Promise<void>;
  spinner: boolean;
  setSpinner: Dispatch<SetStateAction<boolean>>;
  authenticated: boolean;
}

// TODO: Temporary workaround for msal.interaction.status issue
function clearInteractionStatus(): void {
  const itemKey = 'msal.interaction.status';
  if (sessionStorage.getItem(itemKey)) {
    sessionStorage.removeItem(itemKey);
  }
}

export const useLogin = (): LoginProps => {
  const { instance, inProgress } = useMsal();
  const [spinner, setSpinner] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const handleLogin = async (loginType: string): Promise<void> => {
    setSpinner(true);
    clearInteractionStatus();
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest).catch(error => {
        if (error instanceof Error) {
          Logger.log({
            type: 'code',
            level: 'error',
            message: `loginPopup thrown an exception: ${error.message}`,
          });
        }
      });
    } else if (loginType === 'redirect') {
      if (inProgress) {
        setSpinner(true);
      }
      try {
        const redirectStartPage = window.location.pathname;
        await instance.loginRedirect({ ...loginRequest, redirectStartPage });
        setAuthenticated(true);
      } catch (error) {
        if (error instanceof Error) {
          Logger.log({
            type: 'code',
            level: 'error',
            message: `UseLogin thrown an exception: ${error.message}`,
          });
        }
      }
    }
  };

  return {
    handleLogin,
    spinner,
    setSpinner,
    authenticated,
  };
};
