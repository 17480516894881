import { useReducer } from 'react';

export type DocumentStatus = 'submitted' | 'in-review' | 'completed';

export interface SubmittedDocumentsFilters {
  status: DocumentStatus[];
}

interface FilterModalReducerResult {
  selectedFilters: SubmittedDocumentsFilters;
  clearSelectedFilters: () => void;
  changeSelectedStatus: (status: DocumentStatus) => void;
  clearSelectedStatus: () => void;
}

export const useFilterModalReducer = (
  filters: SubmittedDocumentsFilters
): FilterModalReducerResult => {
  const initialState = {
    status: filters.status || [],
  };

  const enum Actions {
    changeSelectedStatus = 'changeSelectedStatus',
    resetSelectedFilters = 'resetSelectedFilters',
    clearSelectedStatus = 'clearSelectedStatus',
  }
  type FilterAction = {
    type: Actions.changeSelectedStatus;
    payload: DocumentStatus;
  };

  type FilterActionWithoutPayload = {
    type: Actions.resetSelectedFilters | Actions.clearSelectedStatus;
  };

  const filtersModalReducer = (
    state: SubmittedDocumentsFilters,
    action: FilterAction | FilterActionWithoutPayload
  ): SubmittedDocumentsFilters => {
    switch (action.type) {
      case Actions.changeSelectedStatus:
        return {
          ...state,
          status: state.status.includes(action.payload)
            ? state.status.filter(item => item !== action.payload)
            : [...state.status, action.payload],
        };
      case Actions.resetSelectedFilters:
        return {
          status: [],
        };
      default:
        return state;
    }
  };

  const [selectedFilters, dispatch] = useReducer(
    filtersModalReducer,
    initialState
  );

  const changeSelectedStatus = (status: DocumentStatus): void => {
    dispatch({ type: Actions.changeSelectedStatus, payload: status });
  };

  const clearSelectedStatus = (): void => {
    dispatch({ type: Actions.clearSelectedStatus });
  };

  const clearSelectedFilters = (): void => {
    dispatch({ type: Actions.resetSelectedFilters });
  };

  return {
    selectedFilters,
    clearSelectedFilters,
    changeSelectedStatus,
    clearSelectedStatus,
  };
};
