import { AlertMessage, AlertType } from '@cae/cobalt-react';

import { useSelectQuickInfo } from '../../api/hooks';

import './QuickInfoFragment.css';
import type { ReservationQuickInfo } from '../../types';

const autoCancellationStyleMapping: Record<string, AlertType> = {
  danger: 'error',
  neutral: 'info',
  warning: 'warning',
  none: 'info',
};

function ShowAlertMessage({
  data,
}: {
  data?: ReservationQuickInfo['autoCancellationDetails'];
}): JSX.Element | null {
  if (!data || data.status === 'NotApplicable') return null;
  return (
    <AlertMessage
      type={autoCancellationStyleMapping[data.style ?? 'none']}
      heading=""
    >
      <p>{data.message}</p>
    </AlertMessage>
  );
}

export function QuickInfoFragment({
  reservationId,
}: {
  reservationId: string;
}): JSX.Element {
  const { data, isError } = useSelectQuickInfo(reservationId);

  return (
    <section className="dialog-details__fragment quick-info">
      {data ? (
        <>
          <ShowAlertMessage data={data.autoCancellationDetails} />

          <ul>
            <li>
              <h6>Course name</h6>
              <p>{data.courseName}</p>
            </li>
            <li>
              <h6>Platform</h6>
              <p>{data.aircraft}</p>
            </li>
            <li>
              <h6>Authority</h6>
              <p>{data.authority}</p>
            </li>
            <li>
              <h6>Objectives</h6>
              <p>{data.objectives}</p>
            </li>
            <li>
              <h6>Dates</h6>
              <p>
                {data.startDate} to {data.endDate}
              </p>
            </li>
            <li>
              <h6>Location</h6>
              <p>{data.location}</p>
            </li>
            <li>
              <h6>Customer</h6>
              <p>{data.customer}</p>
            </li>
          </ul>
        </>
      ) : isError ? (
        <p>Error getting quick info</p>
      ) : null}
    </section>
  );
}
