import { RecordsFilters } from '../components/filter-modal/useFilterModalReducer';

export const enum Actions {
  resetFilter = 'reset-filters',
  toggleAllFilters = 'toggle-fleets-bases-ranks',
}

type ResetFilterAction = {
  type: Actions.resetFilter;
};

type ToggleAllFiltersAction = {
  type: Actions.toggleAllFilters;
  payload: RecordsFilters;
};

type RecordsFiltersActions = ToggleAllFiltersAction | ResetFilterAction;

export function filtersReducer(
  state: RecordsFilters,
  action: RecordsFiltersActions
): RecordsFilters {
  switch (action.type) {
    case Actions.toggleAllFilters:
      return {
        ...state,
        locations: action.payload.locations,
        platforms: action.payload.platforms,
        courseTypes: action.payload.courseTypes,
        dateRange: action.payload.dateRange,
      };
    case Actions.resetFilter:
      return {
        locations: [],
        platforms: [],
        courseTypes: [],
        dateRange: [],
      };
    default:
      return state;
  }
}
