import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { Modal } from '@cae/cobalt-react';
import { Outlet, useNavigate } from 'react-router-dom';

export function InvoicePaymentsDetails(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const handleCloseDetails = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/finance');
  };
  return (
    <Modal
      animate
      dismissible
      onCancel={handleCloseDetails}
      open
      placement={width < 480 ? 'bottom' : 'right'}
    >
      <Outlet />
    </Modal>
  );
}
