/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedListOfRecordResponseDto } from '../models/PagedListOfRecordResponseDto';
import type { RecordAlertsResponse } from '../models/RecordAlertsResponse';
import type { RecordChangeRequest } from '../models/RecordChangeRequest';
import type { RecordDetailsResponse } from '../models/RecordDetailsResponse';
import type { RecordsMetadata } from '../models/RecordsMetadata';
import type { RecordSortBy } from '../models/RecordSortBy';
import type { RecordStatusFilter } from '../models/RecordStatusFilter';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecordManagementService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateRecordRequestEmail({
    reservationId,
    requestBody,
  }: {
    reservationId: string;
    requestBody: RecordChangeRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/records/{reservationId}/update-record-request-email',
      path: {
        reservationId: reservationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendRequestRecordCopyEmail({
    reservationId,
  }: {
    reservationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/records/{reservationId}/request-record-copy-email',
      path: {
        reservationId: reservationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns RecordDetailsResponse OK
   * @throws ApiError
   */
  public static getRecordDetails({
    recordId,
  }: {
    recordId: string;
  }): CancelablePromise<RecordDetailsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/records/{recordId}/details',
      path: {
        recordId: recordId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns string OK
   * @throws ApiError
   */
  public static getDocumentDownloadUrl({
    documentId,
  }: {
    documentId: string;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/records/{documentId}/downloadUrl',
      path: {
        documentId: documentId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getDocumentDownload({
    documentId,
  }: {
    documentId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/records/{documentId}/download',
      path: {
        documentId: documentId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns PagedListOfRecordResponseDto OK
   * @throws ApiError
   */
  public static getRecords({
    pageIndex,
    pageSize = 10,
    sort,
    location,
    platform,
    startDateAfter,
    endDateBefore,
    publishedDate,
    searchText,
    selectedOrganization,
    recordStatus,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Property used for sorting the result
     */
    sort?: Array<RecordSortBy>;
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * Start date of the training event
     */
    startDateAfter?: string;
    /**
     * End date of the training event
     */
    endDateBefore?: string;
    /**
     * published date of the training event
     */
    publishedDate?: string;
    /**
     * Search term for additional filtering on any of Record number, pilot name and course name
     */
    searchText?: string;
    selectedOrganization?: string;
    /**
     * Record status filter
     */
    recordStatus?: RecordStatusFilter;
  }): CancelablePromise<PagedListOfRecordResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/records/paged',
      headers: {
        SelectedOrganization: selectedOrganization,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        sort: sort,
        location: location,
        platform: platform,
        startDateAfter: startDateAfter,
        endDateBefore: endDateBefore,
        publishedDate: publishedDate,
        searchText: searchText,
        recordStatus: recordStatus,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns RecordsMetadata OK
   * @throws ApiError
   */
  public static getRecordsMetadata({
    location,
    platform,
    endDateAfter,
    endDateBefore,
    searchText,
    organizationId,
    publishedDate,
    recordStatus,
  }: {
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * All events where End date of the training event is after this
     */
    endDateAfter?: string;
    /**
     * All events where End date of the training event is before this
     */
    endDateBefore?: string;
    /**
     * Search term for additional filtering on any of pilot name or course name
     */
    searchText?: string;
    organizationId?: string;
    /**
     * published date of the training event
     */
    publishedDate?: string;
    /**
     * Record status filter
     */
    recordStatus?: RecordStatusFilter;
  }): CancelablePromise<RecordsMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/records/meta',
      headers: {
        organizationId: organizationId,
      },
      query: {
        location: location,
        platform: platform,
        endDateAfter: endDateAfter,
        endDateBefore: endDateBefore,
        searchText: searchText,
        publishedDate: publishedDate,
        recordStatus: recordStatus,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns RecordAlertsResponse OK
   * @throws ApiError
   */
  public static getRecordAlertsCountByCardType({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<RecordAlertsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/records/alerts',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
