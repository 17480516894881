import * as React from 'react';
import { Button, Checkbox, Icon, Modal } from '@cae/cobalt-react';
import invoiceIllustration from '../../../assets/images/finance/invoiceIllustration.svg';
import './FinanceLandingPage.css';

import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { NavLink } from 'react-router-dom';

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
};

function ModalActions({ handleCancel }: ModalActionsProps): JSX.Element {
  return (
    <div>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </div>
  );
}

function FinanceLandingPage(): JSX.Element {
  const currentRole = useCurrentRoles();

  const [showModal, setShowModal] = React.useState(false);
  const [count, setCount] = React.useState(3);
  const [checked, setChecked] = React.useState(false);
  const openTerminalNewTab = (url: string): void => {
    setShowModal(false);
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const openModal = (): void => {
    setShowModal(true);
    const url = import.meta.env.VITE_TERMINAL_URI;
    const timer = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      openTerminalNewTab(url);
    }, count * 1000);
  };

  const closeModal = (): void => {
    setShowModal(false);
  };

  return (
    <div className="container-finance-landing">
      <img src={invoiceIllustration} alt="invoice illustration " />

      <div className="container-text">
        {currentRole &&
        currentRole.length === 1 &&
        currentRole[0] === 'Finance' ? (
          <p className="heading">You are currently in Finance role</p>
        ) : (
          <p className="heading">
            Invoices and payments available here October 9th
          </p>
        )}
        <p>
          Finance capabilities will be coming soon to CAE Connect. If you need
          access to billing features of our legacy solution, CAE Terminal, you
          may login with your old account.
        </p>
        <p>
          If you don&apos;t have a legacy CAE Terminal account and would like
          one created, please{' '}
          <NavLink to="/finance?show-support-menu=true">
            reach your Account Executive.
          </NavLink>
        </p>
        {currentRole && !currentRole.includes('Admin') ? (
          <p>
            If you would like additional or different accesses to CAE Connect,
            please{' '}
            <NavLink to="/finance?show-support-menu=true">
              reach your organization administrators.
            </NavLink>
          </p>
        ) : null}
        <Button type="button" size="md" variant="primary" onClick={openModal}>
          {'Access CAE terminal '}
          <Icon id="external-link-outline" size="sm" />
        </Button>
      </div>
      <Modal
        animate
        actions={ModalActions}
        onCancel={closeModal}
        open={showModal}
        placement="center"
        className="record-details--dialog"
        size="sm"
      >
        <h1>Let us get you to CAE Terminal</h1>
        <p> Opening in {count}...</p>
        <Checkbox
          field={{
            id: 'finance-redirect-checkbox',
            checked,
            onChange: () => setChecked(!checked),
          }}
          label="Do not show again"
          className="survey-checkbox"
        />
      </Modal>
    </div>
  );
}

export default FinanceLandingPage;
