/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Document type
 */
export enum DocumentType {
  CAE = 'cae',
  COMPANY = 'company',
  SUBMITTED = 'submitted',
}
