import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { InstructorListDto, InstructorManagementService } from '@/open-api';

export function useInstructorsListQuery(
  program: string,
  trainingCenterId: string
): UseQueryResult<InstructorListDto, Error> {
  return useQuery({
    queryKey: ['instructor', 'instructors', 'list', program, trainingCenterId],
    queryFn: () =>
      InstructorManagementService.getAllInstructorsForProgramAtTrainingCenter({
        trainingCenterId,
        program,
      }),
  });
}
