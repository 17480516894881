import * as React from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

export function UserDetailsPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const handleCloseUserDetails = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/users');
  };
  return userId ? (
    <Modal
      animate
      dismissible
      onCancel={handleCloseUserDetails}
      open
      placement="right"
      className="user-details__dialog"
      size="sm"
    >
      <Outlet />
    </Modal>
  ) : null;
}
