import { useState } from 'react';
import './MyProfileContainer.css';
import { Outlet, useNavigate } from 'react-router-dom';

const profileDetails = [
  {
    name: 'Personal information',
    path: '/my-profile/info',
  },
  {
    name: 'Work information',
    path: '/my-profile/work-info',
  },

  {
    name: 'Password and security',
    path: '/my-profile/password-security',
  },
];
export function MyProfileContainer(): JSX.Element {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState<string>('/my-profile/info');

  const tabsHandler = (path: string): void => {
    setTabs(path);
    navigate(path);
  };
  return (
    <div className="profile-container">
      <div className="__tabs">
        {profileDetails.map(tab => (
          <div
            role="tab"
            key={tab.name}
            className={`${tabs === tab.path ? '__active' : '__inactive'}`}
            onClick={() => tabsHandler(tab.path)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                tabsHandler(tab.path);
              }
            }}
            aria-selected={tabs === tab.path}
            tabIndex={0}
          >
            <p>{tab?.name}</p>
          </div>
        ))}
      </div>
      <Outlet />
    </div>
  );
}
