export const ACCEPTED_FILETYPE_MIMETYPE_MAPPING: [string, string?][] = [
  ['.zip', 'application/zip'],
  ['.gif', 'image/gif'],
  ['.jpg', 'image/jpg'],
  ['.jpeg', 'image/jpeg'],
  ['.png', 'image/png'],
  ['.svg', 'image/svg+xml'],
  ['.txt', 'text/plain'],
  ['.csv', 'text/csv'],
  ['.rtf', 'text/rtf'],
  ['.doc'],
  ['.docx'],
  ['.otf'],
  ['.ttf'],
  ['.ppt'],
  ['.pptx'],
  ['.xls'],
  ['.xlsx'],
];
