import RolesMatrix from '@/features/settings/components/roles-matrix/RolesMatrix';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { ScreenContent, ScreenHeader } from '@cae/cobalt-react';

function RolesPage(): JSX.Element {
  return (
    <HasAccess permissions="modify_role">
      <section className="page page-roles">
        <div className="page-wrapper">
          <ScreenHeader className="roles-header" elevation="base">
            <h2>Roles Mapping</h2>
          </ScreenHeader>
          <ScreenContent>
            <RolesMatrix />
          </ScreenContent>
        </div>
      </section>
    </HasAccess>
  );
}

export default RolesPage;
