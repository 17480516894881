import * as React from 'react';
import { Icon, Table, Tooltip } from '@cae/cobalt-react';

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  Cell,
} from '@tanstack/react-table';
import { Pagination } from '@/shared/components/pagination/Pagination';
import { useEffect } from 'react';

interface CobaltTableCellProps<TData> {
  cell: Cell<TData, unknown>;
}

export type ColumnSort = {
  id: string;
  desc: boolean;
};

export function CobaltTableCell<TData>({
  cell,
}: CobaltTableCellProps<TData>): JSX.Element {
  const ref = React.useRef<React.ElementRef<'span'>>(null);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(true);

  React.useEffect(() => {
    const checkEllipsis = (): void => {
      let hasEllipsis = false;

      if (ref.current) {
        const isOverflowing = ref.current.clientWidth < ref.current.scrollWidth;
        if (isOverflowing) {
          hasEllipsis = true;
        }
      }

      setShowTooltip(hasEllipsis);
    };

    checkEllipsis();

    window.addEventListener('resize', checkEllipsis);
    return () => {
      window.removeEventListener('resize', checkEllipsis);
    };
  }, []);

  const cellContent = flexRender(cell.column.columnDef.cell, cell.getContext());

  return showTooltip ? (
    <Tooltip offsetOptions={7} placement="bottom" highContrast>
      <Tooltip.Trigger>
        <span className="cell-value" ref={ref}>
          {cellContent}
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content>{cellContent}</Tooltip.Content>
    </Tooltip>
  ) : (
    <span className="cell-value" ref={ref}>
      {cellContent}
    </span>
  );
}

interface CobaltTableWithSortingProps<T> {
  readonly className?: string;
  // The type of value in react-table library can be anything (ColumnDef<TData, any>[]), so use of the 'any' is warranted here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly columns: ColumnDef<T, any>[];
  readonly data: T[];
  readonly handleRowClick?: (rowData: T) => void;
  readonly id?: string;
  readonly pageIndex?: number;
  readonly pageSize?: number;
  readonly pagination?: boolean;
  readonly paginationStyle?: React.CSSProperties;
  readonly setPagination?: React.Dispatch<
    React.SetStateAction<PaginationState>
  >;
  readonly style?: React.CSSProperties;
  readonly totalPages?: number;
  readonly setSorting: React.Dispatch<React.SetStateAction<ColumnSort[] | []>>;
  readonly defaultSortBy?: ColumnSort[] | [];
  readonly enableMultiSort?: boolean;
}

export function CobaltTableWithSorting<T>({
  className,
  columns,
  data,
  handleRowClick,
  id,
  pageIndex = 1,
  pageSize = 10,
  pagination = true,
  paginationStyle = {},
  setPagination,
  setSorting,
  style,
  totalPages,
  defaultSortBy,
  enableMultiSort = true,
}: CobaltTableWithSortingProps<T>): JSX.Element {
  const [sortBy, setSortBy] = React.useState<ColumnSort[] | []>(
    defaultSortBy ?? []
  );
  const [selected, setSelected] = React.useState<string>('');

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination: { pageIndex, pageSize },
      sorting: sortBy,
    },
    manualPagination: true,
    manualSorting: true,
    enableMultiSort,
    pageCount: totalPages ?? -1,
    onPaginationChange: setPagination,
    onSortingChange: setSortBy,
  });

  useEffect(() => {
    setSorting(sortBy);
  }, [sortBy, setSorting]);

  if (!data) return <div>Loading...</div>;

  return (
    <>
      <div
        className="scroll-horizontal"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Table className={className} id={id} style={style}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(h => {
                  const toggleSorting = h.column.getToggleSortingHandler();
                  return (
                    <th key={h.id} className={h.id}>
                      <span
                        className={`label ${h.column.getCanSort() ? 'label--sort' : ''}`}
                        {...(h.column.getCanSort() && {
                          onClick: e => {
                            if (toggleSorting) {
                              toggleSorting(e);
                            }
                          },
                          onKeyDown: e => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              if (toggleSorting) {
                                toggleSorting(e);
                              }
                            }
                          },
                          tabIndex: 0,
                          role: 'button',
                        })}
                      >
                        {flexRender(h.column.columnDef.header, h.getContext())}
                        {
                          {
                            asc: <Icon id="arrow-up-outline" size="sm" />,
                            desc: <Icon id="arrow-down-outline" size="sm" />,
                          }[(h.column.getIsSorted() as string) ?? null]
                        }
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                data-selected={row.id === selected ? 'true' : 'false'}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={cell.id}
                    onClick={() => {
                      if (cell.id.endsWith('checkbox')) return;
                      setSelected(row.id);
                      if (typeof handleRowClick === 'function')
                        handleRowClick(row.original);
                    }}
                  >
                    <CobaltTableCell cell={cell} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {pagination && (
        <Pagination
          previousPage={table.previousPage}
          nextPage={table.nextPage}
          getCanPreviousPage={table.getCanPreviousPage}
          getCanNextPage={table.getCanNextPage}
          options={[10, 25, 50, 100]}
          pageSize={table.getState().pagination.pageSize}
          setPageSize={table.setPageSize}
          page={table.getState().pagination.pageIndex + 1}
          setPageIndex={table.setPageIndex}
          maxPage={table.getPageCount()}
          style={paginationStyle}
        />
      )}
    </>
  );
}

export default CobaltTableWithSorting;
