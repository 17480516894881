/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedListOfNotificationResponse } from '../models/PagedListOfNotificationResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DashboardService {
  /**
   * @returns PagedListOfNotificationResponse OK
   * @throws ApiError
   */
  public static getNotifications({
    pageIndex,
    pageSize = 10,
    organizationId,
  }: {
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    organizationId?: string;
  }): CancelablePromise<PagedListOfNotificationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/notifications',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
