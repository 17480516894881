import { InvoicePaymentsHeader } from '../invoice-payments-details-header/InvoicePaymentsHeader';
import { InvoicePaymentsInfoFragments } from '../invoice-payment-info-fragment/InvoicePaymentsInfoFragments';
import { InvoicePaymentsCardsList } from '../invoice-paymets-cards-list/InvoicePaymentsCardsList';
import { InvoicePaymentsContactInfo } from '../invoice-payments-contact-info/InvoicePaymentsContactInfo';
import useInvoiceAndPaymentsDetails from '../../api/useInvoicePaymentsDetails';
import { useParams } from 'react-router-dom';
export function InvoicePaymentsDetailsData(): JSX.Element {
  const { accountReceivableId } = useParams();

  const {
    isLoading,
    data: invoiceDetails,
    isError,
  } = useInvoiceAndPaymentsDetails(accountReceivableId as string);
  return (
    <section className="dialog-details__page overview">
      {!invoiceDetails && isLoading && <p>Loading invoice details</p>}
      {!invoiceDetails && isError && <p>Error loading invoice details</p>}
      {invoiceDetails && (
        <>
          {' '}
          <InvoicePaymentsHeader invoiceDetails={invoiceDetails} />
          <InvoicePaymentsInfoFragments invoiceDetails={invoiceDetails} />
          {invoiceDetails.reservationInfo && (
            <InvoicePaymentsCardsList invoiceDetails={invoiceDetails} />
          )}
          {invoiceDetails && <InvoicePaymentsContactInfo />}
        </>
      )}
    </section>
  );
}
