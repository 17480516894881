import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  InvoiceAndPaymentManagementService,
  InvoiceDetailsResponse,
} from '@/open-api';

function useInvoiceAndPaymentsDetails(
  accountReceivableId: string
): UseQueryResult<InvoiceDetailsResponse> {
  return useQuery({
    queryKey: ['invoiceAndPaymentsDetails', accountReceivableId],
    queryFn: () =>
      InvoiceAndPaymentManagementService.getInvoiceDetails({
        accountReceivableId,
      }),
  });
}

export default useInvoiceAndPaymentsDetails;
