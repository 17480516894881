import { Link } from 'react-router-dom';
import { Card, Icon } from '@cae/cobalt-react';

import {
  type ReservationsTilesCount,
  useGetReservationsTilesCountQuery,
} from '../../api/useGetReservationsTilesCountQuery';

import './ReservationsAlertCards.css';

const categories = [
  {
    category: 'etca',
    title: 'Missing eTCAs',
    subTitle: 'Training starts within 21 days',
  },
  {
    category: 'tsa',
    title: 'Missing TSAs',
    subTitle: 'Training starts within 45 days',
  },
  {
    category: 'provisional',
    title: 'Provisionals at risk',
    subTitle: 'Auto-cancel in less than 30 days',
  },
];

export function ReservationsAlertCards(): null | JSX.Element {
  const { data, status } = useGetReservationsTilesCountQuery();

  if (status !== 'success') return null;

  const counts = data ? Object.values(data).reduce((a, b) => a + b, 0) : 0;

  return counts < 1 ? null : (
    <div className="card-list">
      {categories.map(t => {
        return (
          data &&
          data[t.category as keyof ReservationsTilesCount] > 0 && (
            <Link to={`/reservations/alerts/${t.category}`}>
              <Card status="danger-in" key={t.category}>
                <h3>
                  {data &&
                    t.category &&
                    data[t.category as keyof ReservationsTilesCount]}
                </h3>
                <Icon
                  id="warning-triangle-solid"
                  size="md"
                  style={{ color: 'var(--danger-color)' }}
                />
                <p>
                  <strong>{t.title}</strong> ({t.subTitle})
                </p>
              </Card>
            </Link>
          )
        );
      })}
    </div>
  );
}

export default ReservationsAlertCards;
