import {
  DataLoadingGrid,
  DataLoadingError,
  NoData,
  CobaltTableWithSorting,
  ColumnSort,
} from '@/shared/components';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import * as React from 'react';

import { QueryStatus } from '@tanstack/react-query';

import { RecordResponseDto, RecordStatusFilter } from '@/open-api';
import {
  allColumns,
  availableColumns,
  hardCopyOnlyColumns,
  pendingColumns,
} from './columns';

interface RecordsTableListingProps {
  data: RecordResponseDto[];
  openDetails: (record: RecordResponseDto) => void;
  pageIndex: number;
  pageSize: number;
  recordsType: RecordStatusFilter;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  queryStatus: QueryStatus;
  totalPages: number;
  defaultSortBy?: ColumnSort[];
  setSorting: React.Dispatch<React.SetStateAction<ColumnSort[] | []>>;
}

const columns: Record<
  RecordStatusFilter,
  ColumnDef<RecordResponseDto, string>[]
> = {
  Available: availableColumns,
  HardCopyOnly: hardCopyOnlyColumns,
  Pending: pendingColumns,
  All: allColumns,
  NotApplicable: [],
  NotAvailable: [],
};
export function RecordTableList({
  data,
  openDetails,
  pageIndex,
  pageSize,
  recordsType = RecordStatusFilter.ALL,
  setPagination,
  queryStatus,
  totalPages,
  defaultSortBy,
  setSorting,
}: RecordsTableListingProps): JSX.Element {
  return (
    <>
      {queryStatus === 'loading' && (
        <DataLoadingGrid
          numberColumns={columns[recordsType].length}
          numberRows={10}
          style={{ marginTop: '1rem' }}
        />
      )}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data?.length < 1 && <NoData />}
      {queryStatus === 'success' && data?.length > 0 && (
        <CobaltTableWithSorting
          className="table-records"
          data={data}
          columns={columns[recordsType]}
          handleRowClick={openDetails}
          pageIndex={pageIndex}
          pageSize={pageSize}
          paginationStyle={{
            backgroundColor: 'var(--white)',
            bottom: '0',
            position: 'sticky',
          }}
          setPagination={setPagination}
          setSorting={setSorting}
          defaultSortBy={defaultSortBy}
          totalPages={totalPages ?? 1}
        />
      )}
    </>
  );
}
