import {
  OrganizationManagementService,
  OrganizationProfileResponse,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useCompanyProfileByOrganizationIdQuery(
  id?: string
): UseQueryResult<OrganizationProfileResponse> {
  return useQuery<OrganizationProfileResponse>({
    queryKey: ['companyProfile', id],
    queryFn: async () => {
      const response =
        await OrganizationManagementService.getOrganizationProfile({ id: id! });
      return response;
    },
    enabled: !!id,
  });
}
