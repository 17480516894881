import { Dropdown } from '@cae/cobalt-react';

import './ReportOptionsFields.css';

type Option = {
  label: string;
  value: string;
};

type PlatformOption = {
  location?: string;
  platforms?: Option[];
};

interface Props {
  reportTypeOptions: Option[];
  locationOptions: Option[];
  platformOptions?: PlatformOption[];
  reportType: string;
  location: string;
  platform: string;
  setReportType: (value: string) => void;
  setLocation: (value: string) => void;
  setPlatform: (value: string) => void;
}

export function ReportOptionsFields({
  reportTypeOptions,
  locationOptions,
  platformOptions,
  reportType,
  location,
  platform,
  setReportType,
  setLocation,
  setPlatform,
}: Readonly<Props>): JSX.Element {
  const filteredPlatformOptions = (): Option[] => {
    const platformOptionsFiltered = platformOptions?.find(
      option => option.location === location
    );
    return platformOptionsFiltered?.platforms || [];
  };

  return (
    <fieldset>
      <Dropdown
        field={{
          name: 'reportType',
          onChange: e => setReportType(e.target.value),
          value: reportType,
        }}
        options={reportTypeOptions}
        label="Report Type"
        emptyText="Select report type"
      />
      <Dropdown
        label="Location"
        emptyText="Select location"
        field={{
          name: 'location',
          onChange: e => setLocation(e.target.value),
          value: location,
          disabled: !reportType,
        }}
        options={locationOptions}
      />
      <Dropdown
        label="Platform"
        emptyText="Select platform"
        field={{
          name: 'platform',
          onChange: e => setPlatform(e.target.value),
          value: platform,
          disabled: !location,
        }}
        options={filteredPlatformOptions()}
      />
    </fieldset>
  );
}
