import {
  InvoiceAndPaymentManagementService,
  InvoiceMetadata,
} from '@/open-api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { format } from 'date-fns';
import { InvoicePaymentsStatus } from '../components/filter-modal/useFilterModalReducer';
import useDebounce from '@/shared/hooks/useDebounce';

type Props = {
  invoiceDateRange: (Date | undefined)[];
  trainingDateRange: (Date | undefined)[];
  currency: string[];
  platform: string[];
  location: string[];
  status: Array<InvoicePaymentsStatus>;
};

export function useInvoicePaymentsMetadataQuery(
  filters: Props,
  searchText: string
): UseQueryResult<InvoiceMetadata> {
  const {
    invoiceDateRange,
    trainingDateRange,
    currency,
    platform,
    location,
    status,
  } = filters;
  const debouncedValue = useDebounce(searchText, 500);

  return useQuery<InvoiceMetadata, Error>({
    queryKey: ['invoicePaymentsCount', filters, debouncedValue],
    queryFn: () =>
      InvoiceAndPaymentManagementService.getInvoicePaymentMetadata({
        location,
        platform,
        currency,
        startDueDate: invoiceDateRange[0]
          ? format(invoiceDateRange[0], 'yyyy-MM-dd')
          : undefined,
        endDueDate: invoiceDateRange[1]
          ? format(invoiceDateRange[1], 'yyyy-MM-dd')
          : undefined,
        searchText: debouncedValue,
        issuedStartDate: trainingDateRange[0]
          ? format(trainingDateRange[0], 'yyyy-MM-dd')
          : undefined,
        issuedEndDate: trainingDateRange[1]
          ? format(trainingDateRange[1], 'yyyy-MM-dd')
          : undefined,
        status,
      }),
  });
}
