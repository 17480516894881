import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  PagedListOfRecordResponseDto,
  RecordManagementService,
  RecordSortBy,
  RecordStatusFilter,
} from '@/open-api';
import useDebounce from '@/shared/hooks/useDebounce';
import { format } from 'date-fns';

export interface RecordsTableData {
  reservationId: string;
  firstName: string;
  lastName: string;
  aircraftName: string;
  courseName: null | string;
  startDate: Date;
  endDate: Date;
  location: string;
  recordPublishedOn: Date;
  status: null;
}

export enum Icon {
  Alert = 'alert',
  Error = 'error',
}

type Filters = {
  locations: string[];
  platforms: string[];
  courseTypes: Array<'Initial' | 'Recurrent' | 'Upgrade' | 'Other'>;
  dateRange: (Date | undefined)[];
};

export function useGetRecordsTableDataQuery(
  recordType: RecordStatusFilter,
  pageIndex: number,
  pageSize: number,
  filters: Filters,
  searchText: string,
  sorting?: RecordSortBy[] | []
): UseQueryResult<PagedListOfRecordResponseDto, unknown> {
  const { locations, platforms, dateRange } = filters;

  const debouncedValue = useDebounce(searchText, 500);
  return useQuery({
    queryKey: [
      recordType,
      pageIndex,
      pageSize,
      filters,
      debouncedValue,
      sorting,
    ],
    queryFn: () => {
      return RecordManagementService.getRecords({
        pageIndex,
        pageSize,
        sort: sorting,
        location: locations,
        platform: platforms,
        startDateAfter: dateRange[0]
          ? format(dateRange[0], 'yyyy-MM-dd')
          : undefined,
        endDateBefore: dateRange[1]
          ? format(dateRange[1], 'yyyy-MM-dd')
          : undefined,
        searchText: debouncedValue,
        recordStatus: recordType,
      });
    },
  });
}
