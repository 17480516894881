import { Button } from '@cae/cobalt-react';

export function SkipButton({
  label,
  onClick,
}: {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  return (
    <Button type="button" variant="secondary" onClick={onClick}>
      {label}
    </Button>
  );
}

export function PrimaryButton({
  label,
  onClick,
}: {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  return (
    <Button type="button" variant="primary" onClick={onClick}>
      {label}
    </Button>
  );
}

export function SecondaryButton({
  label,
  onClick,
}: {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  return (
    <Button type="button" variant="secondary" onClick={onClick}>
      {label}
    </Button>
  );
}
