/* eslint import/no-unresolved: "off" */
import { useUserInfo } from '@/contexts/userInfo';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { useMsal } from '@azure/msal-react';
import { AccessPassProps } from 'access_pass_mfe/AccessPass';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

function AccessPass(): JSX.Element {
  const [RemoteComponent, setRemoteComponent] =
    React.useState<React.FC<AccessPassProps> | null>(null);
  const [loadError, setLoadError] = React.useState<Error | null>(null);

  const navigate = useNavigate();
  const { accessPageEnabledWebApp } = useFlags();
  React.useEffect(() => {
    if (!accessPageEnabledWebApp) {
      navigate('/home');
    }
  }, [accessPageEnabledWebApp, navigate]);

  React.useEffect(() => {
    const loadComponent = async (): Promise<void> => {
      try {
        const { default: Component } = await import(
          'access_pass_mfe/AccessPass'
        );
        setRemoteComponent(() => Component);
      } catch (error) {
        console.error('Error loading remote component:', error);
        setLoadError(error as Error);
      }
    };

    loadComponent();
  }, []);

  const { instance } = useMsal();
  const userInfo = useUserInfo();

  return (
    <HasAccess permissions="request_visitor_pass">
      <div className="host">
        {loadError ? (
          <div>
            Error loading the micro-frontend component. Please try again later.
          </div>
        ) : (
          <React.Suspense fallback={<div>Loading...</div>}>
            {RemoteComponent ? (
              <RemoteComponent msalInstance={instance} user={userInfo} />
            ) : (
              <div>Loading...</div>
            )}
          </React.Suspense>
        )}
      </div>
    </HasAccess>
  );
}

export default AccessPass;
