import {
  CourseDto,
  CustomerDto,
  RegulatoryAgencyDto,
  RelatedRecordDto,
} from '@/open-api';
import { RelatedRecordsInfo } from '../types';

export function transformCourseObjectivesToString(input?: CourseDto): string {
  return input && Array.isArray(input.objectives)
    ? input.objectives.map(o => o.name).join(', ')
    : '';
}

export function transformRelatedRecords(
  input: RelatedRecordDto[]
): RelatedRecordsInfo[] {
  return Array.isArray(input) && input.length
    ? input.map(r => ({
        id: r?.recordId,
        recordStatus: r?.status ?? 'Unknown',
        recordId: r.recordId ?? '',
        courseName: r.courseName,
      }))
    : [];
}

export function transformRegulatoryAgencies(
  input: RegulatoryAgencyDto[] | undefined
): string {
  return Array.isArray(input) ? input.map(a => a.name).join(', ') : '';
}

export function transformContactInfo(
  input: CustomerDto | Record<string, never>
): Required<CustomerDto> {
  return {
    accountExecutive: {
      id: input?.accountExecutive?.id ?? '',
      email: input?.accountExecutive?.email ?? '',
      name: input?.accountExecutive?.name ?? '',
      phoneNumber: input?.accountExecutive?.phoneNumber ?? '',
    },
    contactEmail: input.contactEmail ?? '',
    id: input.id ?? '',
    name: input.name ?? '',
    salesForceId: input.salesForceId ?? '',
  };
}
