export function RoleCard({
  data,
}: {
  data: { description: string; iconName: string; name: string };
}): JSX.Element {
  return (
    <figure className="with-image">
      <img
        src={`/img/welcome/${data.iconName}.svg`}
        alt={`${data.iconName} icon`}
      />
      <figcaption>
        <h5>{data.name}</h5>
        <p>{data.description}</p>
      </figcaption>
    </figure>
  );
}
