import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { Modal } from '@cae/cobalt-react';
import { useNavigate } from 'react-router-dom';
import { CompanyOperatingRegionForm } from '../company-operation-region-form.tsx/companyOperatingRegionForm';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import { useUserInfo } from '@/contexts/userInfo';

function CompanyOperatingCountryRegion(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { selectedOrganization } = useUserInfo();
  const { data: { operatingRegion } = {} } =
    useCompanyProfileByOrganizationIdQuery(selectedOrganization?.id);
  const defaultValues = { operatingRegion: operatingRegion };
  const handleClose = (): void => {
    navigate(-1);
  };
  return (
    <Modal
      animate
      dismissible
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
    >
      <CompanyOperatingRegionForm
        defaultValues={defaultValues}
        handleClose={handleClose}
      />
    </Modal>
  );
}

export default CompanyOperatingCountryRegion;
