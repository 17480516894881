import * as React from 'react';
import { Link } from 'react-router-dom';
import { AlertBanner, AlertMessage } from '@cae/cobalt-react';
import { useQueryClient } from '@tanstack/react-query';

import { useUploadDocument } from '@/shared/api/useUploadDocument';
import {
  SubmitDocumentsForm,
  SubmitDocumentsLongForm,
} from '@/features/documents/components';

import type { PostResponseStatus } from '@/shared/types';
import type { DocumentUploadRequest } from '@/open-api';
import { useFlags } from 'launchdarkly-react-client-sdk';

function StatusDisplay({
  onRefresh,
  status,
}: {
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}): React.ReactNode {
  const fn: Record<PostResponseStatus['type'], React.ReactNode> = {
    error: (
      <AlertBanner type="error" heading={status.heading}>
        <p>{status.message}</p>
      </AlertBanner>
    ),
    idle: null,
    pending: null,
    success: (
      <AlertMessage type="success" heading={status.heading}>
        <p>
          Go to <Link to="/documents/submitted">Submitted Documents</Link> to
          get more details.
        </p>
        <p>
          <button type="button" className="anchor" onClick={onRefresh}>
            Submit more documents?
          </button>
        </p>
      </AlertMessage>
    ),
  };

  return fn[status?.type ?? 'idle'];
}

export function UploadDocuments({
  onCancel,
}: Readonly<{
  mode?: 'short' | 'long';
  onCancel: (e: React.SyntheticEvent) => void;
}>): JSX.Element {
  const [key, setKey] = React.useState<string>(`key-${Date.now()}`);
  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });
  const queryClient = useQueryClient();
  const { documentauthorities } = useFlags();

  const { uploadDocument, response, isLoading, refreshUploadStatus } =
    useUploadDocument(setStatus);

  const handleCancel = (e: React.SyntheticEvent): void => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ['documents', 'submitted'],
      });
    }, 10000);
    onCancel(e);
  };

  const handleRefresh: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setStatus({ type: 'idle' });
    refreshUploadStatus();
    setKey(`key-${Date.now()}`);
  };

  const handleSubmit = async (data: DocumentUploadRequest): Promise<void> => {
    setStatus({ type: 'pending' });
    let d: DocumentUploadRequest = data;
    if (response) {
      d = {
        notes: data.notes,
        files: data.files?.filter((f, i) => {
          return (
            (f as File).name === response[i].filename && !response[i].success
          );
        }),
        shouldAddCustomerAuthority: true,
      };
    }
    uploadDocument(d);
  };

  const Form = documentauthorities
    ? SubmitDocumentsLongForm
    : SubmitDocumentsForm;

  return (
    <React.Fragment key={key}>
      <header>
        <h2>Submit document</h2>
        <StatusDisplay onRefresh={handleRefresh} status={status} />
      </header>
      <Form
        maxFiles={1}
        maxFileSize={100}
        maxSize={100}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        status={status}
        isLoading={isLoading}
        uploadResponse={response}
      />
    </React.Fragment>
  );
}

UploadDocuments.displayName = 'UploadDocuments';
