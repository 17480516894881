import { useReducer } from 'react';

export interface UserFilters {
  role: Array<'Admin' | 'Training' | 'Records' | 'Finance'>;
}

type FilterHookReturn = {
  selectedFilters: UserFilters;
  clearSelectedFilters: () => void;
  changeSelectedRole: (
    role: 'Admin' | 'Training' | 'Records' | 'Finance'
  ) => void;
  clearSelectedRole: () => void;
};

export const useFilterModalReducer = (
  filters: UserFilters
): FilterHookReturn => {
  enum ActionTypes {
    ChangeSelectedRole = 'changeSelectedRole',
    ResetSelectedFilters = 'resetSelectedFilters',
    ClearSelectedRole = 'clearSelectedRole',
  }

  type Action =
    | {
        type: ActionTypes.ChangeSelectedRole;
        payload: 'Admin' | 'Training' | 'Records' | 'Finance';
      }
    | { type: ActionTypes.ResetSelectedFilters }
    | { type: ActionTypes.ClearSelectedRole };

  const filtersModalReducer = (
    state: UserFilters,
    action: Action
  ): UserFilters => {
    switch (action.type) {
      case ActionTypes.ChangeSelectedRole:
        return {
          ...state,
          role: state.role.includes(action.payload)
            ? state.role.filter(item => item !== action.payload)
            : [...state.role, action.payload],
        };
      case ActionTypes.ResetSelectedFilters:
        return { role: [] };
      default:
        return state;
    }
  };

  const initialState: UserFilters = {
    role: filters.role || [],
  };

  const [selectedFilters, dispatch] = useReducer(
    filtersModalReducer,
    initialState
  );

  const changeSelectedRole = (
    role: 'Admin' | 'Training' | 'Records' | 'Finance'
  ): void => {
    dispatch({ type: ActionTypes.ChangeSelectedRole, payload: role });
  };

  const clearSelectedRole = (): void => {
    dispatch({ type: ActionTypes.ClearSelectedRole });
  };

  const clearSelectedFilters = (): void => {
    dispatch({ type: ActionTypes.ResetSelectedFilters });
  };

  return {
    selectedFilters,
    clearSelectedFilters,
    changeSelectedRole,
    clearSelectedRole,
  };
};
