import { useUserInfo } from '@/contexts/userInfo';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { Button, Icon, Dialog } from '@cae/cobalt-react';
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useChangeLogoOfOrganizationMutation } from '../../api/useChangeLogoOfOrganizationMutation';
import { useDeleteLogoFromOrganizationMutation } from '../../api/useDeleteLogoFromOrganizationMutation';
import { useSelectedOrganizationLogoQuery } from '../../api/useSelectedOrganizationLogoQuery';
import * as React from 'react';
import './uploadAvatar.css';

type ModalActionsProps = {
  readonly handleCancel: React.MouseEventHandler;
  readonly handleConfirm: React.MouseEventHandler;
};

function DiscardModalActionsChangeRequest({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Delete
      </Button>
    </>
  );
}

const uploadAvatarSchema = z.object({
  logo: z.custom<File>(v => v instanceof File, {
    message: 'Logo is required',
  }),
});

export type UploadAvatarValues = z.infer<typeof uploadAvatarSchema>;

interface Props {
  readonly setIsEditAvatarOpen: Dispatch<SetStateAction<boolean>>;
}

export function UploadAvatar({ setIsEditAvatarOpen }: Props): JSX.Element {
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const { selectedOrganization } = useUserInfo();
  const {
    data: logoImage,
    refetch: refetchLogoImage,
    isFetching,
    isLoading,
  } = useSelectedOrganizationLogoQuery(selectedOrganization?.id);
  const { handleSubmit, control, reset, formState } =
    useForm<UploadAvatarValues>({
      resolver: zodResolver(uploadAvatarSchema),
      defaultValues: {
        logo: undefined,
      },
    });

  const { errors } = formState;
  const [image, setImage] = useState<string | null>(null);

  const onSuccessCallback = (): void => {
    setIsEditAvatarOpen(false);
    toastMessages.success('Logo edited successfully');
    setImage(null);
  };

  const onErrorCallback = (): void => {
    toastMessages.error('Error in updating logo');
    setImage(null);
  };

  const { mutate: uploadLogo, isLoading: isUploadLoading } =
    useChangeLogoOfOrganizationMutation({
      onSuccessCallback,
      onErrorCallback,
    });

  const { mutate: deleteLogo, isLoading: isDeleteLoading } =
    useDeleteLogoFromOrganizationMutation({
      onSuccessCallback,
      onErrorCallback,
    });

  const handleImageUploadChange = (file: File | null): void => {
    if (file) {
      const url = URL.createObjectURL(file);
      setImage(url);
    } else {
      setImage(null);
    }
  };

  const handleSave: SubmitHandler<UploadAvatarValues> = ({ logo }) => {
    const organizationId = selectedOrganization?.id ?? '';
    if (logo) {
      uploadLogo({ id: organizationId, logo });
    }
  };

  const handleDeleteRequest = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(true);
  };

  const handleImageDelete = (): void => {
    const organizationId = selectedOrganization?.id ?? '';
    deleteLogo({ id: organizationId });
    setImage(null);
    reset({ logo: undefined });
    setIsDiscardDialogOpen(false);
  };

  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsEditAvatarOpen(false);
    reset({ logo: undefined });
    refetchLogoImage();
  };

  useEffect(() => {
    if (logoImage && logoImage.size > 0) {
      const url = URL.createObjectURL(logoImage);
      setImage(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setImage(null);
    }
  }, [logoImage]);

  useEffect(() => {
    return () => {
      if (image) URL.revokeObjectURL(image);
    };
  }, [image]);

  return (
    <>
      {!isFetching && !isLoading && (
        <form
          className="company-edit-avatar"
          onSubmit={handleSubmit(handleSave)}
        >
          <h1>Edit picture</h1>
          <div className="__picture-box">
            {image ? (
              <img src={image} alt="Uploaded" className="__uploaded-image" />
            ) : (
              <div className="__placeholder">No Image</div>
            )}
          </div>
          <label htmlFor="upload-input" className="__upload-text">
            <Icon id="edit-outline" size="sm" />
            Upload new picture
            <Controller
              name="logo"
              control={control}
              render={({ field: { onChange, ref, name, onBlur } }) => (
                <input
                  type="file"
                  id="upload-input"
                  accept="image/*"
                  ref={ref}
                  name={name}
                  onBlur={onBlur}
                  onChange={e => {
                    const file = e.target.files?.[0] || null;
                    onChange(file);
                    handleImageUploadChange(file);
                  }}
                  data-testid="upload-company-logo"
                />
              )}
            />
          </label>
          {errors.logo && <span>{errors.logo.message}</span>}
          <button
            className="__delete-button"
            type="button"
            onClick={handleDeleteRequest}
            disabled={!image}
          >
            <Icon id="trash-outline" size="sm" />
            Delete picture
          </button>
          <div className="__footer">
            <Button
              type="submit"
              variant="primary"
              disabled={isUploadLoading || isDeleteLoading}
              pending={isUploadLoading}
            >
              Done
            </Button>
            <Button
              type="button"
              variant="secondary"
              className="primary-btn-color"
              onClick={handleCancel}
              pending={isDeleteLoading}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
      {isFetching && <p> Loading...</p>}
      <Dialog
        modal
        className="dialog"
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Delete picture?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleImageDelete}
        actions={DiscardModalActionsChangeRequest}
        placement="center"
        size="sm"
      >
        <p>You will lose information.</p>
      </Dialog>
    </>
  );
}
