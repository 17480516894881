import { Icon } from '@cae/cobalt-react';

import './DocumentViewer.css';

export function DocumentViewer(): null | JSX.Element {
  return (
    <div className="document-viewer">
      <Icon id="file-solid" className="file-thumbnail" />
    </div>
  );
}
