import { useCallback, useState, useEffect } from 'react';
import { useWindowEvent } from './useWindowEvent';

const eventListerOptions = {
  passive: true,
};

export function useViewportSize(): {
  width: number;
  height: number;
} {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth ?? 0,
    height: window.innerHeight ?? 0,
  });

  const setSize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth || 0,
      height: window.innerHeight || 0,
    });
  }, []);

  useWindowEvent('resize', setSize, eventListerOptions);
  useWindowEvent('orientationchange', setSize, eventListerOptions);
  useEffect(setSize, [setSize]);

  return windowSize;
}
