import APIConfig from '@/configs/urlConfig';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useSelectedOrganizationLogoQuery(
  id?: string
): UseQueryResult<Blob> {
  return useQuery<Blob>({
    queryKey: ['companyAvatar', id],
    queryFn: async () => {
      const tempToken = JSON.parse(localStorage.getItem('idToken') || '{}');

      const response = await fetch(
        `${import.meta.env.VITE_WEBAPI_URI}/organizations/${id}/logo`,
        {
          headers: new Headers({
            Authorization: `Bearer ${tempToken}`,
            'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.blob();
    },
    enabled: !!id,
  });
}
