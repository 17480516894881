import * as React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

export function DashboardAlertModal(): JSX.Element {
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const handleCloseModal = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/home');
  };

  const modalContent =
    width < 768 ? (
      <Modal
        animate
        className="reservations-alerts--dialog"
        dismissible
        onCancel={handleCloseModal}
        open
        placement={width < 480 ? 'bottom' : 'right'}
        size="sm"
      >
        <Outlet />
      </Modal>
    ) : (
      <Modal
        animate
        dismissible
        onCancel={handleCloseModal}
        open
        placement={width < 480 ? 'bottom' : 'right'}
        size="sm"
      >
        <Outlet />
      </Modal>
    );

  return <section className="page page-reservations">{modalContent}</section>;
}
