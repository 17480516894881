import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  InstructorManagementService,
  InstructorProgramAlertDto,
} from '@/open-api';

export function useInstructorAlertQuery(): UseQueryResult<
  Array<InstructorProgramAlertDto>,
  Error
> {
  return useQuery({
    queryKey: ['instructor', 'alerts'],
    queryFn: () => InstructorManagementService.getInstructorProgramAlerts({}),
  });
}
