import './ProfileNameEdit.css';
import { useUserInfo } from '@/contexts/userInfo';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { Modal } from '@cae/cobalt-react';
import { useNavigate } from 'react-router-dom';
import { UserProfileRequest } from '@/open-api';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import {
  ProfileInfo,
  ProfileNameEditForm,
} from '../profile-name-edit-form/ProfileNameEditForm';
import { useProfileMutation } from '../../api/useProfileMutation';
import { toastMessages } from '@/shared/helpers/toastMessages';

let defaultValues: ProfileInfo = {
  firstName: '',
  lastName: '',
  middleName: '',
  salutation: 'Mr.',
  nickname: '',
  active: true,
  title: '',
  phone: '',
};

function ProfileNameEdit(): JSX.Element {
  const { width } = useViewportSize();
  const { firstName, lastName, middleName, salutation, title, isActive, id } =
    useUserInfo();
  const navigate = useNavigate();
  defaultValues = {
    ...defaultValues,
    firstName: firstName,
    lastName: lastName,
    middleName: middleName ?? '',
    salutation: salutation ?? 'Mr.',
    title: title ?? '',
    active: isActive,
  };
  const onSuccessCallback = (): void => {
    navigate(-1);
    toastMessages.success('Profile edited successfully');
  };

  const onErrorCallback = (): void => {
    navigate(-1);
    toastMessages.error('Error in updating profile');
  };

  const { mutate: editProfileMutate, isLoading } = useProfileMutation({
    onSuccessCallback,
    onErrorCallback,
  });

  const onSubmit = async (data: UserProfileRequest): Promise<void> => {
    editProfileMutate({
      id,
      data,
    });
  };

  const handleClose = (): void => {
    navigate(-1);
  };

  return (
    <Modal
      animate
      className="reports-details--dialog"
      dismissible
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
    >
      <ProfileNameEditForm defaultValues={defaultValues} onSubmit={onSubmit} />
      {isLoading && (
        <div className="profile-edit-loader-overlay">
          <LoaderWithLabel title="Editing profile" />
        </div>
      )}
    </Modal>
  );
}

export default ProfileNameEdit;
