import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { DocumentDto } from '@/open-api';
import { useHasPermission } from '@/shared/hooks/useHasPermission';

import {
  DirectoryButton,
  DocumentLink,
  DownloadDocumentAction,
} from '../index';

import './DocumentListing.css';

interface Props {
  data: DocumentDto[];
  onFolderClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function DocumentListing({
  data = [],
  onFolderClick,
}: Readonly<Props>): JSX.Element {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string>('');

  const hasPermission = useHasPermission();
  const hasDownloadPermission = hasPermission('document_download');

  return (
    <div className="document-listing">
      <header>
        <h6>Name</h6>
      </header>
      <ul className="items">
        {data.map(d => (
          <li
            key={`row-${d.id}`}
            data-selected={d.id === selected ? 'true' : 'false'}
          >
            {d.isFolder ? (
              <DirectoryButton
                data={d}
                onClick={e => {
                  e.preventDefault();
                  setSelected(d.id);
                }}
                onDoubleClick={onFolderClick}
              />
            ) : (
              <DocumentLink
                data={d}
                onClick={e => {
                  e.preventDefault();
                  setSelected(d.id);
                  navigate(d.id);
                }}
              />
            )}
            {!d.isFolder && d.id ? (
              <span className="actions">
                <DownloadDocumentAction
                  data={d}
                  permitted={hasDownloadPermission}
                />
              </span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
}
