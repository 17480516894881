import { useGetProfileQuery } from '../api/useGetProfileQuery';

export const useHasPermission = (): ((permissions: string) => boolean) => {
  const profileQuery = useGetProfileQuery();

  const hasPermission = (permissions: string): boolean => {
    if (profileQuery?.data?.userPermissions) {
      const currentPermission = profileQuery.data.userPermissions.filter(
        _permission => {
          return (
            _permission.taskValue === permissions && _permission.enable === true
          );
        }
      );
      if (currentPermission && currentPermission.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  return hasPermission;
};
