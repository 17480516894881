import * as React from 'react';
import { Button, Icon } from '@cae/cobalt-react';
import HasAccess from '@/shared/components/has-access/HasAccess';

export function RequestTrainingButton({
  className = '',
  label,
  style = {},
}: {
  className?: string;
  label: string;
  style?: React.CSSProperties;
}): JSX.Element {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();

    // TODO: Add it to settings
    const url = `${import.meta.env.VITE_REQUEST_NEW_RESERVATION_URL}`;
    window.open(url, '_blank');
  };

  const rootProps = {
    className,
    style,
  };

  return (
    <HasAccess permissions="reservations_request_training">
      <div {...rootProps}>
        <Button type="button" size="md" variant="primary" onClick={handleClick}>
          {label}
          <Icon id="external-link-outline" size="sm" />
        </Button>
      </div>
    </HasAccess>
  );
}
