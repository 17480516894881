import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, OrganizationManagementService } from '@/open-api';

interface Config {
  onErrorCallback: (error: ApiError) => void;
  onSuccessCallback: () => void;
}

export const useDeleteVehicleFromOrganizationMutation = (
  config: Config
): UseMutationResult<
  unknown,
  ApiError,
  { organizationId: string; vehicleTypeId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      vehicleTypeId,
    }: {
      organizationId: string;
      vehicleTypeId: string;
    }) => {
      const response =
        await OrganizationManagementService.deleteOrganizationProfileVehicleType(
          {
            id: organizationId,
            vehicleTypeId: vehicleTypeId,
          }
        );
      return response;
    },
    onSuccess: () => {
      config.onSuccessCallback();
      queryClient.invalidateQueries({ queryKey: ['companyProfile'] });
    },
    onError: (error: ApiError) => {
      config.onErrorCallback(error);
    },
  });
};
