import { useEffect, useState } from 'react';
import { ProgressLine } from '@cae/cobalt-react';
import Loader from '../../../assets/images/loading.svg';
import './Loader.css';

interface LoaderProps {
  title: string;
}
function LoaderWithLabel(props: LoaderProps): JSX.Element {
  const { title } = props;
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="loader">
      <div className="loader-main">
        {' '}
        <img src={Loader} alt="loader" />
      </div>
      <ProgressLine className="loader-progress" value={progress} />
      <div>{title}</div>
    </div>
  );
}

export default LoaderWithLabel;
