import { useState, useEffect } from 'react';
import './CompanyContainer.css';
import { Outlet, useNavigate } from 'react-router-dom';

const companyProfileDetails = [
  {
    name: 'Core contact information',
    path: '/company-profile/contact-info',
  },
  {
    name: 'Fleet information',
    path: '/company-profile/fleet-info',
  },
  {
    name: 'Company information',
    path: '/company-profile/company-info',
  },
  {
    name: 'Billing ',
    path: '/company-profile/billing',
  },
];

export function CompanyContainer(): JSX.Element {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState<string>('/company-profile/contact-info');

  useEffect(() => {
    navigate(tabs);
  }, [tabs, navigate]);

  const tabsHandler = (path: string): void => {
    setTabs(path);
  };

  return (
    <div className="company-container">
      <div className="__tabs">
        {companyProfileDetails.map(tab => (
          <div
            role="tab"
            key={tab.name}
            className={`${tabs === tab.path ? '__active-tab' : '__inactive-tab'}`}
            onClick={() => tabsHandler(tab.path)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                tabsHandler(tab.path);
              }
            }}
            aria-selected={tabs === tab.path}
            tabIndex={0}
          >
            <p>{tab?.name}</p>
          </div>
        ))}
      </div>
      <Outlet />
    </div>
  );
}

export default CompanyContainer;
