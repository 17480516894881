import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import type { ColumnDef } from '@tanstack/react-table';

import {
  Button,
  Icon,
  Modal,
  ScreenContent,
  ScreenHeader,
} from '@cae/cobalt-react';
import { DocumentDto } from '@/open-api';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { CobaltTable } from '@/shared/components/cobalt-table/CobaltTable';

import { useDocumentsQuery } from '@/features/documents/api/useDocumentsQuery';
import useSubmittedDocumentsFilter from '@/features/documents/hooks/useSubmittedDocumentsFilter';
import {
  DocumentStatus,
  FilterModal,
  SearchDocumentsInput,
} from '@/features/documents/components';

import type { SubmittedDocumentsFilters } from '@/features/documents/components/submitted-documents/filter-modal/useFilterModalReducer';

const columns: ColumnDef<DocumentDto>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    cell: info => (
      <>
        <Icon id="file-solid" className="file-icon" /> {info.getValue<string>()}
      </>
    ),
  },
  {
    header: 'Case number',
    accessorKey: 'caseNumber',
    cell: info =>
      info.getValue<string>() ? (
        info.getValue<string>()
      ) : (
        <span className="case-number">(Pending)</span>
      ),
  },
  {
    header: 'Submitted at',
    accessorKey: 'submittedAt',
    cell: info =>
      `${format(new Date(info.getValue<string>()), 'dd-MMM-yyyy')} at ${format(
        new Date(info.getValue<string>()),
        'hh:mm:ss a'
      )}`,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: info => (
      <DocumentStatus
        value={info.getValue<{ status: string; shortMessage: string }>()}
      />
    ),
  },
];

const filterOptions: SubmittedDocumentsFilters = {
  status: ['submitted', 'in-review', 'completed'],
};

export function SubmittedDocumentsPage(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const hasPermission = useHasPermission();

  const [keyword, setKeyword] = React.useState<string>('');

  const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false);
  const { filters, resetFilters, toggleAllFilters, filterCounter } =
    useSubmittedDocumentsFilter();
  const toggleFiltersModal = (): void => {
    setFiltersOpen(!filtersOpen);
  };

  const { data, isError, isSuccess, isInitialLoading } = useDocumentsQuery({
    type: 'submitted',
    filters,
    searchText: keyword,
  });

  const handleAddDocumentsClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/documents/submitted/submit');
  };

  const handleRowClick = (data: DocumentDto): void => {
    if (data && data.id && hasPermission('view_document_details')) {
      navigate(`/documents/submitted/${data.id}`);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <ScreenHeader elevation="base">
          <hgroup>
            <h2>Submitted documents</h2>
          </hgroup>
          <div className="main-cta">
            {hasPermission('document_upload') ? (
              <Button
                type="button"
                data-testid="submitDocumentButton"
                variant="primary"
                size="md"
                onClick={handleAddDocumentsClick}
              >
                Submit document
              </Button>
            ) : null}
          </div>
          <div className="toolbar">
            <SearchDocumentsInput
              keyword={keyword}
              setKeyword={setKeyword}
              isSearchHintVisible={false}
              type="submitted"
            />
            <Button
              type="button"
              variant="ghost"
              onClick={toggleFiltersModal}
              disabled={isInitialLoading}
              data-testid="submittedDocumentFilterPanel"
            >
              <Icon id="filter-outline" size="sm" />
              {`Filters${filterCounter() ? ` (${filterCounter()})` : ''}`}
            </Button>
          </div>
        </ScreenHeader>
        <ScreenContent>
          {isInitialLoading && (
            <DataLoadingGrid numberRows={15} numberColumns={4} />
          )}
          {isError && <DataLoadingError />}
          {isSuccess && data?.items && data?.items?.length < 1 && <NoData />}
          {isSuccess && data?.items && data?.items?.length > 0 && (
            <CobaltTable
              className="submitted-documents"
              data={data.items || []}
              columns={columns}
              handleRowClick={handleRowClick}
              pagination={false}
              defaultSortBy={[{ id: 'submittedAt', desc: true }]}
            />
          )}
        </ScreenContent>
      </div>

      <Outlet />

      <Modal
        open={filtersOpen}
        onCancel={toggleFiltersModal}
        placement={width < 480 ? 'bottom' : 'right'}
        dismissible
        size="sm"
        className="filter--dialog"
      >
        {filtersOpen && (
          <FilterModal
            filterOptions={filterOptions}
            filters={filters}
            resetFilters={resetFilters}
            toggleAllFilters={toggleAllFilters}
            toggleFilterSection={toggleFiltersModal}
          />
        )}
      </Modal>
    </>
  );
}

SubmittedDocumentsPage.displayName = 'SubmittedDocumentsPage';
