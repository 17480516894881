import { MsalAuthenticationTemplate } from '@azure/msal-react';
import OrganizationSelector from '@/features/organization-selector/components/OrganizationSelector';
import { InteractionType } from '@azure/msal-browser';
import LoaderWithLabel from '@/shared/components/loader/Loader';

function Loader(): JSX.Element {
  return <LoaderWithLabel title="Loading..." />;
}

function OrganizationSelectorPage(): JSX.Element {
  return (
    <div>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={Loader}
      >
        <OrganizationSelector />
      </MsalAuthenticationTemplate>
    </div>
  );
}

export default OrganizationSelectorPage;
