import { useUserInfo } from '@/contexts/userInfo';
import { AlertMessage } from '@cae/cobalt-react';
import { useNavigate } from 'react-router-dom';
import './ChangeRequestBanner.css';

export function ChangeRequestBanner(): JSX.Element {
  const { firstName } = useUserInfo();
  const navigate = useNavigate();
  const handleChangeRequestClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/my-profile/info/request');
  };
  return (
    <AlertMessage
      className="user-profile--alert-message"
      size="md"
      type="info"
      heading={`Some fields can only be edited by ${firstName}.`}
    >
      <p className="user-profile--children">
        In order to update any information below, please submit a{' '}
        <button
          className="anchor"
          type="button"
          data-action="swap-view"
          onClick={handleChangeRequestClick}
          data-view="change-request"
        >
          change request
        </button>
      </p>
    </AlertMessage>
  );
}
