import * as React from 'react';
import { AppFrame } from '@cae/cobalt-react';
import { useNavigate } from 'react-router-dom';

import { AppNavigation } from '../app-navigation/AppNavigation';
import AppExtensions from '../app-extension/AppExtensions';
import { UserAppNavigation } from '../user-app-navigation/UserAppNavigation';

type ApplicationFrameProps = {
  children: React.ReactNode;
};

function ApplicationFrame({ children }: ApplicationFrameProps): JSX.Element {
  const navigate = useNavigate();

  const handleAppNameClick = (): void => {
    navigate('/home');
  };
  return (
    <AppFrame
      className="cae-connect--main-frame"
      appName="Connect"
      userNavigation={<UserAppNavigation />}
      navigation={<AppNavigation />}
      extensions={<AppExtensions />}
      onAppNameClick={handleAppNameClick}
    >
      {children}
    </AppFrame>
  );
}

export default ApplicationFrame;
