import { AppExtensions } from '@cae/cobalt-react';

import { SupportMenu } from '@/features/support-menu';
import { useHasPermission } from '@/shared/hooks/useHasPermission';

function Extensions(): JSX.Element {
  const hasPermission = useHasPermission();

  if (!hasPermission('view_customer_care_contacts')) {
    return <></>;
  }

  return (
    <AppExtensions>
      <SupportMenu />
    </AppExtensions>
  );
}

export default Extensions;
