import { Icon } from '@cae/cobalt-react';

function LogoutButton({
  handleLogout,
}: {
  handleLogout: (arg0: string) => void;
}): JSX.Element {
  const handleLogoutClick = (): void => {
    const broadcastChannel = new BroadcastChannel('logout-channel');
    broadcastChannel.postMessage({ type: 'logout' });

    handleLogout('redirect');
  };

  return (
    <ul className="profile-menu">
      <li>
        <button
          type="button"
          onClick={handleLogoutClick}
          tabIndex={0}
          data-toggle-menu="true"
        >
          <Icon id="log-out-outline" size="sm" />
          Logout
        </button>
      </li>
    </ul>
  );
}

export default LogoutButton;
