/* eslint import/no-unresolved: "off" */
import { useMsal } from '@azure/msal-react';
import { Suspense, lazy } from 'react';
import ErrorBoundary from '@/shared/components/error-boundary/ErrorBoundary';
import { DataLoadingError } from '@/shared/components';
import { ScheduleSessionOverviewProps } from 'schedule_mfe/ScheduleSessionOverview';

export function InstructorsScheduleMfe(): JSX.Element {
  const { instance } = useMsal();

  const ScheduleSessionOverview = lazy<React.FC<ScheduleSessionOverviewProps>>(
    () => import('schedule_mfe/ScheduleSessionOverview')
  );
  return (
    <ErrorBoundary fallback={<DataLoadingError />}>
      <Suspense fallback={<div>Loading...</div>}>
        <ScheduleSessionOverview msalInstance={instance} />
      </Suspense>
    </ErrorBoundary>
  );
}
