import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';
import './CompanyAuthorities.css';
import { CompanyAuthoritiesForm } from '../company-authorities-form/CompanyAuthoritiesForm';
import { useUserInfo } from '@/contexts/userInfo';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';

function CompanyAuthorities(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { selectedOrganization } = useUserInfo();
  const { data: { authorities, certCode } = {} } =
    useCompanyProfileByOrganizationIdQuery(selectedOrganization?.id);
  const defaultValues = { authorities: authorities, certCode: certCode };
  const handleClose = (): void => {
    navigate(-1);
  };
  return (
    <Modal
      animate
      dismissible
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
    >
      <CompanyAuthoritiesForm
        defaultValues={defaultValues}
        handleClose={handleClose}
      />
    </Modal>
  );
}
export default CompanyAuthorities;
