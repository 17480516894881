import * as React from 'react';

export function NoData({
  heading = 'No data',
  image,
  message = 'Hmm, nothing to see here.',
  ...rest
}: React.ComponentPropsWithoutRef<'article'> & {
  heading?: string | React.ReactNode;
  image?: React.ReactNode;
  message?: string | React.ReactNode;
}): JSX.Element {
  const rootProps = {
    ...rest,
    className: `co-empty-state ${rest.className ?? ''}`,
    'data-status': 'no-data',
  };

  return (
    <article {...rootProps}>
      <div>
        {image ?? (
          <img
            src="/img/empty-states/no-related-data.svg"
            alt="No data, man!"
          />
        )}
        <h4>{heading}</h4>
        <p>{message}</p>
      </div>
    </article>
  );
}
