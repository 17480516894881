/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutoCancellationDetailsDto } from './AutoCancellationDetailsDto';
import type { EtcaDto } from './EtcaDto';
import type { RegulatoryAgencyDto } from './RegulatoryAgencyDto';
import type { ReservationStatusDto } from './ReservationStatusDto';
import type { ScheduleSummaryDto } from './ScheduleSummaryDto';
import type { TrainingRecordsSummaryDto } from './TrainingRecordsSummaryDto';
import type { TsaDto } from './TsaDto';
/**
 * List with requested items
 */
export type ReservationResponseDto = {
  /**
   * Reservation id
   */
  reservationId?: string;
  /**
   * Scheduler reservation id
   */
  schedulerReservationId?: string;
  /**
   * Reservation status
   */
  status?: ReservationResponseDto.status;
  clientName?: string;
  aircraftName?: string;
  courseName?: string;
  /**
   * Start date of the training event
   */
  startDate?: string;
  /**
   * End date of the training event
   */
  endDate?: string;
  location?: string;
  reservationStatus?: ReservationStatusDto;
  autoCancellation?: AutoCancellationDetailsDto;
  etca?: EtcaDto;
  tsa?: TsaDto;
  schedule?: ScheduleSummaryDto;
  records?: TrainingRecordsSummaryDto;
  regulatoryAgencies?: Array<RegulatoryAgencyDto>;
};
export namespace ReservationResponseDto {
  /**
   * Reservation status
   */
  export enum status {
    PROVISIONAL = 'Provisional',
    CONFIRMED = 'Confirmed',
    IN_PROGRESS = 'InProgress',
    RECENTLY_COMPLETED = 'RecentlyCompleted',
    UNKNOWN = 'Unknown',
  }
}
