import { useGetRecordsDetailsQuery } from '@/features/record-details/api/useGetRecordsDetails';
import { LoadingRecords } from '@/features/record-details/components/empty-states/LoadingRecords';
import { RecordContactInfo } from '@/features/record-details/components/record-contact-info/RecordContactInfo';
import { RecordDetailsCardList } from '@/features/record-details/components/record-details-card-list/RecordDetailsCardList';
import { RecordDetailsTableList } from '@/features/record-details/components/record-details-table-list/RecordDetailsTableList';
import { RecordQuickInfoFragment } from '@/features/record-details/components/record-quick-info/RecordQuickInfoFragment';
import { RecordDetailsResponse } from '@/open-api';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { useParams } from 'react-router-dom';

function RecordOverviewPage(): JSX.Element {
  const { recordId = '' } = useParams();
  const { isLoading, isError, data } =
    useGetRecordsDetailsQuery<RecordDetailsResponse>(recordId);
  const { width } = useViewportSize();
  return (
    <HasAccess permissions="view_records_details">
      {recordId ? (
        <section
          className="dialog-details__page overview"
          data-record-id={recordId}
        >
          {!data && isLoading && <LoadingRecords />}
          {!data && isError && <p>Error loading records details</p>}
          {data ? (
            <>
              <RecordQuickInfoFragment
                recordId={recordId}
                documentId={data?.documentId}
                schedulerReservationId={data?.schedulerReservationId}
              />
              {width > 650 ? (
                <RecordDetailsTableList recordId={recordId} />
              ) : (
                <RecordDetailsCardList />
              )}

              <RecordContactInfo recordId={recordId} />
            </>
          ) : null}
        </section>
      ) : (
        <p>Record id is missing</p>
      )}
    </HasAccess>
  );
}

export default RecordOverviewPage;
