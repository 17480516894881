import { useLocation, useNavigate, useParams, Outlet } from 'react-router-dom';
import { Modal, SplitPanel } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

import './css/DocumentDetailsPage.css';

export function DocumentDetailsPage(): JSX.Element | null {
  const location = useLocation();
  const navigate = useNavigate();
  const { documentId = '' } = useParams();
  const { width } = useViewportSize();

  const handleCloseDocument = (): void => {
    const pathEls = location.pathname.split('/');
    const closePath = pathEls.slice(0, pathEls.length - 1).join('/');
    navigate(closePath);
  };

  if (!documentId) return null;

  return width <= 768 + 22.25 * 16 ? (
    <Modal
      animate
      className="document-details--dialog"
      dismissible
      onCancel={handleCloseDocument}
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
    >
      <Outlet />
    </Modal>
  ) : (
    <SplitPanel
      className="document-details--panel"
      onToggle={handleCloseDocument}
      size="sm"
    >
      <Outlet />
    </SplitPanel>
  );
}

DocumentDetailsPage.displayName = 'DocumentDetailsPage';
