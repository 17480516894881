import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Icon } from '@cae/cobalt-react';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { NoData } from '@/shared/components';

import { useRecentlyViewedDocumentsQuery } from '@/features/documents/api/useRecentlyViewedDocumentsQuery';
import { useUserInfo } from '@/contexts/userInfo';

import { DownloadDocumentAction } from '../download-document-action/DownloadDocumentAction';

import './RecentlyViewedListing.css';

export function RecentlyViewedListing(): JSX.Element {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string>('');

  const { id } = useUserInfo();
  const hasPermission = useHasPermission();
  const hasDownloadPermission = hasPermission('document_download');

  const { data: listOfViewedDocuments = [] } =
    useRecentlyViewedDocumentsQuery(id);

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowId: string
  ): void => {
    e.preventDefault();
    setSelected(rowId);
    navigate(rowId);
  };

  if (listOfViewedDocuments.length < 1) {
    return (
      <NoData
        heading="Well…"
        message="You have not looked at any documents yet."
      />
    );
  }

  return (
    <div className="document-listing recently-viewed">
      <header>
        <h6>Name</h6>
        <h6>Viewed on</h6>
        <h6>&nbsp;</h6>
      </header>
      <ul className="items">
        {Array.isArray(listOfViewedDocuments) &&
          listOfViewedDocuments.map(d => {
            const viewedAt = d.lastViewedAt
              ? format(new Date(d.lastViewedAt), 'dd-MMM-yyyy')
              : '?';
            return (
              <li
                key={d.id}
                data-selected={d.id === selected ? 'true' : 'false'}
              >
                <Link
                  className="name"
                  data-type="document"
                  to={d.id}
                  onClick={e => handleClick(e, d.id)}
                >
                  <Icon id="file-solid" size="md" />
                  {d.name}
                </Link>
                <span className="date">{viewedAt}</span>
                <span className="actions">
                  <DownloadDocumentAction
                    data={d}
                    permitted={hasDownloadPermission}
                  />
                </span>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

RecentlyViewedListing.displayName = 'RecentlyViewedListing';
