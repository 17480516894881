import { MyProfileInfo } from '@/features/my-profile/components/MyProfileInfo/MyProfileInfo';
import ProfileNameEdit from '@/features/my-profile/components/Profile-Name-Edit/ProfileNameEdit';
import ProfileChangeRequest from '@/features/my-profile/components/change-request/ChangeRequest';
import EditWorkInfo from '@/features/my-profile/components/edit-work-info/EditWorkInfo';
import { WorkInfo } from '@/features/my-profile/components/my-profile-work-info/workinfo';
import { PasswordAndSecurity } from '@/features/my-profile/components/password-reset/PasswordAndSecurity';
import MyProfilePage from '@/pages/my-profile/MyProfilePage';

export const profileRoutes = {
  path: 'my-profile',
  element: <MyProfilePage />,
  children: [
    {
      path: 'info',
      element: <MyProfileInfo />,
      children: [
        {
          path: 'change-name',
          element: <ProfileNameEdit />,
        },
        {
          path: 'request',
          element: <ProfileChangeRequest />,
        },
      ],
    },
    {
      path: 'work-info',
      element: <WorkInfo />,
      children: [
        {
          path: 'edit',
          element: <EditWorkInfo />,
        },
      ],
    },
    {
      path: 'password-security',
      element: <PasswordAndSecurity />,
    },
  ],
};
