import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import { Outlet } from 'react-router-dom';

function Loader(): JSX.Element {
  return <LoaderWithLabel title="Loading..." />;
}

function SFConnectPage(): JSX.Element {
  return (
    <div>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={Loader}
      >
        <Outlet />
      </MsalAuthenticationTemplate>
    </div>
  );
}

export default SFConnectPage;
