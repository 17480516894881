import { Tag } from '@cae/cobalt-react';
import { Link, useLocation } from 'react-router-dom';

import { ReservationDetailsResponse } from '@/open-api';

import './ReservationHeader.css';
import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { BackNavigation } from '../back-navigation/BackNavigation';

export const statusColors: {
  [key: string]: string;
} = {
  neutral: 'standard',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
  pending: 'yellow',
  unknown: 'standard',
};

export function ReservationHeader({
  data,
  reservationId,
}: {
  data: ReservationDetailsResponse;
  reservationId: string;
}): JSX.Element {
  const location = useLocation();
  const { state: locationState } = location;
  const hasPermission = useHasPermission();
  const currentUrl = window.location.href;
  const targetUrl = currentUrl.includes('/home')
    ? `/home/${reservationId}/request`
    : `/reservations/${reservationId}/request`;

  return (
    <header>
      {locationState &&
        locationState.originalReservationId &&
        locationState.originalSchedulerReservationId && (
          <BackNavigation
            reservationId={locationState.originalReservationId}
            schedulerReservationId={
              locationState.originalSchedulerReservationId
            }
          />
        )}
      <h5>
        <span className="id">Reservation #{data.schedulerReservationId}</span>
        <Tag
          color={
            (statusColors[
              data.reservationStatus?.style
                ? data.reservationStatus.style?.toLowerCase()
                : 'unknown'
            ] as 'standard' | 'green' | 'red' | 'yellow' | undefined) ??
            'standard'
          }
          title={data.reservationStatus?.longMessage}
        >
          {data.reservationStatus?.message}
        </Tag>
      </h5>

      <h2>{data?.client?.name ?? 'Client name?'}</h2>
      {hasPermission('reservations_change_request') &&
      (data.reservationStatus?.status === 'Provisional' ||
        data.reservationStatus?.status === 'Confirmed') ? (
        <p>
          In order to make changes to this reservation, please submit{' '}
          <Link
            to={targetUrl}
            data-testid="reservationDetailsChangeRequest"
            state={{
              originalSchedulerReservationId: data.schedulerReservationId,
            }}
          >
            a change request
          </Link>
        </p>
      ) : null}
    </header>
  );
}
