import { Icon } from '@cae/cobalt-react';

export function TechIssuesHelp(): JSX.Element {
  return (
    <div className="fragment">
      <h3>Technical issues or app help?</h3>
      <p>
        If you are having issues or need help with CAE Connect, contact our 24/7
        support.
      </p>
      <p>
        <a
          href="https://help.cae.com/home?origin=cae_connect"
          target="_blank"
          rel="noreferrer"
        >
          help.cae.com
          <Icon id="external-link-outline" size="sm" />
        </a>
      </p>
    </div>
  );
}
