import { TaskManagementService, TaskResponse } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetTasksQuery(): UseQueryResult<TaskResponse[], unknown> {
  return useQuery({
    queryKey: ['getAllTasks'],
    queryFn: () => TaskManagementService.getAllTasks(),
    select: data => data,
  });
}
