import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DashboardService, PagedListOfNotificationResponse } from '@/open-api';

function useNotificationQuery(): UseQueryResult<
  PagedListOfNotificationResponse,
  unknown
> {
  return useQuery<PagedListOfNotificationResponse>({
    queryKey: ['notifications'],
    queryFn: () => DashboardService.getNotifications({}),
  });
}

export default useNotificationQuery;
