import { TaskManagementService, TaskResponse, TaskRequest } from '@/open-api';
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

type RolesTaskMutationCallback = {
  onSuccessCallback: (data: TaskResponse) => void;
  onErrorCallback: () => void;
};

export const useCreateTaskMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: RolesTaskMutationCallback): UseMutationResult<
  unknown,
  unknown,
  {
    data: TaskRequest;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ data }: { data: TaskResponse }) => {
      const response = await TaskManagementService.createTask({
        requestBody: data,
      });
      return response;
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: ['createTask'] });
        onSuccessCallback(response);
      },
      onError: error => {
        onErrorCallback();
        throw error;
      },
    }
  );
};
