/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InvoicePaymentStatusDto = {
  /**
   * Status
   */
  status?: InvoicePaymentStatusDto.status;
  /**
   * Status style
   */
  style?: InvoicePaymentStatusDto.style;
};
export namespace InvoicePaymentStatusDto {
  /**
   * Status
   */
  export enum status {
    PAID = 'Paid',
    UNPAID = 'Unpaid',
    NOT_APPLICABLE = 'NotApplicable',
  }
  /**
   * Status style
   */
  export enum style {
    NEUTRAL = 'neutral',
    PENDING = 'pending',
    WARNING = 'warning',
    DANGER = 'danger',
    SUCCESS = 'success',
  }
}
