import * as React from 'react';
import { Card } from '@cae/cobalt-react';
import type { PaginationState } from '@tanstack/react-table';
import type { QueryStatus } from '@tanstack/react-query';

import {
  DataLoadingError,
  NoData,
  DataLoading,
} from '@/shared/components/empty-states';

import { CheckAirmenPerPlatformDto } from '@/open-api';
import { BasicPagination } from '@/shared/components';

import { AirmenNumber } from '../airmen-number/AirmenNumber';

interface Props {
  data?: CheckAirmenPerPlatformDto[];
  queryStatus: QueryStatus;
  pageIndex: number;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
}

export function InstructorsCards({
  data,
  queryStatus,
  pageIndex,
  setPagination,
  totalPages,
}: Props): JSX.Element {
  return (
    <>
      {queryStatus === 'loading' && <DataLoading />}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data && data.length < 1 && <NoData />}
      {queryStatus === 'success' && data && data.length > 0 && (
        <div className="instructors--card-listing">
          {totalPages > 1 && (
            <p className="page-info">
              Showing page {pageIndex + 1} of {totalPages}
            </p>
          )}
          {data.map((d, i) => (
            <Card elevation="skim" key={`i-${i}`}>
              <h4>{d.location}</h4>
              <dl>
                <dt>Program</dt>
                <dd>{d.platform}</dd>
                <dt>Approved check airmen</dt>
                <dd>
                  <AirmenNumber value={d.approvedCheckAirmen} />
                </dd>
              </dl>
            </Card>
          ))}
          {totalPages > 1 && (
            <BasicPagination
              pageIndex={pageIndex}
              setPagination={setPagination}
              totalPages={totalPages}
            />
          )}
        </div>
      )}
    </>
  );
}
