import PasswordResetComponent from './PasswordReset';

export function PasswordAndSecurity(): JSX.Element {
  return (
    <div>
      <h2>Password and security</h2>
      <PasswordResetComponent />
    </div>
  );
}
