import { Modal } from '@cae/cobalt-react';
import CompanyFleetForm from '../company-fleet-form/CompanyFleetForm';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { useNavigate } from 'react-router-dom';

function CompanyFleet(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const handleClose = (): void => {
    navigate(-1);
  };
  return (
    <Modal
      animate
      dismissible
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
    >
      <CompanyFleetForm />
    </Modal>
  );
}

export default CompanyFleet;
