import { useTranslation } from 'react-i18next';

export function WelcomeVideo(): JSX.Element {
  const { t } = useTranslation();
  return (
    <figure className="with-video">
      <video controls>
        <source src="/videos/landingVideo.mp4" type="video/mp4" />
        <p>Your browser does not support HTML5 video.</p>
        <p>
          Download <a href="/videos/landingVideo.mp4">MP4 version</a>
        </p>
      </video>
      <figcaption>
        <h5>{t('firstTimeLogin.videoTitle')}</h5>
        <p>{t('firstTimeLogin.videoSub')}</p>
      </figcaption>
    </figure>
  );
}
