import * as React from 'react';
import { Icon } from '@cae/cobalt-react';
import './Breadcrumbs.css';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

type BreadcrumbProps = {
  parentFolders?: { id: string; name: string }[];
  onFolderClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function Breadcrumbs({
  parentFolders = [],
  onFolderClick,
}: BreadcrumbProps): JSX.Element {
  const { width } = useViewportSize();
  return (
    <nav className="breadcrumbs">
      <button type="button" onClick={onFolderClick}>
        <Icon id="folder-solid" size="md" />
      </button>
      <span>/</span>
      {parentFolders.length > 0 &&
        parentFolders.map((f, i) =>
          i < parentFolders.length - 1 ? (
            <>
              <button
                type="button"
                onClick={onFolderClick}
                value={f.id}
                key={f.id}
              >
                {width < 768 ? (
                  '…'
                ) : (
                  <span
                    style={{
                      display: 'inline-block',
                      pointerEvents: 'none',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {f.name}
                  </span>
                )}
              </button>
              <span>/</span>
            </>
          ) : (
            <span key={f.id}>{f.name}</span>
          )
        )}
    </nav>
  );
}
