import { useState } from 'react';
import { Icon, Modal } from '@cae/cobalt-react';
import './SurveyRedirectModal.css';
import SurveyRedirectInfo from '@/features/survey-redirect-modal/SurveyRedirectInfo';

function SurveyRedirectModal(): JSX.Element {
  const [show, setShow] = useState(false);

  const surveyLink =
    'https://cae.ca1.qualtrics.com/jfe/form/SV_e3g3GNx3KxJ8D54';

  const openSurvey = (): void => {
    if (localStorage.getItem('survey-redirect') === 'true') {
      window.open(surveyLink, '_blank');
    } else setShow(true);
  };

  return (
    <div className="survey-redirect-modal">
      <button onClick={openSurvey} className="survey-nav-button" type="button">
        <Icon id="feedback-outline" className="nav-icon" />
        <span className="survey-nav-button-label">
          Give feedback
          <Icon id="external-link-outline" size="sm" />
        </span>
      </button>
      <Modal
        animate
        onCancel={() => setShow(false)}
        open={show}
        placement="center"
        size="sm"
        className="survey-modal"
      >
        {show && (
          <SurveyRedirectInfo setShow={setShow} surveyLink={surveyLink} />
        )}
      </Modal>
    </div>
  );
}

export default SurveyRedirectModal;
