import {
  SFConnectCreateOrganization,
  SFConnectError,
  SFConnectLoader,
  SFConnectUnauthorized,
  SFConnectWrapper,
} from '@/features/sf-connect';
import SFConnectMultipleAccounts from '@/features/sf-connect/components/sf-connect-multiple-accounts/SFConnectMultipleAccounts';
import SFConnectPage from '@/pages/sf-connect-page/SFConnectPage';

export const sfRoutes = {
  path: 'sf-connect',
  element: <SFConnectPage />,
  children: [
    {
      path: 'create',
      element: <SFConnectWrapper />,
      children: [
        {
          path: 'organization',
          element: <SFConnectCreateOrganization />,
        },
        {
          path: 'error',
          element: <SFConnectError />,
        },
        {
          path: 'multiple-accounts',
          element: <SFConnectMultipleAccounts />,
        },
      ],
    },
    {
      path: 'home',
      element: <SFConnectLoader />,
    },
    {
      path: 'unauthorized',
      element: <SFConnectUnauthorized />,
    },
  ],
};
