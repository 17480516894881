import { format, parseISO } from 'date-fns';

function getFormattedDate(
  dateString: string | undefined,
  dateFormat: string = 'yyyy-MMM-dd'
): string {
  return dateString ? format(parseISO(dateString), dateFormat) : '';
}

export default getFormattedDate;
