import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { WorkInfoTable } from './WorkinfoTable';
import { WorkInfoCard } from './WorkinfoCard';

export function WorkInfo(): JSX.Element {
  const { width } = useViewportSize();
  return (
    <div>
      <h1>Work Information</h1>
      {width > 650 ? <WorkInfoTable /> : <WorkInfoCard />}
    </div>
  );
}
