import { Button, Card, Dialog, Icon } from '@cae/cobalt-react';
import './CompanyFleetInfo.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Platform } from '@/open-api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUserInfo } from '@/contexts/userInfo';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import { useDeleteVehicleFromOrganizationMutation } from '../../api/useDeleteVehicleFromOrganizationMutation';
import { toastMessages } from '@/shared/helpers/toastMessages';

type ModalActionsProps = {
  readonly handleConfirm: React.MouseEventHandler;
  readonly handleCancel: React.MouseEventHandler;
};

function DiscardModalActions({
  handleConfirm,
  handleCancel,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Delete
      </Button>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </>
  );
}

export function CompanyFleetInfo(): JSX.Element {
  const navigate = useNavigate();
  const { selectedOrganization } = useUserInfo();
  const { data: companyData } = useCompanyProfileByOrganizationIdQuery(
    selectedOrganization?.id
  );
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
  const [platformToDelete, setPlatformToDelete] = useState('');
  const [platformIdToDelete, setPlatformIdToDelete] = useState<
    string | undefined
  >('');
  const { fleetInfoEdit } = useFlags();
  const openAddEditModal = (route: string, idx: number | null): void => {
    if (fleetInfoEdit) navigate(route, { state: { idx } });
  };
  const onSuccessCallback = (): void => {
    toastMessages.success('Fleet deleted successfully');
  };

  const onErrorCallback = (): void => {
    toastMessages.error('Error in deleting fleet');
  };
  const { mutate: deleteVehicle } = useDeleteVehicleFromOrganizationMutation({
    onSuccessCallback,
    onErrorCallback,
  });

  const onButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    vehicleId: string | undefined,
    vehicleTypeId: string
  ): void => {
    event.stopPropagation();
    setPlatformIdToDelete(vehicleId);
    setPlatformToDelete(vehicleTypeId);
    setIsDiscardDialogOpen(true);
  };

  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const handleDiscard = (): void => {
    if (platformIdToDelete) {
      deleteVehicle({
        organizationId: selectedOrganization?.id ?? '',
        vehicleTypeId: platformIdToDelete,
      });
    }
    setIsDiscardDialogOpen(false);
  };

  return (
    <div className="company-contact-info">
      <h1 className="__company-heading">Fleet information</h1>
      {companyData ? (
        <div className="__cards-list">
          {companyData.platforms.map((fleet: Platform, idx: number) => (
            <Card
              key={fleet.vehicleTypeId}
              onClick={() => openAddEditModal('edit', idx)}
            >
              <h3>{fleet.vehicleTypeId}</h3>
              <p>{fleet.numberOfAircraft}</p>
              <Button
                type="button"
                variant="transparent"
                className="__trash-icon"
                onClick={event =>
                  onButtonClick(event, fleet.id, fleet.vehicleTypeId)
                }
              >
                <Icon id="trash-outline" size="sm" />
              </Button>
              <Icon
                className="__right-icon"
                id="chevron-right-outline"
                size="sm"
              />
            </Card>
          ))}
          <Button
            variant="transparent"
            className="company-fleet__add-platform-link"
            onClick={() => openAddEditModal('add', null)}
          >
            + Add platform
          </Button>
        </div>
      ) : (
        <p>Profile information not available</p>
      )}
      <Outlet />
      <Dialog
        modal
        className="dialog"
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Delete platform?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
        data-testid="delete-fleet-modal"
      >
        <p>
          Please confirm that you wish to delete {platformToDelete} from your
          fleet
        </p>
      </Dialog>
    </div>
  );
}

export default CompanyFleetInfo;
