import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, TextInput } from '@cae/cobalt-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '@/features/first-time-user/ActionButtons';
import './EditWorkInfoForm.css';
const workInfoSchema = z.object({
  title: z
    .string()
    .min(3, { message: 'First name must be at least 3 characters long' })
    .max(50)
    .regex(/^[A-Za-z]+(?: [A-Za-z]+)*$/, {
      message: 'First name must contain only alphabets',
    })
    .optional(),
});
export type WorkInfo = z.infer<typeof workInfoSchema>;
type Props = {
  readonly defaultValues: WorkInfo;
  readonly onSubmit: (data: WorkInfo) => Promise<void>;
  readonly organizationName: string;
};

export function EditWorkInformForm({
  defaultValues,
  onSubmit,
  organizationName,
}: Props): JSX.Element {
  const { control, formState, handleSubmit } = useForm<WorkInfo>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(workInfoSchema),
  });
  const { isValid, isDirty } = formState;
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const handleConfirm = (): void => {
    handleSubmit(onSubmit)();
  };
  const closeHandler = (): void => {
    isDirty ? setIsSaveDialogOpen(true) : window.history.back();
  };

  const saveActions = (): JSX.Element => (
    <>
      <PrimaryButton label="Yes" onClick={() => handleConfirm()} />
      <SecondaryButton label="No" onClick={() => setIsSaveDialogOpen(false)} />
    </>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="profile-name-edit-form">
      <h5>Work Information</h5>
      <h1>{organizationName}</h1>
      <div className="__fields">
        <Controller
          name="title"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <TextInput
              id={field.name}
              label="Title (optional)"
              field={{ ...field, disabled: true, placeholder: 'title' }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
            />
          )}
        />
      </div>
      <Dialog
        modal
        dismissible
        open={isSaveDialogOpen}
        onCancel={() => setIsSaveDialogOpen(false)}
        header={<h2>Save changes?</h2>}
        actions={saveActions}
        placement="center"
        size="sm"
      >
        <p>
          You made changes without saving. Would you like to save your changes?{' '}
        </p>
      </Dialog>
      <div className="__footer">
        <Button
          type="button"
          variant="secondary"
          className="primary-btn-color"
          onClick={closeHandler}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={!isValid || isDirty}
          onClick={handleConfirm}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
