import './LoadingRecords.css';

export function LoadingRecords(): JSX.Element {
  return (
    <div className="loading-record">
      <div>
        <div className="animation">
          <div className="loader--9-squares" />
        </div>
        <h4>Loading record details</h4>
        <p>Just a moment!</p>
      </div>
    </div>
  );
}
