import { OverflowMenu, Icon } from '@cae/cobalt-react';
import { InstructorDetailsByProgram } from '@/open-api';
import './ActionOverflowMenu.css';

interface ActionOverflowMenuProps {
  onBtnClick: (action: string, instructor: InstructorDetailsByProgram) => void;
  instructor: InstructorDetailsByProgram;
}

export function ActionOverflowMenu({
  onBtnClick,
  instructor,
}: Readonly<ActionOverflowMenuProps>): JSX.Element {
  const {
    groundSchoolApprovalState = '',
    simulatorApprovalState = '',
    checkingApprovalState = '',
  } = instructor;

  const approvalList = [
    groundSchoolApprovalState,
    simulatorApprovalState,
    checkingApprovalState,
  ];
  const isApprovalAllowed = approvalList.some(
    item => item === 'UNSELECTED' || item === 'REVOKED'
  );
  const isRevokeAllowed = approvalList.some(item => item === 'APPROVED');
  return (
    <div className="action-overflow-menu">
      {isApprovalAllowed || isRevokeAllowed ? (
        <OverflowMenu keepOpen={false}>
          {isApprovalAllowed && (
            <OverflowMenu.MenuItem
              iconName="checkmark-circle-outline"
              label="Approve"
              onClick={() => onBtnClick('approve', instructor)}
            />
          )}
          {isRevokeAllowed && (
            <OverflowMenu.MenuItem
              iconName="close-circle-outline"
              label="Revoke"
              onClick={() => onBtnClick('revoke', instructor)}
            />
          )}
        </OverflowMenu>
      ) : (
        <Icon id="menu-overflow-outline" />
      )}
    </div>
  );
}
