import { Card, Icon } from '@cae/cobalt-react';

function DashboardRecordAlertCards(): JSX.Element {
  return (
    <div className="dashboard--alert-cards" data-testid="alertCards">
      <Card
        elevation="skim"
        className="no-alerts"
        style={{ gridColumn: '1/6' }}
      >
        <Icon
          className="status-icon success"
          id="checkmark-circle-solid"
          size="md"
        />
        <h3>You have no alerts</h3>
        <p>Good news! You have no alerts left</p>
      </Card>
    </div>
  );
}

export default DashboardRecordAlertCards;
