import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import './RelatedReservationsFragment.css';
import { useSelectRelatedReservations } from '../../api/hooks';

import { RelatedReservationEmptyState } from '../empty-states/RelatedReservationEmptyState';

export function RelatedReservationsFragment({
  reservationId,
  schedulerReservationId = '',
  navigateTo,
}: {
  reservationId: string;
  schedulerReservationId?: string;
  navigateTo: string;
}): JSX.Element {
  const { data } = useSelectRelatedReservations(reservationId);
  return (
    <section className="dialog-details__fragment related-reservations">
      <h3>Related Reservations</h3>
      {data && Array.isArray(data) && data.length ? (
        <>
          <p>
            Here are other reservations for your company that are happening on
            the same date and course.
          </p>
          <ul>
            <li className="thead">
              <span className="name">Name</span>
              <span className="id">Reservation</span>
              <span>&nbsp;</span>
            </li>
            {data.map(r => (
              <li className="tbody" key={`r-${r.reservationId}`}>
                <h4 className="name">{r.client.name}</h4>
                <p className="id">
                  <b>Reservation:</b>
                  {r.schedulerReservationId}
                </p>
                <Link
                  to={`/${navigateTo}/${r.reservationId}`}
                  state={{
                    originalReservationId: reservationId,
                    originalSchedulerReservationId: schedulerReservationId,
                  }}
                >
                  View details <Icon id="arrow-right-solid" size="sm" />
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <RelatedReservationEmptyState>
          <h5>There are no related reservations.</h5>
          <p>Do we need to explain this in some way?</p>
        </RelatedReservationEmptyState>
      )}
    </section>
  );
}
