export const ROLECARDS = [
  {
    name: 'Admin',
    iconName: 'admin',
    description:
      "The Administrator access allows you to fully control your company's accesses and data for records, training information,  documents and billing.",
  },
  {
    name: 'Finance',
    iconName: 'finance',
    description:
      'The Finance access gives you the ability to view invoices, make payments and access other financial functions.',
  },
  {
    name: 'Training',
    iconName: 'training',
    description:
      'The Training access gives you the ability to view and manage bookings, provide data for training and access documents.',
  },
  {
    name: 'Records',
    iconName: 'records',
    description:
      'The Records access gives you the ability to view, download and manage crew records.',
  },
];
