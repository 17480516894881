import * as React from 'react';

import './DataLoadingError.css';

export function DataLoadingError({
  heading = 'Error loading data',
  image,
  message = 'Looks like something went wrong somewhere',
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & {
  heading?: React.ReactNode;
  image?: React.ReactNode;
  message?: React.ReactNode;
}): JSX.Element {
  const rootProps = {
    ...rest,
    className: `co-empty-state ${rest.className ?? ''}`,
    'data-status': 'error',
  };

  return (
    <article {...rootProps}>
      <div>
        {image ?? (
          <img
            src="/img/empty-states/data-error-man.svg"
            alt="Error loading data"
          />
        )}
        <h4>{heading}</h4>

        <p>
          {message} <br /> Try to refresh the page
        </p>
      </div>
    </article>
  );
}
