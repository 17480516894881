import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import { DocumentDto } from '@/open-api';

export function DocumentLink({
  data,
  onClick,
}: {
  data: DocumentDto;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}): JSX.Element {
  return (
    <Link
      className="name"
      data-type="document"
      to={data.id as string}
      onClick={onClick}
    >
      <Icon id="file-solid" size="md" />
      {data.name}
    </Link>
  );
}
