import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Icon, Tag } from '@cae/cobalt-react';

import {
  ReservationResponseDto,
  ReservationStatusDto,
  EtcaDto,
  TsaDto,
  TrainingRecordsSummaryDto,
  ScheduleSummaryDto,
} from '@/open-api';

import {
  iconMapping,
  statusColors,
} from '../reservations-listing/ReservationsListing';
import getFormattedDate from '@/shared/helpers/getFormattedDate';

const columnHelper = createColumnHelper<ReservationResponseDto>();

function getStatusIcon(
  data:
    | ReservationStatusDto
    | ScheduleSummaryDto
    | EtcaDto
    | TsaDto
    | TrainingRecordsSummaryDto
): JSX.Element {
  return (
    <span title={data.message ?? 'No data available'}>
      <Icon
        className={data.style ?? 'neutral'}
        id={iconMapping[data.style ?? 'neutral']}
        size="sm"
      />
    </span>
  );
}

const columnReservationId = columnHelper.accessor('schedulerReservationId', {
  header: 'Reservation',
});

const columnClientName = columnHelper.accessor('clientName', {
  header: 'Name',
});

const columnPlatformName = columnHelper.accessor('aircraftName', {
  header: 'Platform',
});

const columnCourseName = columnHelper.accessor('courseName', {
  header: 'Course',
});

const columnStartName = columnHelper.accessor('startDate', {
  header: 'Start date',
  cell: props => getFormattedDate(props.row.original.startDate, 'dd-MMM-yyyy'),
});

const columnEndDate = columnHelper.accessor('endDate', {
  header: 'End date',
  cell: props => getFormattedDate(props.row.original.endDate, 'dd-MMM-yyyy'),
});

const columnLocation = columnHelper.accessor('location', {
  header: 'Location',
});

const columnStatus = columnHelper.display({
  id: 'status',
  header: 'Status',
  cell: props => {
    const reservationStatus = props.row.original.reservationStatus;
    if (reservationStatus) {
      return (
        <Tag
          className="reservationsStatus"
          color={
            statusColors[
              reservationStatus.style
                ? reservationStatus.style.toLowerCase()
                : 'unknown'
            ] as 'standard' | 'green' | 'red' | 'yellow'
          }
          compact
          title={reservationStatus.longMessage}
        >
          {reservationStatus.message}
        </Tag>
      );
    }
    return null;
  },
});

const columnAutoCancellation = columnHelper.display({
  id: 'autoCancellation',
  header: 'Days to auto-cancel',
  cell: props => {
    const d = props.row.original.autoCancellation;
    if (!d) return null;
    return (
      <span title={d.message}>
        <Icon
          className={`icon--auto-cancellation ${d.style ?? ''}`}
          id={iconMapping[d.style ?? 'neutral']}
          size="sm"
        />
        {d.daysToAutoCancellation}
      </span>
    );
  },
});

const columnETCA = columnHelper.display({
  id: 'etca',
  header: 'eTCA',
  cell: props => {
    const d = props.row.original.etca;
    if (!d) return null;
    return getStatusIcon(d);
  },
});

const columnSchedule = columnHelper.display({
  id: 'schedule',
  header: 'Schedule',
  cell: props => {
    const d = props.row.original.schedule;
    if (!d) return null;
    return getStatusIcon(d);
  },
});

const columnTSA = columnHelper.display({
  id: 'tsa',
  header: 'TSA',
  cell: props => {
    const d = props.row.original.tsa;
    if (!d) return null;
    return getStatusIcon(d);
  },
});

const columnRecords = columnHelper.display({
  id: 'records',
  header: 'Records',
  cell: props => {
    const d = props.row.original.records;
    if (!d) return null;
    return getStatusIcon(d);
  },
});

export const allColumns: ColumnDef<ReservationResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnStatus,
  columnAutoCancellation,
  columnETCA,
  columnSchedule,
  columnTSA,
  columnRecords,
];

export const provisionalColumns: ColumnDef<ReservationResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnLocation,
  columnAutoCancellation,
];

export const confirmedColumns: ColumnDef<ReservationResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnETCA,
  columnSchedule,
  columnTSA,
];

export const inProgressColumns: ColumnDef<ReservationResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnETCA,
  columnSchedule,
  columnTSA,
];

export const completedColumns: ColumnDef<ReservationResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnETCA,
  columnTSA,
  columnRecords,
];
export const upcoming: ColumnDef<ReservationResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnStatus,
  columnAutoCancellation,
  columnETCA,
  columnSchedule,
  columnTSA,
];
