import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { AlertMessage, Modal } from '@cae/cobalt-react';

import { Form } from './Form';

import './AuthoritiesSetupWizard.css';
import { useUserInfo } from '@/contexts/userInfo';

function Header(): JSX.Element {
  return (
    <h2>
      We’ve noticed you haven’t yet identified your regulatory authority(ies)
    </h2>
  );
}

interface AuthoritiesSetupWizardProps {
  availableAuthorities?: string[];
  organizationId: string;
}

export function AuthoritiesSetupWizard({
  availableAuthorities,
  organizationId,
}: Readonly<AuthoritiesSetupWizardProps>): JSX.Element {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { authorities } = useUserInfo();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/home');
  };

  const handleSuccess = (): void => {
    queryClient.invalidateQueries(['selectedOrganization']);
  };

  return (
    <Modal
      className="authorities-setup-wizard"
      header={<Header />}
      dismissible
      onCancel={handleCancel}
      open
      size="md"
    >
      {organizationId ? (
        <Form
          selectedAuthorities={authorities}
          availableAuthorities={availableAuthorities}
          onCancel={handleCancel}
          onSuccess={handleSuccess}
          organizationId={organizationId}
        />
      ) : (
        <AlertMessage type="error" heading="Missing organization ID">
          <p>Looks like selected organization ID is empty or missing.</p>
          <p>
            <Link to="/home">Go back to Dashboard</Link>
          </p>
        </AlertMessage>
      )}
    </Modal>
  );
}

AuthoritiesSetupWizard.displayName = 'AuthoritiesSetupWizard';
