import {
  PagedListOfRecordResponseDto,
  RecordAlertsResponse,
  RecordManagementService,
  RecordStatusFilter,
  RecordSortBy,
} from '@/open-api';
import useDebounce from '@/shared/hooks/useDebounce';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetRecordsTilesCountQuery(): UseQueryResult<
  RecordAlertsResponse,
  Error
> {
  return useQuery<RecordAlertsResponse, Error>({
    queryKey: ['getRecordTilesCount'],
    queryFn: () => RecordManagementService.getRecordAlertsCountByCardType({}),
  });
}

export function useDashboardLatestRecordsQuery(): UseQueryResult<
  PagedListOfRecordResponseDto,
  unknown
> {
  const queryKey = ['recordNotifications'];
  const debouncedValue = useDebounce('', 500);
  return useQuery<PagedListOfRecordResponseDto, unknown>({
    queryKey,
    queryFn: () =>
      RecordManagementService.getRecords({
        pageIndex: 0,
        pageSize: 5,
        sort: [RecordSortBy._ID],
        searchText: debouncedValue,
        recordStatus: RecordStatusFilter.AVAILABLE,
      }),
  });
}
