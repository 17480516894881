import * as React from 'react';

interface Options {
  ref: React.RefObject<HTMLElement>;
  onClick: React.MouseEventHandler<HTMLElement>;
  onDoubleClick: React.MouseEventHandler<HTMLElement>;
}

type CustomMouseEvent = MouseEvent & {
  preventDefault(): void;
  detail: number;
};

export function useDoubleClick({
  ref,
  onClick = () => null,
  onDoubleClick = () => null,
}: Options): void {
  React.useEffect(() => {
    const el = ref.current;

    const handleClick = (e: CustomMouseEvent): void => {
      e.preventDefault();
      const mouseEvent = e as unknown as React.MouseEvent<HTMLElement>;
      if (typeof onClick === 'function' && mouseEvent.detail === 1) {
        onClick(mouseEvent);
      } else if (
        typeof onDoubleClick === 'function' &&
        mouseEvent.detail === 2
      ) {
        onDoubleClick(mouseEvent);
      }
    };

    if (el) {
      el.addEventListener('click', handleClick);
    }

    return () => {
      if (el) {
        el.removeEventListener('click', handleClick);
      }
    };
  });
}
