import * as React from 'react';
import { Button } from '@cae/cobalt-react';

interface Props {
  disabled?: boolean;
  onCancel: (e: React.SyntheticEvent) => void;
  pending?: boolean;
}

export function FormActions({
  disabled = false,
  onCancel,
  pending = false,
}: Props): JSX.Element {
  return (
    <div className="form-actions">
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        disabled={disabled}
        pending={pending}
      >
        {!pending ? 'Generate' : 'Generating'} report
      </Button>
    </div>
  );
}
