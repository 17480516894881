import * as React from 'react';
import { Icon } from '@cae/cobalt-react';

import type { PaginationState } from '@tanstack/react-table';

export function BasicPagination({
  pageIndex,
  setPagination,
  totalPages,
}: {
  pageIndex: number;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
}): JSX.Element {
  const pages = React.useMemo((): number[] => {
    const output = [];
    for (let i = 1; i <= totalPages; i++) {
      output.push(i);
    }
    return output;
  }, [totalPages]);

  const onPageChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const { value } = e.target;
    if (value) {
      setPagination({
        pageIndex: parseInt(value, 10),
        pageSize: 10,
      });
    }
  };

  const handlePreviousClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (pageIndex > 0) {
      setPagination({
        pageIndex: pageIndex - 1,
        pageSize: 10,
      });
    }
  };

  const handleNextClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (pageIndex < totalPages - 1) {
      setPagination({
        pageIndex: pageIndex + 1,
        pageSize: 10,
      });
    }
  };

  return (
    <nav className="pagination">
      <button
        type="button"
        disabled={pageIndex === 0}
        onClick={handlePreviousClick}
      >
        <Icon id="chevron-left-outline" size="sm" />
      </button>

      <label>
        <select onChange={onPageChange} tabIndex={0} value={pageIndex}>
          {pages.map(n => (
            <option key={n} value={n - 1}>
              {n}
            </option>
          ))}
        </select>{' '}
        of {totalPages} pages
      </label>

      <button
        type="button"
        disabled={pageIndex === totalPages - 1}
        onClick={handleNextClick}
      >
        <Icon id="chevron-right-outline" size="sm" />
      </button>
    </nav>
  );
}
