import { format, parseISO } from 'date-fns';

import {
  CustomerDto,
  EtcaDto,
  RecordStatus,
  RecordStyle,
  ReservationDetailsResponse,
  TsaDto,
} from '@/open-api';

import {
  transformCourse,
  transformCourseObjectivesToString,
  transformRegulatoryAgencies,
  transformRelatedReservations,
  transformSchedule,
  transformTrainingLocation,
  transformContactInfo,
} from './transforms';

import {
  RelatedReservation,
  ReservationBasicInfo,
  ReservationCourse,
  ReservationQuickInfo,
  ReservationTrainingLocation,
  StatusInfo,
  TrainingSchedule,
} from '../types';

export function selectBasicInfo(
  data: ReservationDetailsResponse
): ReservationBasicInfo {
  return {
    reservationId: data.reservationId ?? '',
    clientName: data.client?.name ?? '',
  };
}

export function selectQuickInfo(
  data: ReservationDetailsResponse
): ReservationQuickInfo {
  return {
    aircraft: data?.aircraft?.name ?? '',
    authority: transformRegulatoryAgencies(data?.regulatoryAgencies ?? []),
    courseName: data?.course?.name ?? '',
    customer: data?.customer?.name ?? '',
    endDate: data.endDate ? format(parseISO(data.endDate), 'dd-MMM-yyyy') : '',
    location: data?.trainingLocation?.name ?? '',
    name: data?.client?.name ?? '',
    objectives: transformCourseObjectivesToString(data.course ?? {}),
    startDate: data.startDate
      ? format(parseISO(data.startDate), 'dd-MMM-yyyy')
      : '',
    status: data.status ?? '',
    autoCancellationDetails: data.autoCancellationDetails,
  };
}

export function selectStatusInfo(data: ReservationDetailsResponse): StatusInfo {
  return {
    confirmationEmail: {
      sent: data.confirmationEmail?.sent ?? false,
      sentAt: data.confirmationEmail?.sentAt ?? '',
      sentTo: data.confirmationEmail?.sentTo ?? '',
    },
    etca: {
      message: data.etcaDetails?.message ?? '',
      sentAt: data.etcaDetails?.sentAt ?? '',
      status: data.etcaDetails?.status ?? EtcaDto.status.NOT_APPLICABLE,
      style: data.etcaDetails?.style ?? EtcaDto.style.NEUTRAL,
    },
    status: data.status ?? '',
    trainingRecordDetails: {
      message: data.trainingRecordDetails?.message ?? '',
      status: data.trainingRecordDetails?.status ?? RecordStatus.NOT_APPLICABLE,
      style: data.trainingRecordDetails?.style ?? RecordStyle.NEUTRAL,
    },
    tsa: {
      message: data.tsaDetails?.message ?? '',
      status: data.tsaDetails?.status ?? TsaDto.status.UNKNOWN,
      style: data.tsaDetails?.style ?? TsaDto.style.NEUTRAL,
    },
  };
}

export function selectLocationCourseSchedule(
  data: ReservationDetailsResponse
): {
  course: ReservationCourse;
  trainingLocation: ReservationTrainingLocation;
  schedule: TrainingSchedule;
  status: ReservationDetailsResponse.status | '';
} {
  return {
    course: transformCourse(data.course),
    schedule: transformSchedule(data.schedule),
    trainingLocation: transformTrainingLocation(data.trainingLocation),
    status: data.status ?? '',
  };
}

export function selectRelatedReservations(
  data: ReservationDetailsResponse
): [] | RelatedReservation[] {
  return transformRelatedReservations(data.relatedReservations ?? []);
}

export function selectContactInfo(
  data: ReservationDetailsResponse
): Required<CustomerDto> {
  return transformContactInfo(data.customer ?? {});
}

export function selectSchedulerReservationId(
  data: ReservationDetailsResponse
): string {
  return data.schedulerReservationId ?? '';
}
