import { ConfigurationManagementService, VehicleTypeDto } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetVehiclesListQuery(): UseQueryResult<VehicleTypeDto[]> {
  return useQuery<VehicleTypeDto[]>({
    queryKey: ['companyProfile'],
    queryFn: async () => {
      const response =
        await ConfigurationManagementService.getAllVehicleTypes();
      return response;
    },
  });
}
