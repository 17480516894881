import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import { useReportsOptionsQuery } from '@/features/instructors/api/useReportsOptionsQuery';
import { CriteriaFieldset } from '../criteria-fieldset/CriteriaFieldset';
import { FormActions } from '../form-actions/FormActions';
import { toastMessages } from '@/shared/helpers/toastMessages';

const handleDownloadPath = (location: string, platform: string): string => {
  const params = new URLSearchParams({
    reportType: 'instructor-pilot',
    location,
    platform,
  });
  return `/instructors/report?${params}`;
};

export function IpReportForm(): JSX.Element {
  const navigate = useNavigate();

  const [location, setLocation] = React.useState<string>('');
  const [platform, setPlatform] = React.useState<string>('');

  const { data, isInitialLoading, isError, isSuccess } =
    useReportsOptionsQuery();

  const { status, downloadFile } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success('Report downloaded successfully');
    },
    onError: () => {
      toastMessages.error('Report download failed');
    },
  });

  const pending = status === 'pending';

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/instructors');
  };

  const handleSubmit: React.FormEventHandler = e => {
    e.preventDefault();
    downloadFile({
      url: handleDownloadPath(location, platform),
      fileName: 'instructor pilot.pdf',
    });
  };

  return (
    <form
      action="."
      method="post"
      className="report-form"
      onSubmit={handleSubmit}
    >
      <legend>8440 IP Report</legend>
      <span className="inner">
        <p>
          Please select a location and platform, then click the &quot;Generate
          Report&quot; to download a PDF version of the report.
        </p>
        {isInitialLoading && <p>Loading...</p>}
        {isError && <p>Something went wrong</p>}
        {isSuccess && (
          <CriteriaFieldset
            location={location}
            setLocation={setLocation}
            platform={platform}
            setPlatform={setPlatform}
            locationOptions={data?.locations || []}
            platformOptions={data?.platforms || []}
          />
        )}
      </span>
      <FormActions
        disabled={pending || !location || !platform}
        onCancel={handleCancel}
        pending={pending}
      />
    </form>
  );
}
