import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from '@cae/cobalt-react';

import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import { useReportsOptionsQuery } from '../../api/useReportsOptionsQuery';

import { FormActions } from '../form-actions/FormActions';
import { toastMessages } from '@/shared/helpers/toastMessages';

const handleDownloadPath = (location: string): string => {
  const params = new URLSearchParams({
    reportType: 'approved',
    location,
  });
  return `/instructors/report?${params}`;
};

export function ApprovedInstructorsReportForm(): JSX.Element {
  const navigate = useNavigate();

  const [location, setLocation] = React.useState<string>('');

  const { data, isInitialLoading, isError, isSuccess } =
    useReportsOptionsQuery();

  const { status, downloadFile } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success('Report downloaded successfully');
    },
    onError: () => {
      toastMessages.error('Report download failed');
    },
  });

  const pending = status === 'pending';

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/instructors');
  };

  const handleSubmit: React.FormEventHandler = e => {
    e.preventDefault();
    downloadFile({
      url: handleDownloadPath(location),
      fileName: 'approved instructor.pdf',
    });
  };

  return (
    <form
      action="."
      method="post"
      className="report-form"
      onSubmit={handleSubmit}
    >
      <legend>Approved Instructors Report</legend>
      <span className="inner">
        <p>
          Please select a location, then click the &quot;Generate Report&quot;
          to download a PDF version of the report.
        </p>
        <fieldset>
          {isInitialLoading && <p>Loading...</p>}
          {isError && <p>Something went wrong</p>}
          {isSuccess && (
            <Dropdown
              label="Location"
              emptyText="Select location"
              field={{
                name: 'location',
                onChange: e => setLocation(e.target.value),
                value: location,
              }}
              options={data?.locations || []}
              className="dropdown-location"
            />
          )}
        </fieldset>
      </span>
      <FormActions
        disabled={pending || !location}
        onCancel={handleCancel}
        pending={pending}
      />
    </form>
  );
}
