import { InvoiceAndPaymentManagementService } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export interface invoiceAlertDetailsList {
  transactionNumber: string | undefined;
  amount: number | undefined;
  currency: string | undefined;
  accountReceivableId: string | undefined;
}

export interface InvoiceAndPaymentsTilesCount {
  invoicesDueNext7DaysCount: number;
  invoicesPastDueList: invoiceAlertDetailsList[] | [] | undefined;
  invoicesUpcomingDueCount: number;
  invoicesUpcomingDueList: invoiceAlertDetailsList[] | [] | undefined;
}

const getInvoiceAndPaymentsTilesCount =
  async (): Promise<InvoiceAndPaymentsTilesCount> => {
    const response =
      await InvoiceAndPaymentManagementService.getRecordAlertsCountByDueDate(
        {}
      );
    const dueNext7DaysCount = response.invoicesUpcomingDueCount ?? 0;
    const pastDueCount = response.invoicesPastDueCount ?? 0;

    const invoicesPastDueList = response.invoicesPastDueList?.map(invoice => ({
      transactionNumber: invoice.transactionNumber ?? '',
      amount: invoice.amount ?? 0,
      currency: invoice.currency ?? '',
      accountReceivableId: invoice.accountReceivableId ?? '',
    }));

    const invoicesUpcomingDueList = response.invoicesUpcomingDueList?.map(
      invoice => ({
        transactionNumber: invoice.transactionNumber ?? '',
        amount: invoice.amount ?? 0,
        currency: invoice.currency ?? '',
        accountReceivableId: invoice.accountReceivableId ?? '',
      })
    );

    const tilesCount: InvoiceAndPaymentsTilesCount = {
      invoicesDueNext7DaysCount: dueNext7DaysCount,
      invoicesUpcomingDueCount: pastDueCount,
      invoicesUpcomingDueList: invoicesUpcomingDueList,
      invoicesPastDueList: invoicesPastDueList,
    };
    return tilesCount;
  };

export function useGetInvoiceAndPaymentsTilesCountQuery(): UseQueryResult<
  InvoiceAndPaymentsTilesCount,
  unknown
> {
  return useQuery({
    queryKey: ['getInvoiceAndPaymentsTilesCount'],
    queryFn: () => getInvoiceAndPaymentsTilesCount(),
  });
}
