import { useReducer } from 'react';
import { SubmittedDocumentsFilters } from '@/features/documents/components/submitted-documents/filter-modal/useFilterModalReducer';
import { Actions, filtersReducer } from './submittedDocumentsReducer';

const initialState: SubmittedDocumentsFilters = {
  status: [],
};

function useSubmittedDocumentsFilter(): {
  filters: SubmittedDocumentsFilters;
  resetFilters: () => void;
  toggleAllFilters: (selected: SubmittedDocumentsFilters) => void;
  filterCounter: () => number;
} {
  const [filters, dispatch] = useReducer(filtersReducer, initialState);

  const toggleAllFilters = (selected: SubmittedDocumentsFilters): void => {
    dispatch({ type: Actions.toggleAllFilters, payload: selected });
  };

  const resetFilters = (): void => {
    dispatch({ type: Actions.resetFilter });
  };

  const filterCounter = (): number => {
    let count = 0;
    count = filters.status.length;
    return count;
  };

  return {
    filters,
    resetFilters,
    toggleAllFilters,
    filterCounter,
  };
}

export default useSubmittedDocumentsFilter;
