import { useReducer } from 'react';
import {
  ScheduledStatus,
  CourseType,
  EtcaStatus,
  TsaStatus,
} from '../../types';
import { RecordStatus } from '@/open-api';

export interface ReservationFilters {
  dateRange: (Date | undefined)[];
  platforms: string[];
  locations: string[];
  regulatoryAgencies: string[];
  scheduledStatus: ScheduledStatus[];
  records: RecordStatus[];
  courseTypes: CourseType[];
  etcaStatus: EtcaStatus[];
  tsaStatus: TsaStatus[];
}
export interface UseFilterModalReducerReturnType {
  selectedFilters: ReservationFilters;
  clearSelectedFilters: () => void;
  clearSelectedLocations: () => void;
  clearSelectedPlatforms: () => void;
  clearSelectedCourseTypes: () => void;
  clearSelectedEtcaStatus: () => void;
  clearSelectedTsaStatus: () => void;
  clearSelectedRecords: () => void;
  clearSelectedScheduledStatus: () => void;
  clearSelectedAuthorities: () => void;
  changeSelectedLocations: (locations: string) => void;
  changeSelectedPlatforms: (platforms: string) => void;
  changeSelectedAuthorities: (authorities: string) => void;
  changeSelectedCourseTypes: (
    courseTypes: 'Initial' | 'Recurrent' | 'Upgrade' | 'Other'
  ) => void;
  changeSelectedEtcaStatus: (
    etcaStatus: 'Sent' | 'NotSent' | 'NotApplicable' | 'Optional'
  ) => void;
  changeSelectedTsaStatus: (
    tsaStatus: 'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable'
  ) => void;
  changeSelectedRecords: (
    records: 'Available' | 'HardCopyOnly' | 'Pending' | 'NotApplicable'
  ) => void;
  changeSelectedScheduledStatus: (
    scheduledStatus: 'Available' | 'NotAvailable'
  ) => void;
  changeDateRange: (dateRange: (Date | undefined)[]) => void;
  clearDateRange: () => void;
}

export const useFilterModalReducer = (
  filters: ReservationFilters
): UseFilterModalReducerReturnType => {
  const initialState: ReservationFilters = {
    locations: filters.locations || [],
    platforms: filters.platforms || [],
    courseTypes: filters.courseTypes || [],
    etcaStatus: filters.etcaStatus || [],
    tsaStatus: filters.tsaStatus || [],
    scheduledStatus: filters.scheduledStatus || [],
    records: filters.records || [],
    dateRange: filters.dateRange || [],
    regulatoryAgencies: filters.regulatoryAgencies || [],
  };

  const enum Actions {
    changeSelectedLocations = 'changeSelectedLocations',
    resetSelectedFilters = 'resetSelectedFilters',
    clearSelectedLocations = 'clearSelectedLocations',
    changeSelectedPlatforms = 'changeSelectedPlatforms',
    clearSelectedPlatforms = 'clearSelectedPlatforms',
    changeSelectedCourseTypes = 'changeSelectedCourseTypes',
    clearSelectedCourseTypes = 'clearSelectedCourseTypes',
    changeSelectedEtcaStatus = 'changeSelectedEtcaStatus',
    clearSelectedEtcaStatus = 'clearSelectedEtcaStatus',
    changeSelectedTsaStatus = 'changeSelectedTsaStatus',
    clearSelectedTsaStatus = 'clearSelectedTsaStatus',
    changeSelectedScheduledStatus = 'changeSelectedScheduledStatus',
    clearSelectedScheduledStatus = 'clearSelectedScheduledStatus',
    changeSelectedRecords = 'changeSelectedRecords',
    clearSelectedRecords = 'clearSelectedRecords',
    changeDateRange = 'changeDateRange',
    clearDateRange = 'clearDateRange',
    changeSelectedAuthorities = 'changeSelectedAuthorities',
    clearSelectedAuthorities = 'clearSelectedAuthorities',
  }

  type ChangeSelectedLocations = {
    type: Actions.changeSelectedLocations;
    payload: string;
  };

  type ChangeSelectedPlatforms = {
    type: Actions.changeSelectedPlatforms;
    payload: string;
  };

  type ChangeSelectedCourseTypes = {
    type: Actions.changeSelectedCourseTypes;
    payload: 'Initial' | 'Recurrent' | 'Upgrade' | 'Other';
  };

  type ChangeSelectedEtcaStatus = {
    type: Actions.changeSelectedEtcaStatus;
    payload: 'Sent' | 'NotSent' | 'NotApplicable' | 'Optional';
  };

  type ChangeSelectedTsaStatus = {
    type: Actions.changeSelectedTsaStatus;
    payload: 'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable';
  };

  type ChangeSelectedScheduledStatus = {
    type: Actions.changeSelectedScheduledStatus;
    payload: 'Available' | 'NotAvailable';
  };

  type ChangeSelectedRecords = {
    type: Actions.changeSelectedRecords;
    payload: 'Available' | 'HardCopyOnly' | 'Pending' | 'NotApplicable';
  };

  type ChangeDateRange = {
    type: Actions.changeDateRange;
    payload: (Date | undefined)[];
  };

  type ChangeSelectedAuthorities = {
    type: Actions.changeSelectedAuthorities;
    payload: string;
  };

  type FilterAction =
    | ChangeSelectedLocations
    | ChangeSelectedPlatforms
    | ChangeSelectedCourseTypes
    | ChangeSelectedEtcaStatus
    | ChangeSelectedTsaStatus
    | ChangeSelectedScheduledStatus
    | ChangeSelectedRecords
    | ChangeDateRange
    | ChangeSelectedAuthorities;

  type FilterActionWithoutPayload = {
    type:
      | Actions.resetSelectedFilters
      | Actions.clearSelectedLocations
      | Actions.clearSelectedPlatforms
      | Actions.clearSelectedCourseTypes
      | Actions.clearSelectedEtcaStatus
      | Actions.clearSelectedTsaStatus
      | Actions.clearSelectedScheduledStatus
      | Actions.clearSelectedRecords
      | Actions.clearDateRange
      | Actions.clearSelectedAuthorities;
  };

  const updateArrayState = (
    state: ReservationFilters,
    key: keyof Omit<ReservationFilters, 'dateRange'>,
    payload: string
  ): ReservationFilters => {
    const currentValue: string[] = state[key];
    const updatedValue = currentValue.includes(payload)
      ? currentValue.filter(item => item !== payload)
      : [...currentValue, payload];

    return {
      ...state,
      [key]: updatedValue,
    };
  };

  const filtersModalReducer = (
    state: ReservationFilters,
    action: FilterAction | FilterActionWithoutPayload
  ): ReservationFilters => {
    switch (action.type) {
      case Actions.changeSelectedCourseTypes:
        return updateArrayState(state, 'courseTypes', action.payload);
      case Actions.changeSelectedEtcaStatus:
        return updateArrayState(state, 'etcaStatus', action.payload);
      case Actions.changeSelectedLocations:
        return updateArrayState(state, 'locations', action.payload);
      case Actions.changeSelectedPlatforms:
        return updateArrayState(state, 'platforms', action.payload);
      case Actions.changeSelectedRecords:
        return updateArrayState(state, 'records', action.payload);
      case Actions.changeSelectedScheduledStatus:
        return updateArrayState(state, 'scheduledStatus', action.payload);
      case Actions.changeSelectedTsaStatus:
        return updateArrayState(state, 'tsaStatus', action.payload);
      case Actions.changeSelectedAuthorities:
        return updateArrayState(state, 'regulatoryAgencies', action.payload);
      case Actions.changeDateRange:
        return {
          ...state,
          dateRange: action.payload,
        };
      case Actions.clearSelectedCourseTypes:
        return {
          ...state,
          courseTypes: [],
        };
      case Actions.clearSelectedEtcaStatus:
        return {
          ...state,
          etcaStatus: [],
        };
      case Actions.clearSelectedLocations:
        return {
          ...state,
          locations: [],
        };
      case Actions.clearSelectedPlatforms:
        return {
          ...state,
          platforms: [],
        };
      case Actions.clearSelectedRecords:
        return {
          ...state,
          records: [],
        };
      case Actions.clearSelectedScheduledStatus:
        return {
          ...state,
          scheduledStatus: [],
        };
      case Actions.clearSelectedTsaStatus:
        return {
          ...state,
          tsaStatus: [],
        };
      case Actions.clearDateRange:
        return {
          ...state,
          dateRange: [],
        };
      case Actions.clearSelectedAuthorities:
        return {
          ...state,
          regulatoryAgencies: [],
        };
      case Actions.resetSelectedFilters:
        return {
          locations: [],
          platforms: [],
          courseTypes: [],
          etcaStatus: [],
          tsaStatus: [],
          scheduledStatus: [],
          records: [],
          dateRange: [],
          regulatoryAgencies: [],
        };
      default:
        return state;
    }
  };

  const [selectedFilters, dispatch] = useReducer(
    filtersModalReducer,
    initialState
  );

  const changeSelectedCourseTypes = (
    courseTypes: 'Initial' | 'Recurrent' | 'Upgrade' | 'Other'
  ): void => {
    dispatch({ type: Actions.changeSelectedCourseTypes, payload: courseTypes });
  };

  const changeSelectedEtcaStatus = (
    etcaStatus: 'Sent' | 'NotSent' | 'NotApplicable' | 'Optional'
  ): void => {
    dispatch({ type: Actions.changeSelectedEtcaStatus, payload: etcaStatus });
  };

  const changeSelectedLocations = (locations: string): void => {
    dispatch({ type: Actions.changeSelectedLocations, payload: locations });
  };

  const changeSelectedPlatforms = (platforms: string): void => {
    dispatch({ type: Actions.changeSelectedPlatforms, payload: platforms });
  };

  const changeSelectedRecords = (
    records: 'Available' | 'HardCopyOnly' | 'Pending' | 'NotApplicable'
  ): void => {
    dispatch({ type: Actions.changeSelectedRecords, payload: records });
  };

  const changeSelectedTsaStatus = (
    tsaStatus: 'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable'
  ): void => {
    dispatch({ type: Actions.changeSelectedTsaStatus, payload: tsaStatus });
  };

  const changeSelectedScheduledStatus = (
    scheduledStatus: 'Available' | 'NotAvailable'
  ): void => {
    dispatch({
      type: Actions.changeSelectedScheduledStatus,
      payload: scheduledStatus,
    });
  };
  const changeDateRange = (dateRange: (Date | undefined)[]): void => {
    dispatch({ type: Actions.changeDateRange, payload: dateRange });
  };

  const changeSelectedAuthorities = (authorities: string): void => {
    dispatch({ type: Actions.changeSelectedAuthorities, payload: authorities });
  };

  const clearDateRange = (): void => {
    dispatch({ type: Actions.clearDateRange });
  };

  const clearSelectedCourseTypes = (): void => {
    dispatch({ type: Actions.clearSelectedCourseTypes });
  };

  const clearSelectedLocations = (): void => {
    dispatch({ type: Actions.clearSelectedLocations });
  };

  const clearSelectedPlatforms = (): void => {
    dispatch({ type: Actions.clearSelectedPlatforms });
  };

  const clearSelectedFilters = (): void => {
    dispatch({ type: Actions.resetSelectedFilters });
  };

  const clearSelectedRecords = (): void => {
    dispatch({ type: Actions.clearSelectedRecords });
  };

  const clearSelectedTsaStatus = (): void => {
    dispatch({ type: Actions.clearSelectedTsaStatus });
  };

  const clearSelectedEtcaStatus = (): void => {
    dispatch({ type: Actions.clearSelectedEtcaStatus });
  };

  const clearSelectedScheduledStatus = (): void => {
    dispatch({ type: Actions.clearSelectedScheduledStatus });
  };

  const clearSelectedAuthorities = (): void => {
    dispatch({ type: Actions.clearSelectedAuthorities });
  };

  return {
    selectedFilters,
    clearSelectedFilters,
    clearSelectedPlatforms,
    clearSelectedCourseTypes,
    clearSelectedLocations,
    changeSelectedLocations,
    changeSelectedPlatforms,
    changeSelectedCourseTypes,
    changeSelectedEtcaStatus,
    changeSelectedTsaStatus,
    changeSelectedRecords,
    changeSelectedScheduledStatus,
    changeDateRange,
    changeSelectedAuthorities,
    clearDateRange,
    clearSelectedRecords,
    clearSelectedTsaStatus,
    clearSelectedEtcaStatus,
    clearSelectedScheduledStatus,
    clearSelectedAuthorities,
  };
};
