import { Icon, Tooltip } from '@cae/cobalt-react';
import './AirmenNumber.css';

export function AirmenNumber({ value = 0 }: { value?: number }): JSX.Element {
  if (value > 1) return <span>{value}</span>;
  return (
    <Tooltip
      placement="bottom-start"
      flipOptions={{ mainAxis: true, crossAxis: false }}
      highContrast
    >
      <Tooltip.Trigger>
        <span className="airmen-number">
          {value}
          <Icon id="warning-triangle-solid" />
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content
        style={{
          borderRadius: 'var(--radius-2)',
        }}
      >
        Less than 2 approved CA
      </Tooltip.Content>
    </Tooltip>
  );
}
