/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EtcaDto = {
  /**
   * eTCA status
   */
  status?: EtcaDto.status;
  /**
   * eTCA message
   */
  message?: string;
  /**
   * eTCA style
   */
  style?: EtcaDto.style;
  /**
   * Time when the eTCA was sent
   */
  sentAt?: string;
};
export namespace EtcaDto {
  /**
   * eTCA status
   */
  export enum status {
    SENT = 'Sent',
    NOT_SENT = 'NotSent',
    NOT_APPLICABLE = 'NotApplicable',
    OPTIONAL = 'Optional',
  }
  /**
   * eTCA style
   */
  export enum style {
    NEUTRAL = 'neutral',
    WARNING = 'warning',
    DANGER = 'danger',
    SUCCESS = 'success',
  }
}
