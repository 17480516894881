import { format, parseISO } from 'date-fns';
import {
  CustomerDto,
  RecordDetailsResponse,
  RelatedRecordDto,
} from '@/open-api';
import { RecordBasicInfo, RecordQuickInfo } from '../types';
import {
  transformContactInfo,
  transformCourseObjectivesToString,
  transformRegulatoryAgencies,
  transformRelatedRecords,
} from './transform';

export function selectBasicInfo(data: RecordDetailsResponse): RecordBasicInfo {
  return {
    recordId: data.recordId ?? '',
    clientName: data.client?.name ?? '',
  };
}

export function selectContactInfo(
  data: RecordDetailsResponse
): Required<CustomerDto> {
  return transformContactInfo(data.customer ?? {});
}

export function selectQuickInfo(data: RecordDetailsResponse): RecordQuickInfo {
  return {
    aircraft: data?.aircraft?.name ?? '',
    authority: transformRegulatoryAgencies(data?.regulatoryAgencies ?? []),
    courseName: data?.course?.name ?? '',
    customer: data?.customer?.name ?? '',
    endDate: data.endDate ? format(parseISO(data.endDate), 'dd-MMM-yyyy') : '',
    location: data?.trainingLocation?.name ?? '',
    name: data?.client?.name ?? '',
    objectives: transformCourseObjectivesToString(data.course ?? {}),
    startDate: data.startDate
      ? format(parseISO(data.startDate), 'dd-MMM-yyyy')
      : '',
    recordStatus: data.status ?? 'Unknown',
  };
}

export function selectRelatedRecords(
  data: RecordDetailsResponse
): RelatedRecordDto[] {
  return transformRelatedRecords(data.relatedRecords ?? []);
}
