import { useReducer } from 'react';

type RecordStatus = 'Initial' | 'Recurrent' | 'Upgrade' | 'Other';

export interface RecordsFilters {
  platforms: string[];
  locations: string[];
  courseTypes: Array<RecordStatus>;
  dateRange: (Date | undefined)[];
}

type ChangeSelectedLocations = {
  type: 'changeSelectedLocations';
  payload: string;
};

type ChangeSelectedPlatforms = {
  type: 'changeSelectedPlatforms';
  payload: string;
};

type ChangeSelectedCourseTypes = {
  type: 'changeSelectedCourseTypes';
  payload: RecordStatus;
};

type ChangeDateRange = {
  type: 'changeDateRange';
  payload: (Date | undefined)[];
};

type FilterAction =
  | ChangeSelectedLocations
  | ChangeSelectedPlatforms
  | ChangeSelectedCourseTypes
  | ChangeDateRange
  | { type: 'resetSelectedFilters' }
  | { type: 'clearSelectedLocations' }
  | { type: 'clearSelectedPlatforms' }
  | { type: 'clearSelectedCourseTypes' }
  | { type: 'clearDateRange' };

const filtersModalReducer = (
  state: RecordsFilters,
  action: FilterAction
): RecordsFilters => {
  switch (action.type) {
    case 'changeSelectedCourseTypes':
      return {
        ...state,
        courseTypes: state.courseTypes.includes(action.payload)
          ? state.courseTypes.filter(item => item !== action.payload)
          : [...state.courseTypes, action.payload],
      };
    case 'changeSelectedLocations':
      return {
        ...state,
        locations: state.locations.includes(action.payload)
          ? state.locations.filter(item => item !== action.payload)
          : [...state.locations, action.payload],
      };
    case 'changeSelectedPlatforms':
      return {
        ...state,
        platforms: state.platforms.includes(action.payload)
          ? state.platforms.filter(item => item !== action.payload)
          : [...state.platforms, action.payload],
      };
    case 'changeDateRange':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'clearSelectedCourseTypes':
      return {
        ...state,
        courseTypes: [],
      };
    case 'clearSelectedLocations':
      return {
        ...state,
        locations: [],
      };
    case 'clearSelectedPlatforms':
      return {
        ...state,
        platforms: [],
      };
    case 'clearDateRange':
      return {
        ...state,
        dateRange: [],
      };
    case 'resetSelectedFilters':
      return {
        locations: [],
        platforms: [],
        courseTypes: [],
        dateRange: [],
      };
    default:
      return state;
  }
};

export const useFilterModalReducer = (
  filters: RecordsFilters
): {
  selectedFilters: RecordsFilters;
  clearSelectedFilters: () => void;
  clearSelectedPlatforms: () => void;
  clearSelectedLocations: () => void;
  clearSelectedCourseTypes: () => void;
  clearDateRange: () => void;
  changeSelectedLocations: (locations: string) => void;
  changeSelectedPlatforms: (platforms: string) => void;
  changeSelectedCourseTypes: (courseTypes: RecordStatus) => void;
  changeDateRange: (dateRange: (Date | undefined)[]) => void;
} => {
  const initialState: RecordsFilters = {
    locations: filters.locations || [],
    platforms: filters.platforms || [],
    courseTypes: filters.courseTypes || [],
    dateRange: filters.dateRange || [],
  };

  const [selectedFilters, dispatch] = useReducer(
    filtersModalReducer,
    initialState
  );

  const changeSelectedCourseTypes = (courseTypes: RecordStatus): void => {
    dispatch({ type: 'changeSelectedCourseTypes', payload: courseTypes });
  };

  const changeSelectedLocations = (locations: string): void => {
    dispatch({ type: 'changeSelectedLocations', payload: locations });
  };

  const changeSelectedPlatforms = (platforms: string): void => {
    dispatch({ type: 'changeSelectedPlatforms', payload: platforms });
  };

  const clearSelectedCourseTypes = (): void => {
    dispatch({ type: 'clearSelectedCourseTypes' });
  };

  const clearSelectedLocations = (): void => {
    dispatch({ type: 'clearSelectedLocations' });
  };

  const clearSelectedPlatforms = (): void => {
    dispatch({ type: 'clearSelectedPlatforms' });
  };

  const changeDateRange = (dateRange: (Date | undefined)[]): void => {
    dispatch({ type: 'changeDateRange', payload: dateRange });
  };

  const clearDateRange = (): void => {
    dispatch({ type: 'clearDateRange' });
  };

  const clearSelectedFilters = (): void => {
    dispatch({ type: 'resetSelectedFilters' });
  };

  return {
    selectedFilters,
    clearSelectedFilters,
    clearSelectedPlatforms,
    clearSelectedLocations,
    clearSelectedCourseTypes,
    clearDateRange,
    changeSelectedLocations,
    changeSelectedPlatforms,
    changeSelectedCourseTypes,
    changeDateRange,
  };
};
