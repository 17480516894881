import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AlertMessage, Button, Modal, Tag } from '@cae/cobalt-react';
import * as React from 'react';
import { ApiError, RecordManagementService } from '@/open-api';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import { useSelectQuickInfo } from '../../api/hooks';
import './RecordQuickInfoFragment.css';
import RecordPreview from '../record-preview/RecordPreview';
import { RecordBackNavigation } from '../record-back-navigation/RecordBackNavigation';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { RecordModel, RecordStatus } from '@/features/records';

type RecordAlertHeaderProps = {
  handleRecordCopy: (e: React.SyntheticEvent) => void;
  recordType?: RecordStatus;
};

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function ModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Send Request
      </Button>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </>
  );
}

function RecordAlertHeader({
  handleRecordCopy,
  recordType,
}: RecordAlertHeaderProps): JSX.Element {
  if (recordType === 'HardCopyOnly') {
    return (
      <header className="alert-message__header">
        <AlertMessage type="info" heading="Record available offline" inline>
          <div>
            Some records are not yet available in the digital format in CAE
            Connect. To get the offline copy of the record, you can{' '}
            <button
              className="record--request-a-copy"
              onClick={handleRecordCopy}
            >
              request a copy.
            </button>
          </div>
        </AlertMessage>
      </header>
    );
  }
  return <></>;
}

export function RecordQuickInfoFragment({
  recordId,
  documentId,
  schedulerReservationId,
}: {
  recordId: string;
  documentId?: string | undefined;
  schedulerReservationId?: string;
}): JSX.Element {
  const { data, isError } = useSelectQuickInfo(recordId);
  const [isShowRequestCopyModal, setIsShowRequestCopyModal] =
    React.useState(false);

  const navigate = useNavigate();
  const handleCloseRecordCopy = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsShowRequestCopyModal(false);
  };
  const handleRecordCopy = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsShowRequestCopyModal(true);
  };

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    try {
      await RecordManagementService.sendRequestRecordCopyEmail({
        reservationId: recordId,
      });
      toastMessages.success('Request submitted successfully!');
    } catch (error) {
      if (error instanceof ApiError) {
        toastMessages.error(
          'Your message has not yet been sent. Try again, or contact the administrators.'
        );
      } else {
        toastMessages.error(
          'Oops, an error occurred. Try again, or contact the administrators.'
        );
      }
    }
    setIsShowRequestCopyModal(false);
    navigate('/records');
  };
  const location = useLocation();
  const { state: locationState } = location;

  const tagStatus = data?.recordStatus
    ? RecordModel.mapRecordStatusToTagStatus(data.recordStatus)
    : null;

  return (
    <section className="dialog-details__fragment record-quick-info">
      {data ? (
        <>
          <header>
            {locationState &&
              locationState.originalRecordId &&
              locationState.originalSchedulerReservationId && (
                <RecordBackNavigation
                  recordId={locationState.originalRecordId}
                />
              )}

            <h5>
              <span>Reservation #{schedulerReservationId}</span>
              {tagStatus && (
                <Tag color={tagStatus.color} title={tagStatus.longMessage}>
                  {tagStatus.label}
                </Tag>
              )}
            </h5>

            <h2>{data.name}</h2>
          </header>

          <RecordAlertHeader
            handleRecordCopy={handleRecordCopy}
            recordType={data.recordStatus}
          />

          <p>
            In order request corrections to this record, please submit a{' '}
            <Link
              to={`/records/${recordId}/request`}
              className="record--change-request"
            >
              change request
            </Link>
          </p>

          {data.recordStatus === 'Available' ? (
            <RecordPreview
              documentId={documentId}
              schedulerReservationId={schedulerReservationId}
            />
          ) : null}
          <ul>
            <li>
              <h6>Course name</h6>
              <p>{data.courseName}</p>
            </li>
            <li>
              <h6>Platform</h6>
              <p>{data.aircraft}</p>
            </li>
            <li>
              <h6>Authority</h6>
              <p>{data.authority}</p>
            </li>
            <li>
              <h6>Objectives</h6>
              <p>{data.objectives}</p>
            </li>
            <li>
              <h6>Dates</h6>
              <p>
                {data.startDate} – {data.endDate}
              </p>
            </li>
            <li>
              <h6>Location</h6>
              <p>{data.location}</p>
            </li>
            <li>
              <h6>Customer</h6>
              <p>{data.customer}</p>
            </li>
          </ul>
        </>
      ) : isError ? (
        <p>Error getting quick info</p>
      ) : null}

      <Modal
        animate
        actions={ModalActions}
        dismissible
        onCancel={handleCloseRecordCopy}
        onConfirm={onSubmit}
        open={isShowRequestCopyModal}
        placement="center"
        className="confirm--dialog rounded-corners"
        size="sm"
        header={<h2>Request a copy</h2>}
      >
        <p>
          Confirm that you want to obtain a copy of the training record for this
          reservation.
        </p>
      </Modal>
    </section>
  );
}
