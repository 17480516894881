import { AlertMessage, Button, Dialog, Modal } from '@cae/cobalt-react';
import './ChangeRequest.css';
import { ChangeRequestForm } from '@/shared/components';
import { ApiError, UserManagementService } from '@/open-api';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import * as React from 'react';
import { PostResponseStatus } from '@/shared/types';
import { useUserInfo } from '@/contexts/userInfo';
import { useNavigate } from 'react-router-dom';
import { toastMessages } from '@/shared/helpers/toastMessages';

type ModalActionsProps = {
  readonly handleCancel: React.MouseEventHandler;
  readonly handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}

function ProfileChangeRequest(): JSX.Element {
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const { id: profileId } = useUserInfo();
  const navigate = useNavigate();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(true);
  };

  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    navigate(-1);
  };

  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });

    const { title, details: text } = data;

    try {
      if (profileId) {
        await UserManagementService.sendUserChangeRequest({
          id: profileId,
          requestBody: {
            title,
            text,
          },
        });
        toastMessages.success('Request submitted successfully!');
      }
    } catch (error) {
      if (error instanceof ApiError) {
        toastMessages.error(
          'Your message has not yet been sent. Try again, or contact the administrators.'
        );
      } else {
        toastMessages.error(
          'Oops, an error occurred. Try again, or contact the administrators.'
        );
      }
    } finally {
      navigate('/my-profile/info');
    }
  };
  return (
    <>
      <Modal
        animate
        dismissible
        size="sm"
        onCancel={() => {
          navigate(-1);
        }}
        open
        placement="right"
      >
        <h2 className="my-profile__change-request">Change request</h2>
        <AlertMessage
          type="info"
          heading=""
          className="my-profile__change-request"
        >
          Our team will get back to you within 2 business days with a follow-up
          on your request. Note that requests are subject to change depending on
          availability, feasibility and other factors.
        </AlertMessage>
        <div className="dialog-details__page request">
          <ChangeRequestForm
            initialValues={{ title: '', details: '' }}
            onCancel={handleCancel}
            onSubmit={onSubmit}
            status={status}
          />
        </div>
      </Modal>
      <Dialog
        modal
        className="user-profile_dicard-dialog"
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard request?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </>
  );
}

export default ProfileChangeRequest;
