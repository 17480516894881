import * as React from 'react';
import { AlertMessage, Button, Icon } from '@cae/cobalt-react';
import { useSearchParams } from 'react-router-dom';

import useCustomerCareContactsQuery from '@/features/support-menu/api/useCustomerCareContactsQuery';
import { useUserInfo } from '@/contexts/userInfo';
import { AdministratorListing } from '../AdministratorListing';
import { ContactAccountExecutive } from '../ContactAccountExecutive';
import { TechIssuesHelp } from '../TechIssuesHelp';

import './SupportMenu.css';

function SupportMenu(): JSX.Element | null {
  const triggerRef = React.useRef<React.ElementRef<'button'>>(null);
  const menuRef = React.useRef<React.ElementRef<'nav'>>(null);
  const context = useUserInfo();

  const [visible, setVisible] = React.useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const par = searchParams.get('show-support-menu');
  React.useEffect(() => {
    if (par === 'true') {
      setVisible(true);
    }
  }, [par]);

  const { data, status } = useCustomerCareContactsQuery(
    context.selectedOrganization?.name
  );

  const toggle = (e: React.SyntheticEvent<HTMLElement>): void => {
    e.preventDefault();
    setVisible(!visible);

    if (!visible) {
      const userMenuEl = menuRef.current;
      if (userMenuEl) {
        userMenuEl.scrollTop = 0;
      }
    }
    searchParams.delete('show-support-menu');
    setSearchParams(searchParams);
  };

  React.useEffect(() => {
    const handleClickOutside = (
      e: HTMLElementEventMap['click'] | HTMLElementEventMap['touchstart']
    ): void => {
      const triggerEl = triggerRef.current;
      const userMenuEl = menuRef.current;
      if (triggerEl && userMenuEl) {
        if (
          userMenuEl.contains(e.target as Node) ||
          triggerEl.contains(e.target as Node)
        ) {
          return;
        }
        setVisible(false);
        searchParams.delete('show-support-menu');
        setSearchParams(searchParams);
      }
    };
    if (visible) {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [searchParams, setSearchParams, visible]);

  const handleClick = (e: React.SyntheticEvent<HTMLElement>): void => {
    const target = e.target as HTMLElement;
    const { dataset } = target;
    if (dataset.toggleMenu === 'true') {
      toggle(e);
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="icon"
        title="Customer Care"
        aria-label="Customer Care"
        data-pressed={visible}
        onClick={toggle}
        ref={triggerRef}
      >
        <Icon id="help-circle-outline" size="md" />
      </Button>
      <nav
        className="support-menu"
        aria-hidden={!visible}
        onClick={handleClick}
        ref={menuRef}
      >
        {status === 'loading' && <p>Loading contact information…</p>}
        {status === 'error' && (
          <AlertMessage type="error" heading="Error">
            <p>Looks like there was an error loading contact information.</p>
          </AlertMessage>
        )}
        {status === 'success' && data && (
          <>
            <AdministratorListing data={data.companyAdmins ?? []} />
            <ContactAccountExecutive data={data.accountExecutive ?? {}} />
          </>
        )}
        <TechIssuesHelp />
      </nav>
    </>
  );
}

export default SupportMenu;
