import * as React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, ScreenContent, ScreenHeader } from '@cae/cobalt-react';

import { useHasPermission } from '@/shared/hooks/useHasPermission';

import {
  HomeCards,
  RecentlyViewedListing,
} from '@/features/documents/components';

import HasAccess from '@/shared/components/has-access/HasAccess';

export function DocumentsHomePage(): JSX.Element {
  const navigate = useNavigate();
  const hasPermission = useHasPermission();

  const { easaquicklinkwebapp, documentauthorities } = useFlags();

  const handleAddDocumentsClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/documents/overview/submit');
  };

  return (
    // Permission name need to be updated as for prod we need to change it
    <HasAccess permissions="view_documents_library">
      <div className="page-wrapper">
        <ScreenHeader elevation="base">
          <hgroup>
            <h2>Documents overview</h2>
          </hgroup>
          <div className="main-cta">
            {documentauthorities && hasPermission('document_upload') ? (
              <Button
                data-testid="submitDocumentButton"
                type="button"
                variant="primary"
                size="md"
                onClick={handleAddDocumentsClick}
              >
                Submit document
              </Button>
            ) : null}
          </div>
          {easaquicklinkwebapp && <HomeCards />}
        </ScreenHeader>

        <ScreenContent>
          <h3>Recently viewed</h3>
          <RecentlyViewedListing />
        </ScreenContent>
      </div>
      <Outlet />
    </HasAccess>
  );
}
