import { ReservationsPage } from '@/pages/reservations';
import {
  BookingConfirmationPreviewPage,
  ReservationRequestPage,
  LocationSchedulePage,
  OverviewPage,
  ReservationDetailsPage,
} from '@/pages/reservation-details';
import {
  ReservationsAlertDetailsPage,
  ReservationsAlertsPage,
} from '@/pages/reservations-alerts';

export const reservationsRoutes = {
  path: 'reservations',
  element: <ReservationsPage />,
  children: [
    {
      path: 'alerts',
      element: <ReservationsAlertsPage />,
      children: [
        {
          path: ':type',
          element: <ReservationsAlertDetailsPage />,
        },
      ],
    },
    {
      path: ':reservationId',
      element: <ReservationDetailsPage />,
      children: [
        {
          path: 'booking-confirmation-email',
          element: <BookingConfirmationPreviewPage />,
        },
        { path: 'request', element: <ReservationRequestPage /> },
        {
          path: 'location-schedule',
          element: <LocationSchedulePage />,
        },
        {
          index: true,
          element: <OverviewPage navigateTo="reservations" />,
        },
      ],
    },
  ],
};
