import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import { AlertBanner, AlertMessage, Button, Dialog } from '@cae/cobalt-react';

import { ApiError, ReservationManagementService } from '@/open-api';

import { BackNavigation } from '@/features/reservation-details/components';
import { ChangeRequestForm } from '@/shared/components';

import type { PostResponseStatus } from '@/shared/types';
import { useUserInfo } from '@/contexts/userInfo';

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}
function StatusSplash({
  onRefresh,
  status,
}: {
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}): JSX.Element {
  return (
    <>
      {status.type === 'success' && (
        <AlertMessage type="success" heading={status.message}>
          <p>
            Our team will get back to you within two business days with a
            follow-up on your request. Note that requests are subject to change
            depending on availability, feasibility and other factors.
          </p>
          <p>
            <button type="button" className="anchor" onClick={onRefresh}>
              Create another request?
            </button>
          </p>
        </AlertMessage>
      )}
      {status.type === 'error' && (
        <AlertBanner
          type="error"
          heading={status.heading}
          style={{ marginBottom: '1.5rem' }}
        >
          <p>{status.message}</p>
        </AlertBanner>
      )}
      {status.type !== 'success' && (
        <AlertMessage type="info" heading="" inline>
          Our team will get back to you within two business days with a
          follow-up on your request. Note that requests are subject to change
          depending on availability, feasibility and other factors.
        </AlertMessage>
      )}
    </>
  );
}

function PageHeader({
  onRefresh,
  status,
}: {
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}): JSX.Element {
  return (
    <header>
      <h2>Request</h2>
      <StatusSplash onRefresh={onRefresh} status={status} />
    </header>
  );
}

export function ReservationRequestPage(): null | JSX.Element {
  const navigate = useNavigate();
  const { reservationId = '' } = useParams();
  const location = useLocation();
  const { state: locationState } = location;
  const [key, setKey] = React.useState<string>(`key-${Date.now()}`);
  const currentUrl = window.location.href;
  const targetUrl = currentUrl.includes('/home')
    ? `/home/${reservationId}`
    : `/reservations/${reservationId}`;
  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const { selectedOrganizationId } = useUserInfo();

  const onCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    setIsDiscardDialogOpen(true);
  };
  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    navigate(targetUrl);
  };

  const onRefresh = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setStatus({ type: 'idle' });
    setKey(`key-${Date.now()}`);
  };

  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });
    const { title, details: text } = data;

    try {
      if (selectedOrganizationId) {
        await ReservationManagementService.sendReservationChangeRequest({
          reservationId,
          organizationId: selectedOrganizationId,
          requestBody: {
            title,
            text,
          },
        });
        setStatus({
          type: 'success',
          heading: '',
          message: 'Request submitted successfully!',
        });
      } else {
        throw Error('no organization Id');
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setStatus({
          type: 'error',
          heading: 'API error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      } else {
        setStatus({
          type: 'error',
          heading: 'Oops, an error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      }
    }
  };

  return reservationId ? (
    <section
      className="dialog-details__page request"
      data-reservation-id={reservationId}
      data-testid="reservationDetailsChangeRequestPanel"
      key={key}
    >
      <BackNavigation
        reservationId={reservationId}
        schedulerReservationId={locationState.originalSchedulerReservationId}
      />
      <PageHeader onRefresh={onRefresh} status={status} />
      <ChangeRequestForm
        initialValues={{ title: '', details: '' }}
        onCancel={onCancel}
        onSubmit={onSubmit}
        status={status}
      />
      <Dialog
        modal
        className="reservation-request-page"
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard change request?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
        data-testid="reservationDetailsChangeRequestCancelDialog"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </section>
  ) : null;
}

ReservationRequestPage.displayName = 'ReservationRequestPage';
