import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { UploadDocuments } from '@/features/documents/components';

export function DocumentsOverviewUploadPage({
  modal = false,
}: Readonly<{
  modal?: boolean;
}>): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const pathEls = location.pathname.split('/');
    const closePath = pathEls.slice(0, pathEls.length - 1).join('/');
    navigate(closePath);
  };

  return modal ? (
    <Modal
      animate
      className="upload-documents--dialog"
      dismissible
      onCancel={handleCancel}
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
    >
      <UploadDocuments onCancel={handleCancel} />
    </Modal>
  ) : (
    <div className="documents-upload--page">
      <UploadDocuments onCancel={handleCancel} />
    </div>
  );
}

DocumentsOverviewUploadPage.displayName = 'DocumentsOverviewUploadPage';
