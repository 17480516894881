/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ScheduleSummaryDto = {
  /**
   * Schedule status
   */
  status?: ScheduleSummaryDto.status;
  /**
   * Schedule message
   */
  message?: string;
  /**
   * Schedule style
   */
  style?: ScheduleSummaryDto.style;
};
export namespace ScheduleSummaryDto {
  /**
   * Schedule status
   */
  export enum status {
    AVAILABLE = 'Available',
    NOT_AVAILABLE = 'NotAvailable',
  }
  /**
   * Schedule style
   */
  export enum style {
    NEUTRAL = 'neutral',
    SUCCESS = 'success',
  }
}
