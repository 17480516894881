import FinanceLandingPage from '@/features/finance/components/FinanceLandingPage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InvoiceAndPaymentsPage } from '../invoice-payments/InvoiceAndPaymentsPage';

function FinancePage(): JSX.Element {
  const { invoiceandpaymentwebapp } = useFlags();
  return invoiceandpaymentwebapp ? (
    <InvoiceAndPaymentsPage />
  ) : (
    <FinanceLandingPage />
  );
}

export default FinancePage;
