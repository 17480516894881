/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountExecutiveInfoDto } from './AccountExecutiveInfoDto';
import type { AircraftDto } from './AircraftDto';
import type { AutoCancellationDetailsDto } from './AutoCancellationDetailsDto';
import type { ClientDto } from './ClientDto';
import type { ConfirmationEmailDto } from './ConfirmationEmailDto';
import type { CourseDto } from './CourseDto';
import type { CustomerDto } from './CustomerDto';
import type { EtcaDto } from './EtcaDto';
import type { RegulatoryAgencyDto } from './RegulatoryAgencyDto';
import type { RelatedReservationDto } from './RelatedReservationDto';
import type { ReservationStatusDto } from './ReservationStatusDto';
import type { ScheduleDto } from './ScheduleDto';
import type { TrainingLocationDto } from './TrainingLocationDto';
import type { TrainingRecordDetailsDto } from './TrainingRecordDetailsDto';
import type { TsaDto } from './TsaDto';
export type ReservationDetailsResponse = {
  /**
   * Reservation id
   */
  reservationId?: string;
  /**
   * Scheduler reservation id
   */
  schedulerReservationId?: string;
  /**
   * Reservation status
   */
  status?: ReservationDetailsResponse.status;
  /**
   * Start date of the training event
   */
  startDate?: string;
  /**
   * End date of the training event
   */
  endDate?: string;
  course?: CourseDto;
  client?: ClientDto;
  customer?: CustomerDto;
  aircraft?: AircraftDto;
  regulatoryAgencies?: Array<RegulatoryAgencyDto>;
  trainingLocation?: TrainingLocationDto;
  confirmationEmail?: ConfirmationEmailDto;
  reservationStatus?: ReservationStatusDto;
  trainingRecordDetails?: TrainingRecordDetailsDto;
  etcaDetails?: EtcaDto;
  tsaDetails?: TsaDto;
  reservationAccountExecutive?: AccountExecutiveInfoDto;
  schedule?: ScheduleDto;
  relatedReservations?: Array<RelatedReservationDto>;
  autoCancellationDetails?: AutoCancellationDetailsDto;
};
export namespace ReservationDetailsResponse {
  /**
   * Reservation status
   */
  export enum status {
    PROVISIONAL = 'Provisional',
    CONFIRMED = 'Confirmed',
    IN_PROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    UNKNOWN = 'Unknown',
  }
}
