import { Link } from 'react-router-dom';

import { FaaPart135ProgramLinks } from '@/features/documents-overview';
import { Icon } from '@cae/cobalt-react';

export function FaaPart135IndexPage(): JSX.Element {
  return (
    <div className="dialog-details__page index">
      <header>
        <h2>Looking to get a new FAA part 135 program approved?</h2>
        <p>
          CAE provides the following templates to consult or kickstart program
          approvals, however you must inform us of any new program approvals.
        </p>
      </header>
      <section>
        <h3>Part 1: View and download the pertinent documents</h3>
        <FaaPart135ProgramLinks />
      </section>
      <section>
        <h3>Part 2: Create an instructor report</h3>
        <p>
          Generate an{' '}
          <Link to={`/instructors`} target="_blank" rel="noopener noreferrer">
            <span>instructor report</span>
            <Icon
              id="external-link-outline"
              className="link-destination"
              size="sm"
            />
          </Link>
        </p>
      </section>
      <section>
        <h3>Part 3: Upload the documents</h3>
        <p>
          For the documents that can be filled out you can{' '}
          <Link to="/documents/overview/faa-part135-program/submit">
            upload here
          </Link>
        </p>
      </section>
    </div>
  );
}

FaaPart135IndexPage.displayName = 'FaaPart135IndexPage';
