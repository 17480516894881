import { DocumentsManagementService, PagedListOfDocumentDto } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { SubmittedDocumentsFilters } from '@/features/documents/components/submitted-documents/filter-modal/useFilterModalReducer';
import useDebounce from '@/shared/hooks/useDebounce';

type DocumentsQuery = {
  type: 'cae' | 'company' | 'submitted';
  searchText: string;
  filters?: SubmittedDocumentsFilters;
};

const DEFAULT_INDEX = 0;
const DEFAULT_PAGE_SIZE = 10;

export function useDocumentsSearchQuery({
  type,
  searchText,
  filters,
}: DocumentsQuery): UseQueryResult<PagedListOfDocumentDto, unknown> {
  const { status } = filters || {};

  const debouncedSearchText = useDebounce(searchText, 500);

  return useQuery({
    queryKey: ['documentsSearch', type, debouncedSearchText, filters],
    queryFn: async () =>
      DocumentsManagementService.searchDocuments({
        searchText: debouncedSearchText,
        pageIndex: DEFAULT_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        type,
        status,
      }),
    enabled: !!searchText,
    keepPreviousData: false,
    cacheTime: 1000,
  });
}
