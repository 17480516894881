import { PermissionResponse, RolePermissionsService } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetPermissionsQuery(): UseQueryResult<
  PermissionResponse[],
  unknown
> {
  return useQuery({
    queryKey: ['getAllPermissions'],
    queryFn: () => RolePermissionsService.findByRoleId({}),
  });
}
