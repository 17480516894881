import { format } from 'date-fns';

import type { TrainingSession } from '@/features/reservation-details/types';

import { TrainingEvent } from '../training-event/TrainingEvent';

import './DaySchedule.css';

export function DaySchedule({
  data,
  selectedDate,
}: {
  data: TrainingSession[];
  selectedDate?: Date;
}): JSX.Element {
  return (
    <div className="day-schedule">
      {selectedDate && <h4>{format(selectedDate, 'iiii, MMMM dd, yyyy')}</h4>}
      {Array.isArray(data) &&
        data.map(d => <TrainingEvent data={d} key={`date-${d.id}`} />)}
    </div>
  );
}
