import {
  Button,
  Icon,
  Modal,
  ScreenContent,
  ScreenHeader,
} from '@cae/cobalt-react';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import HasAccess from '@/shared/components/has-access/HasAccess';
import FilterModal from '@/features/users/components/filter-modal/FilterModal';
import useUsersFilter from '@/features/users/hooks/useUsersFilter';
import { UserFilters } from '@/features/users/components/filter-modal/useFilterModalReducer';

import './UsersPage.css';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { internalRoleMatching } from '@/shared/components/check-internal-roles/CheckInternalRoles';

import {
  CreateUser,
  SearchUsersInput,
  UsersListTabs,
} from '../../features/users';
import { Outlet } from 'react-router-dom';

function UserListPage(): JSX.Element {
  const { width } = useViewportSize();
  const { t } = useTranslation();
  const [isFilterModalOpen, setIsFilterModalOpen] =
    React.useState<boolean>(false);
  const [needle, setNeedle] = React.useState<string>('');

  const toggleFilterModal = (): void =>
    setIsFilterModalOpen(!isFilterModalOpen);

  const { filters, resetFilters, toggleAllFilters, filterCounter } =
    useUsersFilter();

  const filterOptions = {
    role: ['Admin', 'Training', 'Records', 'Finance'],
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault();
    setNeedle(e.target.value);
  };

  const currentRoles = useCurrentRoles();

  return (
    <HasAccess permissions="get_user">
      <section className="page page-users">
        <div className="page-wrapper">
          <ScreenHeader elevation="base">
            <hgroup>
              <h2>{t('createUser.manageUsers')}</h2>
            </hgroup>
            <div className="toolbar">
              <div className="create-user--search-with-filter">
                <SearchUsersInput
                  onChange={handleSearch}
                  onClear={e => {
                    e.preventDefault();
                    setNeedle('');
                  }}
                  value={needle}
                />
                <Button
                  className="btn-filters"
                  type="button"
                  variant="ghost"
                  onClick={toggleFilterModal}
                >
                  <Icon id="filter-outline" size="sm" />
                  <span className="label">Filters</span>
                  {`${filterCounter() ? `(${filterCounter()})` : ''}`}
                </Button>
              </div>
              {width > 480 && (
                <HasAccess permissions="add_user">
                  {internalRoleMatching(currentRoles) ? (
                    <CreateUser />
                  ) : (
                    <CreateUser />
                  )}
                </HasAccess>
              )}
            </div>
          </ScreenHeader>
          <ScreenContent>
            <UsersListTabs filters={filters} searchText={needle} />
            <Outlet />
          </ScreenContent>
        </div>
        <Modal
          id=""
          open={isFilterModalOpen}
          onCancel={toggleFilterModal}
          placement={width < 480 ? 'bottom' : 'right'}
          dismissible
          size="sm"
          className="filter--dialog"
        >
          {isFilterModalOpen && (
            <FilterModal
              filterOptions={filterOptions as UserFilters}
              filters={filters}
              resetFilters={resetFilters}
              toggleFilterSection={toggleFilterModal}
              toggleAllFilters={toggleAllFilters}
            />
          )}
        </Modal>
      </section>
    </HasAccess>
  );
}

export default UserListPage;
