/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RecordStatusFilter {
  AVAILABLE = 'Available',
  PENDING = 'Pending',
  NOT_APPLICABLE = 'NotApplicable',
  HARD_COPY_ONLY = 'HardCopyOnly',
  NOT_AVAILABLE = 'NotAvailable',
  ALL = 'All',
}
