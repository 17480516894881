import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  RecordStatus,
  ReservationManagementService,
  ReservationsMetadata,
} from '@/open-api';
import { format } from 'date-fns';
import { ReservationFilters } from '../components/filter-modal/useFilterModalReducer';

export function useReservationsMetadataQuery(
  filters?: ReservationFilters
): UseQueryResult<ReservationsMetadata, Error> {
  const {
    locations,
    platforms,
    courseTypes,
    etcaStatus,
    tsaStatus,
    scheduledStatus,
    records,
    dateRange,
    regulatoryAgencies,
  } = filters || {};

  return useQuery<ReservationsMetadata, Error>({
    queryKey: ['reservationsCount', filters],
    queryFn: () =>
      ReservationManagementService.getReservationsMetadata({
        location: locations,
        courseType: courseTypes,
        platform: platforms,
        regulatoryAgencies,

        startDateRangeFrom:
          dateRange && dateRange[0]
            ? format(dateRange[0], 'yyyy-MM-dd')
            : undefined,
        startDateRangeTo:
          dateRange && dateRange[1]
            ? format(dateRange[1], 'yyyy-MM-dd')
            : undefined,
        etcaStatus,
        tsaStatus,
        scheduleStatus: scheduledStatus,
        trainingRecordStatus:
          records === undefined || records.length === 0
            ? [
                RecordStatus.AVAILABLE,
                RecordStatus.HARD_COPY_ONLY,
                RecordStatus.NOT_APPLICABLE,
                RecordStatus.PENDING,
              ]
            : records,
      }),
  });
}
