import { Icon } from '@cae/cobalt-react';

import './DashboardSupport.css';

const CONTACT_CHANNELS = [
  { label: 'Mail', icon: 'email-solid', url: 'https://help.cae.com/home' },
  { label: 'Call', icon: 'phone-solid', url: 'https://help.cae.com/home' },
  { label: 'Chat', icon: 'comment-solid', url: 'https://help.cae.com/home' },
];

export function DashboardSupport(): JSX.Element {
  return (
    <div className="dashboard-support">
      <h3>24/7 Support</h3>
      <nav className="channel-list">
        {CONTACT_CHANNELS.map(c => (
          <a href={c.url} target="_blank" rel="noreferrer" key={c.label}>
            <span>
              <Icon id={c.icon} size="md" />
            </span>
            <span>{c.label}</span>
          </a>
        ))}
      </nav>
    </div>
  );
}

DashboardSupport.displayName = 'DashboardSupport';
