import * as React from 'react';

export function ScheduleEmptyState({
  children,
}: React.ComponentPropsWithoutRef<'div'>): JSX.Element {
  return (
    <div className="empty-state">
      <svg
        width="152px"
        height="144px"
        viewBox="0 0 152 144"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-283.5, -1008)" fillRule="nonzero">
            <g transform="translate(24, 880)">
              <g transform="translate(213.5, 128)">
                <g transform="translate(46, 0)">
                  <path
                    d="M10.56,0 L141.336165,0 C147.164531,0.00963496306 151.890845,4.73164048 151.905795,10.5599956 L152,113.856216 L152,113.856216 L135.928751,130.141101 L124.275338,144 L10.56,144 C4.72787304,144 -1.06212728e-15,139.272127 0,133.44 L0,10.56 C-7.14229561e-16,4.72787304 4.72787304,4.62405802e-15 10.56,0 Z"
                    fill="#F7F7F7"
                  />
                  <path
                    d="M10.5600004,0 L141.336164,0 C147.168292,-1.66112529e-14 151.896165,4.72787323 151.896165,10.5600004 L151.896165,27.6869565 L151.896165,27.6869565 L0,27.6869565 L0,10.5600004 C1.06212725e-15,4.72787323 4.72787323,1.07134438e-15 10.5600004,0 Z"
                    fill="#A4ACB0"
                  />
                  <rect
                    fill="#EBECED"
                    x="15.8500346"
                    y="43.5478261"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="15.8500346"
                    y="72.626087"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="15.8500346"
                    y="101.704348"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="50.1917762"
                    y="43.5478261"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="50.1917762"
                    y="72.626087"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="50.1917762"
                    y="101.704348"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="83.2126815"
                    y="43.5478261"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#A4ACB0"
                    x="83.2126815"
                    y="72.626087"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="83.2126815"
                    y="101.704348"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="117.554423"
                    y="43.5478261"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="117.554423"
                    y="72.626087"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <rect
                    fill="#EBECED"
                    x="117.554423"
                    y="101.704348"
                    width="18.491707"
                    height="18.5043478"
                  />
                  <path
                    d="M151.599493,114.147283 L124.070892,144 L124.070892,124.615146 C124.070892,118.78302 128.798765,114.055146 134.630892,114.055146 C134.650038,114.055146 134.669183,114.055199 134.688328,114.055303 L151.599493,114.147283 L151.599493,114.147283 Z"
                    fill="#A4ACB0"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      {children}
    </div>
  );
}
