import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Icon, Tag, Tooltip } from '@cae/cobalt-react';
import { InvoiceResponse } from '@/open-api';
import getFormattedDate from '@/shared/helpers/getFormattedDate';
import { useState } from 'react';
import CurrencyFormat from '@/shared/components/format-currency/FormatCurrency';
import InvoiceDownloadButton from '../invoice-download-button/InvoiceDownloadButton';

import './columns.css';

const columnHelper = createColumnHelper<InvoiceResponse>();

const columnDueDate = columnHelper.display({
  header: 'Due date',
  cell: ({ row }) => {
    const { dueDate } = row.original;
    return (
      <>
        {dueDate ? (
          getFormattedDate(dueDate, 'dd-MMM-yyyy')
        ) : (
          <div className="invoices-and-payments_empty"></div>
        )}
      </>
    );
  },
});

const columnIssuedDate = columnHelper.display({
  header: 'Issued on',
  cell: ({ row }) => {
    const { issuedOn } = row.original;
    return (
      <>
        {issuedOn ? (
          getFormattedDate(issuedOn, 'dd-MMM-yyyy')
        ) : (
          <div className="invoices-and-payments_empty"></div>
        )}
      </>
    );
  },
});

function TransactionNumberCell({
  transactionNumber,
}: {
  readonly transactionNumber: string;
}): JSX.Element {
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    navigator.clipboard.writeText(transactionNumber);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };
  return (
    <div className="invoices-and-payments_copy-container">
      <span>{transactionNumber}</span>
      {!copySuccess && (
        <button onClick={handleCopy}>
          <Icon id="duplicate-outline" size="sm" />
        </button>
      )}
      {copySuccess && (
        <span className="invoices-and-payments_copy-container">
          <Icon
            id="checkmark-circle-solid"
            size="sm"
            className="invoices-and-payments__copy-checked"
          />
          <p className="invoices-and-payments_copy-text"> Copied!</p>
        </span>
      )}
    </div>
  );
}

// Use the custom component in the column definition
const columnTransactionNumber = columnHelper.accessor('transactionNumber', {
  header: () => (
    <div>
      Transaction number{' '}
      <Tooltip offsetOptions={7} placement="bottom" highContrast>
        <Tooltip.Trigger>
          <span>
            <Icon
              id="info-circle-solid"
              size="sm"
              className="invoices-and-payments_header-icon"
            />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Content>Your invoice or credit memo number.</Tooltip.Content>
      </Tooltip>
    </div>
  ),
  cell: ({ row }) => (
    <TransactionNumberCell transactionNumber={row.original.transactionNumber} />
  ),
});

const columnAmount = columnHelper.display({
  id: 'Amount',
  header: 'Amount',
  cell: ({ row }) => {
    const { totalAmount, type, currency } = row.original;
    if (!totalAmount || !currency) {
      return <div className="invoices-and-payments_empty"></div>;
    }
    if (type === 'CreditMemo') {
      return (
        <div style={{ color: 'green' }}>
          (<CurrencyFormat value={totalAmount} currency={currency} />)
        </div>
      );
    } else {
      return (
        <div style={{ color: 'black' }}>
          <CurrencyFormat value={totalAmount} currency={currency} />
        </div>
      );
    }
  },
});
const columnPilotNames = columnHelper.display({
  id: 'Pilots',
  header: 'Pilot(s)',
  cell: ({ row }) => {
    const pilots = row.original.pilotNames
      ?.filter(pilot => pilot.givenName ?? pilot.middleName ?? pilot.lastName)
      .map(
        pilot =>
          `${pilot.givenName ?? ''} ${pilot.middleName ?? ''} ${pilot.lastName ?? ''}`
      );
    return (
      <span title={pilots?.join(', ')}>
        {pilots && pilots.length > 0 ? (
          <>
            {pilots.slice(0, 2).join(', ')}
            {pilots.length > 2 && ` ,(+${pilots.length - 2})`}
          </>
        ) : (
          <div className="invoices-and-payments_empty"></div>
        )}
      </span>
    );
  },
});

const columnReservationNumber = columnHelper.display({
  id: 'ReservationNumber',
  header: 'Reservation number(s)',
  cell: props => {
    const reservationIds = props.row.original.schedulerReservationIds;
    return reservationIds &&
      reservationIds.length > 0 &&
      !(
        reservationIds.some(item => item === null) &&
        reservationIds.length === 1
      ) ? (
      <span title={reservationIds.join(', ')}>
        {reservationIds.slice(0, 2).join(', ')}
        {reservationIds.length > 2 ? ` ,(+${reservationIds.length - 2})` : null}
      </span>
    ) : (
      <div className="invoices-and-payments_empty"></div>
    );
  },
});

const columnLegalEntityName = columnHelper.accessor('legalEntityName', {
  header: 'Legal entity',
});

const columnPoNumber = columnHelper.display({
  id: 'PoNumbers',
  header: 'PO number',
  cell: props => {
    const poNumbers = props.row.original.poNumbers;
    return poNumbers && poNumbers.length > 0 ? (
      <span title={poNumbers.join(', ')}>
        {poNumbers.slice(0, 2).join(', ')}
        {poNumbers.length > 2 ? ` ,(+${poNumbers.length - 2})` : null}
      </span>
    ) : (
      <div className="invoices-and-payments_empty"></div>
    );
  },
});
const columnStatus = columnHelper.display({
  id: 'status',
  header: () => (
    <div>
      Status{' '}
      <Tooltip offsetOptions={7} placement="bottom" highContrast>
        <Tooltip.Trigger>
          <span className="invoices-and-payments_header-icon">
            <Icon id="info-circle-solid" size="sm" />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Content>
          It can take up to 3 business days for the status to update.
        </Tooltip.Content>
      </Tooltip>
    </div>
  ),
  cell: props => {
    const { status, type } = props.row.original;
    const color = status === 'Paid' ? 'green' : 'red';
    if ((status === 'Paid' || status === 'Unpaid') && type !== 'CreditMemo') {
      return (
        <Tag className="invoiceStatus" color={color} compact title={status}>
          {status}
        </Tag>
      );
    }
    return (
      <Icon className="invoices-and-payments" id="remove-outline" size="sm" />
    );
  },
});

const columnDownload = columnHelper.display({
  id: 'download',
  cell: props => {
    const invoiceId = props.row.original.accountReceivableId;
    const fileName = props.row.original.transactionNumber;
    return (
      <button
        type="button"
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
        onKeyDown={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        <InvoiceDownloadButton invoiceId={invoiceId} fileName={fileName} />
      </button>
    );
  },
});

export const allColumns: ColumnDef<InvoiceResponse, string>[] = [
  columnDueDate,
  columnIssuedDate,
  columnTransactionNumber,
  columnAmount,
  columnPilotNames,
  columnReservationNumber,
  columnLegalEntityName,
  columnPoNumber,
  columnStatus,
  columnDownload,
];

export const unpaidColumns: ColumnDef<InvoiceResponse, string>[] = [
  columnDueDate,
  columnIssuedDate,
  columnTransactionNumber,
  columnAmount,
  columnPilotNames,
  columnReservationNumber,
  columnLegalEntityName,
  columnPoNumber,
  columnStatus,
  columnDownload,
];
