import * as React from 'react';
import { useParams } from 'react-router-dom';
import { AlertMessage, Button, Icon } from '@cae/cobalt-react';

import { DocumentViewer } from '@/shared/components';

import './DocumentInfoFragment.css';

import { downloadDocument } from '@/shared/api/downloadDocument';
import { DocumentDto } from '@/open-api';
import { setDocumentViewed } from '@/features/documents/helpers/setDocumentViewed';
import DocumentLocation from '../document-location/DocumentLocation';
import { useUserInfo } from '@/contexts/userInfo';

type DownloadStatus = 'idle' | 'pending' | 'success' | 'error';

function shouldDisplayDocumentLocationPath(): boolean {
  return !window.location.href.includes('/documents/submitted');
}

interface DocumentInfoFragmentProps {
  data: DocumentDto;
}
export function DocumentInfoFragment({
  data,
}: Readonly<DocumentInfoFragmentProps>): null | JSX.Element {
  const { documentId = '' } = useParams();
  const [downloadStatus, setDownloadStatus] =
    React.useState<DownloadStatus>('idle');
  const { selectedOrganizationId } = useUserInfo();
  if (!documentId) {
    return (
      <AlertMessage type="error" heading="Oops!">
        <p>Looks like documentID is missing</p>
      </AlertMessage>
    );
  }

  return (
    <section className="dialog-details__fragment document-info">
      <header>
        <h3>Document details</h3>
        <Button
          variant="ghost"
          disabled={downloadStatus === 'pending'}
          pending={downloadStatus === 'pending'}
          onClick={async () => {
            setDownloadStatus('pending');
            const d = await downloadDocument(
              selectedOrganizationId as string,
              data.id
            );
            setDownloadStatus(d ? 'success' : 'error');
            if (d) setDocumentViewed(data);
          }}
        >
          Open
          {downloadStatus !== 'pending' && (
            <Icon id="external-link-outline" size="sm" />
          )}
        </Button>
      </header>
      {downloadStatus === 'error' && (
        <AlertMessage type="error" heading="Error opening file">
          <p>
            Could not open file <b>{data.name}</b>
          </p>
        </AlertMessage>
      )}
      <DocumentViewer />
      {shouldDisplayDocumentLocationPath() && (
        <DocumentLocation parentFolders={data.parentFolders} type={data.type} />
      )}
    </section>
  );
}

DocumentInfoFragment.displayName = 'DocumentInfoFragment';
