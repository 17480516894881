import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';
import Documents from '@/assets/images/dashboard/documents-icon.svg';
import './DashboardCarousel.css';

export function DashboardCarousel(): JSX.Element {
  return (
    <div className="dashboard-carousel">
      <span className="image">
        <img src={Documents} alt="Documents" />
      </span>
      <span className="text">
        <h4>Are your company documents up to date?</h4>
        <Link to="/documents/company" className="carousel-link">
          Learn more <Icon id="arrow-right-outline" size="sm" />
        </Link>
      </span>
    </div>
  );
}

DashboardCarousel.displayName = 'DashboardCarousel';
