/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PilotName } from './PilotName';
/**
 * List with requested items
 */
export type InvoiceResponse = {
  /**
   * Type of account receivable
   */
  type?: InvoiceResponse.type;
  /**
   * Account receivable identifier
   */
  accountReceivableId: string;
  /**
   * Invoice number
   */
  transactionNumber: string;
  /**
   * Total amount of the invoice
   */
  totalAmount?: number;
  currency?: string;
  status?: string;
  pilotNames?: Array<PilotName>;
  schedulerReservationIds?: Array<string>;
  legalEntityName?: string;
  /**
   * Due Date of the Invoice
   */
  dueDate?: string;
  /**
   * Invoice Issued date
   */
  issuedOn?: string;
  poNumbers?: Array<string>;
};
export namespace InvoiceResponse {
  /**
   * Type of account receivable
   */
  export enum type {
    INVOICE = 'Invoice',
    CREDIT_MEMO = 'CreditMemo',
  }
}
