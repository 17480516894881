import * as React from 'react';
import { format, parseISO } from 'date-fns';
import { AlertBanner, AlertMessage, Icon, Modal } from '@cae/cobalt-react';
import { SubmitHandler, FieldValues } from 'react-hook-form';

import {
  ApiError,
  ReservationDetailsResponse,
  ReservationManagementService,
} from '@/open-api';

import { BookingConfirmationEmailForm } from '@/features/reservation-details/components';

import { useGetReservationDetailsQuery } from '@/features/reservation-details/api/useGetReservationDetails';

import type { PostResponseStatus } from '@/shared/types';
import type { ReservationData } from '@/features/reservation-details/types';

import { useHasPermission } from '@/shared/hooks/useHasPermission';

import './css/ConfirmationEmailStatusActions.css';
import { useUserInfo } from '@/contexts/userInfo';

export function ConfirmationEmailStatusActions({
  data,
  reservationId,
}: {
  data: ReservationData['statusInfo']['confirmationEmail'];
  reservationId: string;
}): JSX.Element {
  const hasPermission = useHasPermission();
  const [key, setKey] = React.useState<string>(`key-${Date.now()}`);
  const [open, setOpen] = React.useState<boolean>(false);
  const [emailAddress, setEmailAddress] = React.useState<string>('');
  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });
  const { selectedOrganizationId } = useUserInfo();
  const { data: clientMail } =
    useGetReservationDetailsQuery<ReservationDetailsResponse>(reservationId);

  const handleResendClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setOpen(true);
  };

  const onCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setEmailAddress('');
    setStatus({ type: 'idle' });
    setOpen(false);
  };

  const onRefresh = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setEmailAddress('');
    setStatus({ type: 'idle' });
    setKey(`key-${Date.now()}`);
  };

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });
    const { email } = data;

    if (email) setEmailAddress(email);

    try {
      if (selectedOrganizationId) {
        await ReservationManagementService.sendBookingConfirmationEmail({
          reservationId,
          organizationId: selectedOrganizationId,
          requestBody: {
            sendTo: email,
          },
        });
        setStatus({
          type: 'success',
          heading: '',
          message: `Email successfully sent to`,
        });
      } else {
        throw Error('no organization Id');
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setStatus({
          type: 'error',
          heading: 'API error occurred',
          message:
            'Booking confirmation email has not yet been sent. Try again, or contact the administrators.',
        });
      } else {
        setStatus({
          type: 'error',
          heading: 'Oops, an error occurred',
          message:
            'Booking confirmation email has not yet been sent. Try again, or contact the administrators.',
        });
      }
    }
  };

  return (
    <article>
      <h4>Booking confirmation email</h4>
      {data && (
        <p>
          {data.sent ? (
            <>
              <Icon
                id="checkmark-circle-solid"
                className="status success"
                size="sm"
              />
              Email sent{' '}
              {data.sentAt ? (
                <>on {format(parseISO(data.sentAt), 'dd-MMM-yyyy')}</>
              ) : null}
              {data.sentTo ? <> to {data.sentTo}</> : null}
            </>
          ) : (
            <>
              <Icon
                id="warning-triangle-solid"
                className="status danger"
                size="sm"
              />
              Email not sent yet
            </>
          )}
        </p>
      )}
      <div className="actions">
        {hasPermission('reservations_confirmation_letter_actions') ? (
          <button type="button" onClick={handleResendClick}>
            Resend <Icon id="send-outline" size="sm" />
          </button>
        ) : null}
      </div>
      {open ? (
        <Modal
          animate
          className="booking-confirmation-email"
          dismissible
          onCancel={onCancel}
          open
          placement="center"
          size="sm"
        >
          <span key={key}>
            <header>
              <h3>Resend email</h3>
              <p>Resend the booking confirmation email.</p>
              {status.type === 'success' && (
                <AlertMessage type="success" heading="">
                  <p>
                    {status.message} {emailAddress}
                  </p>
                  <p>
                    <button
                      className="anchor"
                      type="button"
                      onClick={onRefresh}
                    >
                      Send another email?
                    </button>
                  </p>
                </AlertMessage>
              )}
              {status.type === 'error' && (
                <AlertBanner type="error" heading={status.heading}>
                  <p>{status.message}</p>
                </AlertBanner>
              )}
            </header>
            <BookingConfirmationEmailForm
              initialValues={{ email: clientMail?.client?.email || '' }}
              onCancel={onCancel}
              onSubmit={onSubmit}
              status={status}
            />
          </span>
        </Modal>
      ) : null}
    </article>
  );
}

ConfirmationEmailStatusActions.displayName = 'ConfirmationEmailStatusActions';
