import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { UserManagementService, UserProfileRequest } from '@/open-api';

type ProfileMutationCallback = {
  onSuccessCallback: () => void;
  onErrorCallback: () => void;
};

export const useProfileMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: ProfileMutationCallback): UseMutationResult<
  unknown,
  unknown,
  {
    id: string | undefined;
    data: UserProfileRequest;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      id,
      data,
    }: {
      id: string | undefined;
      data: UserProfileRequest;
    }) => {
      if (id === undefined) {
        throw Error('ID required');
      }
      const response = await UserManagementService.updateProfile({
        id,
        requestBody: data,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
        onSuccessCallback();
      },
      onError: error => {
        onErrorCallback();
        throw error;
      },
    }
  );
};
