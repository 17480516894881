import { Card, Tag } from '@cae/cobalt-react';
import { statusColors } from '@/features/reservations/components/reservations-listing/ReservationsListing';
import { format } from 'date-fns';
import { RecordResponseDto } from '@/open-api';

function ReservationIdRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>Reservation</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

function PlatformRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>Platform</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

function CourseRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>Course</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

function StartDateRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>Start date</dt>
      <dd>{data ? format(new Date(), 'dd-MMM-yyyy') : ''}</dd>
    </>
  );
}

function EndDateRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>End date</dt>
      <dd>{data ? format(new Date(), 'dd-MMM-yyyy') : ''}</dd>
    </>
  );
}

function LocationRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>Location</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

function StatusRow({
  data,
}: {
  readonly data?: RecordResponseDto['status'];
}): JSX.Element | null {
  return data ? (
    <Tag
      className="records-status"
      color={
        statusColors[data?.toLowerCase() || 'neutral'] as
          | 'standard'
          | 'blue'
          | 'green'
          | 'red'
          | 'purple'
          | 'yellow'
          | 'orange'
          | 'pink'
          | 'lime'
          | 'turquoise'
          | 'teal'
          | undefined
      }
      compact
    >
      {data}
    </Tag>
  ) : null;
}

function RecordPublishedRow({ data }: { readonly data?: string }): JSX.Element {
  return (
    <>
      <dt>Record published on</dt>
      <dd>{data ? format(new Date(), 'dd-MMM-yyyy') : ''}</dd>
    </>
  );
}

interface CardProps {
  readonly data: RecordResponseDto;
  readonly openDetails: (data: RecordResponseDto) => void;
}

export function RecordFullCard({ data, openDetails }: CardProps): JSX.Element {
  return (
    <Card
      key={`record-${data.recordId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <StatusRow data={data.status} />
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdRow data={data.recordId ?? ''} />
        <PlatformRow data={data.platform} />
        <CourseRow data={data.courseName} />
        <StartDateRow data={data.startDate} />
        <EndDateRow data={data.endDate} />
        <LocationRow data={data.location} />
        <RecordPublishedRow data={data.publishedDate} />
      </dl>
    </Card>
  );
}

export function RecordAvailableCard({
  data,
  openDetails,
}: CardProps): JSX.Element {
  return (
    <Card
      key={`record-${data.recordId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdRow data={data.recordId ?? ''} />
        <PlatformRow data={data.platform} />
        <CourseRow data={data.courseName} />
        <StartDateRow data={data.startDate} />
        <EndDateRow data={data.endDate} />
        <LocationRow data={data.location} />
        <RecordPublishedRow data={data.startDate} />
      </dl>
    </Card>
  );
}

export function CommonRecordCard({
  data,
  openDetails,
  children,
}: CardProps & { children: React.ReactNode }): JSX.Element {
  return (
    <Card
      key={`record-${data.recordId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdRow data={data.recordId ?? ''} />
        <PlatformRow data={data.platform} />
        <CourseRow data={data.courseName} />
        <StartDateRow data={data.startDate} />
        <EndDateRow data={data.endDate} />
        <LocationRow data={data.location} />
        {children}
      </dl>
    </Card>
  );
}

export function RecordPendingCard({
  data,
  openDetails,
}: CardProps): JSX.Element {
  return (
    <CommonRecordCard data={data} openDetails={openDetails}>
      <RecordPublishedRow data={data.publishedDate} />
    </CommonRecordCard>
  );
}

export function RecordNotAvailableCard({
  data,
  openDetails,
}: CardProps): JSX.Element {
  return (
    <CommonRecordCard data={data} openDetails={openDetails}>
      <RecordPublishedRow data={data.publishedDate} />
    </CommonRecordCard>
  );
}
