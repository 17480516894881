import * as React from 'react';
import { Icon } from '@cae/cobalt-react';

interface Props {
  className?: string;
  open?: boolean;
  options?: string[];
  style?: React.CSSProperties;
}

export function SupportedFilesDialog({
  className = '',
  style = {},
  options = [],
}: Props): JSX.Element {
  const rootRef = React.useRef<React.ElementRef<'div'>>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    const handleMouseDown = (e: MouseEvent | TouchEvent): void => {
      const rootEl = (rootRef as React.RefObject<HTMLDivElement>).current;
      if (rootEl?.contains(e.target as Node)) {
        // do nothing
      } else {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchstart', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('touchstart', handleMouseDown);
    };
  }, []);

  const handleToggleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setOpen(!open);
  };

  const dialogProps = {
    className: className || undefined,
    open,
    style,
  };
  return (
    <div className="supported-files" ref={rootRef}>
      <button
        type="button"
        className="supported-files-toggle"
        onClick={handleToggleClick}
      >
        <Icon id="info-circle-solid" size="xs" />
        Supported files
      </button>
      <dialog {...dialogProps}>
        <p>The following file types are accepted:</p>
        <ul>
          {options.map(o => (
            <li key={o}>{o}</li>
          ))}
        </ul>
      </dialog>
    </div>
  );
}
