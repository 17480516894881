import * as React from 'react';
import {
  CaeCustomerAuthority,
  OrganizationAssignmentDto,
  UserAccessResponse,
} from '@/open-api';
import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';
import { useGetOrganizationsQuery } from '@/shared/api/useGetOrganization';
import Logger from '@/libs/logging/Logger';
import { useGetSelectedOrgAuthoritiesQuery } from '@/features/documents/api';
import { AcceptCookiesContext } from '../configs/acceptCookiesContext';
import { Organization } from '@/shared/api/common.interface';

export type UserInfo = {
  id: string | undefined;
  selectedOrganization?: OrganizationAssignmentDto;
  selectedOrganizationId?: string | null;
  organizations: OrganizationAssignmentDto[] | undefined;
  authorities: CaeCustomerAuthority[];
  isInternalUser: boolean | undefined;
  isActive: boolean;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  username?: string;
  email?: string;
  salutation?: string;
  title?: string;
  isPart135Certified: boolean;
};

export const initialState: UserInfo = {
  id: undefined,
  selectedOrganization: undefined,
  authorities: [],
  isInternalUser: undefined,
  organizations: [],
  isActive: false,
  isPart135Certified: false,
};

export function configureHeap(
  email: string | undefined,
  id: string | undefined,
  salesforceId: string | undefined,
  customerId: string | undefined,
  selectedOrganizationId?: string | undefined | null
): void {
  const isInternalUser = sessionStorage.getItem('auth-type') === 'b2e';
  window?.heap?.load && window?.heap?.load(import.meta.env.VITE_HEAP_APIKEY);
  localStorage.setItem('walkme_uuid', id ?? '');
  if (isInternalUser && email && window?.heap) {
    window?.heap?.identify(email);
    window?.heap?.addEventProperties({
      SalesforceId: salesforceId,
      CustomerId: customerId,
      SelectedOrganizationId: selectedOrganizationId,
    });
  } else if (id && (salesforceId || customerId) && window?.heap) {
    window?.heap?.identify(id);
    window?.heap?.addEventProperties({
      SalesforceId: salesforceId,
      CustomerId: customerId,
    });
  } else {
    Logger.log({
      type: 'code',
      level: 'error',
      message: `Error: Not an internal user nor have an SFID: ${salesforceId} or customerID: ${customerId}.`,
    });
  }
}

export function getUserInfo(
  userAccessDto: UserAccessResponse | undefined,
  organizationsDto: Array<OrganizationAssignmentDto> | Organization[],
  authorities: CaeCustomerAuthority[]
): UserInfo {
  if (
    userAccessDto === undefined ||
    organizationsDto === undefined ||
    userAccessDto.id === undefined
  ) {
    return initialState;
  }

  const selectedOrganization = userAccessDto?.organizations?.find(
    o => o.id === userAccessDto.selectedOrganization
  );

  const isInternalUser = sessionStorage.getItem('auth-type') === 'b2e';
  const isPart135Certified =
    userAccessDto?.organization?.part135CertificateCode?.length === 4;

  return {
    id: userAccessDto.id,
    isActive: userAccessDto.active || false,
    organizations: userAccessDto.organizations,
    selectedOrganization,
    authorities,
    salutation: userAccessDto.salutation,
    title: userAccessDto.title,
    firstName: userAccessDto.firstName,
    middleName: userAccessDto.middleName,
    lastName: userAccessDto.lastName,
    email: userAccessDto.email,
    username: userAccessDto.username,
    isInternalUser,
    selectedOrganizationId: userAccessDto.selectedOrganization,
    isPart135Certified,
  };
}

const UserInfoContext = React.createContext<UserInfo>(initialState);
UserInfoContext.displayName = 'UserInfoContext';

function UserInfoProvider({
  children,
}: Readonly<{
  children: JSX.Element[];
}>): JSX.Element {
  const { data: profile } = useGetProfileQuery();
  const userId = profile?.id ?? '';
  const { data: organizations = [] } = useGetOrganizationsQuery(userId);
  const { data: authorities = [] } = useGetSelectedOrgAuthoritiesQuery(
    profile?.selectedOrganization
  );

  const userInfo = getUserInfo(profile, organizations, authorities);
  const { acceptCookiesStatus } = React.useContext(AcceptCookiesContext);
  const [prevProfile, setPrevProfile] = React.useState<
    UserAccessResponse | undefined
  >(undefined);
  React.useEffect(() => {
    if (
      (acceptCookiesStatus ||
        sessionStorage.getItem('acceptCookiesStatus') === 'true') &&
      (prevProfile?.selectedOrganization !== profile?.selectedOrganization ||
        prevProfile?.selectedOrganization === undefined) &&
      userInfo.id
    ) {
      configureHeap(
        profile?.email,
        profile?.userId,
        userInfo?.selectedOrganization?.salesforceId,
        userInfo?.selectedOrganization?.customerId,
        userInfo?.selectedOrganizationId
      );
      setPrevProfile(profile);
    }
  }, [profile, prevProfile, userInfo, acceptCookiesStatus]);
  return (
    <UserInfoContext.Provider value={userInfo}>
      {children}
    </UserInfoContext.Provider>
  );
}

function useUserInfo(): UserInfo {
  const context = React.useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error(`useUserInfo must be used within a UserProvider`);
  }

  return context;
}

export { useUserInfo, UserInfoProvider };
