import { useReducer } from 'react';
import { UserFilters } from '@/features/users/components/filter-modal/useFilterModalReducer';
import { Actions, filtersReducer } from './usersFilterReducer';

const initialFilters: UserFilters = {
  role: [],
};

interface UseUsersFilter {
  filters: UserFilters;
  resetFilters: () => void;
  toggleAllFilters: (selected: UserFilters) => void;
  filterCounter: () => number;
}

function useUsersFilter(): UseUsersFilter {
  const [filters, dispatch] = useReducer(filtersReducer, initialFilters);

  const toggleAllFilters = (selected: UserFilters): void => {
    dispatch({ type: Actions.toggleAllFilters, payload: selected });
  };

  const resetFilters = (): void => {
    dispatch({ type: Actions.resetFilter });
  };

  const filterCounter = (): number => {
    return filters.role.length;
  };

  return {
    filters,
    resetFilters,
    toggleAllFilters,
    filterCounter,
  };
}

export default useUsersFilter;
