import * as React from 'react';

import { PaginationState } from '@tanstack/react-table';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { useInstructorManagementQuery } from '../../api/useInstructorManagementQuery';

import { InstructorsCards } from './InstructorsCards';
import { InstructorsTable } from './InstructorsTable';

import './InstructorsListing.css';

export function InstructorsListing(): JSX.Element {
  const { width } = useViewportSize();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const { data, status } = useInstructorManagementQuery(pageIndex, pageSize);

  const commonProps = {
    data: data?.items,
    queryStatus: status,
    pageIndex,
    pageSize,
    setPagination,
    totalPages: data?.totalPages ? data.totalPages : 1,
  };

  return width <= 768 ? (
    <InstructorsCards {...commonProps} />
  ) : (
    <InstructorsTable {...commonProps} />
  );
}
