import {
  CaeCustomerOperatingRegion,
  ConfigurationManagementService,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetOperatingRegionQuery(): UseQueryResult<
  CaeCustomerOperatingRegion[]
> {
  return useQuery<CaeCustomerOperatingRegion[]>({
    queryKey: ['configuration', 'operatingRegion'],
    queryFn: async () => ConfigurationManagementService.getOperatingRegions(),
  });
}
