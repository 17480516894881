import { Dropdown } from '@cae/cobalt-react';

import './CriteriaFieldset.css';

type Option = {
  label: string;
  value: string;
};

type PlatformOption = {
  location?: string;
  platforms?: Option[];
};

interface Props {
  locationOptions: Option[];
  platformOptions?: PlatformOption[];
  location: string;
  platform: string;
  setLocation: (value: string) => void;
  setPlatform: (value: string) => void;
}

export function CriteriaFieldset({
  locationOptions,
  platformOptions,
  location,
  platform,
  setLocation,
  setPlatform,
}: Props): JSX.Element {
  const filteredPlatformOptions = (): Option[] => {
    const platformOptionsFiltered = platformOptions?.filter(
      option => option.location === location
    );
    return platformOptionsFiltered?.[0]?.platforms || [];
  };

  return (
    <fieldset>
      <Dropdown
        label="Location"
        emptyText="Select location"
        field={{
          name: 'location',
          onChange: e => setLocation(e.target.value),
          value: location,
        }}
        options={locationOptions}
      />
      <Dropdown
        label="Platform"
        emptyText="Select platform"
        field={{
          name: 'platform',
          onChange: e => setPlatform(e.target.value),
          value: platform,
          disabled: !location,
        }}
        options={filteredPlatformOptions()}
      />
    </fieldset>
  );
}
