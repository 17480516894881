import { useNavigate } from 'react-router-dom';

import {
  SubmitHandler,
  Controller,
  useForm,
  FieldValues,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button, Dialog, TextInput } from '@cae/cobalt-react';
import { RecordManagementService, ApiError } from '@/open-api';
import * as React from 'react';
import { toastMessages } from '@/shared/helpers/toastMessages';

const validateTitle = z
  .string()
  .min(1, 'Title is required')
  .min(3, 'Min. 3 characters')
  .max(320, 'Max. 320 characters');

const validateDetails = z
  .string()
  .min(1, 'Details are required')
  .min(3, 'Min. 3 characters')
  .max(1000, 'Max. 1000 characters');

const FormSchema = z.object({
  title: validateTitle,
  details: validateDetails,
});

type Props = {
  recordId: string;
};

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}

export function RecordRequestForm({ recordId = '' }: Props): JSX.Element {
  const navigate = useNavigate();
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const onSubmit: SubmitHandler<FieldValues> = async data => {
    const { details: text, title } = data;
    try {
      await RecordManagementService.updateRecordRequestEmail({
        reservationId: recordId,
        requestBody: {
          title,
          text,
        },
      });
      toastMessages.success('Request submitted successfully!');
      navigate(`/records`);
    } catch (error) {
      if (error instanceof ApiError) {
        toastMessages.error(
          'Your request has not yet been sent. Try again, or contact the administrators.'
        );
        navigate(`/records`);
      } else {
        toastMessages.error(
          'Oops, an error occurred. Try again, or contact the administrators.'
        );
        navigate(`/records`);
      }
    }
  };
  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    navigate(`/records/${recordId}`);
  };
  const handleCancelClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(true);
  };

  const {
    control,
    formState: { errors, touchedFields, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm<FieldValues>({
    defaultValues: {
      recordId,
      title: '',
      details: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(FormSchema),
  });

  return (
    <form
      action="."
      method="post"
      className="dialog-details__fragment"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <h3>Request summary</h3>
      <p>
        Please enter a summary title of the request and then enter as many
        details as you can that will allow us to process it.
      </p>
      <fieldset>
        <input type="hidden" {...register('reservationId')} />
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <TextInput
              field={{
                ...field,
                disabled: isSubmitting,
              }}
              label="Title *"
              meta={{
                error: (errors.title?.message as string) || '',
                touched: !!touchedFields.title,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="details"
          render={({ field }) => (
            <TextInput
              field={{
                ...field,
                disabled: isSubmitting,
              }}
              label="Description and details *"
              meta={{
                error: (errors.details?.message as string) || '',
                touched: !!touchedFields.details,
              }}
              multiline
            />
          )}
        />
      </fieldset>

      <div className="form-actions">
        <Button type="button" variant="secondary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={isSubmitting || !isValid}
          pending={isSubmitting}
        >
          Send request
        </Button>
      </div>
      <Dialog
        modal
        className=""
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard change request?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </form>
  );
}
