import { useNavigate, useLocation } from 'react-router-dom';

import { DocumentsChangeRequest } from '@/features/documents/components';

export function DocumentsOverviewRequestPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCancel = (
    e: React.SyntheticEvent<HTMLDialogElement | Element>
  ): void => {
    e.preventDefault();
    const pathEls = location.pathname.split('/');
    const closePath = pathEls.slice(0, pathEls.length - 1).join('/');
    navigate(closePath);
  };

  return <DocumentsChangeRequest onCancel={handleCancel} />;
}

DocumentsOverviewRequestPage.displayName = 'DocumentsOverviewRequestPage';
