import { AlertMessage } from '@cae/cobalt-react';
import { useNavigate } from 'react-router-dom';
import './ChangeRequestBanner.css';

interface ChangeRequestBannerProps {
  readonly message: string;
  readonly navigate_to: string;
}

function ChangeRequestBanner({
  message,
  navigate_to,
}: ChangeRequestBannerProps): JSX.Element {
  const navigate = useNavigate();
  const handleChangeRequestClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate(navigate_to);
  };
  return (
    <AlertMessage
      className="company-profile--alert-card"
      size="md"
      type="info"
      heading={''}
    >
      <p className="company-profile--alert-message">{message}</p>
      <button
        className="anchor"
        type="button"
        data-action="swap-view"
        onClick={handleChangeRequestClick}
        data-view="change-request"
      >
        change request.
      </button>
    </AlertMessage>
  );
}

export default ChangeRequestBanner;
