import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useLanguageInitialization(): void {
  const { i18n } = useTranslation();

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0];
    const supportedLanguages = ['en', 'fr'];

    const initialLanguage = supportedLanguages.includes(browserLanguage)
      ? browserLanguage
      : 'en';

    i18n.changeLanguage(initialLanguage);
  }, [i18n]);
}
