import * as React from 'react';
import { Icon } from '@cae/cobalt-react';
import { AccountExecutiveContact, WorkingHours } from '@/open-api';
import { SupportRequest } from './support-request/SupportRequest';

const hasPhoneOrEmailValues = (
  data: AccountExecutiveContact
): string | undefined => data && (data.email || data.phoneNumber);

const displayWorkDaysWithHours = (workingHours: WorkingHours): string => {
  const { daysOfWeek, startTime, endTime, timeZone } = workingHours;
  const time = `${startTime} - ${endTime} (${timeZone})`;

  if (daysOfWeek && daysOfWeek.length === 5) {
    return `Mon to Fri: ${time}`;
  }
  const shortDaysOfWeek = daysOfWeek?.map(
    (day: string) => day.charAt(0).toUpperCase() + day.slice(1, 3)
  );
  return `${shortDaysOfWeek?.join(', ')}: ${time}`;
};

export function ContactAccountExecutive({
  data = {},
}: {
  data?: AccountExecutiveContact;
}): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleChangeRequestClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    setOpen(true);
  };

  const handleChangeRequestCancel = (
    e: React.SyntheticEvent<HTMLDialogElement | Element>
  ): void => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <div className="fragment">
      <h3>Training requests or questions?</h3>
      <p>
        For training related topics, contact your Account Executive or{' '}
        <button
          type="button"
          className="anchor"
          data-toggle-menu="true"
          onClick={handleChangeRequestClick}
        >
          submit a request
        </button>
        .
      </p>

      <SupportRequest onCancel={handleChangeRequestCancel} open={open} />
      {hasPhoneOrEmailValues(data) && (
        <div className="account-executive">
          <h4>
            {data.firstName && data.lastName
              ? `${data.firstName} ${data.lastName}`
              : 'Contact Information'}
          </h4>
          <p>
            {data.workingHours && displayWorkDaysWithHours(data.workingHours)}
          </p>
          <dl>
            {data.phoneNumber && (
              <>
                <dt>
                  <Icon id="phone-outline" size="sm" />
                </dt>
                <dd>{data.phoneNumber}</dd>
              </>
            )}

            {data.email && (
              <>
                <dt>
                  <Icon id="email-outline" size="sm" />
                </dt>
                <dd>
                  <a
                    href={`mailto:${data.email}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {data.email}
                  </a>
                </dd>
              </>
            )}
          </dl>
        </div>
      )}
    </div>
  );
}

ContactAccountExecutive.displayName = 'ContactAccountExecutive';
