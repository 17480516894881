import { VictoryLabel, VictoryPie } from 'victory';

import './DashboardReservationChart.css';
import { useReservationsMetadataQuery } from '@/features/reservations/api/useReservationsMetadataQuery';
import { useGetReservationsTilesCountQuery } from '@/features/reservations/api/useGetReservationsTilesCountQuery';

const COLORS = ['var(--blue-4)', 'var(--blue-6)', 'var(--blue-7)'];

const UPCOMING_RESERVATION_DATA = [
  { name: 'Confirmed', symbol: { fill: 'var(--blue-4)' } },
  { name: 'Provisional', symbol: { fill: 'var(--blue-6)' } },
  { name: 'Provisional at Risk', symbol: { fill: 'var(--blue-8)' } },
];

export function DashboardReservationsChart(): JSX.Element {
  const { data: reservationCount } = useReservationsMetadataQuery();
  const { data: provisionRisk } = useGetReservationsTilesCountQuery();
  const countRisk = provisionRisk?.provisional || 0;
  const counters = reservationCount?.counters || {};
  const updatedData = UPCOMING_RESERVATION_DATA.map(item => {
    let y = 0;

    switch (item.name) {
      case 'Confirmed':
        y = counters.confirmed || 0;
        break;
      case 'Provisional':
        y = counters.provisional || 0;
        y -= countRisk;
        break;
      case 'Provisional at Risk':
        y = countRisk || 0;
        break;
      default:
        y = 0;
    }

    return { ...item, y };
  });

  const total = updatedData.reduce((a, d) => a + d.y, 0);

  return (
    <div className="dashboard--reservations-chart">
      <div className="pie-chart" data-testid="pieChart">
        <svg viewBox="0 0 290 290">
          <VictoryPie
            standalone={false}
            width={260}
            height={260}
            innerRadius={96}
            data={updatedData}
            colorScale={COLORS}
            labels={() => null}
            padAngle={1}
            padding={0}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={130}
            y={115}
            className="dashboard-chart-label"
            text={total}
            style={{
              fontFamily: "'Red Hat Display', sans-serif",
              fontSize: 64,
              fontWeight: 900,
              fill: 'var(--neutral-0)',
            }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={130}
            y={160}
            text="Total reservations"
            style={{ fontSize: 18, fill: 'var(--neutral-6)' }}
          />
        </svg>
      </div>

      <ul className="legend">
        {Array.isArray(updatedData) &&
          updatedData.map(item => {
            return (
              <li key={item.name} style={{ color: item.symbol.fill }}>
                <span>
                  {item.name} ( {item.y && Math.round((item.y / total) * 100)}%)
                </span>
                <br />
                <strong>{item.y}</strong>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

DashboardReservationsChart.displayName = 'DashboardReservationsChart';
