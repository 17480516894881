import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, ScreenContent, ScreenHeader } from '@cae/cobalt-react';

import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { useHasPermission } from '@/shared/hooks/useHasPermission';

import { useDocumentsQuery } from '@/features/documents/api/useDocumentsQuery';
import {
  Breadcrumbs,
  DocumentListing,
  DocumentSearchListing,
  SearchDocumentsInput,
} from '@/features/documents/components';

export function CAEDocumentsPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [keyword, setKeyword] = React.useState<string>('');
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = React.useState<string | undefined>(
    location.state?.parentFolderId || undefined
  );

  React.useEffect(() => {
    if (location.state?.parentFolderId) {
      setCurrentFolder(location.state?.parentFolderId);
    }
    setKeyword('');
  }, [location.state?.parentFolderId]);

  const { data, isError, isInitialLoading, isSuccess } = useDocumentsQuery({
    type: 'cae',
    currentFolder,
  });

  const { parentFolders = [], items } = data || {};

  const handleAddDocumentsClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/documents/cae/submit');
  };

  const handleFolderClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    const { value } = e.target as HTMLButtonElement;
    setKeyword('');
    setShowResults(false);
    setCurrentFolder(value || undefined);
  };

  const handleSearchFolderClick = (id: string | undefined): void => {
    setCurrentFolder(id);
  };

  const hasPermission = useHasPermission();

  return (
    <>
      <div className="page-wrapper">
        <ScreenHeader elevation="base">
          <hgroup>
            <h2>CAE documents</h2>
          </hgroup>
          <div className="main-cta">
            {hasPermission('document_upload') ? (
              <Button
                type="button"
                variant="primary"
                size="md"
                onClick={handleAddDocumentsClick}
                data-testid="submitDocumentButton"
              >
                Submit document
              </Button>
            ) : null}
          </div>
          <div className="toolbar">
            <SearchDocumentsInput
              keyword={keyword}
              setKeyword={setKeyword}
              setShowResults={setShowResults}
              handleFolderClick={handleSearchFolderClick}
              type="cae"
            />
          </div>
        </ScreenHeader>
        {!showResults ? (
          <ScreenContent>
            {isInitialLoading && (
              <DataLoadingGrid numberRows={15} numberColumns={1} />
            )}
            {isError && <DataLoadingError />}
            {isSuccess && items && parentFolders?.length > 0 && (
              <Breadcrumbs
                parentFolders={parentFolders}
                onFolderClick={handleFolderClick}
              />
            )}
            {isSuccess && items && items.length < 1 && <NoData />}
            {isSuccess && items && items?.length > 0 && (
              <DocumentListing data={items} onFolderClick={handleFolderClick} />
            )}
          </ScreenContent>
        ) : (
          <ScreenContent>
            <DocumentSearchListing
              type="cae"
              keyword={keyword}
              handleFolderClick={handleFolderClick}
            />
          </ScreenContent>
        )}
      </div>
      <Outlet />
    </>
  );
}

CAEDocumentsPage.displayName = 'CAEDocumentsPage';
