import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { RoleManagementService, RoleResponse } from '@/open-api';

export function useGetAllRolesListQuery(): UseQueryResult<
  RoleResponse[],
  unknown
> {
  return useQuery({
    queryKey: ['getRoles'],
    queryFn: () => RoleManagementService.getAllRoles(),
  });
}
