import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Icon, Tag } from '@cae/cobalt-react';

import {
  DataLoadingError,
  DataLoadingGrid,
} from '@/shared/components/empty-states';

import useNotificationQuery from '@/features/dashboard/api/useNotificationQuery';
import './DashboardAwaiting.css';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { RelatedReservationEmptyState } from '@/features/reservation-details/components/empty-states/RelatedReservationEmptyState';

export function DashboardAwaiting(): JSX.Element {
  const { data, status } = useNotificationQuery();
  const { width } = useViewportSize();

  return (
    <section className="awaiting-action" data-testid="awaitingAction">
      <h3>Awaiting your action</h3>
      {status === 'loading' && (
        <DataLoadingGrid
          numberColumns={5}
          numberRows={6}
          style={{ marginTop: '1rem' }}
        />
      )}
      {status === 'error' && (
        <DataLoadingError heading="Error loading your next actions" />
      )}
      {status === 'success' && data.items && data.items.length < 1 && (
        <RelatedReservationEmptyState>
          <h5>Good news!</h5>
          <p>
            Looks like there is nothing that requires your immediate attention
          </p>
        </RelatedReservationEmptyState>
      )}
      {status === 'success' && data.items && data.items.length > 0 && (
        <ul className="c-grid">
          {data.items.map(d => (
            <li key={`entry-${d.id}`}>
              {width > 768 && <span className="id">{d.id}</span>}
              <span className="client-name">
                {d.name || 'No student assigned'}
              </span>
              <span className="name">
                {width <= 768 ? `${d.id} - ` : ''}
                {d.courseName}
              </span>
              <span className="due-date">
                <span className="label">Start date:</span>
                <span>
                  {d.startDate && format(parseISO(d.startDate), 'dd-MMM-yyyy')}
                </span>
              </span>
              <span className="status">
                <Tag compact>{d.notificationMessage || ''}</Tag>
              </span>
              <Link to={`/home/${d.id}`} className="details-link">
                View Details
                <Icon id="arrow-right-outline" size="sm" />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}

DashboardAwaiting.displayName = 'DashboardAwaiting';
