import { Link } from 'react-router-dom';
import { Icon, Table, Tag, Tooltip } from '@cae/cobalt-react';

import {
  DataLoadingError,
  DataLoadingGrid,
} from '@/shared/components/empty-states';

import './RecordDetailsTableList.css';

import { NoData } from '../empty-states/NoData';
import { useSelectRelatedRecords } from '../../api/hooks';
import { RecordModel } from '@/features/records';

function RecordDetailsTableList({
  recordId,
}: {
  recordId: string;
}): JSX.Element {
  const { data, status } = useSelectRelatedRecords(recordId);

  return (
    <div className="record-table-action">
      <h3>Other records for the same trainee</h3>
      <p>Here are other records for trainings that ended in the last year.</p>
      {status === 'loading' && (
        <DataLoadingGrid
          numberColumns={5}
          numberRows={6}
          style={{ marginTop: '1rem' }}
        />
      )}
      {status === 'error' && (
        <DataLoadingError heading="Error loading your next actions" />
      )}
      {status === 'success' && data.length < 1 && (
        <NoData heading="There are no related records." message="" />
      )}
      {status === 'success' && data.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Reservation</th>
              <th>Course</th>
              <th>Record</th>
            </tr>
          </thead>
          <tbody>
            {data.map(d => {
              const tagStatus = RecordModel.mapRecordStatusToTagStatus(
                d.recordStatus
              );
              return (
                <tr key={`row-${d.recordId}`}>
                  <Tooltip placement="top" highContrast className="test">
                    <Tooltip.Trigger>
                      <td className="table-td">{d.recordId}</td>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{d.recordId}</Tooltip.Content>
                  </Tooltip>
                  <Tooltip placement="top" highContrast className="test">
                    <Tooltip.Trigger>
                      <td className="table-td">{d.courseName}</td>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{d.courseName}</Tooltip.Content>
                  </Tooltip>
                  <td>
                    <Tag
                      color={tagStatus.color}
                      title={tagStatus.longMessage}
                      compact
                    >
                      {tagStatus.label}
                    </Tag>
                  </td>
                  <td>
                    <Link to={`/records/${d.recordId}`}>
                      View Details
                      <Icon id="arrow-right-outline" size="sm" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export { RecordDetailsTableList };
