/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskRequest } from '../models/TaskRequest';
import type { TaskResponse } from '../models/TaskResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TaskManagementService {
  /**
   * @returns TaskResponse OK
   * @throws ApiError
   */
  public static getTaskById({
    id,
  }: {
    id: string;
  }): CancelablePromise<TaskResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tasks/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateTask({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: TaskRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/tasks/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteTask({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/tasks/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns TaskResponse OK
   * @throws ApiError
   */
  public static getAllTasks(): CancelablePromise<Array<TaskResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tasks',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns TaskResponse Created
   * @throws ApiError
   */
  public static createTask({
    requestBody,
  }: {
    requestBody: TaskRequest;
  }): CancelablePromise<TaskResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tasks',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
