/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DocumentStatusDto = {
  /**
   * Document status
   */
  status?: DocumentStatusDto.status;
  shortMessage?: string;
  longMessage?: string;
};
export namespace DocumentStatusDto {
  /**
   * Document status
   */
  export enum status {
    SUBMITTED = 'submitted',
    IN_REVIEW = 'in-review',
    COMPLETED = 'completed',
  }
}
