import { RecordDetailsPage } from '@/pages/record-details/RecordDetailsPage';
import RecordOverviewPage from '@/pages/record-details/RecordOverviewPage';
import { RecordRequestPage } from '@/pages/record-details/RecordRequestPage';
import { RecordsPage } from '@/pages/records';

export const RecordsRoutes = {
  path: 'records',
  element: <RecordsPage />,
  children: [
    {
      path: ':recordId',
      element: <RecordDetailsPage />,
      children: [
        { path: 'request', element: <RecordRequestPage /> },

        {
          index: true,
          element: <RecordOverviewPage />,
        },
      ],
    },
  ],
};
