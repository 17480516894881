import * as React from 'react';
import { Card, Icon } from '@cae/cobalt-react';

import type { TrainingSession } from '@/features/reservation-details/types';

export function TrainingEvent({
  className = '',
  data,
  style = {},
}: {
  className?: string;
  data: TrainingSession;
  style?: React.CSSProperties;
}): JSX.Element {
  const status = data.style === 'grey' ? 'neutral-in' : 'info-in';
  return (
    <Card className={className} status={status} elevation="base" style={style}>
      <h5>{data.unitName}</h5>
      <ul>
        {data.resourceType !== 'SIMULATOR' && !data.detailsHidden && (
          <>
            <li>
              <Icon id="clock-outline" size="xs" />
              {data.localStartTime ?? '?'} - {data.localEndTime ?? '?'}
            </li>
            <li style={{ textTransform: 'capitalize' }}>
              <Icon id="user-outline" size="xs" />
              {data.resourceName?.toLowerCase()}
            </li>
          </>
        )}
        {data.resourceType === 'SIMULATOR' && !data.detailsHidden && (
          <li>{data.message}</li>
        )}
      </ul>
    </Card>
  );
}
