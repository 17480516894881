import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';

import './DashboardGreeting.css';

export function DashboardGreeting(): JSX.Element {
  const { data } = useGetProfileQuery();

  const currentHour = new Date().getHours();

  let welcomeMessage = '';
  if (currentHour >= 5 && currentHour < 12) {
    welcomeMessage = 'Good Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    welcomeMessage = 'Good Afternoon';
  } else {
    welcomeMessage = 'Good Evening';
  }

  const firstName = data?.firstName ? `, ${data.firstName}` : '';

  return (
    <hgroup>
      <h2>
        {welcomeMessage}
        {firstName}
      </h2>
      <p>All of your tools in a single place, enabled by the power of CAE.</p>
    </hgroup>
  );
}

DashboardGreeting.displayName = 'DashboardGreeting';
