import { useReducer } from 'react';

export type InvoicePaymentsStatus = 'Paid' | 'Unpaid' | 'NotApplicable' | 'All';

export interface InvoicePaymentsFilters {
  invoiceDateRange: (Date | undefined)[];
  trainingDateRange: (Date | undefined)[];
  currency: string[];
  platform: string[];
  location: string[];
  status: Array<InvoicePaymentsStatus>;
}

export const useFilterModalReducer = (
  filters: InvoicePaymentsFilters
): {
  selectedFilters: InvoicePaymentsFilters;
  clearSelectedFilters: () => void;
  clearSelectedStatus: () => void;
  clearSelectedLocations: () => void;
  clearSelectedPlatforms: () => void;
  clearSelectedCurrency: () => void;
  clearTrainingDateRange: () => void;
  clearInvoiceDateRange: () => void;
  changeSelectedStatus: (status: InvoicePaymentsStatus) => void;
  changeSelectedPlatforms: (platforms: string) => void;
  changeSelectedLocations: (locations: string) => void;
  changeSelectedCurrency: (currency: string) => void;
  changeTrainingDateRange: (dateRange: (Date | undefined)[]) => void;
  changeInvoiceDateRange: (dateRange: (Date | undefined)[]) => void;
} => {
  const initialState: InvoicePaymentsFilters = {
    invoiceDateRange: filters.invoiceDateRange || [],
    trainingDateRange: filters.trainingDateRange || [],
    currency: filters.currency || [],
    platform: filters.platform || [],
    location: filters.location || [],
    status: filters.status || [],
  };

  const enum Actions {
    resetSelectedFilters = 'resetSelectedFilters',
    changeInvoiceDateRange = 'changeInvoiceDateRange',
    clearInvoiceDateRange = 'clearInvoiceDateRange',
    changeTrainingDateRange = 'changeTrainingDateRange',
    clearTrainingDateRange = 'clearTrainingDateRange',
    changeSelectedCurrency = 'changeSelectedCurrency',
    clearSelectedCurrency = 'clearSelectedCurrency',
    changeSelectedPlatforms = 'changeSelectedPlatforms',
    clearSelectedPlatforms = 'clearSelectedPlatforms',
    changeSelectedLocations = 'changeSelectedLocations',
    clearSelectedLocations = 'clearSelectedLocations',
    changeSelectedStatus = 'changeSelectedStatus',
    clearSelectedStatus = 'clearSelectedStatus',
  }

  type ChangeInvoiceDateRange = {
    type: Actions.changeInvoiceDateRange;
    payload: (Date | undefined)[];
  };

  type ChangeTrainingDateRange = {
    type: Actions.changeTrainingDateRange;
    payload: (Date | undefined)[];
  };

  type ChangeSelectedCurrency = {
    type: Actions.changeSelectedCurrency;
    payload: string;
  };

  type ChangeSelectedPlatforms = {
    type: Actions.changeSelectedPlatforms;
    payload: string;
  };

  type ChangeSelectedLocations = {
    type: Actions.changeSelectedLocations;
    payload: string;
  };

  type ChangeSelectedStatus = {
    type: Actions.changeSelectedStatus;
    payload: InvoicePaymentsStatus;
  };

  type FilterAction =
    | ChangeInvoiceDateRange
    | ChangeTrainingDateRange
    | ChangeSelectedCurrency
    | ChangeSelectedPlatforms
    | ChangeSelectedLocations
    | ChangeSelectedStatus;

  type FilterActionWithoutPayload = {
    type:
      | Actions.resetSelectedFilters
      | Actions.clearInvoiceDateRange
      | Actions.clearTrainingDateRange
      | Actions.clearSelectedCurrency
      | Actions.clearSelectedPlatforms
      | Actions.clearSelectedLocations
      | Actions.clearSelectedStatus;
  };

  const filtersModalReducer = (
    state: InvoicePaymentsFilters,
    action: FilterAction | FilterActionWithoutPayload
  ): InvoicePaymentsFilters => {
    switch (action.type) {
      case Actions.changeInvoiceDateRange:
        return {
          ...state,
          invoiceDateRange: action.payload,
        };

      case Actions.changeTrainingDateRange:
        return {
          ...state,
          trainingDateRange: action.payload,
        };

      case Actions.changeSelectedCurrency:
        return {
          ...state,
          currency: state.currency.includes(action.payload)
            ? state.currency.filter(item => item !== action.payload)
            : [...state.currency, action.payload],
        };

      case Actions.changeSelectedPlatforms:
        return {
          ...state,
          platform: state.platform.includes(action.payload)
            ? state.platform.filter(item => item !== action.payload)
            : [...state.platform, action.payload],
        };

      case Actions.changeSelectedLocations:
        return {
          ...state,
          location: state.location.includes(action.payload)
            ? state.location.filter(item => item !== action.payload)
            : [...state.location, action.payload],
        };

      case Actions.changeSelectedStatus:
        return {
          ...state,
          status: state.status.includes(action.payload)
            ? state.status.filter(item => item !== action.payload)
            : [...state.status, action.payload],
        };

      case Actions.clearInvoiceDateRange:
        return {
          ...state,
          invoiceDateRange: [],
        };

      case Actions.clearTrainingDateRange:
        return {
          ...state,
          trainingDateRange: [],
        };

      case Actions.clearSelectedCurrency:
        return {
          ...state,
          currency: [],
        };

      case Actions.clearSelectedPlatforms:
        return {
          ...state,
          platform: [],
        };

      case Actions.clearSelectedLocations:
        return {
          ...state,
          location: [],
        };

      case Actions.clearSelectedStatus:
        return {
          ...state,
          status: [],
        };

      case Actions.resetSelectedFilters:
        return {
          invoiceDateRange: [],
          trainingDateRange: [],
          currency: [],
          platform: [],
          location: [],
          status: [],
        };

      default:
        return state;
    }
  };

  const [selectedFilters, dispatch] = useReducer(
    filtersModalReducer,
    initialState
  );

  const changeInvoiceDateRange = (dateRange: (Date | undefined)[]): void => {
    dispatch({ type: Actions.changeInvoiceDateRange, payload: dateRange });
  };

  const changeTrainingDateRange = (dateRange: (Date | undefined)[]): void => {
    dispatch({ type: Actions.changeTrainingDateRange, payload: dateRange });
  };

  const changeSelectedCurrency = (currency: string): void => {
    dispatch({ type: Actions.changeSelectedCurrency, payload: currency });
  };

  const changeSelectedPlatforms = (platforms: string): void => {
    dispatch({ type: Actions.changeSelectedPlatforms, payload: platforms });
  };

  const changeSelectedLocations = (locations: string): void => {
    dispatch({ type: Actions.changeSelectedLocations, payload: locations });
  };

  const changeSelectedStatus = (status: InvoicePaymentsStatus): void => {
    dispatch({ type: Actions.changeSelectedStatus, payload: status });
  };

  const clearInvoiceDateRange = (): void => {
    dispatch({ type: Actions.clearInvoiceDateRange });
  };

  const clearTrainingDateRange = (): void => {
    dispatch({ type: Actions.clearTrainingDateRange });
  };

  const clearSelectedCurrency = (): void => {
    dispatch({ type: Actions.clearSelectedCurrency });
  };

  const clearSelectedPlatforms = (): void => {
    dispatch({ type: Actions.clearSelectedPlatforms });
  };

  const clearSelectedLocations = (): void => {
    dispatch({ type: Actions.clearSelectedLocations });
  };

  const clearSelectedStatus = (): void => {
    dispatch({ type: Actions.clearSelectedStatus });
  };

  const clearSelectedFilters = (): void => {
    dispatch({ type: Actions.resetSelectedFilters });
  };

  return {
    selectedFilters,
    clearSelectedFilters,
    clearSelectedStatus,
    clearSelectedLocations,
    clearSelectedPlatforms,
    clearSelectedCurrency,
    clearTrainingDateRange,
    clearInvoiceDateRange,
    changeSelectedStatus,
    changeSelectedPlatforms,
    changeSelectedLocations,
    changeSelectedCurrency,
    changeTrainingDateRange,
    changeInvoiceDateRange,
  };
};
