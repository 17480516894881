import { ScreenContent, ScreenHeader } from '@cae/cobalt-react';

import {
  DashboardCarousel,
  DashboardGreeting,
  DashboardTabs,
  DashboardSupport,
} from '@/features/dashboard';
import FirstTimeUser from '@/features/first-time-user/FirstTimeUser';

import './HomePage.css';
import RoleBasedDashboardAlert from '@/features/dashboard/components/role-based-dashboard-alert/RoleBasedDashboardAlert';
import HasAccess from '@/shared/components/has-access/HasAccess';
import RoleBasedTableWrapper from '@/features/dashboard/components/role-base-wrapper/RoleBasedWrapper';
import { useUserInfo } from '@/contexts/userInfo';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

function HomePage(): JSX.Element {
  const [isSpinnerDisplayed] = useState(false);
  const { isInternalUser, selectedOrganization, selectedOrganizationId } =
    useUserInfo();
  const navigate = useNavigate();
  const { customerorganizationswitcher } = useFlags();
  const sfContact = sessionStorage.getItem('sfmastercontactid');
  if (
    selectedOrganizationId === null &&
    !customerorganizationswitcher &&
    !isInternalUser
  ) {
    return <Navigate to="/org-selector" />;
  }
  if (sfContact && sfContact.length > 0) {
    navigate('/sf-connect/home');
  }

  if (selectedOrganization === undefined) {
    return <LoaderWithLabel title="Loading organization" />;
  }
  return (
    <section className="page page-dashboard">
      {!isSpinnerDisplayed && (
        <div className="page-wrapper">
          <ScreenHeader className="screen-header--dashboard" elevation="base">
            <DashboardGreeting />
          </ScreenHeader>

          <div className="card-list" data-testid="reservationsAlertCards">
            <RoleBasedDashboardAlert />
            <HasAccess permissions="view_documents_library">
              <DashboardCarousel />
            </HasAccess>
          </div>

          <ScreenContent>
            <div className="primary-content">
              <RoleBasedTableWrapper />
              <HasAccess permissions="view_documents_library">
                <DashboardCarousel />
              </HasAccess>
              <DashboardSupport />
            </div>
            <aside>
              <DashboardTabs />
              <DashboardSupport />
            </aside>
          </ScreenContent>
        </div>
      )}
      <Outlet />
      {!isInternalUser && <FirstTimeUser />}
    </section>
  );
}

export default HomePage;
