import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

type SearchRowProps = {
  item: {
    id: string;
    name: string;
    isFolder: boolean;
    parentFolders?: { id: string; name: string }[];
  };
  onFolderClick: React.MouseEventHandler<HTMLButtonElement>;
  searchTerm: string;
};

function BoldSearchText({
  name,
  searchTerm,
}: {
  name: string;
  searchTerm: string;
}): string | JSX.Element {
  const startIndex = name.toLowerCase().indexOf(searchTerm.toLowerCase());
  if (startIndex === -1) {
    return name;
  }
  return (
    <span>
      {name.slice(0, startIndex)}
      <b>{name.slice(startIndex, startIndex + searchTerm.length)}</b>
      {name.slice(startIndex + searchTerm.length)}
    </span>
  );
}

function SearchRow({
  item,
  onFolderClick,
  searchTerm,
}: SearchRowProps): JSX.Element {
  const parentFolder =
    item.parentFolders?.length &&
    item.parentFolders?.[item.parentFolders.length - 1];

  return (
    <li className="search-row">
      {item.isFolder ? (
        <button type="button" onClick={onFolderClick} value={item.id}>
          <Icon id="folder-solid" size="md" />
          <BoldSearchText searchTerm={searchTerm} name={item.name} />
        </button>
      ) : (
        <Link to={item.id as string}>
          <Icon id="file-solid" size="md" />
          <BoldSearchText searchTerm={searchTerm} name={item.name} />
        </Link>
      )}
      {parentFolder ? (
        <button
          type="button"
          className="parent-folder"
          onClick={onFolderClick}
          value={parentFolder.id}
        >
          <Icon id="folder-solid" size="md" />
          {parentFolder.name}
        </button>
      ) : null}
    </li>
  );
}

export default SearchRow;
