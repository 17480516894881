import './SFConnectCreateOrganization.css';
import notOnboarded from '../../../../assets/images/sf-connect/notOnboarded.svg';
import workInProgress from '../../../../assets/images/sf-connect/workInProgress.svg';
import { Button } from '@cae/cobalt-react';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateOrganizationMutation } from '../../api/createOrganizationMutation';
import { UserSyncResponse } from '@/open-api';
import { useGetCurrentOrganization } from '@/features/frame/api/useGetCurrentOrganization';

const CreateOrganization = ({
  organizationName,
  customerName,
  handleCreateOrganization,
}: {
  organizationName: string;
  customerName: string;
  handleCreateOrganization: () => void;
}): JSX.Element => {
  return (
    <>
      <img src={notOnboarded} alt="not-onboarded" />
      <h2 className="create-organization--header">
        {organizationName} belongs to an organization that is not yet onboarded
        in CAE Connect. Do you wish to add {customerName} to CAE Connect?
      </h2>
      <p className="create-organization--subtitle">
        Adding an organization to CAE Connect will not send any communication to
        any contacts. Only users that are onboarded will receive an invitation
        email.
      </p>
      <Button
        type="button"
        className="try-again"
        variant="primary"
        onClick={handleCreateOrganization}
      >
        Create organization
      </Button>
    </>
  );
};

export const LoadingWhileCreating = ({
  organizationName,
  customerName,
}: {
  organizationName: string;
  customerName: string;
}): JSX.Element => {
  return (
    <>
      <img src={workInProgress} alt="workInProgress" />
      <h2>
        We are creating the {organizationName} organization you will be able to
        add {customerName} on the following screen. Please wait a moment…
      </h2>
    </>
  );
};
function SFConnectCreateOrganization(): JSX.Element {
  const { state } = useLocation();
  const organizationName = state?.userData?.organizationName;
  const customerName = useRef('');
  const navigate = useNavigate();
  const { handleOrgUpdate } = useGetCurrentOrganization();
  const sfContactId = state?.sfContact ?? '';
  const sfAccountId = state?.sfAccount ?? '';

  const onSuccessCallback = (data: UserSyncResponse): void => {
    handleOrgUpdate(data.organizationId as string, data);
    if (data?.id && data?.emailInUserService) {
      navigate(`/users/${data?.id}`);
    } else {
      navigate('/users', {
        state: {
          userData: data,
          sfaccount: sfAccountId,
          sfContact: sfContactId,
        },
      });
    }
  };

  const onErrorCallback = (): void => {
    navigate('/sf-connect/create/error');
  };

  const { mutate: createOrganizationMutate, isLoading } =
    useCreateOrganizationMutation({
      onSuccessCallback,
      onErrorCallback,
    });

  useEffect(() => {
    if (state?.userData) {
      customerName.current =
        state.userData.firstName + ' ' + state.userData.lastName;
    }
  });

  const handleCreateOrganization = async (): Promise<void> => {
    createOrganizationMutate({
      data: {
        userSalesforceContact: sfContactId,
        customerSalesforceId: sfAccountId,
        createOrgIfNotExists: true,
      },
    });
  };
  return (
    <>
      {isLoading ? (
        <LoadingWhileCreating
          organizationName={organizationName}
          customerName={customerName.current}
        />
      ) : (
        <CreateOrganization
          organizationName={organizationName}
          customerName={customerName.current}
          handleCreateOrganization={handleCreateOrganization}
        />
      )}
    </>
  );
}

export default SFConnectCreateOrganization;
