import axios from 'axios';

function configureInterceptor(): void {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      // if (axios.isAxiosError(error)) {
      //   const axiosError = error as AxiosError;

      //   const errorLog: LogMessage = {
      //     type: 'api',
      //     url: axiosError.config?.url ?? '',
      //     level: 'error',
      //     statusCode: axiosError.response?.status ?? 500,
      //     message: axiosError.message,
      //   };
      // }

      return Promise.reject(error);
    }
  );
}

export default configureInterceptor;
