import { InstructorManagementService, InstructorMetadataDto } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

interface MappedOptions {
  locations: { label: string; value: string }[] | undefined;
  platforms:
    | {
        location: string | undefined;
        platforms: { label: string; value: string }[] | undefined;
      }[]
    | undefined;
}

const mapOptions = (data: InstructorMetadataDto): MappedOptions => {
  const { location, platformPerLocation } = data.filterOptions || {};
  return {
    locations: location?.map(l => ({
      label: l,
      value: l,
    })),
    platforms: platformPerLocation?.map(p => ({
      location: p.location,
      platforms: p.platforms?.map(pl => ({
        label: pl,
        value: pl,
      })),
    })),
  };
};

export function useReportsOptionsQuery(
  disabled?: boolean
): UseQueryResult<MappedOptions, unknown> {
  return useQuery({
    queryKey: ['reportsOptions'],
    queryFn: () => InstructorManagementService.getInstructorMetadata({}),
    select: data => mapOptions(data),
    enabled: !disabled,
  });
}
