import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextInput, Modal } from '@cae/cobalt-react';
import './CompanyPhoneFax.css';
import {
  FaxFormSchema,
  PhoneFormSchema,
  FormValues,
} from '../../helpers/companyFormSchema';

import { toastMessages } from '@/shared/helpers/toastMessages';
import { useEditCompanyProfileMutation } from '../../api/useEditCompanyProfileMutation';
import { z } from 'zod';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import { useUserInfo } from '@/contexts/userInfo';

interface URLValues {
  inputName: 'phoneNumber' | 'faxNumber';
  title: string;
  label: string;
}
type InitialValuesProps = {
  phone?: string;
  fax?: string;
};
const getInitialValues = ({
  phone,
  fax,
}: InitialValuesProps): { phoneNumber?: string; faxNumber?: string } => {
  const isEditingPhone = window.location.pathname.endsWith('edit-phone');
  if (isEditingPhone) {
    return { phoneNumber: phone };
  } else {
    return { faxNumber: fax };
  }
};
function extractURLValues(): URLValues {
  const isEditingPhone = window.location.pathname.endsWith('edit-phone');
  const inputName = isEditingPhone ? 'phoneNumber' : 'faxNumber';
  const title = isEditingPhone ? 'Phone' : 'Fax';
  const label = isEditingPhone ? 'Phone Number' : 'Fax';

  return { inputName, title, label };
}

const getFormSchema = (): z.ZodSchema => {
  const isEditingPhone = window.location.pathname.endsWith('edit-phone');
  return isEditingPhone ? PhoneFormSchema : FaxFormSchema;
};

function CompanyPhoneFax(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { selectedOrganization } = useUserInfo();
  const { data: { phoneNumber, faxNumber } = {} } =
    useCompanyProfileByOrganizationIdQuery(selectedOrganization?.id);
  const defaultValues = getInitialValues({
    phone: phoneNumber,
    fax: faxNumber,
  });
  const formSchema = getFormSchema();
  const { inputName, title, label } = extractURLValues();
  const handleClose = (): void => {
    navigate(-1);
  };
  const { control, formState, handleSubmit } = useForm<FormValues>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  });
  const { isValid, isDirty } = formState;
  const { mutate: editCompanyMutate, isLoading } =
    useEditCompanyProfileMutation({
      onSuccessCallback: () => {
        handleClose();
        toastMessages.success('Number edited successfully');
      },
      onErrorCallback: () => {
        handleClose();
        toastMessages.error('Error in updating number');
      },
    });
  const onSubmit = async (data: {
    phoneNumber?: string;
    faxNumber?: string;
  }): Promise<void> => {
    const organizationId = selectedOrganization?.id ?? '';
    editCompanyMutate({ id: organizationId, data });
  };

  const handleConfirm = (): void => {
    handleSubmit(onSubmit)();
  };

  const closeHandler = (): void => {
    window.history.back();
  };

  return (
    <Modal
      animate
      dismissible
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="company-phone-number-edit"
      >
        <h5>Core contact information</h5>
        <h1>{title}</h1>

        <Controller
          name={inputName}
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <TextInput
              id={field.name}
              label={label}
              field={{ ...field, disabled: false, placeholder: `${label}` }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
              defaultValue="5354545"
            />
          )}
        />

        <div className="__footer">
          <Button
            type="button"
            variant="primary"
            disabled={!isValid || !isDirty || isLoading}
            onClick={handleConfirm}
            pending={isLoading}
          >
            Save
          </Button>
          <Button
            onClick={closeHandler}
            disabled={isLoading}
            type="button"
            variant="ghost"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
export default CompanyPhoneFax;
