import { Link } from 'react-router-dom';

import { EasaProgramLinks } from '@/features/documents-overview';
export function EasaIndexPage(): JSX.Element {
  return (
    <div className="dialog-details__page index">
      <header>
        <h2>Looking to get a new EASA program approved?</h2>
        <p>
          CAE provides the following templates to consult or kickstart program
          approvals, however you must inform us of any new program approvals.
        </p>
      </header>
      <section>
        <h3>Part 1: View and download the pertinent documents</h3>
        <EasaProgramLinks />
      </section>
      <section>
        <h3>Part 2: Upload the documents</h3>
        <p>
          For the documents that can be filled out you can{' '}
          <Link to="/documents/overview/easa-program/submit">upload here</Link>
        </p>
      </section>
    </div>
  );
}

EasaIndexPage.displayName = 'EasaIndexPage';
