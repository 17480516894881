import { Button, Icon } from '@cae/cobalt-react';
import './RecordPreview.css';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import { toastMessages } from '@/shared/helpers/toastMessages';
import Spinner from '@/shared/components/file-upload/spinner/Spinner';

type RecordPreviewProps = {
  readonly documentId: string | undefined;
  readonly schedulerReservationId?: string;
};

function RecordPreview({
  documentId,
  schedulerReservationId,
}: RecordPreviewProps): JSX.Element {
  const { downloadFile, status } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success(`Export record successfully`);
    },
    onError: () => {
      toastMessages.error(`Export record failed`);
    },
  });
  const handleDownloadRecord: React.MouseEventHandler<
    HTMLButtonElement
  > = async e => {
    e.preventDefault();
    if (documentId) {
      downloadFile({
        url: `/records/${documentId}/download`,
        fileName: schedulerReservationId ?? `records`,
      });
    }
  };
  return (
    <div>
      <div className="record-preview-details-btn">
        {' '}
        <h1 className="record-preview__details">Record details</h1>
        <div className="record-preview--open-btn">
          <Button
            onClick={handleDownloadRecord}
            type="button"
            size="md"
            variant="ghost"
          >
            {status === 'pending' ? <Spinner /> : 'Open'}
            <Icon id="external-link-outline" size="sm" />
          </Button>
        </div>
      </div>
      <div className="record-preview--details-box">
        <Icon id="file-solid" size="xxl" className="record-preview--pdf-icon" />
      </div>
    </div>
  );
}

export default RecordPreview;
