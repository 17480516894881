import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { DocumentsChangeRequest } from '@/features/documents/components';

export function DocumentDetailsRequestPage(): JSX.Element | null {
  const location = useLocation();
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { width } = useViewportSize();

  const handleCancel = (
    e: React.SyntheticEvent<HTMLDialogElement | Element>
  ): void => {
    e.preventDefault();
    const pathEls = location.pathname.split('/');
    const closePath = pathEls.slice(0, pathEls.length - 1).join('/');
    navigate(closePath);
  };

  return documentId ? (
    <Modal
      animate
      className="document-request--dialog"
      dismissible
      onCancel={handleCancel}
      open
      placement={width < 480 ? 'bottom' : 'right'}
    >
      <DocumentsChangeRequest onCancel={handleCancel} />
    </Modal>
  ) : null;
}

DocumentDetailsRequestPage.displayName = 'DocumentDetailsRequestPage';
