import {
  AlertBanner,
  Button,
  Card,
  Modal,
  ProgressLine,
  TextInput,
} from '@cae/cobalt-react';
import './ActionModal.css';
import {
  DisciplineTypeDto,
  InstructorApprovalRequestType,
  InstructorApprovalState,
  InstructorDetailsByProgram,
} from '@/open-api';
import { useEffect, useMemo, useState } from 'react';
import {
  getStatusIcon,
  statusText,
} from '@/features/instructor-management/shared/Utils.tsx';
import { useInstructorApproveQuery } from '@/features/instructor-management/api/useInstructorApproveQuery.ts';
import { useUserInfo } from '@/contexts/userInfo.tsx';

interface ApprovalModalProps {
  modalOpen: boolean;
  toggleActionModal: () => void;
  action: string;
  selectedInstructor: InstructorDetailsByProgram;
  program: string;
  location: string;
  trainingCenterId: string;
}

const getContent = (
  name: string,
  action: string
): { title: string; subTitle: string } => {
  switch (action) {
    case 'approveRequest':
      return {
        title: `You are requesting approval for:`,
        subTitle: `${name} is available to be approved for:`,
      };
    case 'revokeRequest':
      return {
        title: 'You are revoking:',
        subTitle: `${name} is currently approved for:`,
      };
    case 'approveDone':
      return {
        title: 'You requested approval for:',
        subTitle: 'What to expect next...',
      };
    case 'revokeDone':
      return {
        title: 'You revoked:',
        subTitle: ``,
      };
    default:
      return { title: '', subTitle: '' };
  }
};
type ApprovalKeys = 'groundSchool' | 'simulator' | 'checking';

const nameMap: Record<ApprovalKeys, string> = {
  groundSchool: 'Ground school',
  simulator: 'Simulator training',
  checking: 'Checking',
};

const deciplineMap: Record<ApprovalKeys, DisciplineTypeDto> = {
  groundSchool: DisciplineTypeDto.GROUND_SCHOOL,
  simulator: DisciplineTypeDto.SIMULATOR,
  checking: DisciplineTypeDto.CHECK_AIRMAN,
};

export const ActionModal = ({
  modalOpen,
  toggleActionModal,
  action,
  selectedInstructor,
  program,
  location,
  trainingCenterId,
}: Readonly<ApprovalModalProps>): JSX.Element => {
  const [actionState, setActionState] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const { approveInstructor } = useInstructorApproveQuery();
  const { selectedOrganizationId } = useUserInfo();
  const [selected, setSelected] = useState<Record<ApprovalKeys, boolean>>({
    groundSchool: false,
    simulator: false,
    checking: false,
  });
  useEffect(() => {
    setActionState(`${action}Request`);
  }, [action, modalOpen]);

  const { title, subTitle } = useMemo(
    () => getContent(selectedInstructor?.instructorName ?? '', actionState),
    [selectedInstructor, actionState]
  );

  const alertMap = {
    approveDone: `Approval request received for ${selectedInstructor.instructorName}.`,
    revokeDone: `${selectedInstructor.instructorName} revoked successfully.`,
  };

  const handleSubmit = (): void => {
    setError('');
    setLoading(true);
    const payload = Object.keys(selected)
      .filter(key => selected[key as ApprovalKeys])
      .map(key => ({
        organizationId: selectedOrganizationId ?? undefined,
        requestType:
          action === 'approve'
            ? InstructorApprovalRequestType.APPROVE
            : InstructorApprovalRequestType.REVOKE,
        instructorId: selectedInstructor.instructorId,
        discipline: deciplineMap[key as ApprovalKeys],
        trainingCenterId,
        program,
        description,
      }));
    approveInstructor(payload)
      .then(() => {
        setActionState(`${action}Done`);
        setLoading(false);
        setDescription('');
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  const handleClose = (): void => {
    setError('');
    setLoading(false);
    setActionState('');
    setDescription('');
    setSelected({
      groundSchool: false,
      simulator: false,
      checking: false,
    });
    toggleActionModal();
  };
  const {
    groundSchoolApprovalState = '',
    simulatorApprovalState = '',
    checkingApprovalState = '',
  } = selectedInstructor;

  const allStatus: Array<{
    key: ApprovalKeys;
    value: InstructorApprovalState | '';
  }> = [
    { key: 'groundSchool', value: groundSchoolApprovalState },
    { key: 'simulator', value: simulatorApprovalState },
    { key: 'checking', value: checkingApprovalState },
  ];

  const handleCheckbox = (value: ApprovalKeys): void => {
    setError('');
    setSelected({
      ...selected,
      [value]: !selected[value],
    });
  };

  const getOptions = (): JSX.Element => {
    let filterOptions;
    if (actionState === 'approveRequest') {
      filterOptions = allStatus.filter(
        item => item.value === InstructorApprovalState.UNSELECTED
      );
    } else if (actionState === 'revokeRequest') {
      filterOptions = allStatus.filter(
        item => item.value === InstructorApprovalState.APPROVED
      );
    }

    return (
      <>
        {filterOptions?.map(item => (
          <Card
            key={item.key}
            elevation="skim"
            orientation="vertical"
            className={`instructor-approval-modal_card ${selected[item.key] ? 'instructor-approval-modal__card-selected' : ''}`}
          >
            <div className="checkbox-container">
              <div className="checkbox-container__item">
                <span>{nameMap[item.key]}</span>
                <span className="status">
                  {getStatusIcon(item.value)}
                  <span className="status__text">
                    Currently{' '}
                    {statusText[item.value || 'default'].toLowerCase()}
                  </span>
                </span>
              </div>
              <input
                type="checkbox"
                checked={selected[item.key]}
                onChange={() => handleCheckbox(item.key)}
                aria-label={nameMap[item.key]}
              />
            </div>
          </Card>
        ))}
      </>
    );
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={handleClose}
      placement={'center'}
      dismissible
      size="sm"
      className="instructor-action-modal"
    >
      {modalOpen && (
        <div className="modal-content">
          <h2>{title}</h2>
          {error && (
            <AlertBanner
              className="co-alert--error"
              size="sm"
              type="error"
              heading={'An error occurred'}
            ></AlertBanner>
          )}

          <div className="modal-details">
            <div className="modal-details__item">
              <span>Program: </span>
              <span className="item__text">{program}</span>
            </div>
            <div className="modal-details__item">
              <span>Location: </span>
              <span className="item__text">{location}</span>
            </div>
            {(actionState === 'approveDone' ||
              actionState === 'revokeDone') && (
              <div className="modal-details__item">
                <span>Instructor Types: </span>
                <span className="item__text">
                  {Object.keys(selected)
                    .filter(key => selected[key as ApprovalKeys])
                    .map(key => nameMap[key as ApprovalKeys])
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          {loading ? (
            <ProgressLine className="" label="Processing your request…" />
          ) : (
            <>
              <div className="modal-section">
                {(actionState === 'approveDone' ||
                  actionState === 'revokeDone') && (
                  <AlertBanner
                    className="co-alert--success"
                    size="sm"
                    type="success"
                    heading={alertMap[actionState]}
                  ></AlertBanner>
                )}
                <p className="modal-subtitle">{subTitle}</p>
                {actionState !== 'revokeRequest' && getOptions()}
                {actionState === 'revokeRequest' && (
                  <div className="revoke__block">
                    {getOptions()}
                    <p className="modal-subtitle">
                      Tell us why you revoked{' '}
                      {selectedInstructor.instructorName}
                    </p>
                    <TextInput
                      field={{
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setDescription(e.target.value);
                        },
                        placeholder: 'Enter reason here',
                        value: description,
                        maxLength: 1000,
                      }}
                      label={'Description and details (optional)'}
                      multiline={true}
                      maxChars={1000}
                      meta={{
                        active: false,
                        touched: false,
                      }}
                    />
                  </div>
                )}
                {actionState === 'approveRequest' && selected['checking'] && (
                  <AlertBanner
                    className="co-alert--info"
                    size="sm"
                    type="info"
                    heading={
                      'For check airmen approval, your Air Carrier Specialist will contact you to provide you with your documentation package.'
                    }
                  ></AlertBanner>
                )}
                {actionState === 'approveDone' && (
                  <div className="instruction-container">
                    <span className="instruction-container__title">
                      For ground school and simulator training
                    </span>
                    <p>
                      A CAE Employee will process your instructor approval
                      request
                    </p>
                    <br />
                    <span className="instruction-container__title">
                      For checking
                    </span>
                    <p>
                      Your Air Carrier Specialist will be contacting you shortly
                      to provide you your Check Airmen packet. Please send your
                      Check Airman letter to compliance135simuflite@cae.com.
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-actions">
                {actionState === 'approveRequest' && (
                  <Button variant="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
                {actionState === 'revokeRequest' && (
                  <Button
                    variant="primary"
                    danger={true}
                    onClick={handleSubmit}
                  >
                    Revoke
                  </Button>
                )}
                <Button variant="secondary" onClick={handleClose}>
                  {actionState === 'approveRequest' ||
                  actionState === 'revokeRequest'
                    ? 'Cancel'
                    : 'Done'}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
