import * as React from 'react';

export function RelatedReservationEmptyState({
  children,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>): JSX.Element {
  return (
    <div className="empty-state" {...rest}>
      <svg
        width="163.384654px"
        height="155.379934px"
        viewBox="0 0 163.384654 155.379934"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-278, -1427)" fillRule="nonzero">
            <g transform="translate(24, 1299)">
              <g transform="translate(182, 128)">
                <g transform="translate(0, 0)">
                  <g transform="translate(72, 0)">
                    <path
                      d="M73.7259666,12.8555252 L21.1286771,12.8555252 C18.7840786,12.8589639 16.8842331,14.8278256 16.880915,17.2575968 L16.880915,122.855525 L17.3806517,122.697569 L17.3806517,17.2575968 C17.3831684,15.1135042 19.0597432,13.3760242 21.1286771,13.373416 L73.880915,13.373416 L73.7259666,12.8555252 Z M119.056281,13.3916467 L20.5562811,13.3916467 C18.2104474,13.3950745 16.309601,15.3577371 16.3062811,17.7798581 L16.3062811,136.003435 C16.3095996,138.425557 18.2104468,140.38822 20.5562811,140.391647 L119.056281,140.391647 C121.402115,140.38822 123.302963,138.425557 123.306281,136.003435 L123.306281,17.7798581 C123.302961,15.3577371 121.402115,13.3950745 119.056281,13.3916467 L119.056281,13.3916467 Z"
                      fill="#EBECED"
                      transform="translate(69.8063, 76.6236) rotate(-17) translate(-69.8063, -76.6236)"
                    />
                    <path
                      d="M104.228769,36.9120438 L60.5767341,36.9120438 C58.5055433,36.9145612 56.8271397,38.5915796 56.8246202,40.6610609 L56.8246202,137.598145 L56.3243383,137.750617 L45.6157903,141.027258 C44.5590691,141.349197 43.4410091,140.755388 43.1169475,139.700106 L11.2639195,35.7398512 C10.9411352,34.6839255 11.5356457,33.5663755 12.5921828,33.2430058 L29.0940347,28.1943295 L76.9334867,13.5631657 L93.4352135,8.51448944 C93.942402,8.35852209 94.4908493,8.4105162 94.9596307,8.65900782 C95.4284121,8.90749944 95.7790312,9.33208233 95.9341814,9.83914213 L104.076218,36.4121749 L104.228769,36.9120438 Z"
                      fill="#F7F7F7"
                    />
                    <path
                      d="M32.0718399,33.3247294 C31.0821606,33.324025 30.208582,32.6786865 29.9177938,31.7334716 L26.6958861,21.2182836 C26.5210318,20.6478388 26.5801731,20.0313512 26.8602958,19.5044815 C27.1404184,18.9776118 27.6185687,18.5835327 28.1895276,18.4089627 L72.1990719,4.94654993 C73.3878197,4.58410098 74.6458236,5.25175968 75.010836,6.43883366 L78.2327437,16.9541441 C78.5952518,18.1418876 77.9271281,19.3986905 76.7392273,19.7635875 L32.7295604,33.2260003 C32.5164414,33.2913527 32.2947697,33.3247294 32.0718399,33.3247294 L32.0718399,33.3247294 Z"
                      fill="#A4ACB0"
                    />
                    <path
                      d="M113.229706,36.3799337 L60.6324165,36.3799337 C58.2878181,36.3831536 56.3879726,38.226724 56.3846544,40.5018734 L56.3846544,139.379934 L56.8843912,139.232029 L56.8843912,40.5018734 C56.8869079,38.4942231 58.5634826,36.86731 60.6324165,36.8648678 L113.384654,36.8648678 L113.229706,36.3799337 Z M159.134654,36.3799337 L60.6346544,36.3799337 C58.2888208,36.3831456 56.3879743,38.2221759 56.3846544,40.4917223 L56.3846544,151.268145 C56.387973,153.537692 58.2888202,155.376723 60.6346544,155.379934 L159.134654,155.379934 C161.480489,155.376723 163.381336,153.537692 163.384654,151.268145 L163.384654,40.4917223 C163.381335,38.2221759 161.480488,36.3831456 159.134654,36.3799337 L159.134654,36.3799337 Z"
                      fill="#EBECED"
                    />
                    <path
                      d="M152.12837,146.88321 L67.5807375,146.88321 C66.4070314,146.881859 65.4558919,145.931505 65.4545397,144.758767 L65.4545397,43.5353073 C65.4558506,42.3625528 66.4070143,41.4121742 67.5807375,41.4108643 L152.12837,41.4108643 C153.302076,41.4122168 154.253215,42.3625705 154.254568,43.5353073 L154.254568,144.758767 C154.253172,145.931486 153.302058,146.881815 152.12837,146.88321 Z"
                      fill="#F7F7F7"
                    />
                    <path
                      d="M132.867519,47.4092915 L86.8415888,47.4092915 C85.5988276,47.4078935 84.5917196,46.4016167 84.5903205,45.1598813 L84.5903205,34.1627647 C84.591721,32.9210298 85.5988282,31.9147539 86.8415888,31.9133544 L132.867519,31.9133544 C134.11028,31.9147539 135.117387,32.9210298 135.118787,34.1627647 L135.118787,45.1598813 C135.117388,46.4016167 134.11028,47.4078935 132.867519,47.4092915 Z"
                      fill="#A4ACB0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      {children}
    </div>
  );
}
