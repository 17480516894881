import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { DashboardLatestRecords } from '../dashboard-latest-records/DashboardLatestRecords';
import { DashboardAwaiting } from '../dashboard-awaiting/DashboardAwaiting';

function RoleBasedTableWrapper(): JSX.Element {
  const currentRoles = useCurrentRoles();

  return (
    <div>
      {currentRoles &&
      !currentRoles?.includes('Admin') &&
      !currentRoles?.includes('Training') &&
      currentRoles?.includes('Records') ? (
        <DashboardLatestRecords />
      ) : (
        <DashboardAwaiting />
      )}
    </div>
  );
}

export default RoleBasedTableWrapper;
