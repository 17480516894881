import { DocumentDto, DocumentsManagementService } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useDocumentDetailsQuery(
  documentId: string
): UseQueryResult<DocumentDto, unknown> {
  return useQuery({
    queryKey: ['documentsDetails', documentId],
    queryFn: () =>
      DocumentsManagementService.getDocumentDetails({ documentId }),
  });
}
