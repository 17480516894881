import { useParams } from 'react-router-dom';
import { AlertMessage } from '@cae/cobalt-react';
import { RecordBackNavigation } from '@/features/record-details/components/record-back-navigation/RecordBackNavigation';
import { RecordRequestForm } from '@/features/record-details/components/request-form/RecordRequestForm';

function Header(): JSX.Element {
  return (
    <header>
      <h2>Request</h2>
      <AlertMessage type="info" heading="" inline>
        Our team will get back to you within 2 business days with a follow-up on
        your request. Note that requests are subject to change depending on
        availability, feasibility and other factors.
      </AlertMessage>
    </header>
  );
}

export function RecordRequestPage(): null | JSX.Element {
  const { recordId = '' } = useParams();

  return recordId ? (
    <section
      className="dialog-details__page request"
      data-reservation-id={recordId}
    >
      <RecordBackNavigation recordId={recordId} />
      <Header />
      <RecordRequestForm recordId={recordId} />
    </section>
  ) : null;
}

RecordRequestPage.displayName = 'ReservationRequestPage';
