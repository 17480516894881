import { useReducer } from 'react';
import { Actions, filtersReducer } from './recordsFilterReducer';
import { RecordsFilters } from '../components/filter-modal/useFilterModalReducer';

type UseRecordsFilter = {
  filters: RecordsFilters;
  resetFilters: () => void;
  toggleAllFilters: (selected: RecordsFilters) => void;
  filterCounter: () => number;
};

const initialState: RecordsFilters = {
  locations: [],
  platforms: [],
  courseTypes: [],
  dateRange: [],
};

function useRecordsFilter(): UseRecordsFilter {
  const [filters, dispatch] = useReducer(filtersReducer, initialState);

  const toggleAllFilters = (selected: RecordsFilters): void => {
    dispatch({ type: Actions.toggleAllFilters, payload: selected });
  };

  const resetFilters = (): void => {
    dispatch({ type: Actions.resetFilter });
  };

  const filterCounter = (): number => {
    let count = 0;

    count = [...filters.locations, ...filters.platforms, ...filters.courseTypes]
      .length;
    if (filters.dateRange && filters.dateRange.length > 0) {
      count += 1;
    }
    return count;
  };

  return {
    filters,
    resetFilters,
    toggleAllFilters,
    filterCounter,
  };
}

export default useRecordsFilter;
