import { Tabs } from '@cae/cobalt-react';
import { InvoiceAndPaymentsListing } from '../invoice-payments-listings/InvoicePaymentsListings';
import { InvoicePaymentsFilters } from '../filter-modal/useFilterModalReducer';

type Counts = {
  paid?: number;
  unPaid?: number;
  notApplicable?: number;
  creditMemo?: number;
  invoice?: number;
};

type InvoiceAndPaymentsTab = {
  key: 'all' | 'unPaid';
  type: 'All' | 'Unpaid';
};

const INVOICE_AND_PAYMENTS_TABS: InvoiceAndPaymentsTab[] = [
  { key: 'all', type: 'All' },
  { key: 'unPaid', type: 'Unpaid' },
];

interface InvoiceAndPaymentsTabsProps {
  filters: InvoicePaymentsFilters;
  searchText: string;
  counts: Counts;
}

export function InvoiceAndPaymentsTabs({
  filters,
  searchText,
  counts,
}: Readonly<InvoiceAndPaymentsTabsProps>): JSX.Element {
  const searchTextValue = searchText;
  return (
    <Tabs variant="contained" defaultIndex={0}>
      {({ selectedIndex = 1 }) => {
        return (
          <>
            <Tabs.TabList>
              {INVOICE_AND_PAYMENTS_TABS.map((tab, i) => (
                <Tabs.Tab
                  id={`tab--invoice-payments-${tab.key}`}
                  key={`tab--invoice-payments-${tab.key}`}
                  selected={i === selectedIndex}
                  data-testid={`${tab.key}InvoicesPaymentsTabs`}
                >
                  {tab.type}
                  <i className="count">
                    ({`${counts[tab.key as keyof typeof counts] ?? 0}`})
                  </i>
                </Tabs.Tab>
              ))}
            </Tabs.TabList>
            {INVOICE_AND_PAYMENTS_TABS.map((panel, i) => (
              <Tabs.TabPanel
                id={`tab-panel--invoice-payments-${panel.key}`}
                selected={i === selectedIndex}
                aria-labelledby={`tab--invoice-payments-${panel.key}`}
                key={panel.type}
                data-testid={`${panel.key}InvoicesPaymentsList`}
              >
                <InvoiceAndPaymentsListing
                  invoiceType={panel.type}
                  filters={filters}
                  searchText={searchTextValue}
                />
              </Tabs.TabPanel>
            ))}
          </>
        );
      }}
    </Tabs>
  );
}

export default InvoiceAndPaymentsTabs;
