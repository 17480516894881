import { VictoryLabel, VictoryPie } from 'victory';

import './DashboardRecordChart.css';
import { useRecordsMetadataQuery } from '@/features/records/api/useRecordsMetadataQuery';

const COLORS = ['var(--blue-4)', 'var(--blue-6)', 'var(--blue-7)'];

const RECORDS_PIE_CHART_DATA = [
  { name: 'Record available', symbol: { fill: 'var(--blue-4)' } },
  { name: 'Hard copy only', symbol: { fill: 'var(--blue-6)' } },
  { name: 'Pending', symbol: { fill: 'var(--blue-7)' } },
];

export function DashboardRecordChart(): JSX.Element {
  const { data: recordCount } = useRecordsMetadataQuery();
  const counters = recordCount?.counters;
  const updatedData = RECORDS_PIE_CHART_DATA.map(item => {
    let y = 0;

    if (counters === undefined) {
      return { ...item, y };
    }

    switch (item.name) {
      case 'Record available':
        y = counters.available || 0;
        break;
      case 'Hard copy only':
        y = counters.hardCopyOnly || 0;
        break;
      case 'Pending':
        y = counters.pending || 0;
        break;
      default:
        y = 0;
    }

    return { ...item, y };
  });

  const total = updatedData.reduce((a, d) => a + d.y, 0);
  return (
    <div className="dashboard--records-chart">
      <div className="pie-chart" data-testid="pieChart">
        <svg viewBox="0 0 260 260">
          <VictoryPie
            standalone={false}
            width={260}
            height={260}
            innerRadius={96}
            data={updatedData}
            colorScale={COLORS}
            labels={() => null}
            padAngle={1}
            padding={0}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={130}
            y={115}
            className="dashboard-chart-label"
            text={total}
            style={{
              fontFamily: "'Red Hat Display', sans-serif",
              fontSize: 64,
              fontWeight: 900,
              fill: 'var(--neutral-0)',
            }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={130}
            y={160}
            text="Total records"
            style={{ fontSize: 18, fill: 'var(--neutral-6)' }}
          />
        </svg>
      </div>

      <ul className="legend">
        {Array.isArray(updatedData) &&
          updatedData.map(item => {
            return (
              <li key={item.name} style={{ color: item.symbol.fill }}>
                <span>
                  {item.name} ({item.y && Math.round((item.y / total) * 100)}%)
                </span>
                <br />
                <strong>{item.y}</strong>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

DashboardRecordChart.displayName = 'DashboardRecordChart';
