import { Button, Modal } from '@cae/cobalt-react';

interface CheckPromptActionsProps {
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

function CheckActions({
  handleCancel,
  handleConfirm,
}: Readonly<CheckPromptActionsProps>): JSX.Element {
  return (
    <>
      <Button
        id="submit-documents-form--leave-check--cancel"
        type="button"
        variant="secondary"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        id="submit-documents-form--leave-check--confirm"
        type="button"
        variant="primary"
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </>
  );
}

export function LeaveCheck({
  onCancel,
  onConfirm,
  open = false,
}: Readonly<{
  onCancel: (e: React.SyntheticEvent<HTMLDialogElement>) => void;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
}>): JSX.Element {
  return (
    <Modal
      actions={CheckActions}
      className="leave-check"
      dismissible={false}
      id="submit-documents-form--leave-check"
      open={open}
      header={<h2>You want to leave this form?</h2>}
      onCancel={onCancel}
      onConfirm={onConfirm}
      placement="center"
      size="sm"
    >
      <div>
        <p>
          Your documents and notes will be discarded. <b>Are you sure?</b>
        </p>
      </div>
    </Modal>
  );
}

LeaveCheck.displayName = 'LeaveCheck';
