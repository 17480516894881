import * as React from 'react';

import './DataLoadingGrid.css';

export function DataLoadingGrid({
  numberColumns = 10,
  numberRows = 5,
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & {
  numberColumns: number;
  numberRows: number;
}): JSX.Element {
  const c = new Array(numberColumns).fill(null);
  const r = new Array(numberRows).fill(null);

  const rootProps = {
    ...rest,
    className: `co-empty-state--grid no-data ${rest.className ?? ''}`,
    'data-status': 'loading',
  };

  return (
    <div {...rootProps} data-testid="loading-grid">
      <ul className="thead">
        {c.map((_, x) => (
          <li key={`h-${x}`}>
            <span className="loading-mask" />
          </li>
        ))}
      </ul>
      <ul className="tbody">
        {r.map((_, y) => (
          <li key={`r-${y}`}>
            <ul className="row">
              {c.map((__, z) => (
                <li key={`c-${z}`}>
                  <span className="loading-mask" />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
