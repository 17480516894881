import * as React from 'react';
import { Icon, TextInput } from '@cae/cobalt-react';

import './SearchInput.css';

export function SearchInput({
  onChange,
  onClear,
  value,
}: {
  readonly onChange: React.ChangeEventHandler<HTMLInputElement>;
  readonly onClear: React.MouseEventHandler<HTMLButtonElement>;
  value: string;
}): Readonly<JSX.Element> {
  return (
    <TextInput
      className="search-input table-search-input"
      field={{
        onChange,
        placeholder: 'Search by reservation number, etc.',
        value: value ?? '',
      }}
      prefixSlot={<Icon id="search-outline" size="md" />}
      suffixSlot={
        value ? (
          <button
            aria-label="Clear"
            className="btn--clear"
            onClick={onClear}
            title="Clear"
            type="button"
          >
            <Icon id="close-circle-solid" size="sm" />
          </button>
        ) : (
          ''
        )
      }
    />
  );
}

SearchInput.displayName = 'SearchInput';
