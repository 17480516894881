import { useEffect, useRef, useState } from 'react';
import './RolesMatrix.css';
import { Button, Checkbox, Table } from '@cae/cobalt-react';
import {
  ApiError,
  ModuleResponse,
  PermissionResponse,
  RolePermissionsService,
  RoleResponse,
  TaskResponse,
} from '@/open-api';
import LoaderWithLabel from '@/shared/components/loader/Loader';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useGetTasksQuery } from '../../api/useGetTasksQuery';
import { useGetRolesQuery } from '../../api/useGetRolesQuery';
import { useGetModulesQuery } from '../../api/useGetModulesQuery';
import { useGetPermissionsQuery } from '../../api/useGetPermissionsQuery';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { useCreateTaskMutation } from '../../api/useCreateTaskMutation';
import { useCreateModuleMutation } from '../../api/useCreateModuleMutation';

function RolesMatrix(): JSX.Element {
  const [selModule, setSelModule] = useState('0');
  const [roles, setRoles] = useState<RoleResponse[]>([]);
  const [modules, setModules] = useState<ModuleResponse[]>([]);
  const [tasks, setTasks] = useState<TaskResponse[]>([]);
  const [permissions, setPermissions] = useState<PermissionResponse[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isModal, setIsModal] = useState(false);

  const { data: tasksData } = useGetTasksQuery();
  const { data: rolesData } = useGetRolesQuery();
  const { data: modulesData } = useGetModulesQuery();
  const { data: permissionsData } = useGetPermissionsQuery();
  const hasPermission = useHasPermission();
  const taskName = useRef<HTMLInputElement>(null);
  const taskValue = useRef<HTMLInputElement>(null);

  const { mutate: createModule } = useCreateModuleMutation({
    onSuccessCallback: response => {
      setModules(prevModules => [
        ...prevModules,
        { id: response.id, name: response.name, value: response.value },
      ]);
      toastMessages.success('Module created successfully');
    },
    onErrorCallback: () => {
      toastMessages.error('Failed to create module');
    },
  });

  const { mutate: createTask } = useCreateTaskMutation({
    onSuccessCallback: response => {
      setTasks(prevTasks => [
        ...prevTasks,
        {
          id: response.id,
          moduleId: response.moduleId,
          name: response.name,
          value: response.value,
        },
      ]);
      const newPermissions = roles.map(role => ({
        id: `${role.id}_${response.id}`,
        roleId: role.id,
        taskId: response.id,
        enable: false,
      }));
      setPermissions(prevPermissions => [
        ...prevPermissions,
        ...newPermissions,
      ]);
      toastMessages.success('Task created successfully');
    },
    onErrorCallback: () => {
      toastMessages.error('Failed to create task');
    },
  });

  useEffect(() => {
    if (rolesData) setRoles(rolesData);
    if (tasksData) setTasks(tasksData);
    if (modulesData) setModules(modulesData);
    if (permissionsData) setPermissions(permissionsData);
  }, [rolesData, tasksData, modulesData, permissionsData]);

  const handleCheckBox = (
    permission: PermissionResponse | undefined,
    roleId: string,
    taskId: string
  ): void => {
    let tempPermissions = permissions.slice();

    if (permission) {
      tempPermissions = tempPermissions.map(item => {
        if (item.roleId === roleId && item.taskId === taskId) {
          return { ...item, enable: !item.enable };
        }
        return item;
      });
    } else {
      const newPermission = {
        id: `${roleId}_${taskId}`,
        roleId,
        taskId,
        enable: true,
      };
      tempPermissions.push(newPermission);
    }

    setPermissions(tempPermissions);
  };

  const handleNewRole = (): void => {
    const name = prompt('Please enter new role name:');
    if (name !== null) {
      alert(`New Role : ${name} is added!`);
      const newRole = { id: String(roles.length), name };
      setRoles([...roles, newRole]);
      const newPermission = tasks.map(_task => {
        return {
          id: `${newRole.id}_${_task.id}`,
          roleId: newRole.id,
          taskId: _task.id,
          enable: false,
        };
      });
      setPermissions([...permissions, ...newPermission]);
    }
  };

  const handleNewModule = (): void => {
    const name = prompt('Please enter new module name:');
    if (name !== null) {
      createModule({ data: { name, value: name.toLowerCase() } });
    }
  };

  const handleNewTask = (): void => {
    setIsModal(true);
  };

  const handleAddTask = (): void => {
    setIsModal(false);
    const newTaskName = taskName.current ? taskName.current.value : '';
    const newTaskValue = taskValue.current ? taskValue.current.value : '';
    createTask({
      data: { moduleId: selModule, name: newTaskName, value: newTaskValue },
    });
  };

  const handleSave = async (): Promise<void> => {
    try {
      setShowLoader(true);
      await RolePermissionsService.createPermission({
        requestBody: permissions,
      });
      toastMessages.success('Permissions are updated successfully');
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      if (error instanceof ApiError) {
        toastMessages.error(
          'Permissions have not yet been updated. Try again, or contact the administrators.'
        );
      } else {
        toastMessages.error('An error occurred');
      }
    }
  };

  if (!permissions.length || !modules.length || showLoader) {
    return <LoaderWithLabel title="Loading..." />;
  }
  return (
    <div className="role-table-mapping-container">
      <div className="role-actions-box">
        {hasPermission('add_role') ? (
          <Button onClick={() => handleNewRole()} className="">
            Add New Role
          </Button>
        ) : null}
        {hasPermission('add_new_task') ? (
          <Button onClick={() => handleNewModule()} className="">
            Add New Module
          </Button>
        ) : null}
        {hasPermission('add_new_task') ? (
          <Button onClick={() => handleNewTask()} className="m">
            Add New Task
          </Button>
        ) : null}
      </div>
      <Table className="role-task-mapping-table">
        <thead>
          <tr className="">
            <th className="">Module / Capability</th>
            <th className="">Tasks</th>
            {roles.slice(0, -1).map(role => {
              return (
                <th key={`module_${role.id}`} className="roles--role-column">
                  {role.name}
                </th>
              );
            })}
            <th className="py-4 px-4 font-medium text-black dark:text-white">
              {roles.length && roles[roles.length - 1].name}
            </th>
          </tr>
        </thead>
        <tbody>
          {modules.map(_module => {
            const currentTasks = tasks.filter(
              _item => _item.moduleId === _module.id
            );
            const firstTask = tasks.find(
              _item => _item.moduleId === _module.id
            );
            let count = 0;

            currentTasks.forEach(item => {
              if (item.moduleId === _module.id) count++;
            });

            return currentTasks.map((_task, _taskIndex) => {
              return (
                <tr key={`task_name_${_taskIndex}`}>
                  {firstTask?.id === _task.id && (
                    <td className="roles--role-column" rowSpan={count}>
                      {_module && _module.name}
                    </td>
                  )}
                  <td className="">{_task.name}</td>
                  {roles.map((_role, _roleIndex) => {
                    const currentPermission = permissions.find(
                      item =>
                        item.roleId === _role.id && item.taskId === _task.id
                    );
                    return (
                      <td
                        key={`task_checkbox_${_roleIndex}`}
                        className="border-b border-[#eee] py-5 px-4 dark:border-strokedark"
                      >
                        <Checkbox
                          field={{
                            checked: currentPermission
                              ? currentPermission.enable
                              : false,
                          }}
                          onChange={() =>
                            handleCheckBox(
                              currentPermission,
                              _role.id as string,
                              _task.id as string
                            )
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            });
          })}
          {modules
            .filter(
              _module =>
                tasks.filter(_task => _task.moduleId === _module.id).length ===
                0
            )
            .map((_item, _itemIndex) => {
              return (
                <tr key={`module_name_${_itemIndex}`}>
                  <td className="">{_item && _item.name}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <div className="">
        <Button onClick={() => handleSave()} className="">
          Save
        </Button>
      </div>
      {isModal && (
        <div className="add-task-main">
          <div className="add-task-board">
            <div>
              <p className="add-task-label">Select Module</p>
              <select
                value={selModule}
                onChange={e => setSelModule(e.target.value)}
                className="add-task-input"
              >
                {Array.isArray(modules) &&
                  modules.map((_module: ModuleResponse) => {
                    return (
                      <option
                        className="text-black"
                        key={_module.id ? _module.id + 1 : 'empty_module'}
                        value={_module.id}
                      >
                        {_module.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div>
              <label className="add-task-label" htmlFor="taskName">
                Task Name
              </label>
              <input id="taskName" className="add-task-input" ref={taskName} />
            </div>
            <div>
              <label className="add-task-label" htmlFor="taskValue">
                Task Value
              </label>
              <input
                id="taskValue"
                className="add-task-input"
                ref={taskValue}
              />
            </div>
            <div className="add-task-btn-group">
              <button
                type="button"
                onClick={() => handleAddTask()}
                className="add-task-btn btn-add"
              >
                Add
              </button>
              <button
                type="button"
                onClick={() => setIsModal(false)}
                className="add-task-btn btn-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RolesMatrix;
