import * as React from 'react';
import { PaginationState } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { InvoiceAndPaymentsTable } from '../invoice-and-payments-table/InvoiceAndPaymentsTable';
import useInvoiceAndPaymentsTableDataQuery from '../../api/useInvoiceAndPaymentsTableDataQuery';
import {
  InvoicePaymentsFilters,
  InvoicePaymentsStatus,
} from '../filter-modal/useFilterModalReducer';
import { InvoiceResponse } from '@/open-api';
interface Props {
  readonly filters: InvoicePaymentsFilters;
  readonly invoiceType: InvoicePaymentsStatus;
  readonly searchText: string;
}

export function InvoiceAndPaymentsListing({
  filters,
  invoiceType,
  searchText,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });
  React.useEffect(() => {
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  }, [filters, searchText]);

  const { data, status } = useInvoiceAndPaymentsTableDataQuery({
    invoiceType,
    pageIndex,
    pageSize,
    filters,
    searchText,
  });
  const invoicesData = data?.items ? data.items : [];
  const openDetails = (invoice: InvoiceResponse): void => {
    if (invoice?.accountReceivableId) {
      navigate(`/finance/${invoice.accountReceivableId}`);
    }
  };
  const commonProps = {
    data: invoicesData,
    openDetails,
    pageIndex,
    pageSize,
    invoiceType,
    searchText,
    setPagination,
    queryStatus: status,
    totalPages: data?.totalPages ?? 1,
  };

  return (
    <InvoiceAndPaymentsTable
      data={commonProps.data}
      openDetails={commonProps.openDetails}
      pageIndex={commonProps.pageIndex}
      pageSize={commonProps.pageSize}
      invoiceType={commonProps.invoiceType}
      setPagination={commonProps.setPagination}
      queryStatus={commonProps.queryStatus}
      totalPages={commonProps.totalPages}
    />
  );
}
