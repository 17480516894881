import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';
import {
  DataLoadingError,
  DataLoadingGrid,
} from '@/shared/components/empty-states';
import './dashboardLatestRecord.css';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { RelatedRecordsEmptyState } from '@/features/record-details/components/empty-states/RelatedRecordEmptyState';
import { useDashboardLatestRecordsQuery } from '../../api/useGetRecordsTileCountQuery';
import { format, parseISO } from 'date-fns';

export function DashboardLatestRecords(): JSX.Element {
  const { data, status } = useDashboardLatestRecordsQuery();
  const { width } = useViewportSize();
  return (
    <section className="awaiting-action" data-testid="awaitingAction">
      <h3>Latest Records</h3>
      {status === 'loading' && (
        <DataLoadingGrid
          numberColumns={5}
          numberRows={6}
          style={{ marginTop: '1rem' }}
        />
      )}
      {status === 'error' && (
        <DataLoadingError heading="Error loading your next actions" />
      )}
      {status === 'success' && data.items && data.items.length < 1 && (
        <RelatedRecordsEmptyState>
          <h5>Good news!</h5>
          <p>
            Looks like there is nothing that requires your immediate attention
          </p>
        </RelatedRecordsEmptyState>
      )}
      {status === 'success' && data?.items && data?.items?.length > 0 && (
        <ul className="record-c-grid">
          {data.items &&
            data.items.map(d => (
              <li key={`entry-${d.recordId}`}>
                {width > 768 && <span className="record-id">{d.recordId}</span>}
                <span className="record-client-name">
                  {d.clientName ?? 'No student assigned'}
                </span>
                <span className="record-course-name">
                  {width <= 768 ? `${d.recordId} - ` : ''}
                  {d.courseName}
                </span>
                <span className="record-start-date">
                  <span>
                    {d.startDate &&
                      format(parseISO(d.startDate), 'dd-MMM-yyyy')}{' '}
                  </span>
                  <span>
                    to {d.endDate && format(parseISO(d.endDate), 'dd-MMM-yyyy')}
                  </span>
                </span>

                <Link to={`/records/${d.recordId}`} className="details-link">
                  View Details
                  <Icon id="arrow-right-outline" size="sm" />
                </Link>
              </li>
            ))}
        </ul>
      )}
    </section>
  );
}

DashboardLatestRecords.displayName = 'DashboardLatestRecords';
