import { CustomerDto } from '@/open-api';
import { UseQueryResult } from '@tanstack/react-query';
import { RecordBasicInfo, RecordQuickInfo, RelatedRecordsInfo } from '../types';
import {
  selectBasicInfo,
  selectContactInfo,
  selectQuickInfo,
  selectRelatedRecords,
} from './selectors';
import { useGetRecordsDetailsQuery } from './useGetRecordsDetails';

/**
 *
 * @param id {string} Reservation ID
 * @returns
 */
export const useSelectBasicInfo = (
  id: string
): UseQueryResult<RecordBasicInfo, unknown> => {
  return useGetRecordsDetailsQuery(id, data => selectBasicInfo(data));
};

/**
 *
 * @param id {string} Record Id
 * @returns
 */
export const useSelectContactInfo = (
  id: string
): UseQueryResult<Required<CustomerDto>, unknown> => {
  return useGetRecordsDetailsQuery(id, data => selectContactInfo(data));
};

/**
 *
 * @param id {string} Reservation ID
 * @returns
 */
export const useSelectQuickInfo = (
  id: string
): UseQueryResult<RecordQuickInfo> => {
  return useGetRecordsDetailsQuery(id, data => selectQuickInfo(data));
};

export const useSelectRelatedRecords = (
  id: string
): UseQueryResult<RelatedRecordsInfo[]> => {
  return useGetRecordsDetailsQuery(id, data => selectRelatedRecords(data));
};
