import { Modal } from '@cae/cobalt-react';
import './CompanyEditAvatar.css';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { UploadAvatar } from '../company-edit-logo/uploadAvatar';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  readonly isEditAvatarOpen: boolean;
  readonly setIsEditAvatarOpen: Dispatch<SetStateAction<boolean>>;
}

function CompanyEditAvatar({
  isEditAvatarOpen,
  setIsEditAvatarOpen,
}: Props): JSX.Element {
  const { width } = useViewportSize();

  const handleClose = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsEditAvatarOpen(false);
  };

  return (
    <Modal
      animate
      dismissible
      open={isEditAvatarOpen}
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
      data-testid="edit-company-logo-modal"
    >
      <UploadAvatar setIsEditAvatarOpen={setIsEditAvatarOpen} />
    </Modal>
  );
}

export default CompanyEditAvatar;
