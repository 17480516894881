/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountExecutiveInfoDto } from './AccountExecutiveInfoDto';
import type { InvoicePaymentStatusDto } from './InvoicePaymentStatusDto';
import type { ReservationInfoDto } from './ReservationInfoDto';
export type InvoiceDetailsResponse = {
  /**
   * Type of account receivable
   */
  type?: InvoiceDetailsResponse.type;
  /**
   * Account receivable identifier
   */
  accountReceivableId: string;
  /**
   * Invoice number
   */
  transactionNumber: string;
  /**
   * Total amount of the invoice
   */
  amount: number;
  /**
   * Three letter currency code as defined by ISO-4217
   */
  currency?: string;
  invoicePaymentStatusDto?: InvoicePaymentStatusDto;
  reservationInfo?: Array<ReservationInfoDto>;
  /**
   * Legal entity name
   */
  legalEntityName?: string;
  /**
   * Due Date of the Invoice
   */
  dueDate?: string;
  /**
   * Invoice Issued date
   */
  issuedOn?: string;
  /**
   * Published date of the training event
   */
  publishedDate?: string;
  poNumbers?: Array<string>;
  /**
   * Contract Number
   */
  contractNumber?: string;
  accountExecutive?: AccountExecutiveInfoDto;
};
export namespace InvoiceDetailsResponse {
  /**
   * Type of account receivable
   */
  export enum type {
    INVOICE = 'Invoice',
    CREDIT_MEMO = 'CreditMemo',
  }
}
