/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RecordSortBy {
  _ID = '+id',
  _SALESFORCE_ID = '+salesforceId',
  _SCHEDULER_RESERVATION_ID = '+schedulerReservationId',
  _STATUS = '+status',
  _CLIENT_NAME = '+clientName',
  _COURSE_NAME = '+courseName',
  _START_DATE = '+startDate',
  _END_DATE = '+endDate',
  _PUBLISHED_DATE = '+publishedDate',
  _LOCATION = '+location',
  _PLATFORM = '+platform',
  _CLIENT_SALESFORCE_ID = '+clientSalesforceId',
}
