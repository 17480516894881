import * as React from 'react';

import type { QueryStatus } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/react-table';

import { ReservationResponseDto } from '@/open-api';

import {
  DataLoadingError,
  NoData,
  DataLoading,
} from '@/shared/components/empty-states';

import { BasicPagination } from '@/shared/components';

import { type ReservationType } from '../../api/useGetReservationsTableDataQuery';

import './ReservationsCardListing.css';
import {
  CompletedCard,
  ConfirmedCard,
  FullCard,
  InProgressCard,
  ProvisionalCard,
} from './rows';

interface ReservationsCardListingProps {
  data: ReservationResponseDto[];
  openDetails: (reservation: ReservationResponseDto) => void;
  pageIndex: number;
  reservationsType: ReservationType;
  searchText: string;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  queryStatus: QueryStatus;
  totalPages: number;
}

export function ReservationCardListing({
  data,
  openDetails,
  pageIndex,
  reservationsType = 'all',
  setPagination,
  queryStatus,
  totalPages,
}: ReservationsCardListingProps): JSX.Element {
  return (
    <>
      {queryStatus === 'loading' && <DataLoading />}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data.length < 1 && <NoData />}
      {queryStatus === 'success' && data.length > 0 && (
        <div className="reservations--card-listing">
          {totalPages > 1 && (
            <p className="page-info">
              Showing page {pageIndex + 1} of {totalPages}
            </p>
          )}
          {data.map(d => {
            const common = {
              data: d,
              key: `c-${d.reservationId}`,
              openDetails,
            };
            switch (reservationsType) {
              case 'provisional':
                return <ProvisionalCard {...common} />;
              case 'confirmed':
                return <ConfirmedCard {...common} />;
              case 'inProgress':
                return <InProgressCard {...common} />;
              case 'completed':
                return <CompletedCard {...common} />;
              case 'all':
              default:
                return <FullCard {...common} />;
            }
          })}
          {totalPages > 1 && (
            <BasicPagination
              pageIndex={pageIndex}
              setPagination={setPagination}
              totalPages={totalPages}
            />
          )}
        </div>
      )}
    </>
  );
}

ReservationCardListing.displayName = 'ReservationCardListing';
