import * as React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toastMessages } from '@/shared/helpers/toastMessages';
import {
  UserResponse,
  RoleResponse,
  UserOrganizationRoleMapping,
  UserRequest,
} from '@/open-api';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import { useUserInfo } from '@/contexts/userInfo';
import { useGetAllRolesListQuery } from '../../api/useGetAllRolesListQuery';
import { useEditUserMutation } from '../../api/useEditUserMutation';
import { EditUserForm } from '../edit-user-form/EditUserForm';
import { roleOptions } from './editUserConstants';
import { RoleOptions, DefaultValues } from './editUserTypes';
import { useGetUsersDetailsQuery } from '../../api/useGetUsersListQuery';
import './EditUser.css';
import { removeSessionStorageItem } from '@/shared/helpers/sessionStorage';
import SFRedirectModal from '@/pages/users/sf-redirect-modal/SFRedirectModal';

const getCurrentOrgRoles = (
  orgRoleMappingList: UserOrganizationRoleMapping[] | undefined,
  id: string | undefined
): string[] => {
  if (!id || !orgRoleMappingList) return [];
  const currentOrgItem = orgRoleMappingList.find(
    org => id === org.organizationId
  );
  return currentOrgItem?.roleIds ?? [];
};

const getInitialValues = (
  userDetails: UserResponse | undefined,
  id: string | undefined
): DefaultValues => {
  return userDetails
    ? {
        active: userDetails.active ?? false,
        roles: getCurrentOrgRoles(userDetails.organizationRoleMappingList, id),
      }
    : { active: false, roles: [] };
};

const buildRoleList = (
  rolesOptions: RoleResponse[],
  defaultRoles: DefaultValues
): RoleOptions[] => {
  const isAdmin = defaultRoles.roles.includes(
    '219f63c1-679d-420b-b6b3-a2d836771454'
  );
  return rolesOptions
    .map(item => ({
      label: item.name!,
      value: item.id!,
      helperText:
        item.description ??
        roleOptions.find(role => role.value === item.id)?.helperText ??
        '',
      disabled: isAdmin ? item.name !== 'Admin' : false,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export function EditUser(): JSX.Element {
  const { userId = '' } = useParams();
  const navigate = useNavigate();
  const { state: userDetailsFromLocation } = useLocation();
  const { data: userDetails } = useGetUsersDetailsQuery(userId);
  const [showSFRedirectModal, setShowSFRedirectModal] = React.useState(false);
  const { id: currentUserId, selectedOrganization } = useUserInfo();
  const defaultValues = React.useMemo(
    () => getInitialValues(userDetails, selectedOrganization?.id),
    [userDetails, selectedOrganization]
  );
  removeSessionStorageItem('sfmastercontactid');
  removeSessionStorageItem('sfaccountid');
  const { data: rolesOptions } = useGetAllRolesListQuery();
  const roles = React.useMemo(() => {
    return rolesOptions ? buildRoleList(rolesOptions, defaultValues) : [];
  }, [rolesOptions, defaultValues]);

  const [rolesList, setRolesList] = React.useState<RoleOptions[]>(roles);
  const { mutate: editUserMutate, isLoading: userDetailsLoading } =
    useEditUserMutation({
      onSuccessCallback: () => {
        navigate('/users');
        toastMessages.success('User edited successfully');
      },
      onErrorCallback: () => {
        navigate('/users');
        toastMessages.error('Error in updating user');
      },
    });

  React.useEffect(() => {
    if (
      userDetailsFromLocation?.userData?.isOrganizationExists &&
      userDetailsFromLocation?.userData?.isAlreadyExistsInOrg
    ) {
      setShowSFRedirectModal(true);
      const timer = setTimeout(() => {
        setShowSFRedirectModal(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [userDetails, userDetailsFromLocation]);

  React.useEffect(() => {
    setRolesList(roles);
  }, [roles]);

  const onSubmit = async (data: UserRequest): Promise<void> => {
    editUserMutate({ id: userId, data });
  };

  const handleCancelEditUser: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/users');
  };

  return userId ? (
    <section className="edit-user__dialog-details" data-user-id={userId}>
      {userDetails ? (
        <div>
          <h2>Edit User</h2>
          <h3 className="personal-information-heading">Personal Information</h3>
          <EditUserForm
            userDetails={userDetails}
            currentUserId={currentUserId}
            rolesList={rolesList}
            setRolesList={setRolesList}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            handleCancelEditUser={handleCancelEditUser}
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {userDetailsLoading && (
        <div className="create-user-loader-overlay">
          <LoaderWithLabel title="Editing user" />
        </div>
      )}
      {showSFRedirectModal && <SFRedirectModal />}
    </section>
  ) : (
    <p>userId is missing</p>
  );
}
