import { Card } from '@cae/cobalt-react';

import {
  AutoCancellationPart,
  CoursePart,
  EndDatePart,
  EtcaPart,
  LocationPart,
  PlatformPart,
  ReservationIdPart,
  SchedulePart,
  StartDatePart,
  StatusPart,
  TrainingRecordsPart,
  TsaPart,
} from '@/shared/components/reservation-data-parts/ReservationDataParts';

import { ReservationResponseDto } from '@/open-api';

interface CardProps {
  data: ReservationResponseDto;
  openDetails: (data: ReservationResponseDto) => void;
}

export function FullCard({ data, openDetails }: CardProps): JSX.Element {
  return (
    <Card
      key={`reservation-${data.reservationId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <StatusPart data={data.reservationStatus} />
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdPart data={data.reservationId ?? ''} />
        <PlatformPart data={data.aircraftName ?? ''} />
        <CoursePart data={data.courseName ?? ''} />
        <StartDatePart data={data.startDate ?? ''} />
        <EndDatePart data={data.endDate ?? ''} />
        <LocationPart data={data.location ?? ''} />
        <AutoCancellationPart data={data.autoCancellation} />
        <EtcaPart data={data.etca} />
        <SchedulePart data={data.schedule} />
        <TsaPart data={data.tsa} />
        <TrainingRecordsPart data={data.records} />
      </dl>
    </Card>
  );
}

export function ProvisionalCard({ data, openDetails }: CardProps): JSX.Element {
  return (
    <Card
      key={`reservation-${data.schedulerReservationId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdPart data={data.schedulerReservationId ?? ''} />
        <PlatformPart data={data.aircraftName ?? ''} />
        <CoursePart data={data.courseName ?? ''} />
        <StartDatePart data={data.startDate ?? ''} />
        <LocationPart data={data.location ?? ''} />
        <AutoCancellationPart data={data.autoCancellation} />
      </dl>
    </Card>
  );
}

export function ConfirmedCard({ data, openDetails }: CardProps): JSX.Element {
  return (
    <Card
      key={`reservation-${data.schedulerReservationId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdPart data={data.schedulerReservationId ?? ''} />
        <PlatformPart data={data.aircraftName ?? ''} />
        <CoursePart data={data.courseName ?? ''} />
        <StartDatePart data={data.startDate ?? ''} />
        <EndDatePart data={data.endDate ?? ''} />
        <LocationPart data={data.location ?? ''} />
        <EtcaPart data={data.etca} />
        <SchedulePart data={data.schedule} />
        <TsaPart data={data.tsa} />
      </dl>
    </Card>
  );
}

export function InProgressCard({ data, openDetails }: CardProps): JSX.Element {
  return (
    <Card
      key={`reservation-${data.schedulerReservationId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdPart data={data.schedulerReservationId ?? ''} />
        <PlatformPart data={data.aircraftName ?? ''} />
        <CoursePart data={data.courseName ?? ''} />
        <StartDatePart data={data.startDate ?? ''} />
        <EndDatePart data={data.endDate ?? ''} />
        <LocationPart data={data.location ?? ''} />
        <EtcaPart data={data.etca} />
        <SchedulePart data={data.schedule} />
        <TsaPart data={data.tsa} />
      </dl>
    </Card>
  );
}

export function CompletedCard({ data, openDetails }: CardProps): JSX.Element {
  return (
    <Card
      key={`reservation-${data.schedulerReservationId}`}
      onClick={() => {
        openDetails(data);
      }}
    >
      <h4>{data.clientName}</h4>
      <dl>
        <ReservationIdPart data={data.schedulerReservationId ?? ''} />
        <PlatformPart data={data.aircraftName ?? ''} />
        <CoursePart data={data.courseName ?? ''} />
        <StartDatePart data={data.startDate ?? ''} />
        <EndDatePart data={data.endDate ?? ''} />
        <LocationPart data={data.location ?? ''} />
        <EtcaPart data={data.etca} />
        <SchedulePart data={data.schedule} />
        <TsaPart data={data.tsa} />
        <TrainingRecordsPart data={data.records} />
      </dl>
    </Card>
  );
}
