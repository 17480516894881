import * as React from 'react';
import { useParams } from 'react-router-dom';
import { AlertBanner, AlertMessage } from '@cae/cobalt-react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';

import { ApiError, DocumentsManagementService } from '@/open-api';

import { ChangeRequestForm } from '@/shared/components';
import { PostResponseStatus } from '@/shared/types';
import Logger from '@/libs/logging/Logger';
import { useUserInfo } from '@/contexts/userInfo';

export function DocumentsChangeRequest({
  onCancel,
}: Readonly<{
  onCancel?: (e: React.SyntheticEvent<HTMLDialogElement | Element>) => void;
}>): JSX.Element {
  const { documentId } = useParams();
  const { selectedOrganization } = useUserInfo();

  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });
  const [key, setKey] = React.useState<string>(`key-${Date.now()}`);

  const handleRefresh = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setStatus({ type: 'idle' });
    setKey(`key-${Date.now()}`);
  };

  const handleSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });
    const { title, details: text } = data;

    if (documentId === undefined) {
      Logger.log({
        type: 'code',
        level: 'error',
        message: 'Change request with undefined document id',
      });
      return;
    }

    try {
      await DocumentsManagementService.sendDocumentChangeRequest({
        organizationId: selectedOrganization?.id,
        formData: {
          documentId,
          title,
          text,
        },
      });
      setStatus({
        type: 'success',
        heading: '',
        message: 'Request submitted successfully!',
      });
    } catch (error) {
      if (error instanceof ApiError) {
        setStatus({
          type: 'error',
          heading: 'API error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      } else {
        setStatus({
          type: 'error',
          heading: 'Oops, an error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      }
    }
  };

  return (
    <div className="dialog-details__page request" key={key}>
      <header>
        <h2>Request</h2>

        {status.type === 'success' && (
          <AlertMessage type="success" heading={status.message}>
            <p>
              Our team will get back to you within two business days with a
              follow-up on your request. Note that requests are subject to
              change depending on availability, feasibility and other factors.
            </p>
            <p>
              <button type="button" className="anchor" onClick={handleRefresh}>
                Create another request?
              </button>
            </p>
          </AlertMessage>
        )}

        {status.type === 'error' && (
          <AlertBanner
            type="error"
            heading={status.heading}
            style={{ marginBottom: '1.5rem' }}
          >
            <p>{status.message}</p>
          </AlertBanner>
        )}

        {status.type !== 'success' && (
          <AlertMessage type="info" heading="" inline>
            Our team will get back to you within two business days with a
            follow-up on your request. Note that requests are subject to change
            depending on availability, feasibility and other factors.
          </AlertMessage>
        )}
      </header>

      <ChangeRequestForm
        initialValues={{ title: '', details: '' }}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        promptOnLeave
        status={status}
      />
    </div>
  );
}

DocumentsChangeRequest.displayName = 'DocumentsChangeRequest';
