import * as React from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

export function RecordDetailsPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { recordId = '' } = useParams();
  const handleCloseRecord = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/records');
  };

  return recordId ? (
    <Modal
      animate
      dismissible
      onCancel={handleCloseRecord}
      open
      placement="right"
      className="record-details--dialog"
    >
      <Outlet />
    </Modal>
  ) : null;
}
