import { Button, Checkbox } from '@cae/cobalt-react';
import { SyntheticEvent, useEffect, useState } from 'react';
import './PasswordReset.css';
import { IPublicClientApplication } from '@azure/msal-browser';
import { resetPasswordRedirect } from '../../helpers/resetPasswordRedirect';

interface PasswordResetModalProps {
  readonly setResetPasswordDialogOpen: (args: boolean) => void;
  readonly msalInstance: IPublicClientApplication;
}

function PasswordResetContent({
  setResetPasswordDialogOpen,
  msalInstance,
}: PasswordResetModalProps): JSX.Element {
  const [counter, setCounter] = useState(3);
  const [checked, setChecked] = useState(false);

  const handleCloseModal = (e: SyntheticEvent): void => {
    e.stopPropagation();
    setResetPasswordDialogOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevCounter => prevCounter - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    async function handleResetPasswordRedirect(): Promise<void> {
      await resetPasswordRedirect(msalInstance);
      setResetPasswordDialogOpen(false);
    }
    if (counter === 0) {
      if (checked) localStorage.setItem('password-reset', 'true');
      handleResetPasswordRedirect();
    }
  }, [counter, checked, msalInstance, setResetPasswordDialogOpen]);

  return (
    <div className="reset-password-modal-container">
      <p>{`Opening in ${counter}...`}</p>

      <span className="reset-password-modal-container__actions">
        <Button onClick={handleCloseModal} variant="secondary" type="button">
          Cancel
        </Button>
        <Checkbox
          field={{
            id: 'password-reset-checkbox',
            checked,
            onChange: () => setChecked(!checked),
          }}
          label="Do not show again"
        />
      </span>
    </div>
  );
}

export default PasswordResetContent;
