import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, Dropdown, TextInput } from '@cae/cobalt-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '@/features/first-time-user/ActionButtons';
import { ChangeRequestBanner } from '../change-request-banner/ChangeRequestBanner';
import './ProfileNameEditForm.css';

const personalInfoSchema = z.object({
  salutation: z
    .string()
    .regex(/^[A-Za-z.]+$/, {
      message: 'Invalid salutation selection',
    })
    .optional(),
  firstName: z
    .string()
    .min(3, { message: 'First name must be at least 3 characters long' })
    .max(50)
    .regex(/^[A-Za-z]+(?: [A-Za-z]+)*$/, {
      message: 'First name must contain only alphabets',
    })
    .optional(),
  middleName: z
    .string()
    .regex(/^[A-Za-z]*$/, {
      message: 'Middle name must contain only alphabets',
    })
    .optional(),
  lastName: z
    .string()
    .min(3, { message: 'Last name must be at least 3 characters long' })
    .max(50)
    .regex(/^[A-Za-z]+(?: [A-Za-z]+)*$/, {
      message: 'Last name must contain only alphabets',
    })
    .optional(),
  nickname: z
    .string()
    .regex(/^[A-Za-z]*$/, {
      message: 'Nickname name must contain only alphabets',
    })
    .optional(),
  active: z.boolean().default(true),
  title: z
    .string()
    .regex(/^[A-Za-z]*$/, {
      message: 'Nickname name must contain only alphabets',
    })
    .default(''),
  phone: z
    .string()
    .regex(/^\d*$/, {
      message: 'Phone number must contain only numbers',
    })
    .default(''),
});

export type ProfileInfo = z.infer<typeof personalInfoSchema>;
type Props = {
  readonly defaultValues: ProfileInfo;
  readonly onSubmit: (data: ProfileInfo) => Promise<void>;
};

export function ProfileNameEditForm({
  defaultValues,
  onSubmit,
}: Props): JSX.Element {
  const { control, formState, handleSubmit } = useForm<ProfileInfo>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(personalInfoSchema),
  });
  const { isValid, isDirty } = formState;
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const handleConfirm = (): void => {
    handleSubmit(onSubmit)();
    setIsSaveDialogOpen(false);
  };
  const closeHandler = (): void => {
    isDirty ? setIsSaveDialogOpen(true) : window.history.back();
  };
  const saveActions = (): JSX.Element => (
    <>
      <PrimaryButton label="Yes" onClick={() => handleConfirm()} />
      <SecondaryButton label="No" onClick={() => setIsSaveDialogOpen(false)} />
    </>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="profile-name-edit-form">
      <h5>Personal information</h5>
      <h1>Full name and nickname</h1>
      <ChangeRequestBanner />
      <div className="__fields">
        <Controller
          name="salutation"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <Dropdown
              label="Salutation (optional)"
              emptyText="Select location"
              field={{ ...field }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
              options={[
                { label: 'Mr.', value: 'Mr.' },
                { label: 'Mrs.', value: 'Mrs.' },
                { label: 'Ms.', value: 'Ms.' },
              ]}
            />
          )}
        />

        <Controller
          name="firstName"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <TextInput
              id={field.name}
              label="First name *"
              field={{ ...field, disabled: true, placeholder: 'First name' }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
            />
          )}
        />

        <Controller
          name="middleName"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <TextInput
              label="Middle name (optional)"
              field={{
                ...field,
                disabled: true,
                placeholder: 'Type middle name',
              }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <TextInput
              label="Last name *"
              field={{
                ...field,
                disabled: true,
                placeholder: 'Type last name',
              }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
            />
          )}
        />
        <Controller
          name="nickname"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <TextInput
              label="Nickname (optional) "
              field={{
                ...field,
                disabled: true,
                placeholder: 'Type Nick name',
              }}
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid,
              }}
            />
          )}
        />
      </div>
      <Dialog
        modal
        dismissible
        open={isSaveDialogOpen}
        onCancel={() => setIsSaveDialogOpen(false)}
        header={<h2>Save changes?</h2>}
        actions={saveActions}
        placement="center"
        size="sm"
      >
        <p>
          You made changes without saving. Would you like to save your changes?{' '}
        </p>
      </Dialog>
      <div className="__footer">
        <Button
          type="button"
          variant="primary"
          disabled={!isValid || !isDirty}
          onClick={handleConfirm}
        >
          Save
        </Button>
        <Button onClick={closeHandler} type="button" variant="ghost">
          Cancel
        </Button>
      </div>
    </form>
  );
}
