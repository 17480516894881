import { UserManagementService, UserOrganization } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

function useGetUserOrganizationsQuery(): UseQueryResult<
  UserOrganization[],
  unknown
> {
  return useQuery<UserOrganization[]>({
    queryKey: ['getUserOrganizationsById'],
    queryFn: () => UserManagementService.getUserOrganization(),
  });
}

export default useGetUserOrganizationsQuery;
