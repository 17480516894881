import { useState } from 'react';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { InstructorTabs } from '@/features/instructor-management';
import { useInstructorMetadataQuery } from '@/features/instructor-management/api/useInstructorMetadataQuery.ts';
import { SearchInstructorInput } from '@/features/instructor-management/components/search-instructor-input/SearchInstructorInput.tsx';

export function InstructorContainer(): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const {
    data: metadata = {},
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useInstructorMetadataQuery();
  const { instructorProgramCountPerLocation = [] } = metadata;

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleClear = (): void => {
    setSearchText('');
  };
  const getContents = (): JSX.Element => {
    if (isLoading || isFetching) {
      return (
        <DataLoadingGrid
          numberColumns={10}
          numberRows={10}
          style={{ marginTop: '1rem' }}
        />
      );
    }

    if (isError) {
      return <DataLoadingError />;
    }

    if (isSuccess && !instructorProgramCountPerLocation?.length) {
      return <NoData />;
    }

    return (
      <InstructorTabs
        data={instructorProgramCountPerLocation}
        searchText={searchText}
      />
    );
  };

  return (
    <>
      <div className="toolbar">
        <SearchInstructorInput
          onChange={handleSearch}
          onClear={handleClear}
          value={searchText}
        />
      </div>
      {getContents()}
    </>
  );
}
