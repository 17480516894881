/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReservationStatusDto = {
  /**
   * Reservation status
   */
  status?: ReservationStatusDto.status;
  /**
   * Reservation status message
   */
  message?: string;
  /**
   * Reservation status message with additional details
   */
  longMessage?: string;
  /**
   * Reservation status style
   */
  style?: ReservationStatusDto.style;
};
export namespace ReservationStatusDto {
  /**
   * Reservation status
   */
  export enum status {
    PROVISIONAL = 'Provisional',
    CONFIRMED = 'Confirmed',
    IN_PROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    UNKNOWN = 'Unknown',
  }
  /**
   * Reservation status style
   */
  export enum style {
    NEUTRAL = 'neutral',
    PENDING = 'pending',
    WARNING = 'warning',
    DANGER = 'danger',
    SUCCESS = 'success',
  }
}
