import { createContext, ReactNode, useState } from 'react';

interface AcceptCookiesContextType {
  acceptCookiesStatus: boolean;
  setAcceptCookiesStatus: (status: boolean) => void;
}

export const AcceptCookiesContext = createContext<AcceptCookiesContextType>({
  acceptCookiesStatus: false,
  setAcceptCookiesStatus: () => {},
});

interface AcceptCookiesProviderProps {
  children: ReactNode;
}

export const AcceptCookiesProvider: React.FC<AcceptCookiesProviderProps> = ({
  children,
}: AcceptCookiesProviderProps) => {
  const [acceptCookiesStatus, setAcceptCookiesStatus] = useState(false);

  const handleCookiesStatus = (status: boolean): void => {
    if (status) {
      sessionStorage.setItem('acceptCookiesStatus', 'true');
    } else {
      setAcceptCookiesStatus(status);
      sessionStorage.setItem('acceptCookiesStatus', 'false');
    }
  };
  document
    .querySelector('#onetrust-accept-btn-handler')
    ?.addEventListener('click', () => {
      handleCookiesStatus(true);
    });
  document
    .querySelector('#onetrust-reject-all-handler')
    ?.addEventListener('click', () => {
      handleCookiesStatus(false);
    });
  if (document.cookie === '') {
    sessionStorage.removeItem('acceptCookiesStatus');
  }

  return (
    <AcceptCookiesContext.Provider
      value={{
        acceptCookiesStatus,
        setAcceptCookiesStatus,
      }}
    >
      {children}
    </AcceptCookiesContext.Provider>
  );
};
