import {
  AlertBanner,
  AlertMessage,
  Button,
  Dialog,
  Modal,
} from '@cae/cobalt-react';
import './CompanyProfileChangeRequest.css';
import { ChangeRequestForm } from '@/shared/components';
import { ApiError, OrganizationManagementService } from '@/open-api';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import * as React from 'react';
import { PostResponseStatus } from '@/shared/types';
import { useUserInfo } from '@/contexts/userInfo';
import { useNavigate } from 'react-router-dom';
import { generateRandomValue } from '@/shared/helpers/generateRandomValue';

type ModalActionsProps = {
  readonly handleCancel: React.MouseEventHandler;
  readonly handleConfirm: React.MouseEventHandler;
};

function DiscardModalActionsChangeRequest({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}

function StatusSplashRequest({
  onRefresh,
  status,
}: Readonly<{
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}>): JSX.Element {
  const successMessage = (
    <AlertMessage type="success" heading={status.message}>
      <p>
        Our team will get back to you within two business days with a follow-up
        on your request. Note that requests are subject to change depending on
        availability, feasibility, and other factors.
      </p>
      <p>
        <button type="button" className="anchor" onClick={onRefresh}>
          Create another request?
        </button>
      </p>
    </AlertMessage>
  );

  const errorMessage = (
    <AlertBanner
      type="error"
      heading={status.heading}
      style={{ marginBottom: '1.5rem' }}
    >
      <p>{status.message}</p>
    </AlertBanner>
  );

  const infoMessage = (
    <AlertMessage type="info" heading="" inline>
      Our team will get back to you within two business days with a follow-up on
      your request. Note that requests are subject to change depending on
      availability, feasibility, and other factors.
    </AlertMessage>
  );

  return (
    <>
      {status.type === 'success' && successMessage}
      {status.type === 'error' && errorMessage}
      {status.type !== 'success' && infoMessage}
    </>
  );
}

function ChangeRequestPageHeader({
  onRefresh,
  status,
}: Readonly<{
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}>): JSX.Element {
  return (
    <div>
      <h2>Request</h2>
      <StatusSplashRequest onRefresh={onRefresh} status={status} />
    </div>
  );
}

function CompanyProfileChangeRequest(): JSX.Element {
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const [key, setKey] = React.useState<string>(generateRandomValue());
  const { selectedOrganizationId: organizationId } = useUserInfo();
  const navigate = useNavigate();

  const handleCancelRequest = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(true);
  };

  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const onRefresh = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setStatus({ type: 'idle' });
    setKey(generateRandomValue());
  };

  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    navigate(-1);
  };

  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });

    const { title, details: text } = data;

    try {
      if (organizationId) {
        await OrganizationManagementService.updateOrganizationRequestEmail({
          id: organizationId,
          requestBody: {
            title,
            text,
          },
        });
        setStatus({
          type: 'success',
          heading: '',
          message: 'Request submitted successfully!',
        });
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setStatus({
          type: 'error',
          heading: 'API error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      } else {
        setStatus({
          type: 'error',
          heading: 'Oops, an error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      }
    }
  };
  return (
    <>
      <Modal
        animate
        dismissible
        size="md"
        onCancel={() => {
          navigate(-1);
        }}
        open
        placement="right"
      >
        <div className="company-profile__change-request">
          <ChangeRequestPageHeader onRefresh={onRefresh} status={status} />
        </div>
        <div className="dialog-details__page request" key={key}>
          <ChangeRequestForm
            initialValues={{ title: '', details: '' }}
            onCancel={handleCancelRequest}
            onSubmit={onSubmit}
            status={status}
          />
        </div>
      </Modal>
      <Dialog
        modal
        className="dialog"
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard change request?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActionsChangeRequest}
        placement="center"
        size="sm"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </>
  );
}

export default CompanyProfileChangeRequest;
