import { useState } from 'react';
import {
  ApiError,
  DocumentsManagementService,
  DocumentUploadRequest,
  UploadResult,
} from '@/open-api';
import { PostResponseStatus } from '@/shared/types';
import Logger from '@/libs/logging/Logger';

interface UseUploadDocumentResponse {
  uploadDocument: (data: DocumentUploadRequest) => void;
  response: UploadResult[] | undefined;
  isLoading: boolean;
  refreshUploadStatus: () => void;
}

const extractErrorMessage = (errorMessage: string | undefined): string => {
  const defaultMsg =
    'Submitting document failed. Try again, or contact the administrators.';
  const regex = /"message":"(.*?)"/;
  const match = errorMessage?.match(regex);
  const errorMsg = match?.[1]
    ? 'Submitting document failed. ' + match[1]
    : defaultMsg;

  return errorMsg;
};

export function useUploadDocument(
  setStatus: (status: PostResponseStatus) => void
): UseUploadDocumentResponse {
  const [response, setResponse] = useState<UploadResult[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshUploadStatus = (): void => {
    setResponse(undefined);
  };

  const uploadDocument = async (data: DocumentUploadRequest): Promise<void> => {
    setIsLoading(true);
    try {
      const resp = await DocumentsManagementService.uploadDocuments({
        formData: data,
      });
      setResponse(resp.results);
      setIsLoading(false);
      const errorMessage = resp.results?.some(result => !result.success)
        ? extractErrorMessage(resp.results[0].errorMessage)
        : '';
      setStatus({
        type: errorMessage ? 'error' : 'success',
        heading: errorMessage
          ? 'Error occurred'
          : 'Document submitted to CAE successfully',
        message: errorMessage,
      });
    } catch (error) {
      setIsLoading(false);
      let errorMessage =
        'Submitting document failed. Try again, or contact the administrators.';
      if (error instanceof Error) {
        Logger.log({
          type: 'code',
          level: 'error',
          message: `uploadDocument threw an exception: ${error.message}`,
        });
      }
      if (error instanceof ApiError) {
        errorMessage = 'Submitting document failed due to an API error.';
      }
      setStatus({
        type: 'error',
        heading: errorMessage
          ? 'API error occurred'
          : 'Oops, an error occurred',
        message: errorMessage,
      });
    }
  };

  return { uploadDocument, response, isLoading, refreshUploadStatus };
}
