import { Icon, Tabs } from '@cae/cobalt-react';
import { AlertTypeDto, InstructorProgramCountPerLocationDto } from '@/open-api';
import { InstructorListing } from '@/features/instructor-management/components/instructor-listing/InstructorListing.tsx';
import './InstructorTabs.css';

interface InstructorTabsProps {
  data: Array<InstructorProgramCountPerLocationDto> | [];
  searchText: string;
}

export function InstructorTabs({
  data,
  searchText,
}: Readonly<InstructorTabsProps>): JSX.Element {
  if (!data.length) {
    return <></>;
  }
  return (
    <Tabs variant="contained" defaultIndex={0} data-testid={'instructorTabs'}>
      {({ selectedIndex = 0 }) => {
        return (
          <>
            <Tabs.TabList>
              {data.map(({ location, programCount: counts, alertDto }, i) => (
                <Tabs.Tab
                  id={`tab--instructor-${location}`}
                  key={`tab--instructor-${location}`}
                  data-testid={`${location}InstructorTabs`}
                  selected={i === selectedIndex}
                  className="instructor-tabs__tab"
                >
                  {`${location}`}
                  <i className="count">({`${counts ?? 0}`})</i>
                  {alertDto?.type === AlertTypeDto.TOO_FEW_CHECK_AIRMEN && (
                    <Icon
                      data-testid="warning-triangle"
                      id="warning-triangle-solid"
                    />
                  )}
                </Tabs.Tab>
              ))}
            </Tabs.TabList>
            {data.map(({ trainingCenterId: key = '', location }, i) => (
              <Tabs.TabPanel
                id={`tab-panel--instructor-${location}`}
                selected={i === selectedIndex}
                aria-labelledby={`tab--instructor-${location}`}
                key={key}
                data-testid={`${location}InstructorList`}
              >
                <InstructorListing
                  trainingCenterId={key}
                  searchText={searchText}
                />
              </Tabs.TabPanel>
            ))}
          </>
        );
      }}
    </Tabs>
  );
}
