import { Avatar, Icon } from '@cae/cobalt-react';
import './MyProfileAvatar.css';
import { useUserInfo } from '@/contexts/userInfo';
import getInitials from '@/shared/helpers/getInitials';

export function MyProfileAvatar(): JSX.Element {
  const { firstName, lastName, email } = useUserInfo();

  const avatarName =
    firstName && lastName ? getInitials(firstName, lastName) : '--';
  const handleGoBack = (): void => {
    window.history.back();
  };
  return (
    <div className="profile-avatar-main">
      <button className="navigate-home" onClick={handleGoBack}>
        <Icon id="arrow-left-solid" size="xs" />
        <p>Back to app</p>
      </button>
      <div>
        <h1 className="profile-heading">Profile settings</h1>
        <h3 className="profile-heading">
          Here, you can set all of your profile settings.
        </h3>
      </div>
      <div className="profile-avatar-details">
        <Avatar
          as="span"
          className="avatar"
          elevation="raised"
          label={avatarName}
          initials={avatarName}
          size="lg"
          variant="round"
        />
        <div>
          <div className="user-profile--title-box">
            <h1 className="profile-heading">{`${firstName} ${lastName}`}</h1>
          </div>
          <p className="profile-avatar-email">{email}</p>
        </div>
      </div>
    </div>
  );
}

export default MyProfileAvatar;
