import { useUserInfo } from '@/contexts/userInfo';
import { UserManagementService, UsersMetadata } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useUsersMetadataQuery(): UseQueryResult<UsersMetadata, Error> {
  const { selectedOrganization = undefined } = useUserInfo();
  return useQuery<UsersMetadata, Error>({
    queryKey: ['usersCount', selectedOrganization?.id],
    queryFn: () =>
      UserManagementService.getUsersMetadata({
        organizationId: selectedOrganization?.id,
      }),
  });
}
