import { UseQueryResult, useQuery } from '@tanstack/react-query';

import {
  ReservationManagementService,
  type ReservationDetailsResponse,
} from '@/open-api';

export const useGetReservationDetailsQuery = <Data>(
  reservationId: string,
  select?: (data: ReservationDetailsResponse) => Data
): UseQueryResult<Data, unknown> => {
  return useQuery({
    queryKey: ['reservations', reservationId],
    queryFn: () =>
      ReservationManagementService.getReservationDetails({ reservationId }),
    select,
  });
};
