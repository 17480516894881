import * as React from 'react';
import { format, parseISO } from 'date-fns';
import { Button, Icon } from '@cae/cobalt-react';
import { usePDF } from 'react-to-pdf';
import ScheduleTimelineContent from './ScheduleTimelineContent';
import ScheduleToPDF from './schedule-to-pdf/ScheduleToPDF';
import {
  ReservationCourse,
  ReservationTrainingLocation,
  TrainingSchedule,
} from '../../types';
import Spinner from '@/shared/components/file-upload/spinner/Spinner';

interface ScheduleTimelineProps {
  schedule: TrainingSchedule;
  trainingLocation: ReservationTrainingLocation;
  course: ReservationCourse;
}

export function ScheduleTimeline({
  schedule,
  trainingLocation,
  course,
}: Readonly<ScheduleTimelineProps>): JSX.Element {
  const startDate = schedule.localStartDate
    ? format(parseISO(schedule.localStartDate), 'dd-MMM-yyyy')
    : null;
  const endDate = schedule.localEndDate
    ? format(parseISO(schedule.localEndDate), 'dd-MMM-yyyy')
    : null;

  const currentDate = new Date();
  const formattedDate = format(currentDate, 'dd-MM-yyyy');
  const pdfFilename = `Training schedule as of ${formattedDate}`;

  const { toPDF, targetRef } = usePDF({ filename: `${pdfFilename}.pdf` });
  const [loadingPDF, setLoadingPDF] = React.useState(false);

  const handleDownloadPDF = (): void => {
    setLoadingPDF(true);

    setTimeout(() => {
      toPDF({
        page: {
          format: 'a4',
          orientation: 'portrait',
        },
        canvas: {
          mimeType: 'image/jpeg',
          qualityRatio: 1,
        },
        overrides: {
          pdf: {
            compress: true,
          },
          canvas: {
            useCORS: true,
          },
        },
      });

      setLoadingPDF(false);
    }, 2000);
  };

  return (
    <section className="schedule">
      {startDate && endDate && (
        <header className="schedule-header">
          <div className="schedule-header-info">
            <h5>
              From {startDate} to {endDate}
            </h5>
            <p>
              <Icon id="clock-outline" size="sm" />
              Last updated 1 hour ago
            </p>
          </div>
          <span className="schedule-header-action">
            <Button
              variant="ghost"
              onClick={handleDownloadPDF}
              disabled={loadingPDF}
              title="Download schedule"
            >
              <span>Download schedule</span>
              {loadingPDF ? (
                <Spinner />
              ) : (
                <Icon id="download-outline" size="sm" />
              )}
            </Button>
          </span>
        </header>
      )}

      {loadingPDF && (
        <div
          ref={targetRef}
          style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}
        >
          <ScheduleToPDF
            course={course}
            trainingLocation={trainingLocation}
            schedule={schedule}
            startDate={startDate!}
            endDate={endDate!}
          />
        </div>
      )}

      <ScheduleTimelineContent
        dailySessions={schedule.dailySessions}
        trainingCenterCurrentDate={schedule.trainingCenterCurrentDate}
      />
    </section>
  );
}
