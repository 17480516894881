import { Button } from '@cae/cobalt-react';

import { FilterDateRangePicker, FilterSection } from '@/shared/components';

import { RecordFilterOptionsDto } from '@/open-api';
import { RecordsFilters, useFilterModalReducer } from './useFilterModalReducer';

export type FilterModalProps = {
  filterOptions: RecordFilterOptionsDto | undefined;
  filters: RecordsFilters;
  resetFilters: () => void;
  toggleFilterSection: () => void;
  toggleAllFilters: (selected: RecordsFilters) => void;
};

function compareArrays(
  filters: string[] | undefined,
  selectedValues: string[]
): boolean {
  if (
    (!filters || filters.length === 0) &&
    selectedValues.every(value => !value.trim())
  )
    return true;
  if (!filters || !selectedValues) return false;
  if (filters.length !== selectedValues.length) return false;
  return filters.every(item => selectedValues.includes(item));
}

export function FilterModal({
  filterOptions = {},
  filters,
  toggleAllFilters,
  resetFilters,
  toggleFilterSection,
}: FilterModalProps): JSX.Element {
  const {
    selectedFilters,
    clearSelectedFilters,
    changeSelectedLocations,
    changeSelectedPlatforms,
    changeDateRange,
  } = useFilterModalReducer(filters);

  const handleApply = (): void => {
    toggleAllFilters(selectedFilters);
    toggleFilterSection();
  };

  const handleReset = (): void => {
    resetFilters();
    clearSelectedFilters();
  };

  return (
    <div className="filter-list">
      <h3>Filters</h3>
      <div className="filter-sections">
        <FilterDateRangePicker
          onSelect={changeDateRange}
          selected={selectedFilters.dateRange}
          title="Training end date range"
        />
        <FilterSection
          label="Locations"
          searchPlaceholder="Search Locations"
          options={filterOptions?.location}
          selected={selectedFilters.locations}
          onSelect={changeSelectedLocations}
        />
        <FilterSection
          label="Platforms"
          searchPlaceholder="Search Platforms"
          options={filterOptions?.platform}
          selected={selectedFilters.platforms}
          onSelect={changeSelectedPlatforms}
        />
      </div>
      <div className="actions">
        <Button variant="ghost" onClick={handleReset}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleApply}
          disabled={
            compareArrays(filters.locations, selectedFilters.locations) &&
            compareArrays(filters.platforms, selectedFilters.platforms) &&
            compareArrays(
              filters.dateRange.map(date => (date ? date.toDateString() : '')),
              selectedFilters.dateRange.map(date =>
                date ? date.toDateString() : ''
              )
            )
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default FilterModal;
