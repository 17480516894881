import * as React from 'react';

import './DataLoading.css';

export function DataLoading({
  heading = 'Loading data',
  image,
  message = 'Just a moment!',
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & {
  heading?: React.ReactNode;
  image?: React.ReactNode;
  message?: React.ReactNode;
}): JSX.Element {
  const rootProps = {
    ...rest,
    className: `co-empty-state no-data ${rest.className ?? ''}`,
  };

  return (
    <div {...rootProps}>
      {image}
      <h4>{heading}</h4>
      <p>{message}</p>
    </div>
  );
}
