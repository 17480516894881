import { ScreenContent, ScreenHeader } from '@cae/cobalt-react';
import { CompanyAvatar, CompanyContainer } from '@/features/my-company-profile';
import './MyCompanyPage.css';
import { useUserInfo } from '@/contexts/userInfo';
import { useCompanyProfileByOrganizationIdQuery } from '@/features/my-company-profile/api/useCompanyProfileByOrganizationIdQuery';

function MyCompanyPage(): JSX.Element {
  const { selectedOrganization } = useUserInfo();
  const { data: companyData, isLoading } =
    useCompanyProfileByOrganizationIdQuery(selectedOrganization?.id);

  if (isLoading) {
    return <p>loading...</p>;
  }
  return (
    <ScreenContent className="my-company--main">
      <ScreenHeader elevation="skim">
        <CompanyAvatar name={companyData?.name} />
      </ScreenHeader>
      <CompanyContainer />
    </ScreenContent>
  );
}

export default MyCompanyPage;
