import { useNavigate } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { downloadURI } from '@/shared/helpers/dom';

import { RecordStatus, ReservationManagementService } from '@/open-api';
import type { ReservationData } from '@/features/reservation-details/types';

const trainingRecordsIconMapping: { [k: string]: string } = {
  success: 'checkmark-circle-solid',
  warning: 'warning-triangle-solid',
  danger: 'warning-triangle-solid',
  neutral: 'remove-solid',
  'neutral-pending': 'remove-solid',
};

type TrainingRecordsStatusProps = {
  reservationId: string;
  data: ReservationData['statusInfo']['trainingRecordDetails'];
};

function getRecordDocumentById(reservationId: string): void {
  ReservationManagementService.getReservationTrainingRecordDocument({
    reservationId,
  })
    .then(response => {
      downloadURI(
        response.downloadUrl,
        `Record document for reservation ${reservationId}`
      );
    })
    .catch(error => {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      Promise.reject(error);
    });
}

export function TrainingRecordsStatus({
  reservationId,
  data,
}: TrainingRecordsStatusProps): JSX.Element {
  const hasPermission = useHasPermission();
  const navigate = useNavigate();

  const handleViewClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async e => {
    e.preventDefault();
    navigate(`/records/${reservationId}`);
  };
  const handleDownloadClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async e => {
    e.preventDefault();
    getRecordDocumentById(reservationId);
  };
  return (
    <article>
      <h4>Training record</h4>
      {data && (
        <>
          <p>
            {data.status && (
              <Icon
                id={trainingRecordsIconMapping[data.style]}
                className={`status ${data.style}`}
                size="sm"
              />
            )}
            {data.message}
          </p>
          {hasPermission('reservations_confirmation_letter_actions') &&
            hasPermission('view_records_details') &&
            data.status === RecordStatus.AVAILABLE && (
              <div className="actions">
                <button type="button" onClick={handleViewClick}>
                  View
                </button>
                <button type="button" onClick={handleDownloadClick}>
                  <Icon id="download-solid" size="sm" />
                </button>
              </div>
            )}
        </>
      )}
    </article>
  );
}

TrainingRecordsStatus.displayName = 'TrainingRecordsStatus';
