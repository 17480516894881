/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum InstructorApprovalState {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  PENDING_APPROVAL_LETTER = 'PENDING_APPROVAL_LETTER',
  PENDING_REMOVAL = 'PENDING_REMOVAL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNSELECTED = 'UNSELECTED',
}
