import { Card, Icon } from '@cae/cobalt-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/contexts/userInfo';
import ChangeRequestBanner from '../company-change-request-banner/ChangeRequestBanner';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import './CompanyContacts.css';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function CompanyContacts(): JSX.Element {
  const navigate = useNavigate();
  const { selectedOrganization } = useUserInfo();
  const { data: { name, address, phoneNumber, faxNumber } = {} } =
    useCompanyProfileByOrganizationIdQuery(selectedOrganization?.id);
  const currentRoles = useCurrentRoles();
  const { editCompanyProfileFaxNumber, editCompanyProfilePhoneNumber } =
    useFlags();
  const routeFeature = (route: string): boolean => {
    return (
      (route === 'edit-phone' && editCompanyProfilePhoneNumber) ||
      (route === 'edit-fax' && editCompanyProfileFaxNumber)
    );
  };
  const openPhoneFaxModal = (route: string): void => {
    if (
      currentRoles &&
      checkRoleMatch(currentRoles, 'Admin') &&
      routeFeature(route)
    ) {
      navigate(route);
    }
  };
  return (
    <div className="company-contact-info">
      <h1 className="__company-heading">Core contact information</h1>
      <ChangeRequestBanner
        message="In order to edit read only fields, please submit a"
        navigate_to="/company-profile/contact-info/change-request"
      />
      {selectedOrganization ? (
        <div className="__cards-list">
          <Card className="__card-no-shadow no-pointer">
            <h3 className="__cards-list-heading">Company name</h3>
            <p className="__cards-list-para">{name}</p>
          </Card>
          <Card className="__card-no-shadow no-pointer">
            <h3 className="__cards-list-heading">Address</h3>
            <p className="__cards-list-para">{address?.street}</p>
            <p className="__cards-list-para">{address?.city}</p>
            <p className="__cards-list-para">{address?.state}</p>
          </Card>
          <Card onClick={() => openPhoneFaxModal('edit-phone')}>
            <h3 className="__cards-list-heading">Phone</h3>
            <p className="__cards-list-para">{phoneNumber}</p>
            <Icon
              className="__right-icon"
              id="chevron-right-outline"
              size="sm"
            />
          </Card>
          <Card onClick={() => openPhoneFaxModal('edit-fax')}>
            <h3 className="__cards-list-heading">Fax</h3>
            <p className="__cards-list-para">{faxNumber}</p>
            <Icon
              className="__right-icon"
              id="chevron-right-outline"
              size="sm"
            />
          </Card>
        </div>
      ) : (
        <p>Profile information not available</p>
      )}
      <Outlet />
    </div>
  );
}

export default CompanyContacts;
