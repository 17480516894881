function getInitials(firstString: string, secondString: string): string {
  const firstInitial = firstString ? firstString.charAt(0) : '-';

  let secondInitial: string;
  if (secondString) {
    secondInitial = secondString.charAt(0);
  } else if (firstString.length > 1) {
    secondInitial = firstString.charAt(1);
  } else {
    secondInitial = '-';
  }

  return firstInitial + secondInitial;
}

export default getInitials;
