import { Icon } from '@cae/cobalt-react';
import { useDocumentQuickLinksQuery } from '../../api/useDocumentQuickLinksQuery';
import { Link } from 'react-router-dom';

export function FaaPart135ProgramLinks(): JSX.Element {
  const { data } = useDocumentQuickLinksQuery();

  const faaLinks = data?.faaPart135 || [];

  return (
    <ul className="program-links">
      {faaLinks.map(d => (
        <li key={d.id}>
          <Link
            to={`/documents/cae`}
            state={{ parentFolderId: d.id }}
            rel="noopener noreferrer"
          >
            <Icon id="folder-solid" className="link-type folder" />
            <span>{d.name}</span>
            <Icon
              id="external-link-outline"
              className="link-destination"
              size="sm"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}

FaaPart135ProgramLinks.displayName = 'FaaPart135ProgramLinks';
