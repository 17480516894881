import * as React from 'react';
import {
  SubmitHandler,
  Controller,
  useForm,
  FieldValues,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button, Icon, TextInput } from '@cae/cobalt-react';

import type { PostResponseStatus } from '@/shared/types';

const FormSchema = z.object({
  email: z
    .string()
    .min(1, 'Email is required')
    .min(3, 'Min. 3 characters')
    .max(320, 'Max. 320 characters')
    .email('Invalid email address'),
});

interface Props {
  initialValues: {
    email: string;
  };
  onCancel: (e: React.SyntheticEvent<HTMLDialogElement | Element>) => void;
  onSubmit: SubmitHandler<FieldValues>;
  status: PostResponseStatus;
}

export function BookingConfirmationEmailForm({
  initialValues = { email: '' },
  onCancel,
  onSubmit,
  status = { type: 'idle' },
}: Props): JSX.Element {
  const {
    control,
    formState: { errors, touchedFields, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FieldValues>({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: zodResolver(FormSchema),
  });

  return (
    <form
      action="."
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <fieldset>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextInput
              field={{
                ...field,
                disabled: isSubmitting || status.type === 'success',
              }}
              label="Send to"
              meta={{
                error: (errors?.email?.message as string) || '',
                touched: !!touchedFields.email,
              }}
            />
          )}
        />
      </fieldset>
      <div className="form-actions">
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={status.type === 'success' || !isValid || isSubmitting}
          pending={isSubmitting}
        >
          Send request
          {!isSubmitting && <Icon id="send-outline" size="sm" />}
        </Button>
      </div>
    </form>
  );
}

BookingConfirmationEmailForm.displayName = 'BookingConfirmationEmailForm';
