import { Card } from '@cae/cobalt-react';
import './WorkinfoCard.css';
import { useUserInfo } from '@/contexts/userInfo';

export function WorkInfoCard(): JSX.Element {
  const userInfo = useUserInfo();
  if (!userInfo) return <></>;
  return (
    <div className="work-info-card">
      {userInfo?.organizations?.map(organization => (
        <Card key={`o-${organization.name}`}>
          <ul className="work-info-list">
            <li className="card-header">{organization.name}</li>
            <li className="card-title">Title:</li>
            <li className="card-role">-</li>
          </ul>
        </Card>
      ))}
    </div>
  );
}
