import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '@/configs/authConfig';

import './LandingPage.css';
import LandingHeader from '@/features/landing/landing-header/LandingHeader';
import LandingWelcome from '@/features/landing/landing-welcome/LandingWelcome';
import LandingFeatures from '@/features/landing/landing-features/LandingFeatures';
import LandingMoreFeatures from '@/features/landing/landing-more-features/LandingMoreFeatures';
import LandingNeedHelp from '@/features/landing/landing-need-help/LandingNeedHelp';
import { useNavigate } from 'react-router-dom';

async function handleInternalUser(
  instance: IPublicClientApplication
): Promise<void> {
  try {
    if (sessionStorage.getItem('auth-type') === 'b2e') {
      await instance.handleRedirectPromise();

      const accounts = instance.getAllAccounts();
      if (accounts.length === 0) {
        const redirectStartPage = `${window.location.origin}/home`;
        await instance.loginRedirect({ ...loginRequest, redirectStartPage });
      }
    }
  } catch (error) {
    console.error('Error handling internal user:', error);
  }
}

function LandingPage(): JSX.Element {
  const { instance } = useMsal();

  const authenticated = useIsAuthenticated();

  const navigate = useNavigate();

  if (authenticated) {
    navigate('/home');
    return <></>;
  }

  handleInternalUser(instance);

  return (
    <div className="landing-page">
      <LandingHeader />
      <LandingWelcome />

      <LandingFeatures />
      <LandingMoreFeatures />

      <LandingNeedHelp />
    </div>
  );
}

export default LandingPage;
