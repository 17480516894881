import { useReducer } from 'react';
import { InvoicePaymentsFilters } from '../components/filter-modal/useFilterModalReducer';
import { Actions, filtersReducer } from './invoicePaymentsReducer';

const initialState: InvoicePaymentsFilters = {
  invoiceDateRange: [],
  trainingDateRange: [],
  currency: [],
  platform: [],
  location: [],
  status: [],
};

function useInvoicePaymentsFilter(): {
  filters: InvoicePaymentsFilters;
  resetFilters: () => void;
  toggleAllFilters: (selected: InvoicePaymentsFilters) => void;
  filterCounter: () => number;
} {
  const [filters, dispatch] = useReducer(filtersReducer, initialState);

  const toggleAllFilters = (selected: InvoicePaymentsFilters): void => {
    dispatch({ type: Actions.toggleAllFilters, payload: selected });
  };

  const resetFilters = (): void => {
    dispatch({ type: Actions.resetFilter });
  };

  const filterCounter = (): number => {
    let count = 0;

    count = [
      ...filters.location,
      ...filters.platform,
      ...filters.currency,
      ...filters.status,
    ].length;

    if (filters.trainingDateRange && filters.trainingDateRange.length > 0) {
      count += 1;
    }

    if (filters.invoiceDateRange && filters.invoiceDateRange.length > 0) {
      count += 1;
    }

    return count;
  };

  return {
    filters,
    resetFilters,
    toggleAllFilters,
    filterCounter,
  };
}

export default useInvoicePaymentsFilter;
