import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useUserInfo } from '@/contexts/userInfo';

import {
  DocumentsAuthoritySelectionPage,
  UnsupportedAuthorities,
} from './index';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CaeCustomerAuthority } from '@/open-api/models/CaeCustomerAuthority';

import './css/DocumentsPage.css';
import * as React from 'react';

const supportedAuthorities = (authorities: CaeCustomerAuthority[]): boolean =>
  authorities?.includes(CaeCustomerAuthority.EASA) ||
  authorities?.includes(CaeCustomerAuthority.FAA) ||
  authorities?.includes(CaeCustomerAuthority.UK_CAA);

const lacksAuthority = (authorities: CaeCustomerAuthority[]): boolean =>
  authorities?.length < 1;

export function DocumentsPage(): JSX.Element {
  const { documentauthorities } = useFlags();
  const { isInternalUser, authorities } = useUserInfo();

  const [showSelectionPage, setShowSelectionPage] = React.useState(false);

  const handleButtonClick = (): void => {
    setShowSelectionPage(!showSelectionPage);
  };

  React.useEffect(() => {
    setShowSelectionPage(false);
  }, [authorities]);

  if (documentauthorities) {
    if (!isInternalUser && lacksAuthority(authorities)) {
      return (
        <section className="page page-documents">
          <DocumentsAuthoritySelectionPage />
        </section>
      );
    }

    if (!isInternalUser && !supportedAuthorities(authorities)) {
      if (showSelectionPage) {
        return (
          <section className="page page-documents">
            <DocumentsAuthoritySelectionPage />
          </section>
        );
      }

      return <UnsupportedAuthorities onButtonClick={handleButtonClick} />;
    }
  }

  return (
    <section className="page page-documents">
      <ToastContainer
        containerId="notification-container--documents"
        hideProgressBar
        style={{
          position: 'absolute',
          marginTop: '1rem',
          zIndex: 9999,
        }}
        theme="colored"
      />
      <Outlet />
    </section>
  );
}

DocumentsPage.displayName = 'DocumentsPage';
