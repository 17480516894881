import {
  DataLoadingError,
  NoData,
  DataLoadingGrid,
} from '@/shared/components/empty-states';
import type { ColumnDef, PaginationState } from '@tanstack/react-table';
import type { QueryStatus } from '@tanstack/react-query';
import { InstructorProgramDto, InstructorTypeDetailsDto } from '@/open-api';
import { CobaltTable } from '@/shared/components/cobalt-table/CobaltTable';
import { Icon, Tooltip } from '@cae/cobalt-react';
import './InstructorTable.css';
import { useHasPermission } from '@/shared/hooks/useHasPermission.ts';
import { useNavigate } from 'react-router-dom';

interface InstructorTableProps {
  data: Array<InstructorProgramDto>;
  queryStatus: QueryStatus;
  isFetching: boolean;
  pageIndex: number;
  pageSize: number;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
}

const cellNumber = (value: InstructorTypeDetailsDto): JSX.Element => {
  const {
    numberOfApprovedInstructors = 0,
    numberOfAuthorizedInstructors = 0,
    alert,
  } = value;
  const viewValue = `${numberOfApprovedInstructors} / ${numberOfAuthorizedInstructors}`;
  if (alert?.type && alert.message) {
    return (
      <Tooltip
        placement="bottom-start"
        flipOptions={{ mainAxis: true, crossAxis: false }}
        highContrast
      >
        <Tooltip.Trigger>
          <span className="instructor-tooltip__trigger">
            {viewValue}
            <Icon id="warning-triangle-solid" />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <span className="instructor-tooltip__content">{alert.message}</span>
        </Tooltip.Content>
      </Tooltip>
    );
  }
  return <span>{viewValue}</span>;
};
const columns: ColumnDef<InstructorProgramDto>[] = [
  {
    header: 'Program',
    accessorKey: 'program',
  },
  {
    header: 'Ground School',
    accessorKey: 'groundSchoolDetails',
    cell: item => cellNumber(item.getValue<InstructorTypeDetailsDto>()),
  },
  {
    header: 'Simulator training',
    accessorKey: 'simulatorDetails',
    cell: item => cellNumber(item.getValue<InstructorTypeDetailsDto>()),
  },
  {
    header: 'Checking',
    accessorKey: 'checkAirmanDetails',
    cell: item => cellNumber(item.getValue<InstructorTypeDetailsDto>()),
  },
];
export function InstructorTable({
  queryStatus,
  isFetching,
  data = [],
  pageIndex,
  pageSize,
  setPagination,
  totalPages,
}: Readonly<InstructorTableProps>): JSX.Element {
  const hasPermission = useHasPermission();
  const navigate = useNavigate();

  const openDetails = (programObj: InstructorProgramDto): void => {
    if (programObj) {
      if (hasPermission('view_instructor_list')) {
        navigate(`/instructors/program-details/${programObj.program}`);
      }
    }
  };

  return (
    <>
      {(queryStatus === 'loading' || isFetching) && (
        <DataLoadingGrid
          numberColumns={10}
          numberRows={10}
          style={{ marginTop: '1rem' }}
        />
      )}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data.length < 1 && <NoData />}
      {queryStatus === 'success' && data.length > 0 && (
        <CobaltTable
          className="instructor--table"
          data={data}
          columns={columns}
          handleRowClick={openDetails}
          sortable={false}
          pageIndex={pageIndex}
          pageSize={pageSize}
          paginationStyle={{
            backgroundColor: 'var(--white)',
            bottom: '0',
            position: 'sticky',
          }}
          setPagination={setPagination}
          totalPages={totalPages}
        />
      )}
    </>
  );
}
