import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import {
  DataLoadingError,
  DataLoadingGrid,
  DownloadButton,
  NoData,
} from '@/shared/components';

import { useDocumentsQuery } from '@/features/documents/api/useDocumentsQuery';

import { DirectoryButton, DocumentLink, ParentDirectoryButton } from '../index';
import { toastMessages } from '@/shared/helpers/toastMessages';

const downloadOptions = {
  onSuccess: () => {
    toastMessages.success(`Document downloaded successfully`);
  },
  onError: () => {
    toastMessages.error(`Document download failed`);
  },
};

interface Props {
  keyword: string;
  handleFolderClick: React.MouseEventHandler<HTMLButtonElement>;
  type: 'cae' | 'company' | 'submitted';
}

export function DocumentSearchListing({
  keyword = '',
  handleFolderClick,
  type = 'company',
}: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string>('');

  const hasPermission = useHasPermission();
  const hasDownloadPermission = hasPermission('document_download');

  const { data, isInitialLoading, isError, isSuccess } = useDocumentsQuery({
    type,
    searchText: keyword,
  });
  const { items } = data || {};

  const { downloadFile } = useDownloadFile(downloadOptions);

  const handleFileLocationClick = (
    parentFolderId: string | undefined
  ): void => {
    navigate(location.pathname, { state: { parentFolderId } });
  };

  return (
    <>
      <nav className="breadcrumbs">
        <button type="button" onClick={handleFolderClick}>
          <Icon id="folder-solid" size="md" />
        </button>
        <span>/</span>
        <h5>Search results</h5>
      </nav>
      {isInitialLoading && (
        <DataLoadingGrid numberRows={15} numberColumns={2} />
      )}
      {isError && <DataLoadingError />}
      {isSuccess && items && items?.length < 1 && <NoData />}
      {isSuccess && items && items?.length > 0 && (
        <div className="document-listing search-results">
          <header>
            <h6>Name</h6>
            <h6>Parent Folder</h6>
          </header>
          <ul className="items">
            {items.map((d, i) => (
              <li
                key={`row-${i}`}
                data-selected={selected === d.id}
                onClick={() => {
                  setSelected(d.id);
                }}
              >
                {d.isFolder ? (
                  <DirectoryButton
                    data={d}
                    onClick={e => {
                      e.preventDefault;
                    }}
                    onDoubleClick={handleFolderClick}
                  />
                ) : (
                  <DocumentLink
                    data={d}
                    onClick={e => {
                      e.preventDefault();
                      navigate(d.id);
                    }}
                  />
                )}
                <ParentDirectoryButton
                  data={d}
                  onClick={e => {
                    e.preventDefault();
                    handleFileLocationClick(
                      d.parentFolders?.[d.parentFolders.length - 1].id
                    );
                  }}
                />
                {hasDownloadPermission && !d.isFolder && d.id ? (
                  <span className="actions">
                    <DownloadButton
                      onClick={() => {
                        downloadFile({
                          url: `/documents/${d.id}/blob`,
                          fileName: d.name,
                        });
                      }}
                    />
                  </span>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
