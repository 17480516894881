import { Icon } from '@cae/cobalt-react';

import { ReservationTrainingLocation } from '@/features/reservation-details/types';

export function getDirectionsURL(
  name: string,
  displayName: string,
  streetAddress: string
): string {
  const eName = name ? `${encodeURI(name)}%20` : '';
  const eDisplayName = displayName ? `${encodeURI(displayName)}%20` : '';
  const eStreetAddress = streetAddress ? `${encodeURI(streetAddress)}` : '';
  return `https://www.google.com/maps/dir/?api=1&destination=cae%20${eName}${eDisplayName}${eStreetAddress}`;
}

export function LocationInfo({
  data,
}: {
  data: ReservationTrainingLocation;
}): JSX.Element {
  return (
    <>
      <h3>{data.displayName}</h3>
      <div className="location-info">
        <div className="street-address">
          <h5>Address</h5>
          <p>{data.streetAddress}</p>
        </div>
        <div className="phone">
          <h5>Phone</h5>
          <p>{data.phone}</p>
        </div>
        <div className="links">
          {data.website ? (
            <a href={data.website} rel="noreferrer" target="_blank">
              <Icon id="external-link-outline" size="sm" />
              Training center
            </a>
          ) : (
            <span>
              <Icon id="external-link-outline" size="sm" />
              Training center
            </span>
          )}
          {data.streetAddress ? (
            <a
              href={getDirectionsURL(
                data.name,
                data.displayName,
                data.streetAddress
              )}
              rel="noreferrer"
              target="_blank"
            >
              <Icon id="location-pin-outline" size="sm" />
              Directions
            </a>
          ) : (
            <span>
              <Icon id="location-pin-outline" size="sm" />
              Directions
            </span>
          )}
        </div>
      </div>
    </>
  );
}
