import { Organization } from '@/shared/api/common.interface';
import axios from 'axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  OrganizationManagementService,
  OrganizationResponse,
} from '@/open-api';
import APIConfig from '../../configs/urlConfig';

const getOrganizationsList = async (
  userId: string
): Promise<Organization[]> => {
  const tempToken = JSON.parse(localStorage.getItem('idToken') || '{}');

  const config = {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${tempToken}`,
      'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
    },
  };
  const { data } = await axios.get(
    `${APIConfig.apiUrl}/users/${userId}/organizations`,
    config
  );
  return data;
};

export function useGetOrganizationsQuery(
  profileId: string
): UseQueryResult<Organization[], unknown> {
  return useQuery({
    queryKey: ['getOrganization', profileId],
    queryFn: () => getOrganizationsList(profileId),
    enabled: !!profileId,
  });
}

export function useGetAllOrganizationsQuery(): UseQueryResult<
  OrganizationResponse[],
  unknown
> {
  return useQuery({
    queryKey: ['getAllOrganization'],
    queryFn: () => OrganizationManagementService.getAllOrganizations(),
  });
}
