import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '../../configs/authConfig';

import APIConfig from '../../configs/urlConfig';
import { OpenAPI } from '../index';
import Logger from '@/libs/logging/Logger';

function configureOpenAPI(msalInstance: PublicClientApplication) {
  async function getToken() {
    if (import.meta.env.MODE === 'mock') {
      return 'dev-mode';
    }

    const account = msalInstance.getActiveAccount() || undefined;

    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      // Due to legacy reasons, we have to have it here. After removing all custom requests and using only services generated from OpenAPI, we should remove it.
      localStorage.setItem('idToken', JSON.stringify(response.idToken));
      return response.idToken;
    } catch (error: unknown) {
      let errorMessage = 'MSAL: AcquireTokenSilent failed. ';

      if (error instanceof Error) {
        errorMessage = errorMessage + error.message;
      }

      Logger.log({
        type: 'code',
        level: 'error',
        message: errorMessage,
      });

      return Promise.reject(errorMessage);
    }
  }

  OpenAPI.TOKEN = getToken;
  OpenAPI.HEADERS = {
    'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
  };
}

export default configureOpenAPI;
