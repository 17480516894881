import { useInstructorsListQuery } from '@/features/instructor-management/api/useInstructorsListQuery.ts';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { Card, Avatar, Icon, Tooltip, Modal } from '@cae/cobalt-react';
import './InstructosListContainer.css';
import {
  InstructorDetailsByProgram,
  InstructorTypeDetailsDto,
} from '@/open-api';
import getInitials from '@/shared/helpers/getInitials.ts';
import { useEffect, useState } from 'react';
import {
  ActionModal,
  ActionOverflowMenu,
  ReportsForm,
} from '@/features/instructor-management';
import {
  getStatusIcon,
  statusText,
} from '@/features/instructor-management/shared/Utils.tsx';
import { DownloadReportAction } from '@/features/instructor-management/components/download-reports-action/DownloadReportAction.tsx';
import { useViewportSize } from '@/shared/hooks/useViewportSize.ts';

interface InstructorsListContainerProps {
  trainingCenterId: string;
  program: string;
  location: string;
  setIsWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const getProgressSection = (
  title: string,
  instructorDetails: InstructorTypeDetailsDto
): JSX.Element => {
  return (
    <div className="progress-section">
      <span className="progress-title">{title}</span>
      <div className="progress-info">
        <span className="progress-count">
          {instructorDetails?.numberOfApprovedInstructors ?? 0}/
          {instructorDetails?.numberOfAuthorizedInstructors ?? 0}
        </span>
        {instructorDetails?.alert?.type === 'TOO_FEW_CHECK_AIRMEN' && (
          <Icon id="warning-triangle-solid" />
        )}
        <a href="#" className="add-instructors">
          Add instructors
        </a>
      </div>
    </div>
  );
};

const getNameInitials = (name: string): string => {
  if (!name) {
    return '--';
  }
  const [firstName, ...restOfName] = name.split(' ');
  const lastName = restOfName.join(' ');
  if (firstName && lastName) {
    return getInitials(firstName, lastName);
  }
  return '--';
};

export function InstructorsListContainer({
  trainingCenterId,
  program,
  location,
  setIsWarning,
}: Readonly<InstructorsListContainerProps>): JSX.Element {
  const { refetch, data, isError, isSuccess, isLoading, isFetching } =
    useInstructorsListQuery(program, trainingCenterId);

  const { instructorProgram = {}, instructors = [] } = data || {};
  const {
    groundSchoolDetails = {},
    simulatorDetails = {},
    checkAirmanDetails = {},
  } = instructorProgram;
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedInstructor, setSelectedInstructor] = useState({});
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReportsModal = (): void => setReportOpen(!reportOpen);
  const { width } = useViewportSize();

  const handleMenuBtnClick = (
    action: string,
    instructor: InstructorDetailsByProgram
  ): void => {
    setAction(action);
    setSelectedInstructor(instructor);
    setModalOpen(true);
  };
  useEffect(() => {
    setIsWarning(
      checkAirmanDetails?.alert?.type === 'TOO_FEW_CHECK_AIRMEN' || false
    );
  }, [checkAirmanDetails, setIsWarning]);

  useEffect(() => {
    if (!modalOpen) {
      refetch();
    }
  }, [modalOpen, refetch]);

  if (isLoading || isFetching) {
    return (
      <DataLoadingGrid
        numberColumns={10}
        numberRows={10}
        style={{ marginTop: '1rem' }}
      />
    );
  }

  if (isError) {
    return <DataLoadingError />;
  }

  if (isSuccess && !data?.instructors?.length) {
    return <NoData />;
  }

  return (
    <>
      <div className={'instructors-list'}>
        <div className="instructors-list__header">
          <div className="instructors-progress-container">
            {getProgressSection('Ground school', groundSchoolDetails)}
            {getProgressSection('Simulator training', simulatorDetails)}
            {getProgressSection('Checking', checkAirmanDetails)}
          </div>
          <DownloadReportAction
            permitted={true}
            label={'Download reports'}
            onDownloadReportClick={toggleReportsModal}
          />
        </div>
        <div className="instructor-management-list">
          <h4>{location} Instructors</h4>
          <div className="card-list">
            {instructors.map(instructor => (
              <Card
                key={instructor.instructorId}
                elevation="raised"
                orientation="vertical"
                variant="primary"
                className="card"
              >
                <div className="card-content">
                  <Avatar
                    as="span"
                    elevation="base"
                    label={instructor.instructorName}
                    initials={getNameInitials(instructor?.instructorName ?? '')}
                    size="sm"
                    variant="round"
                    className="avatar"
                  />
                  <div className="info-section">
                    <div className="instructor-name">
                      <h3>
                        {instructor.instructorName}
                        {instructor.isNewlyApprovedInstructor && (
                          <span className="new-instructor-badge">
                            New instructor
                          </span>
                        )}
                      </h3>
                    </div>
                    <ul className="details-list">
                      <li className="icon-text-alignment">
                        <Tooltip
                          offsetOptions={7}
                          placement="bottom"
                          highContrast
                        >
                          <Tooltip.Trigger>
                            <span>
                              {getStatusIcon(
                                instructor.groundSchoolApprovalState ?? ''
                              )}
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {instructor?.groundSchoolApprovalState
                              ? statusText[
                                  instructor.groundSchoolApprovalState ||
                                    'default'
                                ]
                              : ''}
                          </Tooltip.Content>
                        </Tooltip>
                        <span className="text">Ground School</span>
                      </li>
                      <li className="icon-text-alignment">
                        <Tooltip
                          offsetOptions={7}
                          placement="bottom"
                          highContrast
                        >
                          <Tooltip.Trigger>
                            <span>
                              {getStatusIcon(
                                instructor.simulatorApprovalState ?? ''
                              )}
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {instructor?.simulatorApprovalState
                              ? statusText[
                                  instructor.simulatorApprovalState || 'default'
                                ]
                              : ''}
                          </Tooltip.Content>
                        </Tooltip>
                        <span className="text">Simulator training</span>
                      </li>
                      <li className="icon-text-alignment">
                        <Tooltip
                          offsetOptions={7}
                          placement="bottom"
                          highContrast
                        >
                          <Tooltip.Trigger>
                            <span>
                              {getStatusIcon(
                                instructor.checkingApprovalState ?? ''
                              )}
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {instructor?.checkingApprovalState
                              ? statusText[
                                  instructor.checkingApprovalState || 'default'
                                ]
                              : ''}
                          </Tooltip.Content>
                        </Tooltip>
                        <span className="text">Checking</span>
                      </li>
                      <li className="icon-text-alignment">
                        <ActionOverflowMenu
                          onBtnClick={handleMenuBtnClick}
                          instructor={instructor}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <ActionModal
        modalOpen={modalOpen}
        toggleActionModal={() => setModalOpen(false)}
        action={action}
        selectedInstructor={selectedInstructor}
        program={program}
        location={location}
        trainingCenterId={trainingCenterId}
      />
      <Modal
        open={reportOpen}
        onCancel={toggleReportsModal}
        placement={width < 480 ? 'bottom' : 'right'}
        dismissible
        size="sm"
        className="instructor-management--dialog"
      >
        {reportOpen && (
          <ReportsForm
            onCancel={toggleReportsModal}
            selectedLocation={location}
            program={program}
          />
        )}
      </Modal>
    </>
  );
}
