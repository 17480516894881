/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AutoCancellationDetailsDto = {
  /**
   * Auto cancellation status
   */
  status?: AutoCancellationDetailsDto.status;
  /**
   * Auto cancellation message
   */
  message?: string;
  /**
   * Auto cancellation style
   */
  style?: AutoCancellationDetailsDto.style;
  /**
   * Number of days to auto cancellation
   */
  daysToAutoCancellation?: number;
};
export namespace AutoCancellationDetailsDto {
  /**
   * Auto cancellation status
   */
  export enum status {
    NOT_APPLICABLE = 'NotApplicable',
    NEXT30DAYS = 'Next30Days',
    NEXT60DAYS = 'Next60Days',
    MORE_THAN60DAYS = 'MoreThan60Days',
  }
  /**
   * Auto cancellation style
   */
  export enum style {
    NEUTRAL = 'neutral',
    WARNING = 'warning',
    DANGER = 'danger',
    NONE = 'none',
  }
}
