import axios, { AxiosError } from 'axios';
import Logger from '@/libs/logging/Logger';

type AxiosErrorResponse = {
  status: number;
  data?: unknown;
};

const handleRequestError = (error: unknown): AxiosError<AxiosErrorResponse> => {
  const axiosError = error as AxiosError<AxiosErrorResponse>;
  try {
    if (axios.isAxiosError(error)) {
      if (axiosError.response) {
        const { status, data } = axiosError.response;
        Logger.log({
          type: 'code',
          level: 'error',
          message: `Request failed with status ${status}. Response data: ${JSON.stringify(
            data
          )}`,
        });
        return axiosError;
      } else if (axiosError.request) {
        Logger.log({
          type: 'code',
          level: 'error',
          message: 'Request failed: no response received',
        });
        return axiosError;
      } else {
        Logger.log({
          type: 'code',
          level: 'error',
          message: `Request setup error: ${axiosError.message}`,
        });
        return axiosError;
      }
    } else {
      const errorMessage =
        typeof error === 'string' ? error : 'Unhandled error';
      Logger.log({
        type: 'code',
        level: 'error',
        message: errorMessage,
      });
      throw new Error(errorMessage);
    }
  } catch (error) {
    return axiosError;
  }
};

export default handleRequestError;
