import { format } from 'date-fns';
import type {
  CourseDto,
  CustomerDto,
  RegulatoryAgencyDto,
  RelatedReservationDto,
  ScheduleDto,
  TrainingDailySessionsDto,
  TrainingLocationDto,
} from '@/open-api';
import type {
  ReservationCourse,
  RelatedReservation,
  TrainingSchedule,
  ReservationTrainingLocation,
} from '../types';
import { getValueSessBool, getValueSessStr } from '../helpers/helpers';

export function transformCourseObjectivesToString(input?: CourseDto): string {
  return input && Array.isArray(input.objectives)
    ? input.objectives.map(o => o.name).join(', ')
    : '';
}

export function transformCourse(input?: CourseDto): ReservationCourse {
  return input && Object.keys(input).length
    ? {
        name: input.name ?? '',
        objectives: Array.isArray(input.objectives)
          ? input.objectives.map(o => ({
              name: o.name ?? '',
              agencyName: o.agencyName ?? '',
            }))
          : [],
      }
    : {
        name: '',
        objectives: [],
      };
}

export function transformRegulatoryAgencies(
  input: RegulatoryAgencyDto[] | undefined
): string {
  return Array.isArray(input) ? input.map(a => a.name).join(', ') : '';
}

export function transformRelatedReservations(
  input: RelatedReservationDto[] | []
): RelatedReservation[] | [] {
  return Array.isArray(input) && input.length
    ? input.map(r => ({
        reservationId: r.reservationId ?? '',
        schedulerReservationId: r.schedulerReservationId ?? '',
        client: {
          id: r?.client?.id ?? '',
          email: r?.client?.email ?? '',
          name: r?.client?.name ?? '',
          salesForceId: r?.client?.salesForceId ?? '',
        },
      }))
    : [];
}

interface SessionTimeInfo {
  localStartDate: string;
  localStartTime: string;
  localEndDate: string;
  localEndTime: string;
}

function getStartEndDateTimes(
  dailySessions: TrainingDailySessionsDto[]
): SessionTimeInfo {
  const sessions = dailySessions.map(s => s.sessions).flat();
  const first = sessions[0];
  const last = sessions[sessions.length - 1];
  return {
    localStartDate: first?.localStartDate ?? '',
    localStartTime: first?.localStartTime ?? '',
    localEndDate: last?.localEndDate ?? '',
    localEndTime: last?.localEndTime ?? '',
  };
}

export function transformSchedule(i?: ScheduleDto): TrainingSchedule {
  if (i && Object.keys(i).length) {
    return {
      scheduleAvailable: i.scheduleAvailable || false,
      cutOffDate: i.cutOffDate ?? '',
      trainingCenterCurrentDate:
        i.trainingCenterCurrentDate ?? format(new Date(), 'yyyy-MM-dd'),
      dailySessions: Array.isArray(i.dailySessions)
        ? i.dailySessions.map(ds => ({
            currentDate: ds.currentDate ?? '',
            cutOffDate: ds.cutOffDate ?? '',
            nextDate: ds.nextDate ?? '',
            sessions: Array.isArray(ds.sessions)
              ? ds.sessions.map(s => ({
                  id: getValueSessStr(s.id),
                  unitName: getValueSessStr(s.unitName),
                  localStartDate: getValueSessStr(s.localStartDate),
                  localStartTime: getValueSessStr(s.localStartTime),
                  localEndDate: getValueSessStr(s.localEndDate),
                  localEndTime: getValueSessStr(s.localEndTime),
                  resourceName: getValueSessStr(s.resourceName),
                  resourceType: getValueSessStr(s.resourceType),
                  appointmentType: getValueSessStr(s.appointmentType),
                  sessionType: getValueSessStr(s.sessionType),
                  trainingCenterTimeZone: getValueSessStr(
                    s.trainingCenterTimeZone
                  ),
                  inWork: getValueSessBool(s.inWork),
                  scheduleUpdated: getValueSessBool(s.scheduleUpdated),
                  scheduleTimeUpdated: getValueSessBool(s.scheduleTimeUpdated),
                  scheduleLocationUpdated: getValueSessBool(
                    s.scheduleLocationUpdated
                  ),
                  message: getValueSessStr(s.message),
                  style: getValueSessStr(s.style),
                  detailsHidden: getValueSessBool(s.detailsHidden),
                }))
              : [],
          }))
        : [],
      ...(Array.isArray(i.dailySessions)
        ? getStartEndDateTimes(i.dailySessions)
        : {}),
    };
  }

  return {
    scheduleAvailable: false,
    cutOffDate: '',
    trainingCenterCurrentDate: format(new Date(), 'yyyy-MM-dd'),
    dailySessions: [],
  };
}

export function transformTrainingLocation(
  input?: TrainingLocationDto
): ReservationTrainingLocation {
  return input && Object.keys(input)
    ? {
        displayName: input.displayName ?? '',
        geminiSiteId: input.geminiSiteId ?? '',
        id: input.id ?? '',
        name: input.name ?? '',
        phone: input.phone ?? '',
        salesForceId: input.salesForceId ?? '',
        streetAddress: input.streetAddress ?? '',
        website: input.website ?? '',
      }
    : {
        displayName: '',
        geminiSiteId: '',
        id: '',
        name: '',
        phone: '',
        salesForceId: '',
        streetAddress: '',
        website: '',
      };
}

export function transformContactInfo(
  input: CustomerDto | Record<string, never>
): Required<CustomerDto> {
  return {
    accountExecutive: {
      id: input?.accountExecutive?.id ?? '',
      email: input?.accountExecutive?.email ?? '',
      name: input?.accountExecutive?.name ?? '',
      phoneNumber: input?.accountExecutive?.phoneNumber ?? '',
    },
    contactEmail: input.contactEmail ?? '',
    id: input.id ?? '',
    name: input.name ?? '',
    salesForceId: input.salesForceId ?? '',
  };
}
