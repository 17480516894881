import { useParams } from 'react-router-dom';

import { BackNavigation } from '@/features/reservation-details/components';

export function BookingConfirmationPreviewPage(): null | JSX.Element {
  const { reservationId = '' } = useParams();

  return reservationId ? (
    <section
      className="dialog-details__page booking-confirmation-email"
      data-reservation-id={reservationId}
    >
      <BackNavigation reservationId={reservationId} />
      <header>
        <h2>Booking confirmation email</h2>
      </header>
      <section className="reservation-details__fragment">
        <p>Booking confirmation email will be displayed here.</p>
      </section>
    </section>
  ) : null;
}

BookingConfirmationPreviewPage.displayName = 'BookingConfirmationPreveiwPage';
