import { useReducer } from 'react';
import APIConfig from '../../configs/urlConfig';
import { useUserInfo } from '@/contexts/userInfo';

export async function downloadDocument(
  path: string,
  fileName: string,
  organizationId: string
): Promise<void> {
  const tempToken = JSON.parse(localStorage.getItem('idToken') || '{}');

  const response = await fetch(`${import.meta.env.VITE_WEBAPI_URI}${path}`, {
    headers: new Headers({
      Authorization: `Bearer ${tempToken}`,
      'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
      'Organization-Id': organizationId,
    }),
  });

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
  let Filename: string = fileName;
  if (fileName === 'records') {
    const headerFilename = response.headers.get('Filename');
    Filename = headerFilename ?? fileName;
  }
  const data = await response.blob();
  const fileURL = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = Filename;
  link.click();
  link.remove();
}

type StartDownload = { type: 'start download'; fileId: string };
type FinishDownload = { type: 'finish download' };
type FailDownload = { type: 'fail download'; error: string };

type Actions = StartDownload | FinishDownload | FailDownload;

type State = {
  status: 'pending' | 'resolved' | 'rejected' | null;
  error: string | null;
  fileId?: string;
};

function downloadFileReducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'start download': {
      return {
        ...state,
        status: 'pending',
        fileId: action.fileId || '',
      };
    }
    case 'finish download': {
      return {
        ...state,
        status: 'resolved',
        error: null,
      };
    }
    case 'fail download': {
      return {
        ...state,
        status: 'rejected',
        error: action.error,
      };
    }
    default: {
      throw new Error('Unhandled action type in downloadFileReducer');
    }
  }
}

const initialState: State = {
  status: null,
  error: null,
};

type UseDownloadFileProps = {
  onSuccess?: () => void;
  onError?: () => void;
  reducer?: (state: State, action: Actions) => State;
};

interface DownloadHookResult {
  downloadFile: ({
    url,
    fileName,
    fileId,
  }: {
    url: string;
    fileName: string;
    fileId?: string;
  }) => Promise<void>;
  status: 'pending' | 'resolved' | 'rejected' | null;
  error: string | null;
  fileId?: string;
}

function useDownloadFile({
  onSuccess = () => {},
  onError = () => {},
  reducer = downloadFileReducer,
}: UseDownloadFileProps): DownloadHookResult {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedOrganizationId } = useUserInfo();
  async function downloadFile({
    url,
    fileName,
    fileId = '',
  }: {
    url: string;
    fileName: string;
    fileId?: string;
  }): Promise<void> {
    dispatch({ type: 'start download', fileId });

    try {
      await downloadDocument(url, fileName, selectedOrganizationId as string);
      dispatch({ type: 'finish download' });
      onSuccess();
    } catch (error: unknown) {
      let errorMessage = 'Error message';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      if (error instanceof Error) {
        // eslint-disable-next-line no-console
        console.log({
          type: 'code',
          level: 'error',
          message: `downloadFile thrown an error: ${errorMessage}`,
        });
      }
      dispatch({ type: 'fail download', error: errorMessage });
      onError();
      return Promise.reject(error);
    }
    return Promise.resolve();
  }

  return { ...state, downloadFile };
}

export { useDownloadFile };
