import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, OrganizationManagementService } from '@/open-api';

interface Config {
  onErrorCallback: (error: ApiError) => void;
  onSuccessCallback: () => void;
}

type UploadProps = {
  id: string;
  logo: Blob;
};

export const useChangeLogoOfOrganizationMutation = (
  config: Config
): UseMutationResult<unknown, ApiError, UploadProps> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, logo }: UploadProps) => {
      const response =
        await OrganizationManagementService.uploadOrganizationLogo({
          id,
          formData: { logo },
        });
      return response;
    },
    onSuccess: () => {
      config?.onSuccessCallback();
      queryClient.invalidateQueries({ queryKey: ['companyAvatar'] });
    },
    onError: (error: ApiError) => {
      config.onErrorCallback(error);
    },
  });
};
