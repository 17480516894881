import { InstructorApprovalState } from '@/open-api';
import { Icon } from '@cae/cobalt-react';

export const getStatusIcon = (status: string): JSX.Element => {
  switch (status) {
    case InstructorApprovalState.APPROVED:
      return (
        <Icon
          id="checkmark-circle-solid"
          size="sm"
          type="success"
          title="Approved"
          className="icon"
        />
      );
    case InstructorApprovalState.UNSELECTED:
      return (
        <Icon
          id="checkmark-circle-outline"
          size="sm"
          title="Unselected"
          className="icon"
        />
      );
    case 'Revoked':
      return (
        <Icon
          id="close-circle-solid"
          size="sm"
          title="Revoked"
          className="icon"
        />
      );
    case InstructorApprovalState.PENDING:
    case InstructorApprovalState.PENDING_APPROVAL_LETTER:
    case InstructorApprovalState.PENDING_REMOVAL:
      return (
        <Icon
          id="clock-outline"
          size="sm"
          title="Pending"
          className="icon icon-secondary"
        />
      );
    case InstructorApprovalState.UNAUTHORIZED:
      return (
        <Icon
          id="remove-outline"
          size="sm"
          title="Unauthorized"
          className="icon icon-secondary"
        />
      );
    default:
      return <></>;
  }
};

export const statusText = {
  [InstructorApprovalState.APPROVED]: 'Approved',
  [InstructorApprovalState.UNSELECTED]: 'Unselected',
  [InstructorApprovalState.PENDING]: 'Pending',
  [InstructorApprovalState.PENDING_APPROVAL_LETTER]: 'Pending Approval Letter',
  [InstructorApprovalState.PENDING_REMOVAL]: 'Pending Removal',
  [InstructorApprovalState.UNAUTHORIZED]: 'Unauthorized',
  Revoked: 'Revoked',
  default: 'Unknown',
};
