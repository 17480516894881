import './SFConnectError.css';
import errorLoading from '@/assets/images/sf-connect/errorLoading.svg';
import { Button } from '@cae/cobalt-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function SFConnectError(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirectToCAE = (): void => {
    sessionStorage.removeItem('sfaccountid');
    sessionStorage.removeItem('sfmastercontactid');
    navigate('/login');
  };
  const handleTryAgain = (): void => {
    const sfaccountid = sessionStorage.getItem('sfaccountid');
    const sfmastercontactid = sessionStorage.getItem('sfmastercontactid');

    window.location.href = `/sso?sfmastercontactid=${sfmastercontactid}&sfaccountid=${sfaccountid}`;
  };

  const handleOpenITTicket = (): void => {
    window.open(import.meta.env.VITE_ITSM_URL, '_blank');
  };

  useEffect(() => {
    const clearSession = (): void => {
      sessionStorage.removeItem('sfaccountid');
      sessionStorage.removeItem('sfmastercontactid');
    };

    window.addEventListener('beforeunload', clearSession);

    return () => {
      window.removeEventListener('beforeunload', clearSession);
    };
  }, [location.pathname]);

  return (
    <>
      <img src={errorLoading} alt="error-logo" />
      <h2>
        Oops! Something went wrong when we tried to add the organization to CAE
        Connect.
      </h2>
      <p>
        {' '}
        Please try again. If the problem persists, please{' '}
        <button
          type="button"
          className="anchor"
          data-toggle-menu="true"
          onClick={handleOpenITTicket}
        >
          open an IT ticket
        </button>
      </p>
      <Button
        type="button"
        className="try-again"
        variant="primary"
        onClick={handleTryAgain}
      >
        Try again
      </Button>
      <Button
        type="button"
        variant="ghost"
        className="primary-btn-color"
        onClick={handleRedirectToCAE}
      >
        Go to CAE Connect
      </Button>
    </>
  );
}

export default SFConnectError;
