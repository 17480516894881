import * as React from 'react';
import { Avatar, Icon, UserNavigation } from '@cae/cobalt-react';

import { UserMenu } from '@/features/user-menu/UserMenu';

import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';

import './UserAppNavigation.css';
import { UserAccessResponse } from '@/open-api';
import { useGetCurrentOrganization } from '@/features/frame/api/useGetCurrentOrganization';
import LogoutButton from '@/features/user-menu/components/logout-button/LogoutButton';
import { useUserInfo } from '@/contexts/userInfo';
import OrganizationSwitcherExternal from '../organization-switcher-external/OrganizationSwitcherExternal';

function getProfileInitials(profile: UserAccessResponse): string {
  const { firstName, lastName } = profile;
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }
  return '?';
}

export function UserAppNavigation(): JSX.Element {
  const triggerRef = React.useRef<React.ElementRef<'button'>>(null);
  const userMenuRef = React.useRef<React.ElementRef<typeof UserMenu>>(null);
  const logoutRef = React.useRef(null);
  const [userMenuVisible, setUserMenuVisible] = React.useState<boolean>(false);
  const [logoutVisible, setLogoutVisible] = React.useState<boolean>(false);

  const { isInternalUser } = useUserInfo();

  const toggleUserMenu = (): void => {
    setUserMenuVisible(!userMenuVisible);
  };

  const toggleLogout = (): void => {
    setLogoutVisible(!logoutVisible);
  };

  React.useEffect(() => {
    const handleClickOutside = (
      e: HTMLElementEventMap['click'] | HTMLElementEventMap['touchstart']
    ): void => {
      const triggerEl = triggerRef.current;
      const userMenuEl = userMenuRef.current;
      if (triggerEl && userMenuEl) {
        if (!triggerEl.contains(e.target as Node)) {
          setUserMenuVisible(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const { data: profile } = useGetProfileQuery();

  const { handleLogout } = useGetCurrentOrganization();

  const handleLogoutClick = (): void => {
    const broadcastChannel = new BroadcastChannel('logout-channel');
    broadcastChannel.postMessage({ type: 'logout' });

    handleLogout('redirect');
  };

  if (!profile)
    return (
      <UserNavigation>
        <button
          data-testid="showUserMenu"
          type="button"
          onClick={toggleLogout}
          ref={triggerRef}
          className="avatar-pending"
        >
          <Icon id="user-solid" size="sm" />
        </button>
        <nav
          className="co-user-nav"
          data-testid="userMenuDropdown"
          onClick={toggleLogout}
          aria-hidden={!logoutVisible}
          ref={logoutRef}
        >
          <LogoutButton handleLogout={handleLogoutClick} />
        </nav>
      </UserNavigation>
    );

  const initials = getProfileInitials(profile);

  return (
    <UserNavigation>
      {!isInternalUser && <OrganizationSwitcherExternal />}
      <button
        data-testid="showUserMenu"
        type="button"
        onClick={toggleUserMenu}
        ref={triggerRef}
      >
        <Avatar
          elevation="base"
          label={`${profile.firstName} ${profile.lastName}`}
          initials={initials}
          onClick={toggleUserMenu}
          size="sm"
        />
      </button>
      <UserMenu
        profile={profile}
        ref={userMenuRef}
        toggleUserMenu={toggleUserMenu}
        visible={userMenuVisible}
      />
    </UserNavigation>
  );
}

export default UserAppNavigation;
