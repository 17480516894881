import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  InstructorManagementService,
  InstructorProgramMetadataDto,
} from '@/open-api';

export function useInstructorMetadataQuery(): UseQueryResult<
  InstructorProgramMetadataDto,
  Error
> {
  return useQuery({
    queryKey: ['instructor', 'metadata'],
    queryFn: () => InstructorManagementService.getInstructorProgramMeta({}),
  });
}
