import { CustomerDto } from '@/open-api';
import { useSelectContactInfo } from '../../api/hooks';

const hasPhoneOrEmailValues = (
  data: Required<CustomerDto> | undefined
): string | undefined =>
  data &&
  data.accountExecutive &&
  (data.accountExecutive.email || data.accountExecutive.phoneNumber);

export function ContactInfo({
  reservationId,
}: {
  reservationId: string;
}): JSX.Element | null {
  const { data, isLoading } = useSelectContactInfo(reservationId);

  if (!hasPhoneOrEmailValues(data) && !isLoading) {
    return null;
  }

  return (
    <section className="dialog-details__fragment contact-info">
      <header>
        <h3>Contact Info</h3>
        <p>
          If you have additional questions about this reservation, please
          contact:
        </p>
      </header>
      {data ? (
        <>
          {data.accountExecutive.name ? (
            <h4>
              <b>{data.accountExecutive.name}</b> | Account Executive
            </h4>
          ) : (
            <h4>Account Executive</h4>
          )}
          {data.accountExecutive.phoneNumber && (
            <p>{data.accountExecutive.phoneNumber}</p>
          )}
          {data.accountExecutive.email && <p>{data.accountExecutive.email}</p>}
        </>
      ) : (
        !isLoading && <p>No contact information available</p>
      )}
    </section>
  );
}
