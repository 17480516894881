/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CaeCustomerAuthority {
  FAA = 'FAA',
  EASA = 'EASA',
  UK_CAA = 'UK CAA',
  TRANSPORT_CANADA = 'Transport Canada',
  ANAC_BR = 'ANAC-BR',
  ANAC_AR = 'ANAC-AR',
}
