import * as React from 'react';
import {
  AlertBanner,
  AlertMessage,
  Button,
  Dialog,
  Modal,
} from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { ChangeRequestForm } from '@/shared/components';

import type { PostResponseStatus } from '@/shared/types';

import { FieldValues, SubmitHandler } from 'react-hook-form';
import { ApiError, CustomerSupportManagementService } from '@/open-api';

import './SupportRequest.css';
import { useUserInfo } from '@/contexts/userInfo';

function StatusSplash({
  onRefresh,
  status,
}: {
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}): JSX.Element {
  return (
    <>
      {status.type === 'success' && (
        <AlertMessage type="success" heading={status.message}>
          <p>
            Our team will get back to you within two business days with a
            follow-up on your request. Note that requests are subject to change
            depending on availability, feasibility and other factors.
          </p>
          <p>
            <button type="button" className="anchor" onClick={onRefresh}>
              Create another request?
            </button>
          </p>
        </AlertMessage>
      )}
      {status.type === 'error' && (
        <AlertBanner
          type="error"
          heading={status.heading}
          style={{ marginBottom: '1.5rem' }}
        >
          <p>{status.message}</p>
        </AlertBanner>
      )}
      {status.type !== 'success' && (
        <AlertMessage type="info" heading="" inline>
          Our team will get back to you within two business days with a
          follow-up on your request. Note that requests are subject to change
          depending on availability, feasibility and other factors.
        </AlertMessage>
      )}
    </>
  );
}

function PageHeader({
  onRefresh,
  status,
}: {
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  status: PostResponseStatus;
}): JSX.Element {
  return (
    <header>
      <h2>Request</h2>
      <StatusSplash onRefresh={onRefresh} status={status} />
    </header>
  );
}

interface Props {
  onCancel: (e: React.SyntheticEvent<HTMLDialogElement | Element>) => void;
  open?: boolean;
}

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}

export function SupportRequest({
  onCancel,
  open = false,
}: Props): JSX.Element | null {
  const { width } = useViewportSize();
  const [key, setKey] = React.useState<string>(`key-${Date.now()}`);
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const [status, setStatus] = React.useState<PostResponseStatus>({
    type: 'idle',
  });
  const { selectedOrganizationId } = useUserInfo();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(true);
  };
  React.useEffect(() => {
    if (open) setStatus({ type: 'idle' });
  }, [open]);

  const onRefresh = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setStatus({ type: 'idle' });
    setKey(`key-${Date.now()}`);
  };

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setStatus({ type: 'pending' });
    const { title, details: text } = data;

    try {
      if (selectedOrganizationId) {
        await CustomerSupportManagementService.sendSupportRequest({
          organizationId: selectedOrganizationId,
          requestBody: {
            title,
            text,
          },
        });
        setStatus({
          type: 'success',
          heading: '',
          message: 'Request submitted successfully!',
        });
      } else {
        throw Error('no organization Id');
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setStatus({
          type: 'error',
          heading: 'API error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      } else {
        setStatus({
          type: 'error',
          heading: 'Oops, an error occurred',
          message:
            'Your message has not yet been sent. Try again, or contact the administrators.',
        });
      }
    }
  };
  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    onCancel(e);
  };
  if (!open) return null;

  return (
    <Modal
      animate
      dismissible
      onCancel={handleCancel}
      open={open}
      placement={width < 480 ? 'bottom' : 'right'}
      size="md"
    >
      <section className="support-request" key={key}>
        <PageHeader onRefresh={onRefresh} status={status} />
        <ChangeRequestForm
          initialValues={{ title: '', details: '' }}
          onCancel={handleCancel}
          onSubmit={onSubmit}
          status={status}
        />
      </section>
      <Dialog
        modal
        className=""
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard change request?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </Modal>
  );
}
