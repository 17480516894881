import { useLocation } from 'react-router-dom';
import multipleAccounts from '@/assets/images/sf-connect/multipleAccounts.svg';
import { Button, Icon } from '@cae/cobalt-react';
import './SFConnectMultipleAccounts.css';

function SFConnectMultipleAccounts(): JSX.Element {
  const { state } = useLocation();
  const sfResponse = state?.userData;
  const handleOpenITTicket = (): void => {
    window.open(import.meta.env.VITE_ITSM_URL, '_blank');
  };
  const isUserIdValid = sfResponse?.isUserIdValid;
  const isUserEmailValid = sfResponse?.isUserEmailValid;
  const sfmastercontactid = sessionStorage.getItem('sfmastercontactid');
  const fullName =
    sfResponse?.firstName +
    ' ' +
    sfResponse?.middleName +
    ' ' +
    sfResponse?.lastName;
  const companyName = sfResponse?.organizationName;
  const renderDetailsEmailsUsers = (): JSX.Element => {
    if (isUserIdValid && !isUserEmailValid) {
      return (
        <>
          <div className="sf-connect-multiple-accounts--email-details-main">
            <div className="sf-connect-multiple-accounts--sf-email-details">
              <div className="sf-connect-multiple-accounts--icon">
                <Icon id="user-management-outline" />
              </div>
              <div>
                <h4 className="sf-connect-multiple-accounts--h4">
                  Email in Salesforce
                </h4>
                <p>{sfResponse?.emailInUserService}</p>
              </div>
            </div>

            <Icon id="switch-outline" />

            <div className="sf-connect-multiple-accounts--portal-email-details">
              <div className="sf-connect-multiple-accounts--icon">
                <Icon id="user-management-outline" />
              </div>
              <div>
                <h4 className="sf-connect-multiple-accounts--h4">
                  Email in CAE Account
                </h4>
                <p>{sfResponse?.email}</p>
              </div>
            </div>
          </div>
          <p>
            To resolve this, you may change the email of the Salesforce contact
            to the email used in CAE Account.
          </p>
          <p>
            If, instead, you want to change the CAE Account email, please open
            an IT ticket requesting to change the email of
            {`${sfResponse?.firstName} ${sfResponse?.lastName}`} in CAE Account
            to the Salesforce email.
          </p>
        </>
      );
    } else if (!isUserIdValid && isUserEmailValid) {
      return (
        <>
          <div className="sf-connect-multiple-users-details-main">
            <div className="sf-connect-multiple-users--users-basic-details">
              <div className="sf-connect-multiple-users--icon">
                <Icon id="user-management-outline" />
              </div>
              <div>
                <h4 className="sf-connect-multiple-accounts--h4">{fullName}</h4>
                <p>({sfmastercontactid})</p>
              </div>
            </div>
            <div className="sf-connect-multiple-users-accounts--company-email-details">
              <div>
                <div>
                  <p>Company</p>
                  <h4 className="sf-connect-multiple-accounts--h4">
                    {companyName}
                  </h4>
                </div>
              </div>
              <div>
                <div>
                  <p>Email in CAE Account</p>
                  <h4 className="sf-connect-multiple-accounts--h4">
                    {sfResponse?.email}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <p>
            To resolve this, you may change the email of the Salesforce contact
            you selected ({sfResponse?.firstName} {sfResponse?.lastName}) to a
            new email.
          </p>
          <p>
            Alternatively, you may open an IT ticket requesting to change the
            email of the existing contact or request that the two contacts be
            merged.
          </p>
        </>
      );
    }
    return <></>;
  };
  return (
    <>
      <img src={multipleAccounts} alt="multiple-accounts-logo" />
      <h2>
        Oh no! It looks like the contact you selected already has a CAE Account
        with a different email.
      </h2>
      {renderDetailsEmailsUsers()}
      <Button
        type="button"
        className="try-again"
        variant="primary"
        onClick={handleOpenITTicket}
      >
        Open a Ticket
      </Button>
    </>
  );
}

export default SFConnectMultipleAccounts;
