import { Avatar, Icon } from '@cae/cobalt-react';
import './CompanyAvatar.css';
import { useEffect, useState } from 'react';
import CompanyEditAvatar from '../company-edit-avatar/CompanyEditAvatar';
import { useSelectedOrganizationLogoQuery } from '../../api/useSelectedOrganizationLogoQuery';
import { useUserInfo } from '@/contexts/userInfo';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { useLocation, useNavigate } from 'react-router-dom';
interface Props {
  readonly name: string | undefined;
}

export function CompanyAvatar({ name }: Props): JSX.Element {
  const [isEditAvatarOpen, setIsEditAvatarOpen] = useState<boolean>(false);
  const { selectedOrganization } = useUserInfo();
  const { data: logoImage } = useSelectedOrganizationLogoQuery(
    selectedOrganization?.id
  );
  const [image, setImage] = useState<string | undefined>(undefined);
  const currentRoles = useCurrentRoles();
  const navigate = useNavigate();
  const location = useLocation();
  const [fromPath, setFromPath] = useState<string>('/home');

  const handleGoBack = (): void => {
    navigate(fromPath);
  };
  const openEditAvatarModal = (): void => {
    setIsEditAvatarOpen(true);
  };

  useEffect(() => {
    if (location.state) {
      setFromPath(location.state.from);
    }
  }, [location.state]);

  useEffect(() => {
    if (logoImage && logoImage.size > 0) {
      const url = URL.createObjectURL(logoImage);
      setImage(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setImage(undefined);
    }
  }, [logoImage]);

  useEffect(() => {
    return () => {
      if (image) URL.revokeObjectURL(image);
    };
  }, [image]);

  return (
    <div className="company-avatar-main">
      <button className="company-avatar__navigate-home" onClick={handleGoBack}>
        <Icon id="arrow-left-solid" size="xs" />
        <p>Back to app</p>
      </button>
      <div className="company-avatar__heading-container">
        <h1 className="company-avatar__company-heading">Company settings</h1>
        <h3 className="company-avatar__company-sub-heading">
          Here, you can set all of your company settings.
        </h3>
      </div>
      <div className="company-avatar__details">
        <div className="company-avatar__modal-open">
          <Avatar
            as="button"
            className="avatar"
            elevation="raised"
            image={image}
            size="lg"
            label={name ? name[0] : '-'}
            initials={name ? name[0] : '-'}
            variant="round"
          />
          {currentRoles && checkRoleMatch(currentRoles, 'Admin') && (
            <button
              onClick={() => openEditAvatarModal()}
              className="company-avatar__icon-container"
              data-testid="edit-company-logo-button"
            >
              <Icon
                id="edit-outline"
                size="xs"
                className="company-avatart__pencil-icon"
              />
            </button>
          )}
        </div>
        <CompanyEditAvatar
          isEditAvatarOpen={isEditAvatarOpen}
          setIsEditAvatarOpen={setIsEditAvatarOpen}
        />
        <div>
          <div className="company-avatar__title-box">
            <h1 className="company-avatar__company-heading">{name}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyAvatar;
