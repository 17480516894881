import AccessPass from '@/features/accesspass-mfe/AccessPass';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate } from 'react-router-dom';

const AccessPassMfeRoute: React.FC = () => {
  const { accessPageEnabledWebApp } = useFlags();
  return accessPageEnabledWebApp ? (
    <AccessPass />
  ) : (
    <Navigate to="/home" replace />
  );
};

export const accesspassMfeRoutes = {
  path: 'accesspass',
  element: <AccessPassMfeRoute />,
};
