import * as React from 'react';
import { Button, Icon } from '@cae/cobalt-react';
import Spinner from '@/shared/components/file-upload/spinner/Spinner';

type ExportReservationsButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  label?: string;
};

export function ExportReservationsButton({
  onClick,
  loading,
  label,
}: Readonly<ExportReservationsButtonProps>): JSX.Element {
  return (
    <Button
      type="button"
      variant="ghost"
      size="sm"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <Spinner /> : <Icon id="file-export-outline" size="sm"></Icon>}
      <span className="label">{label}</span>
    </Button>
  );
}
