import { Link, useParams } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';
import { useGetUserById } from '../../api/useGetUserById';

export function BackNavigation(): null | JSX.Element {
  const { userId = '' } = useParams();
  const { data } = useGetUserById(userId);
  return (
    <nav>
      <Link to={`/users/${userId}`}>
        <Icon id="arrow-left-solid" size="sm" />
        {`Edit User ( ${data ? data.firstName : '..'} ${data ? data.lastName : '..'} )`}
      </Link>
    </nav>
  );
}
