import type { ViewedDocument } from '@/features/documents/types';
import type { DocumentDto } from '@/open-api';

export function setDocumentViewed(document: DocumentDto): void {
  const d = localStorage.getItem('viewedDocuments');

  const viewedDocuments: ViewedDocument[] = d ? JSON.parse(d) : [];

  if (viewedDocuments.find(x => x.id === document.id)) return;

  const v: ViewedDocument = {
    id: document.id,
    name: document.name,
    lastViewedAt: new Date(),
  };

  if (viewedDocuments.length > 9) {
    viewedDocuments.pop();
  }

  localStorage.setItem(
    'viewedDocuments',
    !viewedDocuments
      ? JSON.stringify([v])
      : JSON.stringify([v, ...viewedDocuments])
  );
}
