import { Button, Card } from '@cae/cobalt-react';
import './InvoicePaymentsCardsList.css';
import { useState } from 'react';
import { InvoiceDetailsResponse } from '@/open-api';
import { selectInvoicesReservations } from '../../api/selectors';
type Props = {
  readonly invoiceDetails: InvoiceDetailsResponse;
};

export function InvoicePaymentsCardsList({
  invoiceDetails,
}: Props): JSX.Element {
  const [showAll, setShowAll] = useState<boolean>(false);
  const reservations = selectInvoicesReservations(invoiceDetails);
  const lastIdx = showAll ? reservations.length : 3;
  return (
    <div className="Invoice-Payments__card-container">
      <h2>Invoice Details</h2>
      {reservations.slice(0, lastIdx).map(reservation => (
        <Card
          className="InvoicePayments__card"
          key={reservation.schedulerReservationId}
        >
          <section className="dialog-details__fragment quick-info">
            <h3>Reservation ID: {reservation.schedulerReservationId}</h3>
            <ul>
              <li>
                <h6>Course</h6>
                <p>{reservation.courseName ?? '-'}</p>
              </li>
              <li>
                <h6>Pilot name</h6>
                <p>{reservation.pilotName}</p>
              </li>
              <li>
                <h6>Training start date</h6>
                <p>{reservation.trainingStartDate}</p>
              </li>
              <li>
                <h6>Platform</h6>
                <p>{reservation.platform}</p>
              </li>
              <li>
                <h6>Location</h6>
                <p>{reservation.location ?? '-'}</p>
              </li>
            </ul>
          </section>
        </Card>
      ))}
      {reservations && reservations.length > 3 && (
        <Button
          variant="transparent"
          onClick={() => setShowAll(prevState => !prevState)}
        >
          {showAll ? 'Show Less' : `Load more(+${reservations.length - 3})`}
        </Button>
      )}
    </div>
  );
}
