interface CurrencyFormatProps {
  value: number;
  currency: string;
}

const CurrencyFormat: React.FC<CurrencyFormatProps> = ({
  value,
  currency,
}: CurrencyFormatProps): string => {
  let symbol = '';
  switch (currency) {
    case 'USD':
      symbol = '$';
      break;
    case 'EUR':
      symbol = '€';
      break;
    case 'CAD':
      symbol = '$';
      break;

    default:
      symbol = '$';
      break;
  }

  const formattedValue = value
    .toFixed(2)
    .replace(/\d(?=(?:\d{3})+(?!\d))/g, '$&,');

  return `${symbol} ${formattedValue} ${currency}`;
};

export default CurrencyFormat;
