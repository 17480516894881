import { Tag } from '@cae/cobalt-react';

const statusColorsMap: {
  [key: string]: 'standard' | 'blue' | 'green';
} = {
  submitted: 'standard',
  'in-review': 'blue',
  completed: 'green',
};

export function DocumentStatus({
  value,
}: Readonly<{
  value: { status: string; shortMessage: string };
}>): JSX.Element {
  if (!value) return <></>;

  return (
    <Tag color={statusColorsMap[value.status]} compact>
      {value.shortMessage}
    </Tag>
  );
}

DocumentStatus.displayName = 'DocumentStatus';
