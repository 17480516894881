import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, OrganizationManagementService } from '@/open-api';

interface Config {
  onErrorCallback: (error: ApiError) => void;
  onSuccessCallback: () => void;
}

export const useDeleteLogoFromOrganizationMutation = (
  config: Config
): UseMutationResult<unknown, ApiError, { id: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const response =
        await OrganizationManagementService.deleteOrganizationLogo({
          id,
        });
      return response;
    },
    onSuccess: () => {
      config.onSuccessCallback();
      queryClient.invalidateQueries({ queryKey: ['companyAvatar'] });
    },
    onError: (error: ApiError) => {
      config.onErrorCallback(error);
    },
  });
};
