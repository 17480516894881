import { Link } from 'react-router-dom';
import { Card, Icon, Tag } from '@cae/cobalt-react';

import {
  DataLoadingError,
  NoData,
  DataLoadingGrid,
} from '@/shared/components/empty-states';

import './RecordDetailsCardList.css';
import useRecordDetailsTableQuery from '../../api/useRecordDetailsTableQuery';

function RecordDetailsCardList(): JSX.Element {
  const { data, status } = useRecordDetailsTableQuery();

  return (
    <div className="record-table-action">
      {status === 'loading' && (
        <DataLoadingGrid
          numberColumns={5}
          numberRows={6}
          style={{ marginTop: '1rem' }}
        />
      )}
      {status === 'error' && (
        <DataLoadingError heading="Error loading your next actions" />
      )}
      {status === 'success' && data.items && data.items.length < 1 && (
        <NoData
          heading="Great!"
          message="Looks like you are done with everything"
        />
      )}
      {status === 'success' && data.items && data.items.length > 0 && (
        <div className="card-container">
          {data.items.map(d => (
            <Card key={`card-${d.id}`} className="record-list---card">
              <div className="card-div-flex">
                <h4>Res.ID - {d.id}</h4>

                <p style={{ marginTop: 20 }}>
                  {' '}
                  <Tag compact>{d.status || ''}</Tag>
                </p>
              </div>
              <div className="card-body">
                <div className="course-info">
                  <div>
                    <p>Course</p>
                  </div>
                  <p>{d.courseName}</p>
                </div>

                <p style={{ marginTop: 20 }}>
                  <Link to={`/records/${d.id}`}>
                    <Icon id="arrow-right-outline" size="sm" />
                  </Link>
                </p>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

export { RecordDetailsCardList };
