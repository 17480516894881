export const roleOptions = [
  {
    helperText:
      "Fully control of company's accesses and data for records, training information,  documents and billing.",
    value: '219f63c1-679d-420b-b6b3-a2d836771454',
  },
  {
    helperText:
      'View invoices, make payments and access other financial functions.',
    value: 'fe83c3ad-c2e1-436f-b502-2dcaa411a741',
  },
  {
    helperText:
      'View and manage bookings, provide data for training and access documents.',
    value: '47de38dd-d08a-45b9-8ebb-d18b3dc900d6',
  },
  {
    helperText: 'View, download and manage crew records.',
    value: 'c1adc0b8-1b8d-4346-8a91-46dea6e16c70',
  },
];
