import { Link } from 'react-router-dom';
import { Card, Icon } from '@cae/cobalt-react';

import { useUserInfo } from '@/contexts/userInfo';
import { conditionalElement } from '@/shared/helpers/conditionalElement';

import './HomeCards.css';
import { CaeCustomerAuthority } from '@/open-api';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Card = {
  key: string;
  actionLabel: string;
  heading: string;
  path: string;
};

const isFaa = (authorities: CaeCustomerAuthority[]): boolean =>
  authorities.includes(CaeCustomerAuthority.FAA);

type GetCardsProps = {
  shouldDisplayCompanyCard: boolean;
};

function getCards({ shouldDisplayCompanyCard }: GetCardsProps): Card[] {
  return [
    {
      key: 'easa-program',
      actionLabel: 'Learn more',
      heading: 'You can get a new EASA program approved?',
      path: '/documents/overview/easa-program',
    },
    {
      key: 'faa-part135-program',
      actionLabel: 'Learn more',
      heading: 'You can get a new FAA Part 135 program approved?',
      path: '/documents/overview/faa-part135-program',
    },
    ...conditionalElement(shouldDisplayCompanyCard, {
      key: 'company',
      actionLabel: 'Go to your documents',
      heading: 'You can view your organization documents we have on file?',
      path: '/documents/company',
    }),
    {
      key: 'cae',
      actionLabel: 'Browse CAE documents',
      heading: "CAE's template documents are provided?",
      path: '/documents/cae',
    },
    {
      key: 'submitted',
      actionLabel: 'View submitted documents',
      heading: 'Statuses of previously submitted documents are available?',
      path: '/documents/submitted',
    },
  ];
}

export function HomeCards(): JSX.Element {
  const { authorities } = useUserInfo();
  const { documentauthorities } = useFlags();
  const displayAllCards = !documentauthorities;
  return (
    <div className="home-cards">
      <h3>Did you know…</h3>
      <div className="card-grid">
        {getCards({
          shouldDisplayCompanyCard: displayAllCards || isFaa(authorities),
        }).map(card => (
          <Link to={card.path} key={`card-${card.key}`}>
            <Card>
              <h4>{card.heading}</h4>
              <p className="link">
                {card.actionLabel} <Icon id="arrow-right-solid" size="sm" />
              </p>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
