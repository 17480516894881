import { InstructorsPage } from '@/pages/instructors';
import {
  ApprovedInstructorsReportPage,
  CaReportPage,
  IpReportPage,
  RecurrentReportPage,
} from '@/pages/instructors-reports';

export const instructorManagementRoutes = {
  path: 'instructors',
  element: <InstructorsPage />,
  children: [
    {
      path: 'ca-report',
      element: <CaReportPage />,
    },
    {
      path: 'ip-report',
      element: <IpReportPage />,
    },
    {
      path: 'approved-instructors-report',
      element: <ApprovedInstructorsReportPage />,
    },
    {
      path: 'recurrent-report',
      element: <RecurrentReportPage />,
    },
  ],
};
