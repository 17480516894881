import SelectCustomer from '@/assets/images/welcome/select-customer.svg';
import { Button, Modal } from '@cae/cobalt-react';
import { useState } from 'react';
import './SFConnectUnauthorized.css';
import { ApplicationFrame } from '@/features/frame';
import { removeSessionStorageItem } from '@/shared/helpers/sessionStorage';

type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
};

function ModalActions({
  handleCancel,
}: Readonly<ModalActionsProps>): JSX.Element {
  return (
    <div>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Close
      </Button>
    </div>
  );
}

function SFConnectUnauthorized(): JSX.Element {
  const [isShowModal, setIsShowModal] = useState(true);
  removeSessionStorageItem('sfmastercontactid');
  removeSessionStorageItem('sfaccountid');
  const closeModal = (): void => {
    setIsShowModal(false);
  };
  const handleOpenITTicket = (): void => {
    window.open(import.meta.env.VITE_ITSM_URL, '_blank');
  };
  return (
    <ApplicationFrame>
      <div className="sf-connect--unauthorized-container">
        <img src={SelectCustomer} alt="customer illustration " />
        <div>
          <h1>Default selected a customer</h1>
          <p>
            To view as a customer, select the name of the customer from the
            dropdown menu at the top left of the navigation.
          </p>
        </div>

        <Modal
          animate
          actions={ModalActions}
          onCancel={closeModal}
          open={isShowModal}
          placement="center"
          className="sf-connect-unauthorized--dialog"
          size="md"
        >
          <h1>
            Oh no! You need to have an admin role to add users to CAE Connect.
          </h1>

          <p>
            To get admin access to CAE Connect, please{' '}
            <button
              type="button"
              className="anchor"
              data-toggle-menu="true"
              onClick={handleOpenITTicket}
            >
              open an IT ticket
            </button>{' '}
            requesting to be added to the following group:
            CustomerTrainingPortal-SuperAdmin
          </p>
        </Modal>
      </div>
    </ApplicationFrame>
  );
}

export default SFConnectUnauthorized;
