import { ControlField } from '@/shared/components/form-adapters';
import { Button, Dropdown } from '@cae/cobalt-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useEditCompanyProfileMutation } from '../../api/useEditCompanyProfileMutation';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { useUserInfo } from '@/contexts/userInfo';
import {
  FormValues,
  OperatingRegionSchema,
} from '../../helpers/companyFormSchema';
import { useGetOperatingRegionQuery } from '../../api/useGetOperatingRegionQuery';
type Props = {
  readonly defaultValues: { operatingRegion?: string };
  readonly handleClose: () => void;
};

export function CompanyOperatingRegionForm({
  defaultValues,
  handleClose,
}: Props): JSX.Element {
  const { selectedOrganization } = useUserInfo();
  const { data: operatingRegions = [] } = useGetOperatingRegionQuery();
  const operatingRegionOptions = operatingRegions.map(a => ({
    label: a,
    value: a,
  }));
  const f = useForm<FormValues>({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(OperatingRegionSchema),
  });
  const { isValid, isDirty } = f.formState;

  const { mutate: OperatigRegionMutate, isLoading } =
    useEditCompanyProfileMutation({
      onSuccessCallback: () => {
        handleClose();
        toastMessages.success('Operating region edited successfully');
      },
      onErrorCallback: () => {
        handleClose();
        toastMessages.error('Error in updating operating region');
      },
    });

  const onSubmitForm = async (data: {
    operatingRegion?: string;
  }): Promise<void> => {
    const organizationId = selectedOrganization?.id ?? '';
    OperatigRegionMutate({ id: organizationId, data });
  };
  const handleConfirm = (): void => {
    f.handleSubmit(onSubmitForm)();
  };
  return (
    <form onSubmit={f.handleSubmit(onSubmitForm)} className="company-info">
      <h5>Company information</h5>
      <h1>Operating Region</h1>
      <div className="__authorities">
        <ControlField
          is={Dropdown}
          id="operating-region"
          data-testid="select-authorities"
          name="operatingRegion"
          label="Operating region"
          options={operatingRegionOptions}
          valueDisplay="chips"
          control={f.control}
        />
      </div>
      <div className="__footer">
        <Button
          type="button"
          disabled={!isValid || !isDirty || isLoading}
          variant="primary"
          onClick={handleConfirm}
          pending={isLoading}
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          disabled={isLoading}
          type="button"
          variant="secondary"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
