import { Button } from '@cae/cobalt-react';
import { ReservationFilterOptionsDto } from '@/open-api';
import { FilterSection, FilterDateRangePicker } from '@/shared/components';

import {
  ReservationFilters,
  useFilterModalReducer,
} from './useFilterModalReducer';

import {
  COURSE_TYPE,
  ETCA_STATUS,
  RECORDS,
  SCHEDULED_STATUS,
  TSA_STATUS,
} from './filtersConsts';

export type FilterModalProps = {
  filterOptions: ReservationFilterOptionsDto | undefined;
  filters: ReservationFilters;
  resetFilters: () => void;
  toggleFilterSection: () => void;
  toggleAllFilters: (selected: ReservationFilters) => void;
};

function compareArrays(
  filters: string[] | undefined,
  selectedValues: string[]
): boolean {
  if (!filters && selectedValues.length === 0) return true;
  if (!filters || !selectedValues) return false;
  if (filters.length !== selectedValues.length) return false;
  return filters.every(item => selectedValues.includes(item));
}

export function FilterModal({
  filterOptions,
  filters,
  toggleAllFilters,
  resetFilters,
  toggleFilterSection,
}: FilterModalProps): JSX.Element {
  const {
    selectedFilters,
    clearSelectedFilters,
    changeSelectedLocations,
    changeSelectedPlatforms,
    changeSelectedCourseTypes,
    changeSelectedEtcaStatus,
    changeSelectedTsaStatus,
    changeSelectedScheduledStatus,
    changeSelectedRecords,
    changeDateRange,
    changeSelectedAuthorities,
  } = useFilterModalReducer(filters);

  const handleApply = (): void => {
    toggleAllFilters(selectedFilters);
    toggleFilterSection();
  };

  const handleReset = (): void => {
    resetFilters();
    clearSelectedFilters();
  };

  return (
    <div className="filter-list">
      <h3>Filters</h3>
      <div className="filter-sections">
        <FilterDateRangePicker
          onSelect={changeDateRange}
          selected={selectedFilters.dateRange}
          title="Start date range"
        />
        <FilterSection
          label="Course type"
          options={COURSE_TYPE}
          selected={selectedFilters.courseTypes}
          onSelect={changeSelectedCourseTypes}
          showSearch={false}
        />
        <FilterSection
          label="Locations"
          searchPlaceholder="Search Locations"
          options={filterOptions?.location}
          selected={selectedFilters.locations}
          onSelect={changeSelectedLocations}
        />
        <FilterSection
          label="Platforms"
          searchPlaceholder="Search Platforms"
          options={filterOptions?.platform}
          selected={selectedFilters.platforms}
          onSelect={changeSelectedPlatforms}
        />
        <FilterSection
          label="eTCA status"
          options={ETCA_STATUS}
          selected={selectedFilters.etcaStatus}
          onSelect={changeSelectedEtcaStatus}
          showSearch={false}
        />
        <FilterSection
          label="TSA status"
          options={TSA_STATUS}
          selected={selectedFilters.tsaStatus}
          onSelect={changeSelectedTsaStatus}
          showSearch={false}
        />
        <FilterSection
          label="Schedule status"
          options={SCHEDULED_STATUS}
          selected={selectedFilters.scheduledStatus}
          onSelect={changeSelectedScheduledStatus}
          showSearch={false}
        />
        <FilterSection
          label="Records"
          options={RECORDS}
          selected={selectedFilters.records}
          onSelect={changeSelectedRecords}
          showSearch={false}
        />
        <FilterSection
          className="last"
          label="Authorities"
          options={filterOptions?.regulatoryAgencies}
          selected={selectedFilters.regulatoryAgencies}
          onSelect={changeSelectedAuthorities}
          showSearch={false}
        />
      </div>
      <div className="actions">
        <Button variant="ghost" onClick={handleReset}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleApply}
          disabled={
            compareArrays(filters.locations, selectedFilters.locations) &&
            compareArrays(filters.platforms, selectedFilters.platforms) &&
            compareArrays(filters.courseTypes, selectedFilters.courseTypes) &&
            compareArrays(filters.etcaStatus, selectedFilters.etcaStatus) &&
            compareArrays(filters.tsaStatus, selectedFilters.tsaStatus) &&
            compareArrays(
              filters.scheduledStatus,
              selectedFilters.scheduledStatus
            ) &&
            compareArrays(filters.records, selectedFilters.records) &&
            compareArrays(
              filters.dateRange.map(date => (date ? date.toDateString() : '')),
              selectedFilters.dateRange.map(date =>
                date ? date.toDateString() : ''
              )
            ) &&
            compareArrays(
              filters.regulatoryAgencies,
              selectedFilters.regulatoryAgencies
            )
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default FilterModal;
