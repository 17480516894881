import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { ModuleManagementService, ModuleResponse } from '@/open-api';

export function useGetModulesQuery(): UseQueryResult<
  ModuleResponse[],
  unknown
> {
  return useQuery({
    queryKey: ['getAllModules'],
    queryFn: () => ModuleManagementService.getAllModules(),
  });
}
