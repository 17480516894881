import { DocumentsManagementService } from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import type { ViewedDocument } from '../types';
import { useUserInfo } from '@/contexts/userInfo';

export function useRecentlyViewedDocumentsQuery(
  userId: string | undefined
): UseQueryResult<ViewedDocument[]> {
  const { selectedOrganizationId } = useUserInfo();
  return useQuery({
    queryKey: ['viewedDocuments', userId],
    queryFn: () =>
      DocumentsManagementService.getViewedDocuments({
        userId: userId!,
        organizationId: selectedOrganizationId as string,
      }),
    enabled: !!userId,
  });
}
