import { useTranslation } from 'react-i18next';
import './LandingFeatures.css';

function LandingFeatures(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="landing-features">
      <div className="landing-features-swirls first"></div>
      <div className="landing-features-swirls second"></div>
      <div className="landing-features-swirls third"></div>
      <div className="landing-features-top">
        <img
          src="/img/landing/floating-calendar.png"
          className="floating-calendar"
          alt="Floating calendar"
        />

        <span className="landing-features-top-cards">
          <img
            className="reservation-card"
            src="/img/landing/provisional-reservations-card.png"
            alt="Provisional Reservations at risk card"
          />
          <img
            className="reservation-card"
            src="/img/landing/missing-etca-card.png"
            alt="Missing eTCA card"
          />
        </span>
      </div>
      <div className="landing-features-portal">
        <h2>
          <span className="landing-features-portal-title">
            {t('landingPage.featuresTitle1')}
          </span>{' '}
          {t('landingPage.featuresTitle2')}
        </h2>
        <div className="landing-features-portal-cards">
          <img
            src="/img/landing/floating-reservations.png"
            alt="Reservations dashboard"
            className="floating-reservations"
          />
          <img
            src="/img/landing/floating-upload.png"
            alt="Submit documents"
            className="floating-upload"
          />
        </div>
      </div>
      <div className="landing-features reservations">
        <div className="landing-features-img">
          <img
            src="/img/landing/reservation-desktop.png"
            alt="Reservations page"
          />
        </div>

        <div className="landing-features-info">
          <img
            className="feature-icon"
            src="/img/landing/reservation-icon.svg"
            alt="Reservation icon"
          />
          <h2>{t('landingPage.reservationsFeature')}</h2>
          <p>{t('landingPage.reservationsFeatureDescription')}</p>
        </div>
      </div>

      <div className="landing-features records">
        <div className="landing-features-info records">
          <img
            className="feature-icon"
            src="/img/landing/records-icon.svg"
            alt="Records icon"
          />
          <h2>{t('landingPage.recordsFeature')}</h2>
          <p>{t('landingPage.recordsFeatureDescription')}</p>
        </div>

        <div className="landing-features-img">
          <img src="/img/landing/records-desktop.png" alt="Records page" />
        </div>
      </div>

      <div className="landing-features documents">
        <div className="landing-features-img">
          <img src="/img/landing/documents-desktop.png" alt="Documents page" />
        </div>

        <div className="landing-features-info">
          <img
            className="feature-icon"
            src="/img/landing/documents-icon.svg"
            alt="Documents icon"
          />
          <h2>{t('landingPage.documentsFeature')}</h2>
          <p>{t('landingPage.documentsFeatureDescription')}</p>
        </div>
      </div>

      <div className="landing-features-linear"></div>
    </div>
  );
}

export default LandingFeatures;
