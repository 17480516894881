import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Icon, Tag } from '@cae/cobalt-react';

import { RecordResponseDto } from '@/open-api';
import getFormattedDate from '@/shared/helpers/getFormattedDate';
import { RecordModel, RecordStatus } from '@/features/records';

const columnHelper = createColumnHelper<RecordResponseDto>();

/* ---------------------- Individual column definitions --------------------- */

const columnReservationId = columnHelper.accessor('schedulerReservationId', {
  header: 'Reservation',
  cell: props => props.row.original?.schedulerReservationId,
});

const columnClientName = columnHelper.accessor('clientName', {
  header: 'Name',
});

const columnPlatformName = columnHelper.accessor('platform', {
  header: 'Platform',
});

const columnCourseName = columnHelper.accessor('courseName', {
  header: 'Course',
});

const columnStartName = columnHelper.accessor('startDate', {
  header: 'Start date',
  cell: props => getFormattedDate(props.row.original.startDate, 'dd-MMM-yyyy'),
});

const columnEndDate = columnHelper.accessor('endDate', {
  header: 'End date',
  cell: props => getFormattedDate(props.row.original.endDate, 'dd-MMM-yyyy'),
});

const columnLocation = columnHelper.accessor('location', {
  header: 'Location',
});

const columnStatus = columnHelper.display({
  id: 'records',
  header: 'Status',
  cell: props => {
    const recordStatus: RecordStatus =
      props.row.original.records?.status ?? 'Unknown';
    const tag = RecordModel.mapRecordStatusToTagStatus(recordStatus);
    return (
      <Tag
        className="recordStatus"
        color={tag.color}
        title={tag.longMessage}
        compact
      >
        {tag.label}
      </Tag>
    );
  },
});

// In next MVP we will get record published date,  for now commenting
// const columnPublishedOn = columnHelper.accessor('recordPublishedOn', {
//   header: 'Record published on',
//   cell: getFormattedDate,
// });

const columnDownloadIcon = columnHelper.display({
  id: 'download',
  header: '',
  cell: props => {
    const d = props.row.original.status;
    if (d === 'Available') {
      return <Icon id="download-outline" />;
    }
    return null;
  },
});
/* ----------------- Each tab has a variable set of columns ----------------- */

export const allColumns: ColumnDef<RecordResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnStatus,
  columnDownloadIcon,
];

export const availableColumns: ColumnDef<RecordResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnLocation,
  columnDownloadIcon,
];

export const hardCopyOnlyColumns: ColumnDef<RecordResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnDownloadIcon,
];

export const pendingColumns: ColumnDef<RecordResponseDto, string>[] = [
  columnReservationId,
  columnClientName,
  columnPlatformName,
  columnCourseName,
  columnStartName,
  columnEndDate,
  columnLocation,
  columnDownloadIcon,
];
