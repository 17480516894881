import {
  ContactInformation,
  CustomerSupportManagementService,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

function useCustomerCareContactsQuery(
  selectedOrganization?: string
): UseQueryResult<ContactInformation, Error> {
  return useQuery<ContactInformation, Error>({
    queryKey: ['customerCareContacts', selectedOrganization],
    queryFn: () => CustomerSupportManagementService.getContactInformation({}),
  });
}

export default useCustomerCareContactsQuery;
