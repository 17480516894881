import { InvoiceDetailsResponse, AccountExecutiveContact } from '@/open-api';
import {
  InvoiceContactInfo,
  InvoiceFragmentsType,
  InvoiceReservations,
} from './selectorsType';
import getFormattedDate from '@/shared/helpers/getFormattedDate';
import { transformInvoiceReservation } from './transforms';

export function selectInvoiceFragments(
  data: InvoiceDetailsResponse
): InvoiceFragmentsType {
  return {
    type: data.type,
    dueDate:
      getFormattedDate(data?.dueDate?.split('T')[0], 'dd-MMM-yyyy') ?? '-',
    issuedOn:
      getFormattedDate(data?.issuedOn?.split('T')[0], 'dd-MMM-yyyy') ?? '-',
    amount: data?.currency && data?.amount ? data.amount : '-',
    currency: data?.currency,
    legalEntityName: data?.legalEntityName ?? '-',
    poNumbers: data?.poNumbers?.filter(Boolean).join(', ') ?? '-',
    contractNumber: data?.contractNumber ?? '-',
  };
}

export function selectInvoicesReservations(
  data: InvoiceDetailsResponse
): InvoiceReservations[] {
  return transformInvoiceReservation(data.reservationInfo ?? []);
}

export function selectInvoicesContactInfo(
  data: AccountExecutiveContact | undefined
): InvoiceContactInfo | Record<string, never> | null {
  if (!data) return null;
  let name = data.firstName + ' ' + data.lastName;
  if (!data.firstName && !data.lastName) name = '-';
  return {
    name: name,
    email: data.email ?? '-',
    phone: data.phoneNumber ?? '-',
  };
}
