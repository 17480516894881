import { SubmittedDocumentsFilters } from '@/features/documents/components/submitted-documents/filter-modal/useFilterModalReducer';

export const enum Actions {
  resetFilter = 'reset-filters',
  toggleAllFilters = 'toggle-fleets-bases-ranks',
}

type ResetFilterAction = {
  type: Actions.resetFilter;
};

type ToggleAllFiltersAction = {
  type: Actions.toggleAllFilters;
  payload: SubmittedDocumentsFilters;
};

type UsersFilterActions = ToggleAllFiltersAction | ResetFilterAction;

export function filtersReducer(
  state: SubmittedDocumentsFilters,
  action: UsersFilterActions
): SubmittedDocumentsFilters {
  switch (action.type) {
    case Actions.toggleAllFilters:
      return {
        ...state,
        status: action.payload.status,
      };
    case Actions.resetFilter:
      return {
        status: [],
      };
    default:
      return state;
  }
}
