import { ReservationInfoDto } from '@/open-api';
import { InvoiceReservations } from './selectorsType';
import getFormattedDate from '@/shared/helpers/getFormattedDate';

export function transformInvoiceReservation(
  input: ReservationInfoDto[] | []
): InvoiceReservations[] | [] {
  return Array.isArray(input) && input.length
    ? input.map(r => {
        const pilotName = [
          r.pilotName?.givenName,
          r.pilotName?.middleName,
          r.pilotName?.lastName,
        ].filter(Boolean);
        return {
          schedulerReservationId: r.schedulerReservationId ?? '-',
          courseName: r.courseName ?? '-',
          pilotName: pilotName.length !== 0 ? pilotName.join(' ') : '-',
          platform: r.aircraftName ?? '-',
          trainingStartDate:
            getFormattedDate(r.trainingStartDate, 'dd-MMM-yyyy') ?? '-',
          location: r.location ?? '-',
        };
      })
    : [];
}
