import {
  ModuleManagementService,
  ModuleRequest,
  ModuleResponse,
} from '@/open-api';
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

type RolesModuleMutationCallback = {
  onSuccessCallback: (data: ModuleResponse) => void;
  onErrorCallback: () => void;
};

export const useCreateModuleMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: RolesModuleMutationCallback): UseMutationResult<
  unknown,
  unknown,
  {
    data: ModuleRequest;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ data }: { data: ModuleResponse }) => {
      const response = await ModuleManagementService.createModule({
        requestBody: data,
      });
      return response;
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: ['createModule'] });
        onSuccessCallback(response);
      },
      onError: error => {
        onErrorCallback();
        throw error;
      },
    }
  );
};
