import * as React from 'react';
import { Icon } from '@cae/cobalt-react';

import { DocumentDto } from '@/open-api';

export function ParentDirectoryButton({
  data,
  onClick,
}: {
  data: DocumentDto;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  return data.parentFolders && data.parentFolders?.length > 0 ? (
    <button
      type="button"
      className="name"
      data-type="directory"
      onClick={onClick}
    >
      {data.parentFolders?.[data.parentFolders.length - 1]?.name}
    </button>
  ) : (
    <span className="name">
      <Icon id="remove-outline" size="sm" />
    </span>
  );
}
