import * as React from 'react';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import { useReportsOptionsQuery } from '@/features/instructors/api/useReportsOptionsQuery';

import { FormActions } from '@/features/instructors/components/form-actions/FormActions';

import { ReportOptionsFields } from '@/features/instructor-management/components/report-options-fields/ReportOptionsFields.tsx';
import { useEffect } from 'react';
import { AlertBanner } from '@cae/cobalt-react';

function handleDownloadPath(
  reportType: string,
  location: string,
  platform: string
): string {
  const params = new URLSearchParams({
    reportType,
    location,
    platform,
  });
  return `/instructors/report?${params}`;
}

const reportTypes = [
  {
    label: '8440 IP Report',
    value: 'instructor-pilot',
  },
  {
    label: '8440 CA Report',
    value: 'check-airmen',
  },
  {
    label: '8470 Recurrent',
    value: 'recurrent',
  },
  {
    label: 'Approved Instructors',
    value: 'approved',
  },
];

const downloadFileNameMap: Record<string, string> = {
  'instructor-pilot': 'instructor pilot.pdf',
  'check-airmen': 'check airman.pdf',
  recurrent: 'recurrent report.pdf',
  approved: 'approved instructors.pdf',
};

export function ReportsForm({
  onCancel,
  selectedLocation,
  program,
}: Readonly<{
  onCancel: (e: React.SyntheticEvent) => void;
  selectedLocation?: string;
  program?: string;
}>): JSX.Element {
  const [location, setLocation] = React.useState<string>('');
  const [platform, setPlatform] = React.useState<string>('');
  const [reportType, setReportType] = React.useState<string>('');
  const [statusInfo, setStatusInfo] = React.useState<boolean>(false);

  useEffect(() => {
    if (selectedLocation && program) {
      setLocation(selectedLocation);
      setPlatform(program);
    }
  }, [selectedLocation, program]);

  const { data, isInitialLoading, isError, isSuccess } = useReportsOptionsQuery(
    !!(selectedLocation && program)
  );

  const { status, downloadFile } = useDownloadFile({});

  const pending = status === 'pending';

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setStatusInfo(false);
    onCancel(e);
  };

  const handleSubmit: React.FormEventHandler = e => {
    e.preventDefault();
    setStatusInfo(false);
    downloadFile({
      url: handleDownloadPath(reportType, location, platform),
      fileName: reportType ? downloadFileNameMap[reportType] : 'report.pdf',
    })
      .then(() => {
        setStatusInfo(true);
      })
      .catch(() => {
        setStatusInfo(true);
      });
  };

  return (
    <form
      action=""
      method="post"
      className="report-form"
      onSubmit={handleSubmit}
    >
      <legend>Download Reports</legend>
      <div className="inner">
        <p>
          Please select the type and choose the options for the report you want
          to download.
        </p>
        {isInitialLoading && <p>Loading...</p>}
        {isError && <p>Something went wrong</p>}
        {(isSuccess || (selectedLocation && program)) && (
          <>
            {statusInfo && status === 'resolved' && (
              <AlertBanner
                className="co-alert--success"
                size="sm"
                type="success"
                heading={'Report downloaded successfully!'}
              ></AlertBanner>
            )}
            {statusInfo && status === 'rejected' && (
              <AlertBanner
                className="co-alert--error"
                size="sm"
                type="error"
                heading={'There was a problem with the download. Try again.'}
              ></AlertBanner>
            )}
            <ReportOptionsFields
              reportType={reportType}
              location={location}
              setLocation={setLocation}
              setReportType={setReportType}
              platform={platform}
              setPlatform={setPlatform}
              reportTypeOptions={reportTypes}
              locationOptions={
                selectedLocation
                  ? [{ label: selectedLocation, value: selectedLocation }]
                  : data?.locations || []
              }
              platformOptions={
                program
                  ? [
                      {
                        location: selectedLocation,
                        platforms: [{ label: program, value: program }],
                      },
                    ]
                  : data?.platforms || []
              }
            />
          </>
        )}
      </div>
      <FormActions
        disabled={pending || !location || !platform || !reportType}
        onCancel={handleCancel}
        pending={pending}
      />
    </form>
  );
}
