/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactInformation } from '../models/ContactInformation';
import type { SupportRequest } from '../models/SupportRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CustomerSupportManagementService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendSupportRequest({
    requestBody,
    organizationId,
  }: {
    requestBody: SupportRequest;
    organizationId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-support/request',
      headers: {
        'Organization-Id': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ContactInformation OK
   * @throws ApiError
   */
  public static getContactInformation({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<ContactInformation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/customer-support/contacts',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
