import { useUserInfo } from '@/contexts/userInfo';
import { Card } from '@cae/cobalt-react';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import ChangeRequestBanner from '../company-change-request-banner/ChangeRequestBanner';
import './companyBilling.css';

function CompanyBilling(): JSX.Element {
  const { selectedOrganization } = useUserInfo();

  const { data: companyData } = useCompanyProfileByOrganizationIdQuery(
    selectedOrganization?.id
  );

  return (
    <div className="company-profile--billing">
      <h2>Billing</h2>
      <ChangeRequestBanner
        message="In order to edit read only fields, please submit a"
        navigate_to="/company-profile/contact-info/change-request"
      />

      <h3>Billing information</h3>
      <Card className="company-billing-card">
        <h3 className="billing-heading">Billing address</h3>
        <p className="billing-para">{companyData?.billingAddress?.street}</p>
        <p className="billing-para">{companyData?.billingAddress?.city}</p>
        <p className="billing-para">{companyData?.billingAddress?.state}</p>
        <p className="billing-para">{companyData?.billingAddress?.zip}</p>
        <p className="billing-para">
          {companyData?.billingAddress?.countryCode}
        </p>
      </Card>
    </div>
  );
}

export default CompanyBilling;
