import * as React from 'react';

import './RecordsTableWrapper.css';
import { RecordResponseDto, RecordStatusFilter } from '@/open-api';
import { PaginationState } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { useGetRecordsTableDataQuery } from '../../api/useGetRecordsTableDataQuery';
import RecordCardList from '../RecordsTableCards/RecordCardList';
import { RecordTableList } from '../RecordsTableList/RecordTableList';
import { TagColor } from '@cae/cobalt-react';
import { ColumnSort } from '@/shared/components';
import { mapSorting } from '@/shared/helpers/mapSortingString.ts';

export const recordStatusColors: {
  [key: string]: TagColor;
} = {
  available: 'green',
  pending: 'yellow',
  unknown: 'standard',
};

interface RecordsConfirmedProps {
  filters: {
    platforms: string[];
    locations: string[];
    courseTypes: Array<'Initial' | 'Recurrent' | 'Upgrade' | 'Other'>;
    dateRange: (Date | undefined)[];
  };
  recordsType: RecordStatusFilter;
  searchText?: string;
}
export function RecordsTableWrapper({
  filters,
  recordsType = RecordStatusFilter.ALL,
  searchText = '',
}: RecordsConfirmedProps): JSX.Element {
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const [sorting, setSorting] = React.useState<ColumnSort[] | []>([
    {
      id: 'startDate',
      desc: true,
    },
  ]);

  React.useEffect(() => {
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  }, [filters, searchText]);

  const { data, status } = useGetRecordsTableDataQuery(
    recordsType,
    pageIndex,
    pageSize,
    filters,
    searchText,
    mapSorting(sorting)
  );

  const records = data?.items ? data.items : [];

  const openDetails = (record: RecordResponseDto): void => {
    if (record && record.recordId) {
      navigate(`/records/${record.recordId}`);
    }
  };

  const commonProps = {
    data: records,
    openDetails,
    pageIndex,
    pageSize,
    recordsType,
    searchText,
    setPagination,
    setSorting,
    defaultSortBy: sorting,
    queryStatus: status,
    totalPages: data?.totalPages ?? 1,
  };

  return width > 650 ? (
    <RecordTableList {...commonProps} />
  ) : (
    <RecordCardList {...commonProps} />
  );
}
