/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Training record style
 */
export enum RecordStyle {
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  SUCCESS = 'success',
}
