import * as React from 'react';
import { Icon, Table, Tooltip } from '@cae/cobalt-react';

import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  Cell,
} from '@tanstack/react-table';
import { Pagination } from '@/shared/components/pagination/Pagination';

interface CobaltTableCellProps<TData> {
  cell: Cell<TData, unknown>;
}

export function CobaltTableCell<TData>({
  cell,
}: CobaltTableCellProps<TData>): JSX.Element {
  const ref = React.useRef<React.ElementRef<'span'>>(null);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(true);

  React.useEffect(() => {
    const checkEllipsis = (): void => {
      let hasEllipsis = false;

      if (ref.current) {
        const isOverflowing = ref.current.clientWidth < ref.current.scrollWidth;
        if (isOverflowing) {
          hasEllipsis = true;
        }
      }

      setShowTooltip(hasEllipsis);
    };

    checkEllipsis();

    window.addEventListener('resize', checkEllipsis);
    return () => {
      window.removeEventListener('resize', checkEllipsis);
    };
  }, []);

  const cellContent = flexRender(cell.column.columnDef.cell, cell.getContext());

  return showTooltip ? (
    <Tooltip offsetOptions={7} placement="bottom" highContrast>
      <Tooltip.Trigger>
        <span className="cell-value" ref={ref}>
          {cellContent}
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content>{cellContent}</Tooltip.Content>
    </Tooltip>
  ) : (
    <span className="cell-value" ref={ref}>
      {cellContent}
    </span>
  );
}

interface CobaltTableProps<T> {
  className?: string;
  // The type of value in react-table library can be anything (ColumnDef<TData, any>[]), so use of the 'any' is warranted here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  data: T[];
  handleRowClick?: (rowData: T) => void;
  id?: string;
  pageIndex?: number;
  pageSize?: number;
  pagination?: boolean;
  paginationStyle?: React.CSSProperties;
  setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
  sortable?: boolean;
  style?: React.CSSProperties;
  totalPages?: number;
  defaultSortBy?: [{ desc: boolean; id: string }];
}

export function CobaltTable<T>({
  className,
  columns,
  data,
  handleRowClick,
  id,
  pageIndex = 1,
  pageSize = 10,
  pagination = true,
  paginationStyle = {},
  setPagination,
  sortable = true,
  style,
  totalPages,
  defaultSortBy,
}: CobaltTableProps<T>): JSX.Element {
  const [sorting, setSorting] = React.useState<SortingState>(
    defaultSortBy || []
  );
  const [selected, setSelected] = React.useState<string>('');

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sortable ? sorting : undefined,
      pagination: { pageIndex, pageSize },
    },
    manualPagination: true,
    onSortingChange: setSorting,
    pageCount: (totalPages ?? -1) as number,
    onPaginationChange: setPagination,
  });

  if (!data) return <div>Loading...</div>;

  return (
    <>
      <div
        className="scroll-horizontal"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Table className={className} id={id} style={style}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(h => (
                  <th
                    key={h.id}
                    className={h.id}
                    onClick={h.column.getToggleSortingHandler()}
                  >
                    <span className="label">
                      {flexRender(h.column.columnDef.header, h.getContext())}
                      {
                        {
                          asc: <Icon id="arrow-up-outline" size="sm" />,
                          desc: <Icon id="arrow-down-outline" size="sm" />,
                        }[(h.column.getIsSorted() as string) ?? null]
                      }
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                data-selected={row.id === selected ? 'true' : 'false'}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={cell.id}
                    onClick={() => {
                      if (cell.id.endsWith('checkbox')) return;
                      setSelected(row.id);
                      if (typeof handleRowClick === 'function')
                        handleRowClick(row.original);
                    }}
                  >
                    <CobaltTableCell cell={cell} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {pagination && (
        <Pagination
          previousPage={table.previousPage}
          nextPage={table.nextPage}
          getCanPreviousPage={table.getCanPreviousPage}
          getCanNextPage={table.getCanNextPage}
          options={[10, 25, 50, 100]}
          pageSize={table.getState().pagination.pageSize}
          setPageSize={table.setPageSize}
          page={table.getState().pagination.pageIndex + 1}
          setPageIndex={table.setPageIndex}
          maxPage={table.getPageCount()}
          style={paginationStyle}
        />
      )}
    </>
  );
}

export default CobaltTable;
