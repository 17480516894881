import * as React from 'react';
import { Button } from '@cae/cobalt-react';

import { ActiveDropZone, InactiveDropZone } from './DropZone';
import { ValidationErrorMessage } from './ValidationErrorMessage';
import { ValidAttachmentList } from './ValidAttachmentList';
import { InvalidAttachmentList } from './InvalidAttachmentList';

import type { InvalidFile, FileUploadProps } from './FileUpload.types';
import './FileUpload.css';

function clsx(classNames: Record<string, string | boolean>): string {
  let cls = '';
  Object.keys(classNames).forEach(key => {
    if (classNames[key]) {
      cls += ` ${key}`;
    }
  });
  return cls;
}

export function FileUpload({
  accept,
  buttonLabel = 'Add file(s)',
  buttonVariant = 'tertiary',
  className = '',
  dropZoneText = 'Drag and drop file here or click to select file',
  error = '',
  helperText = '',
  label = '',
  maxFiles = 1,
  maxFileSize = 0,
  maxSize = 0,
  onFilesChange,
  orientation = 'vertical',
  size = 'md',
  variant = 'button',
  onLoading = false,
  uploadResponse,
  disabled,
  ...rest
}: FileUploadProps): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const dropZoneRef = React.useRef<HTMLButtonElement>(null);

  const [validFiles, setValidFiles] = React.useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = React.useState<InvalidFile[]>([]);

  const [errorState, setErrorState] = React.useState<string>(error);
  const [maxCountError, setMaxCountError] = React.useState<string>('');
  const [maxSizeError, setMaxSizeError] = React.useState<string>('');

  const MB = 1024 * 1000;

  React.useEffect(() => {
    setErrorState(error);
  }, [error]);

  React.useEffect(() => {
    const count = validFiles.length;
    const sizeSum = validFiles.reduce((acc, file) => acc + file.size, 0);
    if (count <= maxFiles) setMaxCountError('');
    if (count > 0 && sizeSum <= maxSize * MB) {
      setMaxSizeError('');
    }
    if (typeof onFilesChange === 'function') onFilesChange(validFiles);
  }, [MB, maxFiles, maxSize, onFilesChange, validFiles]);

  const handleDropZoneClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    const inputElement = inputRef.current;
    if (inputElement && validFiles.length < maxFiles) {
      inputElement.click();
    }
  };

  const handleDropZoneKeyDown: React.KeyboardEventHandler = e => {
    if (e.key === 'Enter') {
      const inputElement = inputRef.current;
      if (inputElement && validFiles.length < maxFiles) {
        inputElement.click();
      }
    }
  };

  const updateFiles = (files: File[]): void => {
    const valid = [...validFiles];
    const invalid = [...invalidFiles];
    const allowedTypes = accept ? accept.split(',') : ['all'];
    let count = valid.length;
    const maxAllowedSize = maxFileSize ?? 0;
    const maxAllowedTotalSize = maxSize ?? 0;
    let totalSize = valid.reduce((acc, file) => acc + file.size, 0);
    files.forEach(file => {
      totalSize += file.size;

      if (count >= maxFiles) {
        setMaxCountError(`Max. file count (${maxFiles}) reached`);
        return;
      }
      if (maxAllowedTotalSize && totalSize >= MB * maxAllowedTotalSize) {
        setMaxSizeError(`Max. size (${maxSize}MB) reached`);
        return;
      }

      if (validFiles.find(validFile => validFile.name === file.name)) {
        invalid.push({
          name: file.name,
          message: `This file has been added already`,
        });
      } else if (
        !allowedTypes.includes('all') &&
        allowedTypes.findIndex(type => type === file.type) < 0
      ) {
        invalid.push({
          name: file.name,
          message: 'This type of file is not allowed',
        });
      } else if (maxAllowedSize && file.size > MB * maxAllowedSize) {
        invalid.push({
          name: file.name,
          message: `This file is too large. (${(
            file.size /
            1024 /
            1000
          ).toFixed(2)}MB > ${maxFileSize}MB)`,
        });
      } else if (valid.findIndex(v => v.name === file.name) < 0) {
        valid.push(file);
        count += 1;
      }
    });

    setValidFiles(valid);
    setInvalidFiles(invalid);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.target;
    const fileList = [...(files ?? [])];
    if (fileList.length) updateFiles(fileList);
  };

  const handleDropZoneDrop: React.DragEventHandler = e => {
    e.preventDefault();
    const dropZoneElement = dropZoneRef.current;
    if (dropZoneElement) {
      dropZoneElement.classList.remove('drag-enter');
      dropZoneElement.classList.remove('drag-leave');
      dropZoneElement.classList.remove('drag-over');
      dropZoneElement.classList.add('drag-drop');
    }

    let fileList: File[] = [];

    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach(item => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) fileList.push(file);
        }
      });
    } else {
      fileList = [...e.dataTransfer.files];
    }
    updateFiles(fileList);
  };

  const handleDropZoneDragEnter: React.DragEventHandler = e => {
    e.preventDefault();
    const dropZoneElement = dropZoneRef.current;
    if (dropZoneElement) {
      dropZoneElement.classList.remove('drag-leave');
      dropZoneElement.classList.add('drag-enter');
    }
  };

  const handleDropZoneDragOver: React.DragEventHandler = e => {
    e.preventDefault();
    const dropZoneElement = dropZoneRef.current;
    if (dropZoneElement) {
      dropZoneElement.classList.add('drag-over');
    }
  };

  const handleDropZoneDragLeave: React.DragEventHandler = e => {
    e.preventDefault();
    const dropZoneElement = dropZoneRef.current;
    if (dropZoneElement) {
      dropZoneElement.classList.add('drag-leave');
      dropZoneElement.classList.remove('drag-enter');
      dropZoneElement.classList.remove('drag-over');
    }
  };

  const handleFileClick: React.MouseEventHandler<HTMLElement> = e => {
    const { action, name } = (e.target as HTMLElement).dataset;

    if (action === 'remove-file' && name) {
      const files = [...validFiles].filter(f => f.name !== name);
      setValidFiles(files);
      const inputEl = inputRef.current;
      if (inputEl) {
        inputEl.value = '';
      }
    }
  };

  const handleErrorClick: React.MouseEventHandler<HTMLElement> = e => {
    const { action, name } = (e.target as HTMLElement).dataset;

    if (action === 'remove-error' && name) {
      const updatedInvalidFiles = [...invalidFiles].filter(
        file => file.name !== name
      );
      setInvalidFiles(updatedInvalidFiles);
    }
  };

  const rootProps: React.ComponentPropsWithoutRef<'div'> = {
    ...rest,
    className: clsx({
      'co-form__control': true,
      'co-form__file': true,
      [`co-form__control--${orientation}`]: orientation,
      [`co-form__control--${size}`]: size,
      [className]: className,
    }),
  };

  const inputProps: React.ComponentPropsWithRef<'input'> = {
    className: 'visually-hidden',
    ref: inputRef,
    multiple: maxFiles > 1,
    onChange: handleInputChange,
    tabIndex: -1,
    type: 'file',
  };

  return (
    <div {...rootProps}>
      <label className="co-form__label">
        {label && <span>{label}</span>}
        <input {...inputProps} />
      </label>

      {helperText && <span className="co-form__helper-text">{helperText}</span>}

      {!disabled && variant === 'dnd' && validFiles.length < maxFiles && (
        <ActiveDropZone
          label={dropZoneText}
          onClick={handleDropZoneClick}
          onKeyDown={handleDropZoneKeyDown}
          onDragEnter={handleDropZoneDragEnter}
          onDragLeave={handleDropZoneDragLeave}
          onDragOver={handleDropZoneDragOver}
          onDrop={handleDropZoneDrop}
          ref={dropZoneRef}
        />
      )}

      {!disabled && variant === 'dnd' && validFiles.length >= maxFiles && (
        <InactiveDropZone label={dropZoneText} disabled={true} />
      )}

      {variant === 'button' && (
        <Button
          type="button"
          size={size}
          variant={buttonVariant}
          disabled={validFiles.length >= maxFiles}
          onClick={handleDropZoneClick}
        >
          {buttonLabel}
        </Button>
      )}

      {maxCountError && (
        <ValidationErrorMessage>{maxCountError}</ValidationErrorMessage>
      )}
      {maxSizeError && (
        <ValidationErrorMessage>{maxSizeError}</ValidationErrorMessage>
      )}
      {errorState && (
        <ValidationErrorMessage>{errorState}</ValidationErrorMessage>
      )}

      <ValidAttachmentList
        files={validFiles}
        onClick={handleFileClick}
        onLoading={onLoading}
        uploadResponse={uploadResponse}
      />

      <InvalidAttachmentList files={invalidFiles} onClick={handleErrorClick} />
    </div>
  );
}

FileUpload.displayName = 'FileUpload';
