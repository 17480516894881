import { EditUser } from '@/features/users/components/edit-user/EditUser';
import { UsersPage } from '@/pages/users';
import { UserDetailsPage } from '@/pages/users/UserDetailsPage';
import UserChangeRequest from '@/features/users/components/change-request/UserChangeRequest';

export const usersRoutes = {
  path: 'users',
  element: <UsersPage />,
  children: [
    {
      path: ':userId',
      element: <UserDetailsPage />,
      children: [
        { path: 'request', element: <UserChangeRequest /> },

        {
          index: true,
          element: <EditUser />,
        },
      ],
    },
  ],
};
