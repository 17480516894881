import * as React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

export function FaaPart135Page(): JSX.Element {
  const { width } = useViewportSize();
  const navigate = useNavigate();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/documents/overview');
  };

  return (
    <Modal
      animate
      className="faa-part135-program--dialog"
      dismissible
      onCancel={handleCancel}
      open
      placement={width < 480 ? 'bottom' : 'right'}
    >
      <Outlet />
    </Modal>
  );
}

FaaPart135Page.displayName = 'FaaPart135Page';
