import * as React from 'react';
import {
  useController,
  type FieldValues,
  type Path,
  type UseControllerProps,
} from 'react-hook-form';

import { omit, toggleValue } from './helpers';

import type { ComponentProps } from './types';

export type ControlFieldProps<T extends FieldValues> = Omit<
  UseControllerProps<T>,
  'name'
> &
  ComponentProps & {
    is?: React.ElementType;
    name?: string;
  };

export function ControlField<T extends FieldValues>({
  control,
  defaultValue,
  is,
  name,
  rules = {},
  shouldUnregister = false,
  ...props
}: ControlFieldProps<T>): JSX.Element | null {
  const fieldName = (name ?? props?.field?.name) as Path<T>;

  const {
    field,
    fieldState: { error, invalid, isDirty, isTouched },
  } = useController({
    ...(control && { control }),
    ...(defaultValue && { defaultValue }),
    name: fieldName,
    rules,
    shouldUnregister,
  });

  if (!is) {
    console.error('Provide `is` prop to use a custom input component.');
    return null;
  }
  if (!name && props.field && !props.field.name) {
    console.error('Provide either `name` prop or `field.name` prop value.');
    return null;
  }

  const fieldValue = props?.field?.value;

  const isChecked = {
    checked: Array.isArray(field.value)
      ? field.value.includes(fieldValue)
      : field.value === fieldValue,
  };

  const inputProps: React.ComponentPropsWithoutRef<'input'> = {
    ...omit(props.field ?? {}, ['name', 'onChange']),
    ...omit(field, ['onChange', 'ref']),

    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const nextValue: string[] | string = Array.isArray(field.value)
        ? toggleValue(value, field.value)
        : value;
      field.onChange(nextValue);
      props?.field?.onChange && props.field.onChange(e);
    },

    ...(fieldValue !== undefined ? isChecked : {}),
  };

  const Component = is;

  if (Component) {
    return (
      <Component
        {...props}
        field={inputProps}
        meta={{
          dirty: isDirty,
          error: error?.message ?? '',
          invalid,
          touched: isTouched,
          valid: !invalid,
        }}
      />
    );
  } else {
    return null;
  }
}
