import { Button } from '@cae/cobalt-react';
import { FilterSection, FilterDateRangePicker } from '@/shared/components';

import { useFilterModalReducer } from './useFilterModalReducer';

import { STATUS_TYPE } from './filtersConsts';
import { FilterModalProps } from './mock';

function compareArrays(
  filters: string[] | undefined,
  selectedValues: string[]
): boolean {
  if (!filters && selectedValues.length === 0) return true;
  if (!filters || !selectedValues) return false;
  if (filters.length !== selectedValues.length) return false;
  return filters.every(item => selectedValues.includes(item));
}

export function FilterModal({
  filterOptions,
  filters,
  toggleAllFilters,
  resetFilters,
  toggleFilterSection,
}: Readonly<FilterModalProps>): JSX.Element {
  const {
    selectedFilters,
    clearSelectedFilters,
    changeSelectedStatus,
    changeSelectedPlatforms,
    changeSelectedLocations,
    changeSelectedCurrency,
    changeTrainingDateRange,
    changeInvoiceDateRange,
  } = useFilterModalReducer(filters);

  const handleApply = (): void => {
    toggleAllFilters(selectedFilters);
    toggleFilterSection();
  };

  const handleReset = (): void => {
    resetFilters();
    clearSelectedFilters();
  };

  return (
    <div className="filter-list">
      <h3>Filters</h3>
      <div className="filter-sections">
        <FilterDateRangePicker
          onSelect={changeInvoiceDateRange}
          selected={selectedFilters.invoiceDateRange}
          title="Invoice due date"
        />
        <FilterDateRangePicker
          onSelect={changeTrainingDateRange}
          selected={selectedFilters.trainingDateRange}
          title="Issued date"
        />
        <FilterSection
          label="Currency"
          searchPlaceholder="Search currency"
          options={filterOptions?.currency}
          selected={selectedFilters.currency}
          onSelect={changeSelectedCurrency}
          showSearch
        />
        <FilterSection
          label="Platform"
          searchPlaceholder="Search platforms"
          options={filterOptions?.platform}
          selected={selectedFilters.platform}
          onSelect={changeSelectedPlatforms}
          showSearch
        />
        <FilterSection
          label="Location"
          options={filterOptions?.location}
          selected={selectedFilters.location}
          onSelect={changeSelectedLocations}
          showSearch
        />
        <FilterSection
          label="Status"
          options={STATUS_TYPE}
          selected={selectedFilters.status}
          onSelect={changeSelectedStatus}
        />
      </div>
      <div className="actions">
        <Button variant="ghost" onClick={handleReset}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleApply}
          disabled={
            compareArrays(filters.location, selectedFilters.location) &&
            compareArrays(filters.platform, selectedFilters.platform) &&
            compareArrays(filters.currency, selectedFilters.currency) &&
            compareArrays(filters.status, selectedFilters.status) &&
            compareArrays(
              filters.invoiceDateRange.map(date =>
                date ? date.toDateString() : ''
              ),
              selectedFilters.invoiceDateRange.map(date =>
                date ? date.toDateString() : ''
              )
            ) &&
            compareArrays(
              filters.trainingDateRange.map(date =>
                date ? date.toDateString() : ''
              ),
              selectedFilters.trainingDateRange.map(date =>
                date ? date.toDateString() : ''
              )
            )
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default FilterModal;
