import { Outlet } from 'react-router-dom';
import './SFConnectWrapper.css';

function SFConnectWrapper(): JSX.Element {
  return (
    <div className="sf-connect_container">
      <Outlet />
    </div>
  );
}

export default SFConnectWrapper;
