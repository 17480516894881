import { useUserInfo } from '@/contexts/userInfo';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { Button, Icon, Tag, Toggletip } from '@cae/cobalt-react';
import * as React from 'react';
import { useGetCurrentOrganization } from '../../api/useGetCurrentOrganization';
import { useGetOrganizationsQuery } from '@/shared/api/useGetOrganization';
import './OrganizationSwitcherExternal.css';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

function OrganizationSwitcherExternal(): JSX.Element {
  const { selectedOrganization, id = '' } = useUserInfo();
  const navigate = useNavigate();
  const { handleOrgUpdate } = useGetCurrentOrganization();
  const [toggleOpen, setToggleOpen] = React.useState<boolean>(false);
  const { width } = useViewportSize();
  const { data: organizations = [] } = useGetOrganizationsQuery(id);
  const { companyProfileRedirect } = useFlags();
  function handleOrgSwitcher(orgId: string): void {
    handleOrgUpdate(orgId);
    setToggleOpen(false);
  }
  const handleCompanyNavigate: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    e.stopPropagation();
    if (companyProfileRedirect)
      navigate('/company-profile/contact-info', {
        state: { from: location.pathname },
      });
    setToggleOpen(false);
  };

  return (
    <Toggletip
      offsetOptions={16}
      placement="bottom-end"
      size="md"
      withRole="menu"
      open={toggleOpen}
      onOpenChange={() => setToggleOpen(!toggleOpen)}
    >
      <Toggletip.Trigger>
        <Button
          type="button"
          className="org-switcher"
          variant="ghost"
          onClick={() => setToggleOpen(!toggleOpen)}
          data-testid="orgSelectorPanel"
        >
          {width > 768 ? (
            <>
              {selectedOrganization?.name}
              <Icon
                id={`chevron-${toggleOpen ? 'up' : 'down'}-outline`}
                size="sm"
              />
            </>
          ) : (
            <Icon id="menu-overflow-outline" />
          )}
        </Button>
      </Toggletip.Trigger>
      <Toggletip.Content>
        <h6>Switch Organization</h6>
        <menu>
          {organizations.map(org => {
            const roles: string[] = org.roles.split(',');
            const isAdmin = roles.includes('Admin');
            const displayRoles = isAdmin ? 'Admin' : roles.join(', ');
            const currentOrg = selectedOrganization?.id === org.id;

            return (
              <li key={org.id}>
                <button
                  className="switcher-btn"
                  onClick={() => handleOrgSwitcher(org.id)}
                >
                  <span className="organization-switcher-item-header">
                    <h5 title={org.name}>{org.name}</h5>
                    {currentOrg && (
                      <Tag color="standard" compact>
                        Current
                      </Tag>
                    )}
                  </span>
                  <span className="organization-switcher-roles">
                    {displayRoles}
                  </span>
                  {currentOrg && companyProfileRedirect && (
                    <button
                      type="button"
                      className="btn-link-company"
                      onClick={handleCompanyNavigate}
                      tabIndex={0}
                      data-toggle-menu="true"
                      data-testid="manageProfile"
                    >
                      <span className="link-label">
                        Manage company settings
                      </span>
                    </button>
                  )}
                </button>
              </li>
            );
          })}
        </menu>
      </Toggletip.Content>
    </Toggletip>
  );
}

export default OrganizationSwitcherExternal;
