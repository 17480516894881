import {
  CaeCustomerAuthority,
  ConfigurationManagementService,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export function useGetAuthoritiesQuery(): UseQueryResult<
  CaeCustomerAuthority[]
> {
  return useQuery<CaeCustomerAuthority[]>({
    queryKey: ['configuration', 'authorities'],
    queryFn: async () => ConfigurationManagementService.getAuthorities1(),
  });
}
