import {
  DataLoadingGrid,
  DataLoadingError,
  NoData,
  BasicPagination,
} from '@/shared/components';
import { PaginationState } from '@tanstack/react-table';
import * as React from 'react';
import './RecordCardList.css';
import { QueryStatus } from '@tanstack/react-query';

import { RecordResponseDto } from '@/open-api';

import {
  RecordAvailableCard,
  RecordFullCard,
  RecordNotAvailableCard,
  RecordPendingCard,
} from '../record-card/RecordCard';

interface RecordsCardListingProps {
  data: RecordResponseDto[];
  openDetails: (record: RecordResponseDto) => void;
  pageIndex: number;
  pageSize: number;
  recordsType: string;
  searchText: string;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  queryStatus: QueryStatus;
  totalPages: number;
}

function RecordCardList({
  data,
  openDetails,
  pageIndex,
  recordsType = 'all',
  setPagination,
  queryStatus,
  totalPages,
}: RecordsCardListingProps): JSX.Element {
  return (
    <>
      {queryStatus === 'loading' && (
        <DataLoadingGrid
          numberColumns={10}
          numberRows={10}
          style={{ marginTop: '1rem' }}
        />
      )}
      {queryStatus === 'error' && <DataLoadingError />}
      {queryStatus === 'success' && data.length < 1 && <NoData />}
      {queryStatus === 'success' && data.length > 0 && (
        <div className="records--card-listing">
          {totalPages > 1 && (
            <p className="page-info">
              Showing page {pageIndex + 1} of {totalPages}
            </p>
          )}
          {data.map(d => {
            const common = {
              data: d,
              key: `record-${d.recordId}`,
              openDetails,
            };
            switch (recordsType) {
              case 'available':
                return <RecordAvailableCard {...common} />;
              case 'notAvailable':
                return <RecordNotAvailableCard {...common} />;
              case 'pending':
                return <RecordPendingCard {...common} />;
              case 'all':
              default:
                return <RecordFullCard {...common} />;
            }
          })}
          {totalPages > 1 && (
            <BasicPagination
              pageIndex={pageIndex}
              setPagination={setPagination}
              totalPages={totalPages}
            />
          )}
        </div>
      )}
    </>
  );
}

export default RecordCardList;
