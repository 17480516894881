import { Link } from 'react-router-dom';
import { Card, CardStatus, Icon } from '@cae/cobalt-react';
import './InvoiceAndPaymentsAlertCards.css';
import {
  InvoiceAndPaymentsTilesCount,
  useGetInvoiceAndPaymentsTilesCountQuery,
} from '../../api/useGetInoviceTilesCountQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';
interface Category {
  category: keyof InvoiceAndPaymentsTilesCount;
  title: string;
  iconName: string;
  style: string;
  type: string;
}

const categories: Category[] = [
  {
    category: 'invoicesDueNext7DaysCount',
    title: 'Invoices due in the next 7 days',
    iconName: 'warning-circle-solid',
    style: 'warning',
    type: 'invoicesUpcomingDueList',
  },
  {
    category: 'invoicesUpcomingDueCount',
    title: 'Invoices past due',
    iconName: 'warning-triangle-solid',
    style: 'danger',
    type: 'invoicesPastDueList',
  },
];

export function InvoiceAndPaymentsAlertCards(): JSX.Element | null {
  const { data, status } = useGetInvoiceAndPaymentsTilesCountQuery();
  const { invoicesandpaymentsalertcards } = useFlags();
  if (status !== 'success' || !data) return <></>;

  const counts = data
    ? Object.values(data).reduce((a, b) => {
        if (typeof b === 'number') {
          return a + b;
        } else {
          return a;
        }
      }, 0)
    : 0;
  const getAlertStatus = (style: string): CardStatus => {
    return `${style}-in` as CardStatus;
  };
  return counts < 1 && !invoicesandpaymentsalertcards ? null : (
    <div className="card-list">
      <div className="card-list--wrapper" data-testid="invoicesPaymentsTiles">
        {categories.map(categoryType =>
          typeof data[categoryType.category] === 'number' &&
          (data[categoryType.category] as number) > 0 ? (
            <Link
              to={`/finance/alerts/${categoryType.type}`}
              key={categoryType.iconName}
            >
              <Card status={getAlertStatus(categoryType.style)}>
                <h3>{data[categoryType.category] as number}</h3>
                <Icon
                  id={categoryType.iconName}
                  size="md"
                  style={{ color: `var(--${categoryType.style}-color)` }}
                />
                <p>
                  <strong>{categoryType.title}</strong>
                </p>
              </Card>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
}
export default InvoiceAndPaymentsAlertCards;
