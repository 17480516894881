import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import APIConfig from '../../../configs/urlConfig';

export interface ReservationsTilesCount {
  tsa: number;
  provisional: number;
  etca: number;
}

const getGetReservationsTilesCount =
  async (): Promise<ReservationsTilesCount> => {
    const tempToken = JSON.parse(localStorage.getItem('idToken') || '{}');

    const config = {
      crossDomain: true,
      headers: {
        Authorization: `Bearer ${tempToken}`,
        'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
      },
    };
    const { data } = await axios.get(
      `${APIConfig.apiUrl}/reservations/alerts`,
      config
    );
    return data;
  };

export function useGetReservationsTilesCountQuery(): UseQueryResult<
  ReservationsTilesCount,
  unknown
> {
  return useQuery({
    queryKey: ['getReservationsTilesCount'],
    queryFn: () => getGetReservationsTilesCount(),
  });
}
