import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@cae/cobalt-react';
import { ToastContainer } from 'react-toastify';

import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { RecurrentReportForm } from '@/features/instructors';

export function RecurrentReportPage(): JSX.Element {
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const handleCloseReport = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    navigate('/instructors');
  };

  return (
    <Modal
      animate
      className="reports-details--dialog"
      dismissible
      onCancel={handleCloseReport}
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
    >
      <RecurrentReportForm />
      <ToastContainer
        containerId="recurrent-report"
        hideProgressBar
        style={{
          position: 'absolute',
          marginTop: '4rem',
          zIndex: 9999,
        }}
        theme="colored"
      />
    </Modal>
  );
}
