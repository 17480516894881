/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleRequest } from '../models/RoleRequest';
import type { RoleResponse } from '../models/RoleResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RoleManagementService {
  /**
   * @returns RoleResponse OK
   * @throws ApiError
   */
  public static getRoleById({
    id,
  }: {
    id: string;
  }): CancelablePromise<RoleResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateRole({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: RoleRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/roles/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteRole({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/roles/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateRoleTaskPermission({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: RoleRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/roles/{id}/application/permissions',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns RoleResponse OK
   * @throws ApiError
   */
  public static getAllRoles(): CancelablePromise<Array<RoleResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns RoleResponse Created
   * @throws ApiError
   */
  public static createRole({
    requestBody,
  }: {
    requestBody: RoleRequest;
  }): CancelablePromise<RoleResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/roles',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
