import * as React from 'react';
import { add, addMonths, sub, subMonths, subYears } from 'date-fns';
import { Button, DateRangePicker } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

import './FilterDateRangePicker.css';

interface Shortcut {
  children: string;
  [key: string]: boolean | string | undefined;
}

function shouldDisable(date: Date, min?: Date, max?: Date): boolean {
  if (!min && max) return date >= max;
  if (min && !max) return date <= min;
  if (min && max) return date <= min || date >= max;
  return false;
}

function makeCustomShortcuts(min?: Date, max?: Date): Shortcut[] {
  const today = new Date();
  return [
    {
      children: 'Next month',
      'data-next-month': true,
      disabled: shouldDisable(addMonths(today, 1), min, max),
    },
    {
      children: 'Next 3 months',
      'data-next-three-months': true,
      disabled: shouldDisable(addMonths(today, 3), min, max),
    },
    {
      children: 'Next 6 months',
      'data-next-six-months': true,
      disabled: shouldDisable(addMonths(today, 6), min, max),
    },
  ];
}

function makeRecordsCustomShortcuts(min?: Date, max?: Date): Shortcut[] {
  const today = new Date();
  return [
    {
      children: 'Past month',
      'data-past-month': true,
      disabled: shouldDisable(subMonths(today, 1), min, max),
    },
    {
      children: 'Past 3 months',
      'data-past-three-months': true,
      disabled: shouldDisable(subMonths(today, 3), min, max),
    },
    {
      children: 'Past year',
      'data-Past-year': true,
      disabled: shouldDisable(subYears(today, 1), min, max),
    },
  ];
}
function CustomShortcutFrame({
  maxDate,
  minDate,
  onClick,
  title,
}: {
  maxDate?: Date;
  minDate?: Date;
  onClick: React.MouseEventHandler<HTMLElement>;
  title?: string;
}): JSX.Element {
  const options =
    title === 'Training end date range'
      ? makeRecordsCustomShortcuts(minDate, maxDate)
      : makeCustomShortcuts(minDate, maxDate);
  return (
    <aside className="shortcut-frame" onClick={onClick}>
      <ul>
        {options.map((opt, i) => (
          <li key={`s-${i}`}>
            <Button variant="ghost" {...opt} />
          </li>
        ))}
      </ul>
    </aside>
  );
}

type FilterDateRangePickerProps = {
  onSelect: (value: (Date | undefined)[]) => void;
  selected: (Date | undefined)[];
  title: string;
};
export function FilterDateRangePicker({
  onSelect,
  selected,
  title = 'Start date range',
}: FilterDateRangePickerProps): JSX.Element | null {
  const [startTouched, setStartTouched] = React.useState<boolean>(false);
  const [endTouched, setEndTouched] = React.useState<boolean>(false);
  const { width } = useViewportSize();

  const handleChange = (value: (Date | undefined)[]): void => {
    onSelect(value);
  };

  const handleStartFocus = (): void => {
    setStartTouched(true);
  };

  const handleEndFocus = (): void => {
    setEndTouched(true);
  };

  const handleShortcutClick: React.MouseEventHandler<HTMLElement> = e => {
    const today = new Date();

    function addDateRange(options = {}): void {
      const d = add(today, options);
      handleChange([today, d]);
    }

    function subDateRange(options = {}): void {
      const d = sub(today, options);
      handleChange([d, today]);
    }
    const {
      dataset: {
        nextMonth,
        nextSixMonths,
        nextThreeMonths,
        pastMonth,
        pastThreeMonths,
        pastYear,
      },
    } = e.target as HTMLElement;

    if (nextMonth) addDateRange({ months: 1 });
    if (nextThreeMonths) addDateRange({ months: 3 });
    if (nextSixMonths) addDateRange({ months: 6 });
    if (pastMonth) subDateRange({ months: 1 });
    if (pastThreeMonths) subDateRange({ months: 3 });
    if (pastYear) subDateRange({ months: 12 });
  };

  const widthLess480 = width < 480 ? 'bottom-end' : 'bottom';

  return (
    <section className="filter-date-range-picker">
      <header>
        <h4>{title}</h4>
      </header>
      <DateRangePicker
        customShortcutFrame={
          title === 'Training end date range' ? (
            <CustomShortcutFrame onClick={handleShortcutClick} title={title} />
          ) : (
            <CustomShortcutFrame onClick={handleShortcutClick} />
          )
        }
        dateFormat="dd-MMM-yyyy"
        inputs={[
          {
            value: selected[0]?.toISOString(),
            onFocus: handleStartFocus,
          },
          {
            value: selected[1]?.toISOString(),
            onFocus: handleEndFocus,
          },
        ]}
        meta={{
          touched: startTouched || endTouched,
        }}
        value={selected}
        onChange={handleChange}
        popoverPlacement={width < 768 ? widthLess480 : 'bottom-start'}
        withShortcutFrame
      />
    </section>
  );
}
