export function getSessionStorageItem(key: string): void {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

export function removeSessionStorageItem(key: string): void {
  sessionStorage.removeItem(key);
}

export function clearSessionStorage(): void {
  sessionStorage.clear();
}
