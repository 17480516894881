export function truncate(input: string, length: number = 10): string {
  if (input.length <= length) {
    return input;
  }
  const l = input.length;
  const left = input.substring(0, length);
  const right = input.substring(l - length, l);

  return `${left}...${right}`;
}
