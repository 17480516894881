import { useViewportSize } from '@/shared/hooks/useViewportSize';
import { Modal } from '@cae/cobalt-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditWorkInformForm } from '../edit-work-info-form/EditWorkInfoForm';

function EditWorkInfo(): JSX.Element {
  const { width } = useViewportSize();
  const location = useLocation();
  const defaultValues = { title: '' };
  const navigate = useNavigate();
  const handleClose = (): void => {
    navigate(-1);
  };
  const onSubmit = async (): Promise<void> => {};
  return (
    <Modal
      animate
      dismissible
      open
      placement={width < 480 ? 'bottom' : 'right'}
      size="sm"
      onCancel={handleClose}
    >
      <EditWorkInformForm
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        organizationName={location.state.orgName}
      />
    </Modal>
  );
}

export default EditWorkInfo;
