import * as React from 'react';
import { Icon } from '@cae/cobalt-react';

export function ValidationErrorMessage({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <p className="error__message">
      <Icon id="warning-triangle-solid" />
      {children}
    </p>
  );
}

ValidationErrorMessage.displayName = 'ValidationErrorMessage';
