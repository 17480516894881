import * as React from 'react';
import { PaginationState } from '@tanstack/react-table';
import { useInstructorProgramQuery } from '@/features/instructor-management/api/useInstructorProgramQuery.ts';
import { InstructorTable } from '@/features/instructor-management/components/instuctor-table/InstructorTable.tsx';

interface InstructorListingProps {
  trainingCenterId: string;
  searchText: string;
}

export function InstructorListing({
  trainingCenterId,
  searchText,
}: Readonly<InstructorListingProps>): JSX.Element {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  React.useEffect(() => {
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  }, [searchText]);

  const { data, status, isFetching } = useInstructorProgramQuery({
    trainingCenterId,
    pageIndex,
    pageSize,
    searchText,
  });

  const commonProps = {
    data: data?.items ?? [],
    queryStatus: status,
    isFetching,
    pageIndex,
    pageSize,
    setPagination,
    totalPages: data?.totalPages ?? 1,
  };

  return <InstructorTable {...commonProps} />;
}
