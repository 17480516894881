import { Button, Icon } from '@cae/cobalt-react';
import './LandingWelcome.css';
import { useTranslation } from 'react-i18next';
import { useLogin } from '@/shared/hooks/useLogin';

function LandingWelcome(): JSX.Element {
  const { t } = useTranslation();
  const { handleLogin } = useLogin();

  return (
    <div className="landing-welcome">
      <div className="landing-welcome-info">
        <h1>
          {t('landingPage.welcome')} <br />
          <span className="landing-welcome-info-app">CAEConnect</span>
        </h1>
        <p data-testid="languageValidationText">
          {t('landingPage.description')}
        </p>

        <Button
          type="button"
          variant="primary"
          onClick={() => handleLogin('redirect')}
          onKeyDown={() => handleLogin('redirect')}
        >
          {t('common.login')}
          <Icon id="arrow-right-solid" size="sm"></Icon>
        </Button>
      </div>
      <img src="/img/landing/home-page-sample.png" alt="home page sample" />
      <div className="landing-welcome-linear"></div>
    </div>
  );
}

export default LandingWelcome;
