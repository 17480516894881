// eslint-disable USED HERE BECAUSE IT NEEDS TO SINCE ITS THE WAY THE LIBRARY

/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: true,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
