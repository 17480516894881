import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import { DashboardRecordChart } from '../dashboard-record-chart/DashboardRecordChart';
import { DashboardReservationsChart } from '../dashboard-reservations-chart/DashboardReservationsChart';
import { useUserInfo } from '@/contexts/userInfo';

function DashboardChartWrapper(): JSX.Element {
  const currentRoles = useCurrentRoles();
  const { isInternalUser } = useUserInfo();
  if (
    (currentRoles && checkRoleMatch(currentRoles as string[], 'Admin')) ||
    isInternalUser ||
    checkRoleMatch(currentRoles as string[], 'Training')
  ) {
    return <DashboardReservationsChart />;
  }
  if (currentRoles && checkRoleMatch(currentRoles as string[], 'Records')) {
    return <DashboardRecordChart />;
  } else {
    return <></>;
  }
}

export default DashboardChartWrapper;
