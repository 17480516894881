import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ReservationManagementService, ReservationResponse } from '@/open-api';

const AlertsKey = {
  alerts: ['alertDetails'],
  getAlertDetails: (alertType: string) => [...AlertsKey.alerts, alertType],
};

export function useAlertDetailsByTypeQuery(
  alertType: string
): UseQueryResult<ReservationResponse[], unknown> {
  return useQuery<ReservationResponse[]>({
    queryKey: AlertsKey.getAlertDetails(alertType),
    queryFn: () =>
      ReservationManagementService.getAlertDetailsByType({ alertType }),
    enabled: !!alertType,
  });
}
