import SelectCustomer from '../../../../assets/images/welcome/select-customer.svg';

function SelectCustomerLanding(): JSX.Element {
  return (
    <div className="container">
      <img src={SelectCustomer} alt="customer illustration " />
      <div>
        <h1>To begin, select a customer</h1>
        <p>
          To view as a customer, select the name of the customer from the
          dropdown menu at the top left of the navigation.
        </p>
      </div>
    </div>
  );
}

export default SelectCustomerLanding;
