import { ControlField } from '@/shared/components/form-adapters';
import { AlertMessage, Button, Dropdown, TextInput } from '@cae/cobalt-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { useEditCompanyProfileMutation } from '../../api/useEditCompanyProfileMutation';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { useUserInfo } from '@/contexts/userInfo';
import { useEffect } from 'react';

import { useGetAuthoritiesQuery } from '@/features/documents/api';
import { CaeCustomerAuthority } from '@/open-api';
import {
  AuthoritiesFormSchema,
  FormValues,
} from '../../helpers/companyFormSchema.ts';

type Props = {
  readonly defaultValues: {
    authorities?: Array<CaeCustomerAuthority>;
    certCode?: string;
  };
  readonly handleClose: () => void;
};

export function CompanyAuthoritiesForm({
  defaultValues,
  handleClose,
}: Props): JSX.Element {
  const { data: authoritiesList = [] } = useGetAuthoritiesQuery();
  const authorityOptions = authoritiesList.map(a => ({
    label: a,
    value: a,
  }));
  const { selectedOrganization } = useUserInfo();

  const f = useForm<FormValues>({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(AuthoritiesFormSchema),
  });
  const { formState } = f;
  const { isValid, isDirty } = formState;
  const watchCompanyAuthorities = useWatch({
    control: f.control,
    name: 'authorities',
  });
  const watchCertType = useWatch({
    control: f.control,
    name: 'certCode',
  });
  const { mutate: editCompanyMutate, isLoading } =
    useEditCompanyProfileMutation({
      onSuccessCallback: () => {
        handleClose();
        toastMessages.success('Authorities edited successfully');
      },
      onErrorCallback: () => {
        handleClose();
        toastMessages.error('Error in updating authorities');
      },
    });
  useEffect(() => {
    if ((watchCompanyAuthorities || []).indexOf(CaeCustomerAuthority.FAA) < 0) {
      f.setValue('certCode', '');
    }
    if (watchCompanyAuthorities?.length === 0) {
      f.setError('authorities', { message: 'Select at least one authority' });
    } else {
      f.setError('authorities', { message: '' });
    }
  }, [f, watchCompanyAuthorities]);

  useEffect(() => {
    if (watchCertType && watchCertType.length > 10) {
      f.setError('certCode', { message: 'At most 10 characters' });
    } else if (!watchCertType) {
      f.setError('certCode', { message: 'Enter the value' });
    } else {
      f.setError('certCode', { message: '' });
    }
  }, [f, watchCertType]);

  const onSubmit = async (data: FormValues): Promise<void> => {
    const organizationId = selectedOrganization?.id ?? '';
    editCompanyMutate({ id: organizationId, data });
  };

  const handleConfirm = (): void => {
    f.handleSubmit(onSubmit)();
  };
  return (
    <form onSubmit={f.handleSubmit(onSubmit)} className="company-info">
      <h5>Company information</h5>
      <h1>Authorities</h1>
      <div className="__authorities">
        <ControlField
          is={Dropdown}
          id="field-authorities"
          data-testid="select-authorities"
          name="authorities"
          label="Authority name(s)"
          multiple
          options={authorityOptions}
          valueDisplay="chips"
          control={f.control}
        />
        {watchCompanyAuthorities?.includes(CaeCustomerAuthority.FAA) ? (
          <>
            <AlertMessage type="warning" heading="">
              <p>
                If you have FAA Part-135 training, please enter the Certificate
                Code below.
              </p>
            </AlertMessage>
            <ControlField
              is={TextInput}
              name="certCode"
              label="Certificate Code (optional)"
              control={f.control}
            />
          </>
        ) : null}
      </div>
      <div className="__footer">
        <Button
          type="button"
          disabled={isLoading || !isValid || !isDirty}
          variant="primary"
          onClick={handleConfirm}
          pending={isLoading}
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          disabled={isLoading}
          type="button"
          variant="secondary"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
