import './UsersListTable.css';
import { format } from 'date-fns';
import * as React from 'react';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import { UserResponse } from '@/open-api';
import { UserFilters } from '@/features/users/components/filter-modal/useFilterModalReducer';
import { useViewportSize } from '@/shared/hooks/useViewportSize';
import HasAccess from '@/shared/components/has-access/HasAccess';
import Documents from '@/assets/images/users/undraw_web_search_re_efla.svg';
import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';
import UsersListMobile from '../users-list-mobile-card/UsersListMobile';
import {
  type UserType,
  type UserSortingOptions,
  useGetUsersListQuery,
} from '../../api/useGetUsersListQuery';
import { CreateUser } from '../create-user/CreateUser';
import {
  CobaltTableWithSorting,
  ColumnSort,
  DataLoadingError,
  DataLoadingGrid,
  NoData,
} from '@/shared/components';
import { mapSorting } from '@/shared/helpers/mapSortingString.ts';

interface UsersListProps {
  readonly userType: UserType;
  readonly filters: UserFilters;
  readonly searchText: string;
}

const columns: ColumnDef<UserResponse>[] = [
  {
    header: 'Name',
    accessorKey: 'firstName',
    cell: info =>
      `${info.row.original.firstName} ${info.row.original.lastName}`,
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
  {
    header: 'Access granted',
    accessorKey: 'roleNames',
    enableSorting: false,
  },
  {
    header: 'Last login',
    accessorKey: 'lastLoggedInTime',
    cell: info =>
      info.getValue()
        ? format(new Date(Number(info.getValue())), 'dd-MMM-yyyy')
        : '-',
  },
  {
    header: 'Last modified',
    accessorKey: 'updatedDate',
    cell: info =>
      info.getValue()
        ? format(new Date(Number(info.getValue())), 'dd-MMM-yyyy')
        : '-',
  },
];

function UserNoData(): JSX.Element {
  return (
    <NoData
      image={<img src={Documents} alt="No data, man!" />}
      heading={'No results found'}
      message={
        'We couldn’t find what you searched for. Please retry or reload the page.'
      }
    />
  );
}

function UsersListTable({
  userType,
  filters,
  searchText,
}: UsersListProps): JSX.Element {
  const { width } = useViewportSize();

  const hasPermission = useHasPermission();
  const navigate = useNavigate();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const [sorting, setSorting] = React.useState<ColumnSort[] | []>([
    {
      id: 'updatedDate',
      desc: true,
    },
  ]);

  React.useEffect(() => {
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  }, [filters, searchText]);

  const { data: profile } = useGetProfileQuery();
  // To be discuss with Mateusz
  // const organizationId = userInfo?.selectedOrganization?.id;
  const organizationId = profile?.selectedOrganization;

  const { data, status: queryStatus } = useGetUsersListQuery(
    userType,
    filters,
    searchText,
    pageIndex,
    pageSize,
    organizationId,
    mapSorting<UserSortingOptions>(sorting)
  );
  const users = data?.items ? data.items : [];

  const openUsersDetails = (rowData: UserResponse): void => {
    if (hasPermission('modify_user')) {
      navigate(`/users/${rowData?.id}`);
    }
  };

  return (
    <div>
      {width < 768 ? (
        <>
          <UsersListMobile users={users} cardUserDetails={openUsersDetails} />
          <div className="create-btn-container">
            <div className="create-btn-container">
              <HasAccess permissions="add_user">
                <CreateUser />
              </HasAccess>
            </div>
          </div>
          {users.length === 0 && <UserNoData />}
        </>
      ) : (
        <>
          {queryStatus === 'loading' && (
            <DataLoadingGrid
              numberColumns={columns.length}
              numberRows={10}
              style={{ marginTop: '1rem' }}
            />
          )}
          {queryStatus === 'error' && <DataLoadingError />}
          {queryStatus === 'success' && users.length < 1 && <UserNoData />}
          {queryStatus === 'success' && users.length > 0 && (
            <CobaltTableWithSorting
              data={users}
              columns={columns}
              handleRowClick={openUsersDetails}
              pageIndex={pageIndex}
              pageSize={pageSize}
              paginationStyle={{
                backgroundColor: 'var(--white)',
                bottom: '0',
                position: 'sticky',
              }}
              setPagination={setPagination}
              setSorting={setSorting}
              defaultSortBy={sorting}
              enableMultiSort={false}
              totalPages={data?.totalPages ?? 1}
            />
          )}
        </>
      )}
    </div>
  );
}

export default UsersListTable;
