import { Card, Icon } from '@cae/cobalt-react';
import './WorkInfoTable.css';
import { useUserInfo } from '@/contexts/userInfo';
import { Outlet, useNavigate } from 'react-router-dom';

export function WorkInfoTable(): JSX.Element {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const handleClick = (orgName: string): void => {
    navigate('edit', { state: { orgName } });
  };
  if (!userInfo) return <></>;
  return (
    <div className="__cards-list">
      {userInfo?.organizations?.map(organization => (
        <Card
          onClick={() =>
            organization.name ? handleClick(organization.name) : null
          }
          key={userInfo.id}
        >
          <h3>{organization.name}</h3>
          <p>Title:</p>
          <Icon className="__right-icon" id="chevron-right-outline" size="sm" />
        </Card>
      ))}
      <Outlet />
    </div>
  );
}
