/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModuleRequest } from '../models/ModuleRequest';
import type { ModuleResponse } from '../models/ModuleResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ModuleManagementService {
  /**
   * @returns ModuleResponse OK
   * @throws ApiError
   */
  public static getModuleById({
    id,
  }: {
    id: string;
  }): CancelablePromise<ModuleResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/modules/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateModule({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: ModuleRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/modules/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteModule({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/modules/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ModuleResponse OK
   * @throws ApiError
   */
  public static getAllModules(): CancelablePromise<Array<ModuleResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/modules',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ModuleResponse Created
   * @throws ApiError
   */
  public static createModule({
    requestBody,
  }: {
    requestBody: ModuleRequest;
  }): CancelablePromise<ModuleResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/modules',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
