import * as React from 'react';
import { Button, Icon } from '@cae/cobalt-react';

type PaginationProps = React.ComponentPropsWithoutRef<'div'> & {
  getCanNextPage: () => boolean;
  getCanPreviousPage: () => boolean;
  maxPage: number;
  nextPage: React.MouseEventHandler<HTMLButtonElement>;
  options: number[];
  page: number;
  pageSize: number;
  previousPage: React.MouseEventHandler<HTMLButtonElement>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  style: React.CSSProperties;
};

export function Pagination({
  getCanNextPage,
  getCanPreviousPage,
  maxPage,
  nextPage,
  options = [10, 25, 50, 100],
  page = 1,
  pageSize = 10,
  previousPage,
  setPageIndex,
  setPageSize,
  style = {},
}: PaginationProps): JSX.Element {
  return (
    <div className="co-pagination" style={style}>
      <div className="co-pagination__page-size" style={{ marginRight: 'auto' }}>
        <p>Items per page:</p>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {options.map((o, i) => (
            <option value={o} key={`opt-${i}`}>
              {o}
            </option>
          ))}
        </select>
      </div>
      <div className="co-pagination__page-select">
        <select
          value={page}
          onChange={e => {
            const pageIndex = e.target.value ? Number(e.target.value) - 1 : 0;
            setPageIndex(pageIndex);
          }}
        >
          {[...Array(maxPage)].map((p, i) => (
            <option value={i + 1} key={`${p}-${i}`}>
              {i + 1}
            </option>
          ))}
        </select>
        <span>of {maxPage} pages</span>
      </div>
      <Button
        aria-label="Previous page"
        variant="ghost"
        onClick={previousPage}
        disabled={!getCanPreviousPage()}
      >
        <Icon id="chevron-left-outline" />
      </Button>
      <span className="divider" />
      <Button
        aria-label="Next page"
        variant="ghost"
        onClick={nextPage}
        disabled={!getCanNextPage()}
      >
        <Icon id="chevron-right-outline" />
      </Button>
    </div>
  );
}
