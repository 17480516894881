import {
  Button,
  Icon,
  Modal,
  ScreenContent,
  ScreenHeader,
} from '@cae/cobalt-react';

import './RecordsPage.css';
import { FilterModal } from '@/features/records/components/filter-modal/FilterModal';
import useRecordsFilter from '@/features/records/hooks/useRecordsFilter';
import * as React from 'react';
import { useRecordsMetadataQuery } from '@/features/records/api/useRecordsMetadataQuery';
import { RecordTabs, SearchRecordsInput } from '@/features/records';
import { Outlet } from 'react-router-dom';
import HasAccess from '@/shared/components/has-access/HasAccess';

export function RecordsPage(): JSX.Element {
  const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false);
  const toggleFiltersModal = (): void => setFiltersOpen(!filtersOpen);
  const { filters, resetFilters, toggleAllFilters, filterCounter } =
    useRecordsFilter();

  const { data: metadata = {}, isLoading } = useRecordsMetadataQuery();
  const { filterOptions = {} } = metadata;

  // Search
  const [needle, setNeedle] = React.useState<string>('');
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    setNeedle(value);
  };
  const isFilterApplied = filterCounter() > 0;
  return (
    <HasAccess permissions="view_records_list">
      <section className="page page-records">
        <div className="page-wrapper">
          <ScreenHeader elevation="skim">
            <hgroup>
              <h2>Records management</h2>
            </hgroup>
            <div className="toolbar">
              <SearchRecordsInput
                onChange={handleSearch}
                onClear={e => {
                  e.preventDefault();
                  setNeedle('');
                }}
                value={needle}
              />
              <Button
                className="btn-filters"
                type="button"
                variant="ghost"
                onClick={toggleFiltersModal}
                disabled={isLoading}
              >
                <Icon
                  id={isFilterApplied ? 'filter-solid' : 'filter-outline'}
                  size="sm"
                />
                <span className="label">Filters</span>
                {`${filterCounter() ? `(${filterCounter()})` : ''}`}
              </Button>
            </div>
          </ScreenHeader>
          <ScreenContent>
            <RecordTabs filters={filters} searchText={needle} />
          </ScreenContent>
          <Outlet />
        </div>

        <Modal
          open={filtersOpen}
          onCancel={toggleFiltersModal}
          placement="right"
          dismissible
          size="md"
          className="filter--dialog"
        >
          {filtersOpen && (
            <FilterModal
              filterOptions={filterOptions}
              filters={filters}
              resetFilters={resetFilters}
              toggleAllFilters={toggleAllFilters}
              toggleFilterSection={toggleFiltersModal}
            />
          )}
        </Modal>
      </section>
    </HasAccess>
  );
}
