import { Icon } from '@cae/cobalt-react';
import { useDocumentQuickLinksQuery } from '../../api/useDocumentQuickLinksQuery';
import { Link } from 'react-router-dom';

export function EasaProgramLinks(): JSX.Element {
  const { data } = useDocumentQuickLinksQuery();

  const easaLinks = data?.easa || [];

  return (
    <ul className="program-links">
      {easaLinks.map(d => (
        <li key={d.id}>
          <Link to={`/documents/cae`} state={{ parentFolderId: d.id }}>
            <Icon id="folder-solid" className="link-type folder" />
            <span>{d.name}</span>
            <Icon
              id="external-link-outline"
              className="link-destination"
              size="sm"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}

EasaProgramLinks.displayName = 'EasaProgramLinks';
