import './LoadingReservation.css';

export function LoadingReservation(): JSX.Element {
  return (
    <div className="loading-reservation">
      <div>
        <div className="animation">
          <div className="loader--9-squares" />
        </div>
        <h4>Loading reservation details</h4>
        <p>Just a moment!</p>
      </div>
    </div>
  );
}
