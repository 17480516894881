import {
  DataLoadingGrid,
  DataLoadingError,
  NoData,
  CobaltTable,
} from '@/shared/components';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import * as React from 'react';
import { QueryStatus } from '@tanstack/react-query';
import { InvoiceResponse } from '@/open-api';
import { allColumns, unpaidColumns } from './columns';
import { InvoicePaymentsStatus } from '../filter-modal/useFilterModalReducer';
import './InvoicepaymentstableListing.css';

interface InvoiceAndPaymentsTableProps {
  data: InvoiceResponse[];
  openDetails: (invoice: InvoiceResponse) => void;
  pageIndex: number;
  pageSize: number;
  invoiceType: InvoicePaymentsStatus;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  queryStatus: QueryStatus;
  totalPages: number;
}

type InvoiceType = 'All' | 'Unpaid';

const columns: Record<InvoiceType, ColumnDef<InvoiceResponse, string>[]> = {
  All: allColumns,
  Unpaid: unpaidColumns,
};

export function InvoiceAndPaymentsTable({
  data,
  openDetails,
  pageIndex,
  pageSize,
  invoiceType = 'All',
  setPagination,
  queryStatus,
  totalPages,
}: Readonly<InvoiceAndPaymentsTableProps>): JSX.Element {
  if (invoiceType === 'All' || invoiceType === 'Unpaid') {
    return (
      <>
        {queryStatus === 'loading' && (
          <DataLoadingGrid
            numberColumns={columns[invoiceType]?.length}
            numberRows={10}
            style={{ marginTop: '1rem' }}
          />
        )}
        {queryStatus === 'error' && <DataLoadingError />}
        {queryStatus === 'success' && data?.length < 1 && <NoData />}
        {queryStatus === 'success' && data?.length > 0 && (
          <CobaltTable
            className="invoice-and-payments--table"
            data={data}
            columns={columns[invoiceType]}
            handleRowClick={openDetails}
            sortable={true}
            pageIndex={pageIndex}
            pageSize={pageSize}
            paginationStyle={{
              backgroundColor: 'var(--white)',
              bottom: '0',
              position: 'sticky',
            }}
            setPagination={setPagination}
            totalPages={totalPages}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
}
