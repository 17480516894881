import { useNavigate, useParams } from 'react-router-dom';

export function EditChangeRequestForm(): JSX.Element {
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const handleChangeRequestClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate(`/users/${userId}/request`);
  };
  return (
    <p className="user-profile--children">
      In order to make changes to the information listed, please submit a{' '}
      <button
        className="anchor"
        type="button"
        data-action="swap-view"
        onClick={handleChangeRequestClick}
        data-view="change-request"
      >
        Change request
      </button>
    </p>
  );
}
