import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserResponse, UserManagementService } from '@/open-api';

export function useGetUserById(
  userId: string
): UseQueryResult<UserResponse, unknown> {
  return useQuery({
    queryKey: ['getUser'],
    queryFn: () => UserManagementService.getUserById({ id: userId }),
  });
}
