import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query';
import { UserManagementService, UserRequest } from '@/open-api';

type EditUserMutationCallback = {
  onSuccessCallback: () => void;
  onErrorCallback: () => void;
};

export const useEditUserMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: EditUserMutationCallback): UseMutationResult<
  unknown,
  unknown,
  { id: string; data: UserRequest },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, data }: { id: string; data: UserRequest }) => {
      const response = await UserManagementService.updateUser({
        id,
        requestBody: {
          ...data,
          selectedOrganization: data.selectedOrganization as string,
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['users'] });
        queryClient.invalidateQueries({ queryKey: ['usersDetails'] });

        onSuccessCallback();
      },
      onError: error => {
        onErrorCallback();
        throw error;
      },
    }
  );
};
