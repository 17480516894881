import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  PagedListOfUserResponse,
  UserManagementService,
  UserResponse,
} from '@/open-api';
import { UserFilters } from '@/features/users/components/filter-modal/useFilterModalReducer';
import useDebounce from '@/shared/hooks/useDebounce';

export type UserType = 'all' | 'active' | 'inactive';

export type UserSortingOptions =
  | '+firstName'
  | '+email'
  | '+lastLoggedInTime'
  | '+updatedDate'
  | '+roleNames'
  | '-firstName'
  | '-email'
  | '-lastLoggedInTime'
  | '-updatedDate'
  | '-roleNames';

export function useGetUsersListQuery(
  userListType: UserType,
  filters?: UserFilters,
  searchText: string = '',
  pageIndex: number = 1,
  pageSize: number = 10,
  organizationId: string = '',
  sorting?: Array<UserSortingOptions>
): UseQueryResult<PagedListOfUserResponse, Error> {
  const { role } = filters || {};

  const debouncedValue = useDebounce(searchText, 500);

  return useQuery<PagedListOfUserResponse, Error>({
    queryKey: [
      'users',
      userListType,
      pageIndex,
      pageSize,
      filters,
      debouncedValue,
      sorting,
    ],
    queryFn: () =>
      UserManagementService.getUsers({
        status: userListType,
        pageIndex,
        pageSize,
        sort: sorting,
        role,
        searchText: debouncedValue,
        organizationId,
      }),
  });
}

export function useGetUsersDetailsQuery(
  userId: string
): UseQueryResult<UserResponse, Error> {
  return useQuery<UserResponse, Error>({
    queryKey: ['usersDetails', userId],
    queryFn: () => UserManagementService.getUserById({ id: userId }),
  });
}
