import {
  UserManagementService,
  UserSyncRequest,
  UserSyncResponse,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

interface UsePostSFConnectQueryProps {
  userSalesforceContact: string;
  customerSalesforceId: string;
}

export function usePostSFConnectQuery({
  userSalesforceContact,
  customerSalesforceId,
}: UsePostSFConnectQueryProps): UseQueryResult<UserSyncResponse, unknown> {
  const requestBody: { requestBody: UserSyncRequest } = {
    requestBody: { userSalesforceContact, customerSalesforceId },
  };

  return useQuery({
    queryKey: ['syncSFDetails'],
    queryFn: () => UserManagementService.syncUser(requestBody),
  });
}
