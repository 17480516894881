import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  InstructorManagementService,
  PagedListOfInstructorProgramDto,
} from '@/open-api';
import useDebounce from '@/shared/hooks/useDebounce.ts';

export function useInstructorProgramQuery({
  trainingCenterId,
  pageIndex,
  pageSize,
  searchText,
}: {
  trainingCenterId: string;
  pageIndex: number;
  pageSize: number;
  searchText: string;
}): UseQueryResult<PagedListOfInstructorProgramDto, Error> {
  const debouncedValue = useDebounce(searchText, 800);

  return useQuery({
    queryKey: [
      'instructor',
      { pageIndex },
      { pageSize },
      { trainingCenterId },
      'programItems',
      debouncedValue,
    ],
    queryFn: () =>
      InstructorManagementService.getInstructorPrograms({
        trainingCenterId,
        pageIndex,
        pageSize,
        searchText: debouncedValue,
      }),
  });
}
