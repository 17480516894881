import { useParams, Link } from 'react-router-dom';
import { Card, CardHeader } from '@cae/cobalt-react';

import { useAlertDetailsByTypeQuery } from '@/features/reservations/api/useAlertDetailsByTypeQuery';

function getDescription(
  alertType: string,
  numberOfAlerts: number | undefined
): string {
  const numberToDisplay = numberOfAlerts ? numberOfAlerts.toString() : '';

  const descriptions: { [name: string]: string } = {
    etca: `You have ${numberToDisplay} reservations missing eTCAs with training starting in the next 21 days."`,
    tsa: `You have ${numberToDisplay} reservations missing TSAs with training starting in the next 45 days.`,
    provisional: `You have ${numberToDisplay} provisional reservations at risk with auto-cancel in less than 30 days.`,
  };
  return descriptions[alertType];
}

function renderMessage(dateOfTraining: string): '' | JSX.Element {
  let message = '';
  const today = new Date();
  const diffInMs = Date.parse(dateOfTraining) - today.getTime();
  const diffInDays = Math.ceil(diffInMs / (1000 * 3600 * 24));

  if (diffInDays >= 0) message = `Training starts in ${diffInDays} days`;
  else message = 'Training is already overdue';

  return (
    dateOfTraining && (
      <div className="alert-card-footer">
        <div className="alert-card-footer">{message}</div>
      </div>
    )
  );
}

export function ReservationsAlertDetailsPage(): JSX.Element {
  const { type } = useParams();
  const { data, status } = useAlertDetailsByTypeQuery(type as string);
  return (
    <aside className="reservations--alert-details">
      {status === 'loading' && <p>Loading alerts...</p>}
      {status === 'error' && <p>Error loading alerts</p>}
      {status === 'success' && data.length && (
        <>
          <h3>Your Alerts</h3>
          {type && <p>{getDescription(type, data.length)}</p>}
          <div className="scroll-container">
            {data.map(alert => {
              return (
                <Link
                  to={`/reservations/${alert.reservationId}`}
                  key={alert.reservationId}
                  state={{ pathname: location.pathname }}
                >
                  <Card>
                    <CardHeader>
                      <hgroup>
                        <h5>{alert.courseName}</h5>
                        <h4>{alert.clientName}</h4>
                      </hgroup>
                      <svg
                        height="12"
                        width="12"
                        className="alert-danger-circle"
                      >
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          fill="var(--danger-color)"
                        />
                      </svg>
                    </CardHeader>
                    <p>{renderMessage(alert.startDate!)}</p>
                  </Card>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </aside>
  );
}

ReservationsAlertDetailsPage.displayName = 'ReservationsAlertDetailsPage';
