import { Button, Icon } from '@cae/cobalt-react';
import './UserForbidden.css';
import Logo from '../../../../assets/images/cae-logo.svg';
import handleHelpButtonClick from '@/shared/helpers/help-button/helpButtonClick';
import { t } from 'i18next';
import { useGetCurrentOrganization } from '@/features/frame/api/useGetCurrentOrganization';

function UserForbidden(): JSX.Element {
  const { handleLogout } = useGetCurrentOrganization();
  const handleLogoutClick = (): void => {
    const broadcastChannel = new BroadcastChannel('logout-channel');
    broadcastChannel.postMessage({ type: 'logout' });

    handleLogout('redirect');
  };

  return (
    <div className="user-forbidden">
      <img className="user-forbidden--logo" src={Logo} alt="cae-logo" />
      <div className="user-forbidden--header">
        <Button variant="transparent" onClick={handleHelpButtonClick}>
          <Icon id="help-circle-outline" size="sm" />
          {t('common.help')}
        </Button>
      </div>
      <div className="user-forbidden--name">
        <span
          style={{
            fontFamily: 'var(--font-family-display)',
            fontSize: '48px',
          }}
        >
          CAE{' '}
        </span>
        <span
          style={{
            fontFamily: 'var(--font-family-display)',
            fontWeight: 'bold',
            fontSize: '48px',
          }}
        >
          Connect
        </span>
      </div>
      <div className="user-forbidden--row">
        <div>
          <Icon
            id="warning-triangle-solid"
            size="md"
            style={{ color: 'var(--danger-color)' }}
          />
        </div>
        <h2 className="user-forbidden--cae">
          You don&apos;t have access to CAE Connect.
        </h2>
        <p>
          Please communicate with your
          <br />
          Account Executive
        </p>
        <Button
          variant="ghost"
          onClick={handleLogoutClick}
          tabIndex={0}
          data-toggle-menu="true"
        >
          <Icon id="log-out-outline" size="sm" />
          Logout
        </Button>
      </div>
      <p className="user-forbidden--footer">
        Copyright ©2024, CAE Inc. All rights reserved.
      </p>
    </div>
  );
}

export default UserForbidden;
