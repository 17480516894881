import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { RecordManagementService, RecordsMetadata } from '@/open-api';
import { format } from 'date-fns';
import { RecordsFilters } from '../components/filter-modal/useFilterModalReducer';

export function useRecordsMetadataQuery(
  filters?: RecordsFilters
): UseQueryResult<RecordsMetadata, Error> {
  const { locations, platforms, dateRange } = filters || {};

  return useQuery<RecordsMetadata, Error>({
    queryKey: ['getRecordsCount', filters],
    queryFn: () =>
      RecordManagementService.getRecordsMetadata({
        location: locations,
        platform: platforms,
        endDateAfter:
          dateRange && dateRange[0]
            ? format(dateRange[0], 'yyyy-MM-dd')
            : undefined,
        endDateBefore:
          dateRange && dateRange[1]
            ? format(dateRange[1], 'yyyy-MM-dd')
            : undefined,
      }),
  });
}
