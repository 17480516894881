import { useParams, Link } from 'react-router-dom';
import HasAccess from '@/shared/components/has-access/HasAccess.tsx';
import { ScreenHeader, ScreenContent } from '@cae/cobalt-react';
import './InstructorsPage.css';
import { useProgramMetadataQuery } from '@/features/instructor-management/api/useProgramMetadataQuery.ts';
import { DataLoadingError, NoData, DataLoadingGrid } from '@/shared/components';
import { ProgramTabs } from '@/features/instructor-management/components/program-tabs/ProgamTabs.tsx';

export function ProgramDetailsPage(): JSX.Element {
  const { programId = '' } = useParams<{ programId: string }>();
  const {
    data: metadata = {},
    isSuccess,
    isError,
    isLoading,
    isFetching,
  } = useProgramMetadataQuery(programId);
  const { instructorCountPerLocation = [], program = '' } = metadata;

  if (isLoading || isFetching) {
    return (
      <DataLoadingGrid
        numberColumns={10}
        numberRows={10}
        style={{ marginTop: '1rem' }}
      />
    );
  }

  if (isError) {
    return <DataLoadingError />;
  }

  if (isSuccess && !metadata?.program) {
    return <NoData />;
  }

  return (
    <HasAccess permissions="view_instructor_list">
      <section className="page page-instructor-management">
        <div className="page-wrapper">
          <nav className="program-details__breadcrumbs">
            <Link className="parent-link" to={'/instructors'}>
              Instructor Management /
            </Link>
            <Link
              className="child-link"
              to={`/instructors/program-details/${programId}`}
            >
              &nbsp;
              {program}
            </Link>
          </nav>
          <ScreenHeader elevation="base">
            <hgroup>
              <h2>{program}</h2>
            </hgroup>
          </ScreenHeader>
          <ScreenContent>
            <ProgramTabs data={instructorCountPerLocation} program={program} />
          </ScreenContent>
        </div>
      </section>
    </HasAccess>
  );
}
