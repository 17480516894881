import { useUserInfo } from '@/contexts/userInfo';
import { Card, Icon } from '@cae/cobalt-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';

const fixAuthorities = (authorities?: string[]): string => {
  if (!authorities || authorities.length === 0) {
    return '-';
  } else if (authorities.length <= 3) {
    return authorities.join(', ');
  } else {
    const slicedAuthorities = authorities.slice(0, 3);
    return `${slicedAuthorities.join(', ')}, (+${authorities.length - 3})`;
  }
};

export function CompanyInformation(): JSX.Element {
  const navigate = useNavigate();
  const { selectedOrganization } = useUserInfo();
  const { data: { operatingRegion, authorities } = {}, isLoading } =
    useCompanyProfileByOrganizationIdQuery(selectedOrganization?.id);
  const currentRoles = useCurrentRoles();

  const openAuthoritiesRegion = (route: string): void => {
    if (currentRoles && checkRoleMatch(currentRoles, 'Admin')) {
      navigate(route);
    }
  };

  return (
    <div className="company-contact-info">
      <h1 className="__company-heading">Company information</h1>
      {authorities || operatingRegion ? (
        <div className="__cards-list">
          <Card onClick={() => openAuthoritiesRegion('authorities')}>
            <h3>Authorities</h3>
            <p>{!isLoading && fixAuthorities(authorities)}</p>
            <Icon
              className="__right-icon"
              id="chevron-right-outline"
              size="sm"
            />
          </Card>
          <Card onClick={() => openAuthoritiesRegion('region')}>
            <h3>Operating region</h3>
            <p>{!isLoading && operatingRegion}</p>
            <Icon
              className="__right-icon"
              id="chevron-right-outline"
              size="sm"
            />
          </Card>
        </div>
      ) : (
        <p>Profile information not available</p>
      )}
      <Outlet />
    </div>
  );
}

export default CompanyInformation;
