import { InvoicePaymentsFilters } from '../components/filter-modal/useFilterModalReducer';

export const enum Actions {
  resetFilter = 'reset-filters',
  toggleAllFilters = 'toggle-fleets-bases-ranks',
}

type ResetFilterAction = {
  type: Actions.resetFilter;
};

type ToggleAllFiltersAction = {
  type: Actions.toggleAllFilters;
  payload: InvoicePaymentsFilters;
};

type InvoicePaymentsFilterActions = ToggleAllFiltersAction | ResetFilterAction;

export function filtersReducer(
  state: InvoicePaymentsFilters,
  action: InvoicePaymentsFilterActions
): InvoicePaymentsFilters {
  switch (action.type) {
    case Actions.toggleAllFilters:
      return {
        ...state,
        invoiceDateRange: action.payload.invoiceDateRange,
        trainingDateRange: action.payload.trainingDateRange,
        currency: action.payload.currency,
        platform: action.payload.platform,
        location: action.payload.location,
        status: action.payload.status,
      };
    case Actions.resetFilter:
      return {
        invoiceDateRange: [],
        trainingDateRange: [],
        currency: [],
        platform: [],
        location: [],
        status: [],
      };
    default:
      return state;
  }
}
