import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dropdown, TextInput } from '@cae/cobalt-react';

import { ACCEPTED_FILETYPE_MIMETYPE_MAPPING } from '../submit-documents-form/constants';

import { FileUpload } from '@/shared/components/file-upload/FileUpload';
import { SupportedFilesDialog } from '../submit-documents-form/SupportedFilesDialog';
import { LeaveCheck } from './LeaveCheck';
import { SubmitCheck } from './SubmitCheck';

import {
  CaeDocumentUploadAuthority,
  type DocumentUploadRequest,
  type UploadResult,
} from '@/open-api';
import type { PostResponseStatus } from '@/shared/types';

import './SubmitDocumentsLongForm.css';

type AuthorityOptions = {
  label: string;
  value: CaeDocumentUploadAuthority;
};

export const AUTHORITY_OPTIONS: AuthorityOptions[] = [
  { label: 'EASA', value: CaeDocumentUploadAuthority.EASA },
  { label: 'FAA', value: CaeDocumentUploadAuthority.FAA },
  { label: 'Other NAA', value: CaeDocumentUploadAuthority.OTHER_NAA },
];

const FormSchema = z
  .object({
    authority: z.string().min(1, { message: 'Authority is required' }),
    notes: z.string().max(1000, { message: 'Max. 1000 characters' }),
  })
  .refine(data => data.notes.length > 0 || data.authority === 'FAA', {
    message: 'Notes are required',
    path: ['notes'],
  });

export type FormValues = {
  authority: CaeDocumentUploadAuthority;
  notes: string;
};

export interface Props {
  maxFiles?: number;
  maxFileSize?: number;
  maxSize?: number;
  onCancel: (e: React.SyntheticEvent<HTMLDialogElement | Element>) => void;
  onSubmit: (data: DocumentUploadRequest) => void;
  status: PostResponseStatus;
  isLoading?: boolean;
  uploadResponse?: UploadResult[];
}

export function SubmitDocumentsLongForm({
  maxFiles = 10,
  maxFileSize,
  maxSize = 500,
  onCancel,
  onSubmit,
  status = { type: 'idle' },
  isLoading = false,
  uploadResponse,
}: Readonly<Props>): JSX.Element {
  const [files, setFiles] = React.useState<File[]>([]);
  const [authorityConfirmed, setAuthorityConfirmed] = React.useState<
    boolean | undefined
  >(undefined);
  const [leaveConfirmed, setLeaveConfirmed] = React.useState<
    boolean | undefined
  >();
  const [submitConfirmed, setSubmitConfirmed] = React.useState<boolean>(false);

  const {
    trigger,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isValid, touchedFields },
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      authority: undefined,
      notes: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(FormSchema),
  });

  React.useEffect(() => {
    if (authorityConfirmed === false) {
      setSubmitConfirmed(true);
    } else {
      setSubmitConfirmed(false);
    }
  }, [authorityConfirmed]);

  const watchedAuthority = watch('authority');

  const onFilesChange = (files: File[]): void => {
    setFiles(files);
  };

  const handleCancelClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (
      (!isDirty && files.length < 1) ||
      leaveConfirmed ||
      status.type === 'success'
    ) {
      onCancel(e);
    } else {
      setLeaveConfirmed(false);
    }
  };

  const handleSubmitClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    trigger();
    if (!isValid) {
      e.preventDefault();
    } else if (authorityConfirmed === undefined) {
      e.preventDefault();
      setAuthorityConfirmed(false);
    }
  };

  const submitForm = (data: FormValues): void => {
    if (typeof onSubmit === 'function') {
      onSubmit({
        ...data,
        files,
        shouldAddCustomerAuthority: true,
      });
    }
  };

  const submitDisabled =
    !isDirty ||
    files.length < 1 ||
    isSubmitting ||
    status.type === 'pending' ||
    status.type === 'success' ||
    uploadResponse?.every(result => result.success);

  const tryAgainSubmitLabel = uploadResponse?.some(result => !result.success)
    ? 'Try again'
    : 'Submit';
  const submitLabel =
    status.type === 'pending' ? 'Submitting' : tryAgainSubmitLabel;

  return (
    <form
      action="."
      autoComplete="off"
      className="submit-documents long"
      encType="multipart/form-data"
      method="post"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <fieldset>
        {status.type !== 'success' && (
          <Controller
            control={control}
            name="authority"
            render={({ field }) => (
              <Dropdown
                onChange={() => {
                  clearErrors();
                }}
                emptyText="Select an authority"
                field={{
                  ...field,
                  disabled: status.type === 'success',
                }}
                label="What authority are these documents associated with?"
                meta={{
                  error: (errors.authority?.message as string) || '',
                  touched: !!touchedFields.authority,
                }}
                options={AUTHORITY_OPTIONS}
              />
            )}
          />
        )}
        {status.type !== 'success' && (
          <>
            <h4>Upload file</h4>
            <p>Total upload size should not exceed {maxSize}MB.</p>
            <SupportedFilesDialog
              className="supported-file-types"
              options={ACCEPTED_FILETYPE_MIMETYPE_MAPPING.map(x => x[0])}
            />
          </>
        )}

        <FileUpload
          disabled={status.type === 'success'}
          onFilesChange={onFilesChange}
          maxFiles={maxFiles}
          maxFileSize={maxFileSize}
          maxSize={maxSize}
          variant="dnd"
          onLoading={isLoading}
          uploadResponse={uploadResponse}
        />

        <h4>Why are you submitting these files?</h4>
        <Controller
          control={control}
          name="notes"
          render={({ field }) => (
            <TextInput
              field={{
                id: 'submit-documents-note',
                ...field,
                disabled: status.type === 'success',
              }}
              label="Notes"
              maxChars={1000}
              onChange={() => {
                trigger();
              }}
              meta={{
                error: (errors.notes?.message as string) || '',
                touched: true,
              }}
              multiline
            />
          )}
        />
      </fieldset>

      <LeaveCheck
        open={leaveConfirmed === false}
        onCancel={(e: React.SyntheticEvent) => {
          e.preventDefault();
          setLeaveConfirmed(undefined);
        }}
        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          onCancel(e);
        }}
      />

      <SubmitCheck
        authority={watchedAuthority}
        files={files}
        open={submitConfirmed && authorityConfirmed === false}
        setConfirmed={setAuthorityConfirmed}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
      />

      <div className="form-actions">
        <Button
          id="submit-documents-form--cancel"
          type="button"
          variant="secondary"
          onClick={handleCancelClick}
        >
          {uploadResponse?.every(result => result.success) ? `Close` : `Cancel`}
        </Button>
        <Button
          id="submit-documents-form--submit"
          type="submit"
          variant="primary"
          disabled={submitDisabled}
          pending={status.type === 'pending'}
          onClick={handleSubmitClick}
        >
          {submitLabel}
        </Button>
      </div>
    </form>
  );
}

SubmitDocumentsLongForm.displayName = 'SubmitDocumentsLongForm';
