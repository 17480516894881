import * as React from 'react';
import { Button, ButtonVariant, Icon } from '@cae/cobalt-react';
import Spinner from '@/shared/components/file-upload/spinner/Spinner';

type DownloadButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  label?: string;
  buttonType?: ButtonVariant;
};

export function DownloadButton({
  onClick,
  loading,
  label,
  buttonType = 'icon',
}: DownloadButtonProps): JSX.Element {
  return (
    <Button
      type="button"
      variant={buttonType}
      size="md"
      onClick={onClick}
      disabled={loading}
      data-testid="shared-download-button"
    >
      {label}
      {loading ? (
        <Spinner />
      ) : (
        <Icon
          id="download-outline"
          size="md"
          data-testid="icon-download-outline"
        />
      )}
    </Button>
  );
}
