export const COURSE_TYPE = ['Initial', 'Recurrent', 'Upgrade', 'Other'];

export const ETCA_STATUS = ['Sent', 'NotSent', 'NotApplicable', 'Optional'];

export const TSA_STATUS = [
  'Approved',
  'WaitPeriodApproved',
  'Other',
  'NotApplicable',
];

export const SCHEDULED_STATUS = ['Available', 'NotAvailable'];

export const RECORDS = [
  'Available',
  'HardCopyOnly',
  'Pending',
  'NotApplicable',
];
