import { Button, Checkbox, Modal } from '@cae/cobalt-react';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import './RedirectDialog.css';

interface RedirectDialogProps {
  readonly title: string;
  readonly setIsDialogOpen: (isOpen: boolean) => void;
  readonly isDialogOpen: boolean;
  readonly redirectLink: string;
  readonly identifier: string;
}

export function RedirectDialog({
  title,
  setIsDialogOpen,
  isDialogOpen,
  redirectLink,
  identifier,
}: Readonly<RedirectDialogProps>): JSX.Element {
  const [counter, setCounter] = useState(3);
  const [donotShowAgain, setDonotShowAgain] = useState<boolean>(
    localStorage.getItem(`dontShowAgain_${identifier}`) === 'true'
  );

  const handleRedirect = useCallback(() => {
    window.open(redirectLink, '_blank');
    setIsDialogOpen(false);
  }, [redirectLink, setIsDialogOpen]);

  const handleCloseModal = (e: SyntheticEvent): void => {
    e.stopPropagation();
    setIsDialogOpen(false);
  };

  const handleCheckboxSelected = (): void => {
    localStorage.setItem(`dontShowAgain_${identifier}`, 'true');
    setDonotShowAgain(!donotShowAgain);
  };

  useEffect(() => {
    if (!donotShowAgain && isDialogOpen) {
      setCounter(3);
      const intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isDialogOpen, donotShowAgain]);

  useEffect(() => {
    if (counter === 0) {
      handleRedirect();
    }
  }, [counter, handleRedirect]);

  useEffect(() => {
    if (
      localStorage.getItem(`dontShowAgain_${identifier}`) === 'true' &&
      donotShowAgain &&
      isDialogOpen
    ) {
      const timeoutId = setTimeout(() => {
        handleRedirect();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [identifier, handleRedirect, donotShowAgain, isDialogOpen]);

  return (
    <Modal
      animate
      className="redirect-modal"
      header={<h4>{title}</h4>}
      placement="center"
      open={isDialogOpen && !donotShowAgain}
      onCancel={handleCloseModal}
      size="sm"
    >
      {isDialogOpen && !donotShowAgain && (
        <div className="redirect-modal-container">
          <p>{`Opening in ${counter}...`}</p>

          <span className="redirect-modal-container__actions">
            <Button
              onClick={handleCloseModal}
              variant="secondary"
              type="button"
            >
              Cancel
            </Button>
            <Checkbox
              field={{
                id: 'redirect-do-not-show-checkbox',
                checked: donotShowAgain,
                onChange: () => {
                  handleCheckboxSelected();
                },
              }}
              label="Do not show again"
            />
          </span>
        </div>
      )}
    </Modal>
  );
}

RedirectDialog.displayName = 'RedirectDialog';
