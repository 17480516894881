import { Button, Icon, Modal } from '@cae/cobalt-react';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import PasswordResetContent from './PasswordResetContent';
import './PasswordReset.css';
import { resetPasswordRedirect } from '../../helpers/resetPasswordRedirect';

function PasswordReset(): JSX.Element {
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const { instance: msalInstance } = useMsal();

  const openPasswordReset = (): void => {
    if (localStorage.getItem('password-reset') === 'true') {
      resetPasswordRedirect(msalInstance);
    } else setResetPasswordDialogOpen(true);
  };

  return (
    <div>
      <div className="reset-password_margin">
        <p className="subheading">Password</p>
        <p className="reset-password_description">
          To reset your global CAE Account password. Click the link below and
          follow the steps.
        </p>
      </div>
      <Button
        type="button"
        variant="ghost"
        size="md"
        onClick={openPasswordReset}
        className="reset-password_button"
      >
        Reset Password
        <Icon id="external-link-outline" size="sm" />
      </Button>

      <Modal
        animate
        className="reset-password-modal"
        header={<h3>On your way to reset password</h3>}
        placement="center"
        open={resetPasswordDialogOpen}
        onCancel={() => setResetPasswordDialogOpen(false)}
        size="sm"
      >
        {resetPasswordDialogOpen && (
          <PasswordResetContent
            setResetPasswordDialogOpen={setResetPasswordDialogOpen}
            msalInstance={msalInstance}
          />
        )}
      </Modal>
    </div>
  );
}

export default PasswordReset;
