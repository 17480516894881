import { format, parseISO } from 'date-fns';
import { Icon, Tag } from '@cae/cobalt-react';

import { ReservationResponseDto } from '@/open-api';

export const iconMapping: {
  [key: string]: string;
} = {
  neutral: 'remove-outline',
  neutralPending: 'circle-dotted-outline',
  none: 'none',
  warning: 'warning-circle-solid',
  danger: 'warning-triangle-solid',
  success: 'checkmark-circle-solid',
};

export const statusColors: {
  [key: string]: string;
} = {
  neutral: 'standard',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
  pending: 'yellow',
  unknown: 'standard',
};

export function ReservationIdPart({ data }: { data: string }): JSX.Element {
  return (
    <>
      <dt>Reservation</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

export function PlatformPart({ data }: { data: string }): JSX.Element {
  return (
    <>
      <dt>Platform</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

export function CoursePart({ data }: { data: string }): JSX.Element {
  return (
    <>
      <dt>Course</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

export function StartDatePart({ data }: { data: string }): JSX.Element {
  return (
    <>
      <dt>Start date</dt>
      <dd>{data ? format(parseISO(data), 'dd-MMM-yyyy') : ''}</dd>
    </>
  );
}

export function EndDatePart({ data }: { data: string }): JSX.Element {
  return (
    <>
      <dt>End date</dt>
      <dd>{data ? format(parseISO(data), 'dd-MMM-yyyy') : ''}</dd>
    </>
  );
}

export function LocationPart({ data }: { data: string }): JSX.Element {
  return (
    <>
      <dt>Location</dt>
      <dd>{data ?? ''}</dd>
    </>
  );
}

export function StatusPart({
  data,
}: {
  data: ReservationResponseDto['reservationStatus'];
}): JSX.Element | null {
  return data ? (
    <Tag
      className="reservations-status"
      color={
        statusColors[data.style || 'neutral'] as
          | 'standard'
          | 'green'
          | 'red'
          | 'yellow'
      }
      compact
    >
      {data.message}
    </Tag>
  ) : null;
}

export function AutoCancellationPart({
  data,
}: {
  data: ReservationResponseDto['autoCancellation'];
}): JSX.Element {
  return (
    <>
      <dt>eTCA</dt>
      <dd className="status">
        {data ? (
          <>
            <Icon
              id={iconMapping[data?.style || 'neutral']}
              className={data?.style}
              size="sm"
            />
            {data?.daysToAutoCancellation}
          </>
        ) : null}
      </dd>
    </>
  );
}

export function EtcaPart({
  data = {},
}: {
  data: ReservationResponseDto['etca'];
}): JSX.Element {
  return (
    <>
      <dt>eTCA</dt>
      <dd className="status">
        {data ? (
          <>
            <Icon
              id={iconMapping[data?.style || 'neutral']}
              className={data?.style}
              size="sm"
            />
            {data?.message}
          </>
        ) : null}
      </dd>
    </>
  );
}

export function SchedulePart({
  data = {},
}: {
  data: ReservationResponseDto['schedule'];
}): JSX.Element {
  return (
    <>
      <dt>Schedule</dt>
      <dd className="status">
        {data ? (
          <>
            <Icon
              id={iconMapping[data?.style || 'neutral']}
              className={data?.style}
              size="sm"
            />
            {data?.message}
          </>
        ) : null}
      </dd>
    </>
  );
}

export function TsaPart({
  data = {},
}: {
  data: ReservationResponseDto['tsa'];
}): JSX.Element {
  return (
    <>
      <dt>TSA</dt>
      <dd className="status">
        {data ? (
          <>
            <Icon
              id={iconMapping[data?.style || 'neutral']}
              className={data?.style}
              size="sm"
            />
            {data?.message}
          </>
        ) : null}
      </dd>
    </>
  );
}

export function TrainingRecordsPart({
  data = {},
}: {
  data: ReservationResponseDto['records'];
}): JSX.Element {
  return (
    <>
      <dt>Records</dt>
      <dd className="status">
        {data ? (
          <>
            <Icon
              id={iconMapping[data?.style || 'neutral']}
              className={data?.style}
              size="sm"
            />
            {data?.message}
          </>
        ) : null}
      </dd>
    </>
  );
}
