import { useUserInfo } from '@/contexts/userInfo';
import { CaeCustomerAuthority, CaeDocumentUploadAuthority } from '@/open-api';
import { AlertMessage, Button, Icon, Modal } from '@cae/cobalt-react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { FormValues } from './SubmitDocumentsLongForm';

function CheckHeader({ files = [] }: Readonly<{ files: File[] }>): JSX.Element {
  return (
    <h2>
      Confirm that {files.length} document{files.length < 2 ? '' : 's'} you are
      uploading belong{files.length < 2 ? 's' : ''} to the selected authority:
    </h2>
  );
}

function CheckBody({
  authority,
  selectedAuthorities = [],
  files = [],
}: Readonly<{
  authority: CaeDocumentUploadAuthority;
  selectedAuthorities: CaeCustomerAuthority[];
  files: File[];
}>): JSX.Element {
  const shouldDisplayAlert =
    !selectedAuthorities.includes(
      authority as unknown as CaeCustomerAuthority
    ) && authority !== CaeDocumentUploadAuthority.OTHER_NAA;

  return (
    <div>
      <h6>Selected authority:</h6>
      <p className="authority-value">{authority}</p>
      {shouldDisplayAlert && (
        <AlertMessage className="authority-alert" type="warning" heading="">
          <p>
            {`You are submitting documents for ${authority}, however it is not listed as an existing authority. By confirming, you will add this authority.`}
          </p>
        </AlertMessage>
      )}
      <h6>Documents:</h6>
      <ul className="file-listing">
        {files.map(f => (
          <li key={`${f.name}`}>
            <Icon id="document-outline" />
            {f.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

interface CheckActionsProps {
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

function CheckActions({
  handleCancel,
  handleConfirm,
}: Readonly<CheckActionsProps>): JSX.Element {
  return (
    <>
      <Button
        id="submit-documents-form--submit-check--cancel"
        type="button"
        variant="secondary"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        id="submit-documents-form--submit-check--confirm"
        type="button"
        variant="primary"
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </>
  );
}

interface SubmitCheckProps {
  authority: CaeDocumentUploadAuthority;
  files: File[];
  open?: boolean;
  setConfirmed: (v: boolean | undefined) => void;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  submitForm: (data: FormValues) => void;
}

export function SubmitCheck({
  authority,
  files = [],
  open = false,
  setConfirmed,
  handleSubmit,
  submitForm,
}: Readonly<SubmitCheckProps>): JSX.Element {
  const { authorities: selectedAuthorities } = useUserInfo();

  const handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setConfirmed(undefined);
  };

  const handleConfirm = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setConfirmed(true);
    handleSubmit(submitForm)();
  };

  return (
    <Modal
      actions={CheckActions}
      className="submit-check"
      dismissible={false}
      id="submit-documents-form--submit-check"
      open={open}
      header={<CheckHeader files={files} />}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      placement="center"
      size="md"
    >
      <CheckBody
        authority={authority}
        selectedAuthorities={selectedAuthorities}
        files={files}
      />
    </Modal>
  );
}

SubmitCheck.displayName = 'SubmitCheck';
