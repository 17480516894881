import * as React from 'react';

interface ActiveDropZoneProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onKeyDown: React.KeyboardEventHandler;
  onDragEnter: React.DragEventHandler<HTMLButtonElement>;
  onDragLeave: React.DragEventHandler<HTMLButtonElement>;
  onDragOver: React.DragEventHandler<HTMLButtonElement>;
  onDrop: React.DragEventHandler<HTMLButtonElement>;
}

export const ActiveDropZone = React.forwardRef<
  HTMLButtonElement,
  ActiveDropZoneProps
>(
  (
    {
      label,
      onClick,
      onKeyDown,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
    }: ActiveDropZoneProps,
    ref
  ) => {
    return (
      <button
        type="button"
        className="drop-zone"
        onClick={onClick}
        onKeyDown={onKeyDown}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        ref={ref}
        tabIndex={0}
      >
        {label}
      </button>
    );
  }
);

ActiveDropZone.displayName = 'ActiveDropZone';

export function InactiveDropZone({
  label,
  disabled,
}: {
  label: string;
  disabled: boolean;
}): JSX.Element | null {
  if (disabled) {
    return null;
  }

  return <div className="drop-zone disabled">{label}</div>;
}

InactiveDropZone.displayName = 'InactiveDropZone';
