import { Link } from 'react-router-dom';
import { Icon } from '@cae/cobalt-react';

import { useSelectBasicInfo } from '../../api/hooks';

interface Props {
  recordId: string;
}

export function RecordBackNavigation({
  recordId = '',
}: Props): null | JSX.Element {
  const { data } = useSelectBasicInfo(recordId);
  if (data?.recordId === '') return null;

  return (
    <nav>
      <Link to={`/records/${recordId}`}>
        <Icon id="arrow-left-solid" size="xs" />
        {data?.clientName || 'Overview'} (Record #{recordId})
      </Link>
    </nav>
  );
}
