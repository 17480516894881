import { Outlet, useNavigate } from 'react-router-dom';

import { InstructorsScheduleMfe } from '@/features/instructors-schedule-mfe';
import './ScheduleMfePage.css';
import HasAccess from '@/shared/components/has-access/HasAccess';
import { useUserInfo } from '@/contexts/userInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { useEffect } from 'react';

export function ScheduleMfePage(): JSX.Element {
  const navigate = useNavigate();
  const currentRoles = useCurrentRoles();
  const { isInternalUser } = useUserInfo();
  const { instructormyschedule } = useFlags();
  useEffect(() => {
    if (
      !isInternalUser ||
      !instructormyschedule ||
      !checkRoleMatch(currentRoles, 'CAE Instructor')
    ) {
      navigate('/home');
    }
  }, [isInternalUser, instructormyschedule, currentRoles, navigate]);

  return (
    <HasAccess permissions="view_schedule">
      <InstructorsScheduleMfe />
      <Outlet />
    </HasAccess>
  );
}
