import { UserPermissions } from '@/open-api';
import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';

type Permissions = {
  permissions: string;
  renderAuthFailed?: React.ReactNode;
  children?: React.ReactNode;
};
function HasAccess({
  permissions,
  renderAuthFailed = null,
  children,
}: Permissions): JSX.Element {
  const profileQuery = useGetProfileQuery();
  const currentPermission =
    profileQuery?.data &&
    profileQuery?.data?.userPermissions &&
    profileQuery?.data?.userPermissions.filter(
      (_permission: UserPermissions) =>
        _permission.taskValue === permissions && _permission.enable === true
    );
  if (currentPermission && currentPermission.length > 0) {
    return <>{children}</>;
  }

  return <>{renderAuthFailed}</>;
}
export type CheckPermissionsProps = {
  permissions: string;
};

export default HasAccess;
