import { useGetReservationDetailsQuery } from './useGetReservationDetails';
import {
  selectBasicInfo,
  selectQuickInfo,
  selectLocationCourseSchedule,
  selectStatusInfo,
  selectRelatedReservations,
  selectContactInfo,
} from './selectors';
import { UseQueryResult } from '@tanstack/react-query';
import {
  RelatedReservation,
  ReservationBasicInfo,
  ReservationCourse,
  ReservationQuickInfo,
  ReservationTrainingLocation,
  StatusInfo,
  TrainingSchedule,
} from '../types';
import { CustomerDto, ReservationDetailsResponse } from '@/open-api';

/**
 *
 * @param id {string} Reservation ID
 * @returns
 */
export const useSelectBasicInfo = (
  id: string
): UseQueryResult<ReservationBasicInfo, unknown> => {
  return useGetReservationDetailsQuery(id, data => selectBasicInfo(data));
};

/**
 *
 * @param id {string} Reservation ID
 * @returns
 */
export const useSelectQuickInfo = (
  id: string
): UseQueryResult<ReservationQuickInfo, unknown> => {
  return useGetReservationDetailsQuery(id, data => selectQuickInfo(data));
};

/**
 *
 * @param id {string} Reservation ID
 * @returns
 */
export const useSelectStatusInfo = (
  id: string
): UseQueryResult<StatusInfo, unknown> => {
  return useGetReservationDetailsQuery(id, data => selectStatusInfo(data));
};

type QueryResult = {
  course: ReservationCourse;
  trainingLocation: ReservationTrainingLocation;
  schedule: TrainingSchedule;
  status: '' | ReservationDetailsResponse.status;
};
/**
 * Selects course, location and schedule information from
 * fetched response payload.
 *
 * @param id {string} Reservation ID
 * @returns
 */
export const useSelectLocationCourseSchedule = (
  id: string
): UseQueryResult<QueryResult, unknown> => {
  return useGetReservationDetailsQuery(id, data =>
    selectLocationCourseSchedule(data)
  );
};

/**
 *
 * @param id {string} Reservation Id
 * @returns
 */
export const useSelectRelatedReservations = (
  id: string
): UseQueryResult<[] | RelatedReservation[], unknown> => {
  return useGetReservationDetailsQuery(id, data =>
    selectRelatedReservations(data)
  );
};

/**
 *
 * @param id {string} Reservation Id
 * @returns
 */
export const useSelectContactInfo = (
  id: string
): UseQueryResult<Required<CustomerDto>, unknown> => {
  return useGetReservationDetailsQuery(id, data => selectContactInfo(data));
};
