import { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  Dropdown,
  Icon,
  NumberInput,
  TextInput,
} from '@cae/cobalt-react';
import './CompanyFleetForm.css';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useUserInfo } from '@/contexts/userInfo';
import { useCompanyProfileByOrganizationIdQuery } from '../../api/useCompanyProfileByOrganizationIdQuery';
import { companyFleetSchema } from '../../helpers/companyFormSchema';
import { useEditCompanyProfileMutation } from '../../api/useEditCompanyProfileMutation';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { OrganizationProfileUpdateRequest, Platform } from '@/open-api';
import { useGetVehiclesListQuery } from '../../api/useGetVehiclesListQuery';

type CompanyFleetValues = {
  vehicleTypeId: string;
  numberOfAircraft: string;
  tailNumbers: { tailNumber: string }[];
};
export type companyFleetSchema = z.infer<typeof companyFleetSchema>;

function CompanyFleetForm(): JSX.Element {
  const [addAircraft, setAddAircraft] = useState(false);
  const [counter, setCounter] = useState('1');
  const [isValidFleet, setIsValidFleet] = useState(true);
  const [isAircrafts, setIsAircrafts] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { idx } = location.state;
  const { selectedOrganization } = useUserInfo();
  const { data: vehiclesList } = useGetVehiclesListQuery();
  const { data } = useCompanyProfileByOrganizationIdQuery(
    selectedOrganization?.id
  );
  const listOfAircraft: { label: string; value: string }[] = vehiclesList
    ? vehiclesList.map(v => ({
        label: v.typeDesignator as string,
        value: v.typeDesignator as string,
      }))
    : [];
  const fleetData: Platform = data?.platforms?.[idx] || {
    vehicleTypeId: '',
    numberOfAircraft: '',
    tailNumbers: [],
  };
  const { id, vehicleTypeId, numberOfAircraft, tailNumbers } = fleetData;
  const convertedTailNumbers = tailNumbers.map((tailNumber, index) => ({
    id: `tailNumber${index + 1}`,
    tailNumber,
  }));
  const aircrafts = Number(numberOfAircraft);
  const [totalNumberOfFields, setTotalNumberOfFields] = useState(
    aircrafts || 0
  );
  const [selectedFleet, setSelectedFleet] = useState<string>(vehicleTypeId);
  const dialogRef = useRef<HTMLDialogElement>(null);

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
    control,
    setValue,
  } = useForm<CompanyFleetValues>({
    defaultValues: {
      vehicleTypeId: vehicleTypeId,
      numberOfAircraft: numberOfAircraft,
      tailNumbers: convertedTailNumbers,
    },
    mode: 'onChange',
    resolver: zodResolver(companyFleetSchema),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'tailNumbers',
  });
  useEffect(() => {
    setValue('numberOfAircraft', String(totalNumberOfFields));
  }, [totalNumberOfFields, setValue]);

  useEffect(() => {
    const handleClickOutside = (
      e: HTMLElementEventMap['click'] | HTMLElementEventMap['touchstart']
    ): void => {
      const dialogElement = dialogRef.current;
      if (dialogElement && !dialogElement.contains(e.target as Node)) {
        setAddAircraft(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const selectedFleetData = data?.platforms?.find(
      platform => platform.vehicleTypeId === selectedFleet
    );
    if (selectedFleetData) {
      const updatedTailNumbers = selectedFleetData.tailNumbers.map(
        (tailNumber, index) => ({
          id: `tailNumber${index + 1}`,
          tailNumber,
        })
      );
      setTotalNumberOfFields(updatedTailNumbers.length);
      replace(updatedTailNumbers);
    } else {
      setTotalNumberOfFields(0);
      replace([]);
    }
  }, [selectedFleet, isAircrafts, replace, data?.platforms]);

  useEffect(() => {
    if (!isValidFleet) {
      replace([]);
      setTotalNumberOfFields(0);
      setValue('numberOfAircraft', '0');
    }
  }, [isValidFleet, replace, setValue]);

  const handleClose = (): void => {
    navigate(-1);
  };

  const handleButton = (): void => {
    setAddAircraft(!addAircraft);
  };

  const handleAdd = (): void => {
    const newTailNumbers = Array.from(
      { length: Number(counter) },
      (_, index) => ({
        id: `tailNumber${totalNumberOfFields + index + 1}`,
        tailNumber: '',
      })
    );
    append(newTailNumbers);
    setTotalNumberOfFields(
      prevInitialNumberOfFields => prevInitialNumberOfFields + Number(counter)
    );
    setAddAircraft(!addAircraft);
    setCounter('1');
  };

  const handleDelete = (index: number): void => {
    remove(index);
    setTotalNumberOfFields(
      prevInitialNumberOfFields => prevInitialNumberOfFields - 1
    );
  };
  const { mutate: editCompanyMutate, isLoading } =
    useEditCompanyProfileMutation({
      onSuccessCallback: () => {
        handleClose();
        toastMessages.success('Platform edited successfully');
      },
      onErrorCallback: () => {
        handleClose();
        toastMessages.error('Error in updating number');
      },
    });
  const onSubmit = async (data: CompanyFleetValues): Promise<void> => {
    const organizationId = selectedOrganization?.id ?? '';

    const transformedData: OrganizationProfileUpdateRequest = {
      platform: {
        tailNumbers: data.tailNumbers.map(item => item.tailNumber),
        vehicleTypeId: data.vehicleTypeId,
        numberOfAircraft: data.numberOfAircraft,
        ...(id && { id: id }),
      },
    };
    editCompanyMutate({ id: organizationId, data: transformedData });
  };

  const handleConfirm = (): void => {
    handleSubmit(onSubmit)();
  };

  function handleCounter(value: string): void {
    const numericValue = Number(value);
    if (numericValue >= 1) {
      setCounter(value);
    }
  }

  return (
    <form className="company-fleet" onSubmit={handleSubmit(onSubmit)}>
      <h5>Fleet information</h5>
      <div className="search-platform">
        <Controller
          control={control}
          name="vehicleTypeId"
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <Dropdown
              className="dropdown"
              emptyText="Select platform"
              field={{
                ...field,
                disabled: !!vehicleTypeId,
                onChange: e => {
                  field.onChange(e);
                  setSelectedFleet(e.target.value);
                  setIsValidFleet(true);
                },
              }}
              onFilterChange={e => {
                const selectedValue = e.target.value;
                setIsAircrafts(
                  selectedValue === '' ||
                    listOfAircraft.some(item => item.value === selectedValue)
                );
                setIsValidFleet(isAircrafts);
                if (isAircrafts) {
                  setValue('vehicleTypeId', selectedValue);
                }
              }}
              filter
              meta={{
                dirty: isDirty,
                error: error?.message ?? '',
                touched: isTouched,
                valid: !invalid && isValidFleet,
              }}
              options={listOfAircraft}
              orientation="vertical"
              size="md"
              valueDisplay="basic"
            />
          )}
        />
        {!isValidFleet && (
          <span style={{ color: 'red' }}>Select a valid platform</span>
        )}
      </div>
      <div>
        <h3 data-testid="aircrafts-in-fleet">
          Aircrafts in fleet ({totalNumberOfFields})
        </h3>
        <Button
          className="btn-add"
          type="button"
          variant="ghost"
          onClick={handleButton}
        >
          <Icon id="add-outline" size="sm" />
          <span className="label">Add aircrafts</span>
        </Button>
      </div>
      <div className="dialog-overlay">
        <Dialog
          className=""
          placement="top"
          size="sm"
          open={addAircraft}
          ref={dialogRef}
        >
          <div className="number-input">
            <NumberInput
              className="number-input__actions"
              field={{
                disabled: false,
                name: 'aircrafts',
                onBlur: () => {},
                onChange: e => handleCounter(e.target.value),
                onFocus: () => {},
                placeholder: 'aircrafts',
                required: true,
                size: 3,
                step: 1,
                value: counter,
              }}
              size="sm"
              label="How many aircrafts to add?"
              meta={{
                active: false,
                touched: false,
              }}
            />
            <Button onClick={handleAdd} variant="secondary" type="button">
              Add
            </Button>
          </div>
        </Dialog>
      </div>
      <div className="__fields">
        {fields.map((field, index) => (
          <div className="text-field" key={field.id}>
            <Controller
              name={`tailNumbers.${index}.tailNumber`}
              control={control}
              render={({
                field: tailNumberField,
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <TextInput
                  key={field.id}
                  className="text-input"
                  label={`Tail number (optional)`}
                  field={{
                    ...tailNumberField,
                    name: 'tailNumbers',
                    placeholder: 'Enter aircraft ID',
                  }}
                  meta={{
                    dirty: isDirty,
                    error: error?.message ?? '',
                    touched: isTouched,
                    valid: !invalid,
                  }}
                />
              )}
            />
            <Button
              variant="transparent"
              className="__trash-icon"
              onClick={() => handleDelete(index)}
            >
              <Icon id="trash-outline" size="sm" />
            </Button>
          </div>
        ))}
      </div>
      <div className="__footer">
        <Button
          type="submit"
          variant="primary"
          onClick={handleConfirm}
          disabled={
            !isDirty || !isValid || isSubmitting || isLoading || !isValidFleet
          }
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          type="button"
          variant="ghost"
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}

export default CompanyFleetForm;
