import * as React from 'react';
import { Button, CheckboxGroup, TextInput } from '@cae/cobalt-react';
import './FilterSection.css';

export type FilterSectionProps<T> = {
  className?: string;
  options?: string[];
  label: string;
  selected: T[];
  searchPlaceholder?: string;
  onSelect: (arg: T) => void;
  onClear?: () => void;
  showSearch?: boolean;
};

export function FilterSection<T extends string>({
  className = '',
  options = [],
  label,
  selected,
  searchPlaceholder = 'Search',
  onSelect,
  onClear,
  showSearch = true,
}: FilterSectionProps<T>): JSX.Element | null {
  const [search, setSearch] = React.useState<string>('');
  const [showAll, setShowAll] = React.useState<boolean>(false);

  if (options.length === 0) {
    return null;
  }

  const items = options
    ?.filter(option =>
      search ? option.toLowerCase().includes(search.toLowerCase()) : option
    )
    .map(option => {
      return {
        label: option.replace(/([a-z])([A-Z])/g, '$1 $2'),
        value: option,
      };
    });

  return (
    <section className={`filter-section ${className}`}>
      <header>
        <h4>{label}</h4>
        {selected.length > 0 ? (
          <span className="count">({selected.length} selected)</span>
        ) : null}
      </header>
      {typeof onClear === 'function' && (
        <Button
          variant="transparent"
          className="deselect-button"
          onClick={onClear}
          disabled={selected.length === 0}
        >
          Deselect All
        </Button>
      )}
      {showSearch && items && options?.length > 15 && (
        <TextInput
          field={{
            placeholder: searchPlaceholder,
            value: search,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(event.target.value),
          }}
          className="search-input"
        />
      )}
      {items && items.length > 0 ? (
        <CheckboxGroup
          field={{
            name: label,
            onChange: e => onSelect(e.target.value as T),
            value: selected,
          }}
          options={showAll ? items : items?.slice(0, 5)}
          className="checkbox-group"
        />
      ) : (
        <span>No filters to display</span>
      )}
      {items && items.length > 5 && (
        <Button
          variant="transparent"
          onClick={() => setShowAll(prevState => !prevState)}
        >
          {`${showAll ? 'Show Less' : 'Show All'}`}
        </Button>
      )}
    </section>
  );
}

FilterSection.displayName = 'FilterSection';
